<template>
  <div v-if="!loading" style="position: relative;">
    <b-dropdown
      style="position: absolute; top: 10px; right: 10px;"
      v-if="isMissionControlSession && auditsDropdownItems.length"
      aria-role="list"
    >
      <template #trigger>
        <b-button
          icon-right="angle-down">
          Showing: <span class="font-weight-bold">{{auditsDropdownItems?.filter(item => item.key === activeAuditId)?.[0]?.label || '-'}}</span>
        </b-button>
      </template>
      <b-dropdown-item v-for="item in auditsDropdownItems" :key="item.key" @click="activeAuditId = item.key">{{ item.label }}</b-dropdown-item>
    </b-dropdown>
    <single-audit-view
      :key="activeAudit?.id"
      :showOrderNewAuditButton="activeAudit && !activeAudit.showNewAuditPurchaseView && activeAudit.id === audits[0].id"
      :site="site"
      :activeAudit="activeAudit"
      :contentAuditPackages="contentAuditPackages"
      :loadData="loadData"
      @auditUpdated="audits = audits.map(audit => audit.id === $event.id ? $event : audit)"
    />
  </div>
</template>

<script>
import SingleAuditView from './SingleAuditView.vue'


export default {
  components: { SingleAuditView },
  props: ['site'],
  data () {
    return {
      loading: true,
      audits: [],
      activeAuditId: null,
      contentAuditPackages: null,
    }
  },
  computed: {
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return null
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    isMissionControlSession () {
      return this.$store.state.selectedSubAccountRelation?.isMissionControlSubAccount
    },
    activeAudit () {
      if (this.activeAuditId) {
        return this.audits.filter(audit => audit.id === this.activeAuditId)?.[0]
      }
      return null
    },
    showNewAuditPurchaseView() {
      return !this.audits?.length || this.audits?.[0]?.showNewAuditPurchaseView
    },
    auditsDropdownItems () {
      return [
        ...this.showNewAuditPurchaseView
          ?[
            {
              key: null,
              label: 'New audit'
            }
          ]:[],
        ...this.audits.map((audit) => ({
          key: audit.id,
          label: `Audit ${audit.id}`
        }))
      ]
    }
  },
  watch: {
    'site.id': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.loadData(true)
        }
      },
      immediate: true,
    },
  },
  methods: {
    loadData (resetActiveAuditId = false) {
      return this.$store.dispatch('getSiteContentAudits', {
        siteId: this.site.id,
      })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.audits = response.data.data
            if (resetActiveAuditId) {
              const lastAudit = this.audits?.[0]
              if (lastAudit.showNewAuditPurchaseView) {
                this.activeAuditId = null
              } else {
                this.activeAuditId = lastAudit?.id
              }
            }
            return Promise.resolve()
          }
        })
        .then(() => {
          return this.$store.dispatch('getAvailableWhiteGloveAddonPackages')
            .then(response => {
              if (response.data && response.data.status == 'SUCCESS') {
                this.contentAuditPackages = Object.fromEntries(
                  response.data.packages
                    .filter(p => ["WHITE_GLOVE_ADDON_CONTENT_AUDIT", "WHITE_GLOVE_ADDON_CONTENT_AUDIT_SPEEDY_DELIVERY"].includes(p.key))
                    .map(p => [p.key, p])
                );
                return Promise.resolve()
              }
            })
        })
        .finally(() => {
          this.loading = false;
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
