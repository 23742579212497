<template>

  <div v-if="campaign">

    <!-- Tabs -->
    <div class="mb-4">
      <div class="card-big-title">
        Keyword Strategy
        <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
          @click="() => {
            $store.commit('setCurrentVideoTutorialToDisplay', {
              title: 'How to Use the Keyword Strategy Tool',
              videoLink: 'https://www.youtube.com/embed/rkd9oZKph-E',
              description: 'In this video, you\'ll get a step-by-step walkthrough of how to use POP\'s Keyword Strategy tool. The Keyword Strategy tool is perfect for starting your research from scratch. This tool lets you enter up to 10 target keywords or topics, along with up to 20 competitor URLs, to generate clusters of high-quality keywords based on Google suggestions, People Also Ask, and related terms—all backed by keyword data and enhanced by AI.',
              time: '8m 05s'
            });
          }">
          videocam
        </span>
      </div>
      <div v-if="grantedTabKeys.length > 1" class="mt-6 mb-6 keyword-strategy-tab-group">
        <v-btn-toggle>
            <v-btn
              v-if="grantedTabKeys.includes('new-content')"
              :to="`/sites/${siteId}/campaign/${campaignId}/keyword-wizard/new-content`"
            >
              New content
            </v-btn>
            <template
              v-if="grantedTabKeys.includes('existing-content')"
            >
              <v-btn
                :to="`/sites/${siteId}/campaign/${campaignId}/keyword-wizard/existing-content`"
              >
                Existing content
              </v-btn>
            </template>
        </v-btn-toggle>
      </div>
    </div>

    <!-- View -->
    <router-view
      :campaignId="campaignId"
      :hasUnlimitedAccess="hasUnlimitedAccess"
    ></router-view>

  </div>

</template>

<script>
import { getPureServicePkgName } from '@/utils'
import { SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS, SUBSCRIPTIONS_WITH_UNLIMITED_ACCESS_TO_KEYWORD_RESEARCH_TOOLS } from "@/components/plans_constants";

const ALL_TAB_KEYS = [
  'new-content',
  'existing-content',
]

export default {
  props: ['hasUnlimitedAccess'],
  computed: {
    config() {
      return this.$store.getters.insightKeywordsConfig;
    },
    campaignId () {
      return parseInt(this.$route.params.campaignId || 0)
    },
    siteId () {
      return parseInt(this.$route.params.siteId || 0)
    },
    campaign () {
      return this.$store.state.currentCampaign
    },
    currentUser () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return {}
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    isSpecialOwnerSelected () {
      return this.$store.getters.isSpecialOwnerSelected
    },
    grantedTabKeys () {
      if (this.selectedSubAccountRelation) {
        const grantedKeywordWizardTools = this.selectedSubAccountRelation.grantedDevModeTools.filter(key => key.startsWith('keyword-wizard'))
        if (this.isSpecialOwnerSelected || grantedKeywordWizardTools?.includes?.('keyword-wizard')) {
          return ALL_TAB_KEYS
        }
        return ALL_TAB_KEYS.filter(k => {
          if (k === 'new-content' && SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS.map(x => getPureServicePkgName(x)).includes(getPureServicePkgName(this.selectedSubAccountRelation.ownerAccountType))) {
            return true
          }
          if (k === 'existing-content' && SUBSCRIPTIONS_WITH_UNLIMITED_ACCESS_TO_KEYWORD_RESEARCH_TOOLS.map(x => getPureServicePkgName(x)).includes(getPureServicePkgName(this.selectedSubAccountRelation.ownerAccountType))) {
            return true
          }
          return grantedKeywordWizardTools.map(key => key.split('/')[1]).includes(k)
        })
      }
      if (this.currentUser) {
        const grantedKeywordWizardTools = this.currentUser.grantedDevModeTools.filter(key => key.startsWith('keyword-wizard'))
        if (this.isSpecialAdminUser || grantedKeywordWizardTools?.includes?.('keyword-wizard')) {
          return ALL_TAB_KEYS
        }
        return ALL_TAB_KEYS.filter(k => {
          if (k === 'new-content' && SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS.map(x => getPureServicePkgName(x)).includes(getPureServicePkgName(this.currentUser.accountType))) {
            return true
          }
          if (k === 'existing-content' && SUBSCRIPTIONS_WITH_UNLIMITED_ACCESS_TO_KEYWORD_RESEARCH_TOOLS.map(x => getPureServicePkgName(x)).includes(getPureServicePkgName(this.currentUser.accountType))) {
            return true
          }
          return grantedKeywordWizardTools.map(key => key.split('/')[1]).includes(k)
        })
      }
      return []
    },
  },
  watch: {
    campaignId: function (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.getCampaignById()
      }
    },
  },
  methods: {
    getCampaignById () {
      this.$store.commit('showLoading')
      return this.$store.dispatch('getCampaignById', this.campaignId)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            let campaign = response.data.campaign
            this.$store.commit('setCurrentCampaign', campaign)
            return Promise.resolve()
          }
          return Promise.reject('Invalid response')
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
  },
  mounted () {
    // Try to match route to a granted tab key
    const matchedRoutesNames = this.$route.matched.map(matchedRoute => matchedRoute.name)
    const matchedGrantedTab = this.grantedTabKeys.filter(key => matchedRoutesNames.includes(key))[0]

    // If no granted tab was matched
    if (!matchedGrantedTab) {
      if (this.grantedTabKeys.length) {
        // Redirect to first granted tab
        this.$router.push(`/sites/${this.siteId}/campaign/${this.campaignId}/keyword-wizard/${this.grantedTabKeys[0]}`)
      } else {
        // Or redirect back to site view
        this.$router.push(`/sites/${this.siteId}/tools`)
      }
    }

    // Arriving here, means all is good and we do not need to redirect, so we load data and continue
    this.getCampaignById()
  }
}
</script>

<style scoped lang="scss">
@import "../keyword-research.scss";

.white-glove-site-content-audit-oboarding-tip {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
</style>

<style lang="scss">
.keyword-strategy-tab-group {
  
  // NOTE: .v-btn class is repeated to give the css rule more weight
  .v-btn.v-btn.v-btn.v-btn {
    text-transform: none;
    border-color: #CCCCCC !important;
    background-color: var(--v-cardBg-base) !important;
    height: 38px !important;

    color: var(--v-mainColor-base) !important;

    &.v-btn--active {
      border: 1.11px solid #FF5700 !important;
      background: #FFEDDD !important;
      color: #014751 !important;
      box-shadow: none !important;
      &::before {
        background: none !important;
      }
    }

    .v-btn__content {
      font-size: 0.95rem !important;
      font-weight: 500 !important;
      letter-spacing: normal;
    }
  }
}
</style>