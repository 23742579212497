<template>
  <div class="container-launch px-4 py-8 mt-10" style="flex: 1; max-width: 1150px;">
    <div class="flex flex-column justify-space-between width704">
      <div
        class="flex"
        style="gap: 20px;"
      >
        <div class="step-control">
          <v-btn
            :style="currentStep < 2 || currentStep > STEPS.length ? 'visibility: hidden;':''"
            icon
            outlined
            @click="currentStep -= 1"
          >
            <img src="/static/orange_caret_left_icon.svg" width="12" /> 
          </v-btn>
        </div>

        <div
          v-if="activeAudit && currentStep === 5"
          class="onboarding-completed"
        >
          <img src="/static/whote_glove_site_content_audit_onboarding_completed.png" />
          <div class="mainColor--text" style="font-weight: 800; font-size: 1.4rem; line-height: 1.5;">
            Onboarding completed <i class="material-icons font-weight-bold" style="color: #0CB370; font-size: 1.5rem; vertical-align: sub;">check</i>
            <div class="mt-2" style="font-weight: normal; font-size: 0.9rem; line-height: 1.5;">
              You're all set! Your onboarding is complete. If you want to run another audit for this site in a few months, you can order a new audit whenever you're ready.
            </div>
            <div class="d-flex align-center justify-space-between mt-4">

              <div>
                <v-btn
                  v-if="showOrderNewAuditButton"
                  rounded
                  color="anchor1Color"
                  class="button-with-icon text-none whiteColor--text padding8 height38"
                  tag="a"
                  :loading="newAuditOrderLoading"
                  @click="newAuditOrder();"
                >
                  Order new audit
                </v-btn>
              </div>

              <b-dropdown
                v-if="isMissionControlSession"
                aria-role="list"
              >
                <template #trigger>
                  <b-button
                    :label="getFulfillementStatusLabel(activeAudit.fulfillementStatus)"
                    icon-right="angle-down" />
                </template>
                <b-dropdown-item v-if="activeAudit.fulfillementStatus !== 'pending'" aria-role="listitem" @click="updateAudit({set_pending: true})">{{ getFulfillementStatusLabel('pending')}}</b-dropdown-item>
                <b-dropdown-item v-if="activeAudit.fulfillementStatus !== 'in_progress'" aria-role="listitem" @click="updateAudit({set_in_progress: true})">{{ getFulfillementStatusLabel('in_progress')}}</b-dropdown-item>
                <b-dropdown-item v-if="activeAudit.fulfillementStatus !== 'completed'" aria-role="listitem" @click="updateAudit({set_completed: true})">{{ getFulfillementStatusLabel('completed')}}</b-dropdown-item>
              </b-dropdown>

            </div>
          </div>
        </div>
        
        <div v-else class="flex flex-column justify-space-between" style="flex: 1;">

        
          <div class="flex" style="align-items: center;">
            <img src="/static/thunder_icon.svg" width="12" />
            <div class="ml-2 mainColor--text font-weight-bold" style="flex: 1;">Complete your White Glove services onboarding</div>
          </div>
    
          <div class="steps-status mt-4 mb-8">
            <div
              v-for="(step, idx) in STEPS"
              :key="idx"
              :class="{
                'step-item': true,
                'active': currentStep === (idx + 1),
                'completed': isStepValid(idx + 1)
              }"
            >
              <div class="step-dot">
                <i class="material-icons">check</i>
              </div>
              <div class="step-label">
                {{ step.label }}
              </div>
              <div class="step-seperator" v-if="idx < (STEPS.length - 1)"></div>
            </div>
          </div>
    
          <div
            v-if="currentStep === 1"
            :class="{
              'step-content': true,
              'completed': isStepValid(currentStep)
            }"
          >
            <div class="step-text step-text-number mb-3">Step {{currentStep}}</div>
            <div class="step-text step-text-title mb-2">Buy content audit for this site</div>
            <div class="step-text step-text-description mb-4">
              Buy a full website content audit and EEAT analysis with recommendations and a project plan.
            </div>
            <v-btn
              v-if="isStepValid(currentStep)"
              rounded
              color="anchor1Color"
              class="success-disabled-btn button-with-icon text-none padding8 height38"
              tag="a"
              :disabled="true"
              @click=""
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">task_alt</i>
              Audit purchased
            </v-btn>

            <div
              v-else
            >
              <div v-if="!(!isMissionControlSession && site.stripe_hosted_invoice_url)" class="mb-4">
                <div class="font-weight-bold mainColor--text mb-4">Select when you want to receive your audit</div>

                <div v-if="contentAuditPackages" style="display: flex; flex-direction: row; align-items: center; gap: 12px;">

                  <div
                    :class="{
                      'package-option': true,
                      'package-option-active': step1SelectedPackage === 'WHITE_GLOVE_ADDON_CONTENT_AUDIT'
                    }"
                    @click="step1SelectedPackage = 'WHITE_GLOVE_ADDON_CONTENT_AUDIT'"
                  >
                    <div class="package-option-dot"></div>
                    <div>
                      <div class="font-weight-bold mb-1" style="font-size: 0.9rem;">${{ contentAuditPackages['WHITE_GLOVE_ADDON_CONTENT_AUDIT'].price }} • Standard delivery</div>
                      <div>Content audit delivered in 5 business days</div>
                    </div>
                  </div>

                  <div
                    :class="{
                      'package-option': true,
                      'package-option-active': step1SelectedPackage === 'WHITE_GLOVE_ADDON_CONTENT_AUDIT_SPEEDY_DELIVERY'
                    }"
                    @click="step1SelectedPackage = 'WHITE_GLOVE_ADDON_CONTENT_AUDIT_SPEEDY_DELIVERY'"
                  >
                    <div class="package-option-dot"></div>
                    <div>
                      <div class="font-weight-bold mb-1" style="font-size: 0.9rem;">${{ contentAuditPackages['WHITE_GLOVE_ADDON_CONTENT_AUDIT_SPEEDY_DELIVERY'].price }} • Speedy delivery</div>
                      <div>Content audit delivered in 72 hours</div>
                    </div>
                  </div>

                </div>
              </div>

              <div
                style="display: flex; flex-direction: row; align-items: center; gap: 12px;"
              >

                <v-btn
                  v-if="isMissionControlSession"
                  rounded
                  color="anchor1Color"
                  class="button-with-icon text-none whiteColor--text padding8 height38"
                  tag="a"
                  @click="buyNow('invoice');"
                >
                  <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">shopping_basket</i>
                  Send invoice to user (${{ finalPrice }})
                </v-btn>
                <template v-else>
                  <v-btn
                    v-if="site.stripe_hosted_invoice_url"
                    rounded
                    color="anchor1Color"
                    class="button-with-icon text-none whiteColor--text padding8 height38"
                    tag="a"
                    :href="site.stripe_hosted_invoice_url"
                    target="_blank"
                  >
                    <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">shopping_basket</i>
                    Pay your invoice (${{ site.stripe_hosted_invoice_amount }})
                  </v-btn>
                  <v-btn
                    v-else
                    rounded
                    color="anchor1Color"
                    class="button-with-icon text-none whiteColor--text padding8 height38"
                    tag="a"
                    @click="buyNow();"
                  >
                    <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">shopping_basket</i>
                    Buy now for ${{ finalPrice }}
                  </v-btn>
                </template>

                <template v-if="!(!isMissionControlSession && site.stripe_hosted_invoice_url)">
                  <span
                    v-if="appliedCoupon"
                    style="color: #0CB370;"
                  ><i class="material-icons" style="vertical-align: sub;">check</i> Coupon applied ({{ appliedCoupon.coupon.couponType === 'AmountOff' ? '$':'' }}{{ appliedCoupon.coupon.amountOff }}{{ appliedCoupon.coupon.couponType === 'Percent Off' ? '%':'' }} off)</span>
                  <a
                    v-else
                    href="#" @click.prevent="showCouponModal = true"
                    style="color: var(--v-link15Color-base);"
                  >Apply a coupon</a>
                </template>

                <div class="modal" :class="{'is-active': showCouponModal}">
                  <div class="modal-background" @click.stop="showCouponModal = false"></div>
                  <div class="modal-content">
                    <div class="box modal-container">
                      <div class="modal-content-root">
                        <p class="title-font is-size-4 mainColor--text">Apply coupon</p>
                        <div class="mainColor--text" style="font-size: 1rem; line-height: 150%;">
                          Insert your coupon here for a discount<br /><br />
                          <v-text-field outlined dense
                            v-model="modalCouponFieldValue"
                            placeholder="Enter coupon"
                            color="grayColor"
                          ></v-text-field>
                        </div>
                        <div class="d-flex justify-end gap10 mt-5">
                          <v-btn rounded outlined color="mainColor"
                            class="text-none"
                            @click.stop="() => {
                              showCouponModal = false;
                            }">
                            <span>Cancel</span>
                          </v-btn>
                          <v-btn rounded color="profileAnchorColor"
                            class="whiteColor--text text-none"
                            :disabled="!modalCouponFieldValue"
                            @click.stop="() => {
                              applyCoupon();
                            }"
                          >
                            <span>Apply coupon</span>
                          </v-btn>
                          
                        </div>
                      </div>
                    </div>
                    <button
                      class="custom-modal-close-btn"
                      aria-label="close"
                      @click="() => (showCouponModal = false)">
                      <i class="material-icons">close</i>
                    </button>
                  </div>
                </div>

              </div>

            </div>
          </div>
    
          <div
            v-if="activeAudit && currentStep === 2"
            :class="{
              'step-content': true,
              'completed': isStepValid(currentStep)
            }"
          >
            <div class="step-text step-text-number mb-3">Step {{currentStep}}</div>
            <div class="step-text step-text-title mb-2">Tell us more about your site</div>
            <div class="step-text step-text-description mb-4">
              Fill out the form so that we have all details about your site. Once you complete the form, you will receive the copy of your replies to your POP email address.
            </div>
            <div
              v-if="isStepValid(currentStep)"
              style="display: flex; flex-direction: row; gap:12px; align-items: center;"
            >
              <v-btn
                rounded
                color="anchor1Color"
                class="success-disabled-btn button-with-icon text-none padding8 height38"
                tag="a"
                :disabled="true"
                @click=""
              >
                <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">task_alt</i>
                Form filled
              </v-btn>
              <a href="#"
                class="mainColor--text font-weight-bold"
                style="text-decoration: underline;"
                @click.prevent.stop="() => {
                  showFormModal = true
                }">
                {{ isMissionControlSession ? 'Edit/view responses' : 'Edit your responses' }}
              </a>
            </div>
            <v-btn
              v-else
              rounded
              color="anchor1Color"
              class="button-with-icon text-none whiteColor--text padding8 height38"
              tag="a"
              @click="showFormModal = true"
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">list</i>
              Fill the form
            </v-btn>
          </div>
    
          <div
            v-if="activeAudit && currentStep === 3"
            :class="{
              'step-content': true,
              'completed': isStepValid(currentStep)
            }"
          >
            <div class="step-text step-text-number mb-3">Step {{currentStep}}</div>
            <div class="step-text step-text-title mb-2">Set up Keyword Research</div>
            <div class="step-text step-text-description mb-4">
              In this step we will ask you to confirm your domain name, as well as location and language for the website. This will give you and your POP team access to all keyword research data & metrics.
            </div>
            <v-btn
              v-if="isStepValid(currentStep)"
              rounded
              color="anchor1Color"
              class="success-disabled-btn button-with-icon text-none padding8 height38"
              tag="a"
              :disabled="true"
              @click=""
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">task_alt</i>
              Keyword Research set
            </v-btn>
            <v-btn
              v-else
              rounded
              color="anchor1Color"
              class="button-with-icon text-none whiteColor--text padding8 height38"
              tag="a"
              @click="() => {
                $refs.createDomainCampaignModalRef.showModalAction();
              }"
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">vpn_key</i>
              Set up Keyword Research
            </v-btn>
          </div>
    
          <div
            v-if="activeAudit && currentStep === 4"
            :class="{
              'step-content': true,
              'completed': isStepValid(currentStep)
            }"
          >
            <div class="step-text step-text-number mb-3">Step {{currentStep}}</div>
            <div class="step-text step-text-title mb-2">Get Google Search Console data</div>
            <div class="step-text step-text-description mb-4">
              Connect GSC and configure other settings such as location, period and more to unlock keyword insights and complete your onboarding for this project.
            </div>
            <v-btn
              v-if="isStepValid(currentStep)"
              rounded
              color="anchor1Color"
              class="success-disabled-btn button-with-icon text-none padding8 height38"
              tag="a"
              :disabled="true"
              @click=""
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">task_alt</i>
              GSC Connected
            </v-btn>
            <div
              v-else
              style="display: flex; flex-direction: row; gap: 12px; align-items: center;"
            >
              <v-btn
                rounded
                color="anchor1Color"
                class="button-with-icon text-none whiteColor--text padding8 height38"
                tag="a"
                @click="showGSCDataModal = true"
              >
                <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">build_circle</i>
                Get GSC data
              </v-btn>

              <a
                v-if="isMissionControlSession"
                href="#" @click.prevent="$store.commit('showLoading'); updateAudit({hasRetrievedFreshGSCData: 1}).finally(() => $store.commit('hideLoading'))"
                style="color: var(--v-link15Color-base);"
              >Set as completed anyway (Mission control only option)</a>
            </div>
          </div>

        </div>

        <div class="step-control">
          <v-btn
            :style="currentStep > (STEPS.length - 1) ? 'visibility: hidden;':''"
            icon
            outlined
            :disabled="!isStepValid(currentStep)"
            @click="currentStep += 1"
          >
            <img src="/static/orange_caret_right_icon.svg" width="12" />   
          </v-btn>
        </div>

        <form-modal
          v-if="activeAudit"
          :active="showFormModal"
          :site="site"
          :activeAudit="activeAudit"
          @close="showFormModal = false"
          @auditUpdated="$emit('auditUpdated', $event)"
        />

        <create-domain-level-campaign-modal
          ref="createDomainCampaignModalRef"
          @success="loadData().then(() => this.refreshCurrentStep())"
        />

        <google-data-modal
          v-if="showGSCDataModal"
          :site="site"
          :activeAudit="activeAudit"
          @close="showGSCDataModal = false"
          @success="loadData().then(() => this.refreshCurrentStep()); $store.commit('triggerRefreshKeywordResearchCard')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormModal from './FormModal.vue'
import GoogleDataModal from './GoogleDataModal.vue'
import CreateDomainLevelCampaignModal from '@/components/Tools/CreateDomainLevelCampaignModal.vue'
import { getFulfillementStatusLabel } from './utils'


const STEPS = [
  {
    label: 'Buy audit'
  },
  {
    label: 'Fill the form'
  },
  {
    label: 'Set up keyword research'
  },
  {
    label: 'Get Google Search Console data'
  }
]

export default {
  components: { FormModal, GoogleDataModal, CreateDomainLevelCampaignModal },
  props: [
    'site',
    'loadData',
    'activeAudit',
    'contentAuditPackages',
    'showOrderNewAuditButton',
  ],
  data () {
    return {
      currentStep: 1,
      stepsCount: 4,
      showFormModal: false,
      step1SelectedPackage: 'WHITE_GLOVE_ADDON_CONTENT_AUDIT',
      showCouponModal: false,
      modalCouponFieldValue: '',
      appliedCoupon: null,
      newAuditOrderLoading: false,
      showGSCDataModal: false,
    }
  },
  computed: {
    STEPS: () => STEPS,
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return null
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    isMissionControlSession () {
      return this.$store.state.selectedSubAccountRelation?.isMissionControlSubAccount
    },
    finalPrice() {
      let price = this.contentAuditPackages[this.step1SelectedPackage].price
      let amountOff = 0
      if (this.appliedCoupon) {
        amountOff = this.appliedCoupon.coupon.amountOff
        if (this.appliedCoupon.coupon.couponType == 'Percent Off') {
          amountOff = (price * amountOff / 100)
        }
      }
      return (price - amountOff)
    },
  },
  watch: {
    step1SelectedPackage: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.appliedCoupon = null
      }
    }
  },
  methods: {
    getFulfillementStatusLabel: getFulfillementStatusLabel,
    isStepValid(step) {
      if (step === 1) {
        return Boolean(this.activeAudit)
      }
      if (step === 2) {
        return this.isStepValid(step - 1) && this?.activeAudit?.status === 'form_submitted'
      }
      if (step === 3) {
        return this.isStepValid(step - 1) && Boolean(this?.activeAudit?.hasAssociatedDomainLevelCampaign)
      }
      if (step === 4) {
        return this.isStepValid(step - 1) && Boolean(this?.activeAudit?.hasRetrievedFreshGSCData)
      }
      return false;
    },
    applyCoupon () {
      this.appliedCoupon = null;
      this.$store.commit('showLoading')
      return this.$store.dispatch('fetchValidatedCoupons', { promoCode: this.modalCouponFieldValue.trim(), isRegisterPage: 0 })
        .then((response) => {
          if (!response.data) {
            this.$notify({
              group: 'info', type: 'warning',
              text: 'Invalid coupon'
            })
            return;
          }
          if (response.data && response.data.status == 'SUCCESS') {
            let allServiceCoupons = response.data.coupons
            if (allServiceCoupons.length == 1) {
              const coupon = allServiceCoupons[0];
              if (['Percent Off', 'Amount Off'].includes(coupon.couponType) && coupon.product === this.step1SelectedPackage) {
                this.appliedCoupon = {
                  code: this.modalCouponFieldValue.trim(),
                  coupon
                };
                this.showCouponModal = false;
              } else {
                this.$notify({
                  group: 'info', type: 'warning',
                  text: 'Invalid coupon'
                })
              }
            } else {
              this.$notify({
                group: 'info', type: 'warning',
                text: 'Invalid coupon'
              })
            }
          } else {
            if (response.data.status == 'FAILURE') {
              this.$notify({
                group: 'info', type: 'warning',
                text: response.data.errorMsg ? response.data.errorMsg : 'Invalid coupon'
              })
            }
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
    buyNow(mode='checkout') {
      this.$store.commit('showLoading');
      this.$store.dispatch('createStripePayment', {
        packageType: 'WHITE_GLOVE_ADDON_PURCHASE',
        selectedPackage: this.step1SelectedPackage,
        userID: this?.selectedSubAccountRelation?.ownerId || this.user.id,
        selectedSiteId: this.site.id,
        redirect_front_url: this.$router.currentRoute.fullPath,
        redirect_front_for_cancel_url: this.$router.currentRoute.fullPath,
        promoCode: this.appliedCoupon?.code,
        mode,
      })
      .then((response) => {
        if (response.data.url) {
          window.open(response.data.url, '_self')
        } else if (mode === 'invoice' && response.data.status === 'SUCCESS') {
          this.$notify({
            group: 'info', type: 'success',
            text: 'Invoice sent to user'
          })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
    updateAudit(payload) {
      return this.$store.dispatch('updateSiteContentAudit', {
        auditId: this.activeAudit.id,
        body: payload
      })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            return this.loadData().then(() => this.refreshCurrentStep())
          }
        })
    },
    newAuditOrder() {
      this.newAuditOrderLoading = true;
      this.$store.dispatch('updateSiteContentAudit', {
        auditId: this.activeAudit.id,
        body: {
          showNewAuditPurchaseView: 1,
        }
      })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            if (response?.data?.audit?.showNewAuditPurchaseView) {
              this.loadData(true)
            }
          }
        })
        .finally(() => {
          this.newAuditOrderLoading = false;
        })
    },
    refreshCurrentStep() {
      if (this.activeAudit) {
        // go to the last valid step or default to first step
        let currentStep = 1
        for (let index = 1; index <= this.stepsCount; index++) {
          if (this.isStepValid(index)) {
            currentStep = index + 1;
          } else {
            break;
          }
        }
        this.currentStep = currentStep;
      }
    },
  },
  mounted () {
    this.refreshCurrentStep()
  },
}
</script>

<style lang="scss" scoped>
.container-launch {
  display: flex;
  background-color: #fff;
  border: 1px solid #CCCCCC;
  border-radius: 12px;

  .width704 {
    width: 44rem;
  }

  .v-btn.v-btn--disabled.success-disabled-btn {
    background-color: #0CB370 !important;
    color: #fff !important;

    .material-icons-outlined {
      font-size: 1.2rem;
    }
  }

  .onboarding-completed {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;

    img {
      height: 160px;
      margin-bottom: -50px;
    }
  }

  .step-control {
    padding-top: 110px;
    text-align: center;

    .v-btn {
      background-color: #fff;
      border-color: #CCCCCC;
      border-radius: 6px;
      box-shadow: 0px 0.32px 7.93px 0px rgba(0, 0, 0, 0.11);

      &.v-btn--disabled {
        opacity: 0.4 !important;
      }
    }
  }

  .steps-status {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    .step-item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      &, * {
        cursor: default;
      }

      .step-label {
        font-weight: bold;
        color: #9D9D9D;
      }
      .step-dot {
        box-sizing: border-box;
        border-radius: 100px;
        border: 1px solid #CCCCCC;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        line-height: 25px;
        
        .material-icons {
          visibility: hidden;
          font-size: 1.1rem;
          font-weight: bold;
        }
      }

      .step-seperator {
        display:inline-block;
        width: 40px;
        height: 2px;
        background: #9D9D9D;
      }

      &.active {
        .step-label {
          font-weight: bold;
          color: var(--v-mainColor-base);
        }
        .step-dot {
          border: 5px solid #FF5C00;
        }
      }

      &.completed {
        .step-label {
          color: #0CB370;
        }
        .step-dot {
          border: none;
          background: #0CB370;
          .material-icons {
            visibility: visible;
            color: #fff;
          }
        }
        .step-seperator {
          display:inline-block;
          width: 40px;
          height: 2px;
          background: #0CB370;
        }
      }
    }
  }

  .step-content {
    
    .v-btn {
      letter-spacing: initial;
    }
    
    .step-text {
      color: var(--v-primary-base);
    }

    .step-text-number {
      font-size: 0.95rem;
      font-weight: 800;
    }
  
    .step-text-title {
      font-size: 1.25rem;
      font-weight: 800;
    }
  
    .step-text-description {
      font-size: 1rem !important;
      font-weight: 400 !important;
      line-height: 1.6rem;
    }

    &.completed {
      .step-text {
        text-decoration: line-through;
      }
    }
  }

  .height38 {
    height: 2.375rem !important;
  }

  .padding8 {
    padding: 8px 12px !important;
  }

  .cross-container {
    margin-top: -10px;
  }

  .package-option {
    padding: 20px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid var(--v-grayColor-base);
    color: var(--v-mainColor-base);

    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;

    .package-option-dot {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 1px solid var(--v-grayColor-base);
    }

    &.package-option-active {
      border-color: var(--v-profileAnchorColor-base);

      .package-option-dot {
        border: 4px solid var(--v-profileAnchorColor-base);
      }
    }
  }
}
</style>

<style lang="scss">

.container-launch .step-control .v-btn.v-btn--plain:not(.v-btn--active):not(.v-btn--disabled):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
  opacity: 1 !important;
}

</style>