<template>
    <v-card class="white-common-box mb-10" v-if="aiRun">
        <div class="pa-4">
            <div class="d-flex gap10 justify-space-between">

                <div class="font-large d-flex gap10 align-center">
                    <i class="material-icons-outlined" style="color: #25A97A;">replay</i>
                    Regenerate credits history
                </div>

                <b-dropdown aria-role="list" position="is-bottom-left">
                    <template #trigger>
                        <v-btn
                            icon
                            small
                        >
                        <i class="material-icons-outlined">more_horiz</i>
                        </v-btn>
                    </template>

                    <b-dropdown-item custom style="width: 400px;">
                        <div class="mb-4" v-if="separateSearchEngineTitleAndTitle">
                            <div class="d-flex justify-space-between">
                                <div class="font-weight-bold">Regenerate run - Search Engine Title</div>
                                <div class="font-weight-bold">{{ titleRegenerateCount }}</div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <div class="d-flex justify-space-between">
                                <div class="font-weight-bold">Regenerate run - {{ pageTitlelabel }}</div>
                                <div class="font-weight-bold">{{ pageTitleRegenerateCount }}</div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <div class="d-flex justify-space-between">
                                <div class="font-weight-bold">Regenerate run - Sub-headings (H2 and H3)</div>
                                <div class="font-weight-bold">{{ subHeadingsRegenerateCount }}</div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <div class="d-flex justify-space-between">
                                <div class="font-weight-bold">Regenerate run - Main content</div>
                                <div class="font-weight-bold">{{ paragraphRegenerateCount }}</div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex justify-space-between">
                                <div class="font-weight-bold">Regenerate run - Rich content</div>
                                <div class="font-weight-bold">{{ contentPromptSignalRegenerateCount }}</div>
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
            </div>

            <div
              class="mt-2 mb-5"
            >
                <div class="d-flex gap4 align-center mb-2">
                    <i
                        class="material-icons-outlined"
                        style="cursor: pointer;"
                        @click.stop="showExplain = !showExplain"
                    >{{ showExplain ? 'arrow_drop_down': 'arrow_right' }}</i>
                    <a
                        class="show-hide-link"
                        @click.stop="showExplain = !showExplain"
                        >Regeneration Credits Explained</a
                    >
                </div>
                <div v-show="showExplain" class="has-text-grey has-text-weight-light" style="border-left: 2px solid #4E4E4E; padding-left: 8px; margin-left: 9px;">
                    Each article in POP comes with <span class="font-weight-bold">5 free regeneration credits</span> to regenerate Title / H1, Sub-headings and Main content. After you use all 5 of them, each additional regenerate run will cost <span class="font-weight-bold">1 credit</span>.
                </div>
            </div>

            <div class="mt-4">
                <div class="d-flex justify-space-between mb-2">
                    <div class="font-weight-bold">Used free regenerate credits</div>
                    <div><span class="font-weight-bold">{{ 5 - remainedFreeRegenerate }}</span> / 5</div>
                </div>
                <v-progress-linear
                    rounded
                    color="#838689"
                    :value="((5 - remainedFreeRegenerate) / 5) * 100" :height="6"></v-progress-linear>

            </div>
        </div>
    </v-card>
</template>

<script>

export default {
    data() {
        return {
            slider_options: {
                dotSize: 16,
                width: 'auto',
                height: 4,
                min: 0,
                max: 100,
                interval: 1,
                dotStyle: { border: '1px solid #D2D3D9' },
                processStyle: { background: '#FF5700' },
            },
            showExplain: false,
        };
    },
    computed: {
        popCreditCharges () {
            return this.$store.state.popCreditCharges
        },
        user () {
            return this.$store.state.user;
        },
        isSubAccountRelationSelected () {
            return !!this.$store.state.selectedSubAccountRelation
        },
        ownerUser () {
            return this.$store.state.selectedSubAccountRelation || this.user
        },
        ownerAvailableCredits () {
            if (this.user) {
                return this.ownerUser.monthlyCredits + this.ownerUser.remainedUniversalCredits
            }
            return 0
        },

        pageTitlelabel () {
            if (this.separateSearchEngineTitleAndTitle) {
                return 'Page title'
            } else {
                return 'Search engine title and page title'
            }
        },

        aiRun () {
            return this.$store.state.currentAIRun
        },
        separateSearchEngineTitleAndTitle () {
            if (!this.aiRun) return false;
            return this.aiRun.isSeparateTitleAndPagetitle;
        },
        remainedFreeRegenerate () {
            if (!this.aiRun) return 0;
            return this.aiRun.remainedFreeRegenerate
        },
        numberOfWords () {
            if (!this.aiRun) return 0;
            return this.aiRun.wordCount;
        },
        articleCredits () {
            if (!this.aiRun) return 0;
            return this.aiRun.articleCredits;
        },
        richContentCreditsForArticle () {
            if (!this.aiRun) return 0;
            return this.aiRun.richContentCreditsForArticle;
        },
        titleRegenerateCount () {
            if (!this.aiRun) return 0;
            return this.aiRun.titleRegenerateCount;
        },
        pageTitleRegenerateCount () {
            if (!this.aiRun) return 0;
            return this.aiRun.pageTitleRegenerateCount;
        },
        subHeadingsRegenerateCount () {
            if (!this.aiRun) return 0;
            return this.aiRun.subHeadingsRegenerateCount;
        },
        paragraphRegenerateCount () {
            if (!this.aiRun) return 0;
            return this.aiRun.paragraphRegenerateCount;
        },
        contentPromptSignalRegenerateCount () {
            if (!this.aiRun) return 0;
            return this.aiRun.contentPromptSignalRegenerateCount;
        },
        totalCreditsSpent () {
            return this.articleCredits + this.richContentCreditsForArticle + this.titleRegenerateCount + this.pageTitleRegenerateCount + this.subHeadingsRegenerateCount + this.paragraphRegenerateCount + this.contentPromptSignalRegenerateCount
        }

    },
    mounted() {

    }
};
</script>
<style lang="scss" scoped>
.white-common-box {
    background-color: #F8FFFF !important;
    overflow-x: initial;
}

.theme--dark {
    .white-common-box {
        background-color: #0c0c0c !important;
        overflow-x: initial;
    }
}

.font-large {
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1;
}

.show-hide-link {
  font-size: 1rem;
  line-height: 24px;
  text-decoration: underline;
  color: var(--v-gray2Color-base);
}

.link {
    color: #0089ED;
}

.input-container {
    position: relative;

    img {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 10px;
        top: 8px;
    }

    .custom-input {
        background-color: #F2F6FF;
        height: 35px;
        border-radius: 5px;
        border: 1px solid #DCDDEB;
        padding-left: 2rem;
        width: 100%;
        font-weight: bold;
    }
}


.font-bold {
    font-weight: bold;
}

.slider-view {
    margin: 10px 0px;
    position: relative;
    display: grid;
    align-items: center;

    .fill, .unfill {
        height: 6px;
        border-radius: 6px;
    }
    .fill {
        background: var(--v-green7Color-base);
        &.used-almost {
            background: var(--v-profileAnchorColor-base);
        }
    }
    .unfill {
        background: var(--v-grayColor-base);        
    }
    .thumb {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: var(--v-whiteColor-base);
        border: 1px solid var(--v-grayColor-base);
    }
}
</style>