<template>
    <v-card class="settings-area white-common-box">
      <div class="d-flex flex-wrap flex-row-gap-5 align-center mb-2">
        <div v-if="pAIResult.length == 0">
          <div class="font-weight-bold mb-6" style="font-size: 1rem;">
            Select how AI should write your main content

            <v-tooltip bottom color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <i
                  class="material-icons info-icon-size ml-2 mainColor3--text"
                  v-bind="attrs"
                  v-on="on"
                  style="font-size: 14px"
                  >info</i
                >
              </template>
              <div class="white-bg-tooltip" style="max-width: 500px">
                You can manually write in text or copy and paste in existing
                text from your webpage by checking the box below. The AI
                will then use that text to re-write it and insert important
                terms. You can also click "Generate" and the AI will write
                new text for each H2 and H3. You can click "Check your work"
                to check your new scores.
              </div>
            </v-tooltip>
          </div>
        </div>
      </div>

      <div
        class="p-settings-area d-flex gap20"
        v-if="
          isDemo
            ? mainContentProgress >= 0 && mainContentProgress < 100
            : pAIResult.length == 0
        "
      >
        <div
          class="white-common-box pa-5"
          :class="{ 'is-active': !isInputParagraphByUser }"
          style="flex: 1;"
          @click="
            () => {
              if (isDemo) return;
              $emit('update:isInputParagraphByUser', false)
            }
          "
        >
          <div class="box-top-icon-wrapper">
            <span class="material-icons box-top-icon">auto_awesome</span>
          </div>
          <div class="d-flex align-center gap10">
            <span class="box-top-text"
              >Quick content</span> <span class="option-label">Best for speed</span>
          </div>
          <div class="section-label mt-2 mb-3">
            I want AI to generate new original content as my main content
          </div>
          <div>
            <ul>
              <li>
                Description: AI will write completely original text for
                each H2 and H3 you selected in the previous step. AI will
                insert your keyword and important terms according to POP
                recommendations.
              </li>
              <li>
                Benefits: Easier and faster, but you have less control
                over the output. This is the perfect option if you haven’t
                built this page yet.
              </li>
            </ul>
          </div>
        </div>
        <div
          class="white-common-box pa-5"
          :class="{ 'is-active': isInputParagraphByUser }"
          style="flex: 1;"
          @click="
            () => {
              if (isDemo) return;
              $emit('update:isInputParagraphByUser', true)
            }
          "
        >
          <div class="box-top-icon-wrapper">
            <span class="material-icons box-top-icon">edit_note</span>
          </div>
          <div class="d-flex align-center gap10">
            <span class="box-top-text">Custom content</span>  <span class="option-label">Best for quality</span>
          </div>
          <div class="section-label mt-2 mb-3">
            I want to provide content for AI to re-write
          </div>
          <div>
            <ul>
              <li>
                Description: Click on an empty field to see a dropdown
                menu with paragraphs from your target page and your
                competitors. You can select multiple paragraphs from this
                menu to add them to the field for AI to re-write. You can
                also manually edit these paragraphs before clicking
                "Rewrite with AI" button.
              </li>
              <li>
                Benefits: Takes longer, but you have more control over the
                output. Ideal for those who want higher quality and
                customization.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        v-if="pAIResult.length == 0 && isInputParagraphByUser"
        class="mt-12 input-your-paragraph-section"
      >
        <div class="d-flex align-center mb-3">
          <div class="mainColor3--text font-weight-bold mr-2" style="font-size: 1.2rem;">
            Select the level of revision:
          </div>
        </div>

        <div class="revision-selector mt-2 d-flex gap10" v-if="!willSelectRevisionIndividually">
          <v-tooltip
            v-for="item in availableRevisions"
            :key="item.value"
            top color="transparent"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                :class="{
                  'revision-option': true,
                  'revision-option-active': item.value == selectedRevisionForAllParagraphs
                }"
                v-bind="attrs"
                v-on="on"
                @click="
                  () => {
                    $emit('update:selectedRevisionForAllParagraphs', item.value)
                  }
                "
              >
                {{ item.label }} <span v-if="item.recommended" class="recommended-label">Recommended</span>
              </div>
            </template>
            <div class="white-bg-tooltip" style="max-width: 500px">
              {{ item.text }}
            </div>
          </v-tooltip>
        </div>

        <div class="d-flex align-center mt-5">
          <v-checkbox
            hide-details
            class="mt-0"
            :value="willSelectRevisionIndividually"
            @change="$emit('update:willSelectRevisionIndividually', $event)"
          ></v-checkbox>
          <span class="mainColor3--text"
            >I want to select the level of revision separately for each
            paragraph</span
          >
        </div>
        
        <div class="mainColor3--text font-weight-bold mt-10" style="font-size: 1.2rem;">
          Click to write or select your or your competitors’ paragraphs: 
        </div>

        <div class="notify-label">
          <i class="material-icons">info</i>
          For a better output we recommend to provide at least {{
            Math.round(
              numberOfWords /
                (calculated_subHeadingsAIResultArr.length || 1)
            )
          }} words in each paragraph field. You can select multiple paragraphs at the same time. You can also manually edit these paragraphs.
        </div>
        
        <div class="mt-5">
          <div :class="`user-input-paragraph user-input-paragraph-${calculated_subHeadingsAIResultArr[idx].tagName}`" v-for="(item, idx) in userInputParagraphs" :key="idx">
            <div class="d-flex align-center gap5 mb-2">
              <div class="d-flex align-center gap5" style="flex: 1;">
                <span class="tag-symbol-span">{{
                  calculated_subHeadingsAIResultArr[idx].tagName
                }}</span>
                {{ calculated_subHeadingsAIResultArr[idx].text }}
              </div>

            </div>
            <v-textarea
              class="user-input-paragraph-textarea"
              outlined
              dense
              rows="1"
              auto-grow
              hide-details="auto"
              background-color="#ffffff"
              placeholder="Click to write or select paragraphs"
              :value="userInputParagraphs[idx]"
              @input="$emit('update:userInputParagraphs', userInputParagraphs.map(
                (oldVal, i) => {
                  return idx === i
                    ? $event
                    : oldVal;
                }
              ))"
              @focus="
                () => {
                  $emit('update:currentInputParagraphIndex', idx)
                }
              "
            >
            </v-textarea>
            <div class="text-right" style="font-size: 0.8rem;">
              {{ userInputedParagraphWordCounts[idx] }} words
            </div>
            
            <div class="revision-selector d-flex gap10" v-if="willSelectRevisionIndividually">
              <v-tooltip
                v-for="item in availableRevisions"
                :key="item.value"
                top color="transparent"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    :class="{
                      'revision-option': true,
                      'revision-option-active': item.value == selectedRevisions[idx]
                    }"
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      () => {
                        $emit('update:selectedRevisions', selectedRevisions.map(
                          (revItem, subIdx) => {
                            return subIdx == idx ? item.value : revItem;
                          }
                        ))
                      }
                    "
                  >
                    {{ item.label }} <span v-if="item.recommended" class="recommended-label">Recommended</span>
                  </div>
                </template>
                <div class="white-bg-tooltip" style="max-width: 500px">
                  {{ item.text }}
                </div>
              </v-tooltip>
            </div>

            <select-paragraph-box
              v-if="idx == currentInputParagraphIndex"
              :currentSubheadingInfo="
                calculated_subHeadingsAIResultArr[idx]
              "
              :pageNotBuilt="pageNotBuilt"
              :userInputParagraphs="userInputParagraphs"
              :currentText="item"
              :currentSelectedRevision="
                selectedRevisions.length > idx
                  ? selectedRevisions[idx]
                  : ''
              "
              :targetUrlParagraphs="targetUrlParagraphs"
              :competitorsParagraphs="competitorsParagraphs"
              @clickedParagraphAction="
                (text) => {
                  let newText =
                    userInputParagraphs[currentInputParagraphIndex];
                  if (newText) {
                    newText += '\n';
                  }
                  newText += text;
                  $emit('update:userInputParagraphs', userInputParagraphs.map(
                    (item, idx) => {
                      return idx == currentInputParagraphIndex
                        ? newText
                        : item;
                    }
                  ))
                }
              "
              @closeAction="
                () => {
                  $emit('update:currentInputParagraphIndex', -1)
                }
              "
            ></select-paragraph-box>
          </div>
        </div>
      </div>

      <div class="py-5 d-flex align-center">
        <v-btn
          v-if="
            isDemo &&
            mainContentProgress >= 0 &&
            mainContentProgress < 100
          "
          color="profileAnchorColor"
          class="text-none whiteColor--text pulsing-yellow-button"
          :disabled="mainContentProgress > 0"
          style="border-radius: 8px;"
          @click="fakeProgress('mainContentProgress')"
        >
          <img
            src="/static/ai-writer-stars.png"
            width="26px"
            style="margin-right: 5px"
          />
          Generate main content
        </v-btn>
        <template v-else>
          <v-btn
            v-if="isInputParagraphByUser && pAIResult.length == 0"
            color="profileAnchorColor"
            class="text-none whiteColor--text"
            :class="{
              'pulsing-yellow-button':
                isInputParagraphByUser && needsPulsing(STEPS.PARAGRAPH),
            }"
            :disabled="isLoading || !isInputParagraphByUser"
            style="border-radius: 8px;"
            @click="
              () => {
                generateResultFor('p');
              }
            "
          >
            <img
              src="/static/ai-writer-stars.png"
              width="26px"
              style="margin-right: 5px"
            />
            Rewrite with AI
          </v-btn>

          <v-btn
            v-if="!isInputParagraphByUser && pAIResult.length == 0"
            color="profileAnchorColor"
            class="text-none whiteColor--text"
            :class="{
              'pulsing-yellow-button':
                !isInputParagraphByUser && needsPulsing(STEPS.PARAGRAPH),
            }"
            :disabled="isLoading || isInputParagraphByUser"
            style="border-radius: 8px;"
            @click="
              () => {
                generateResultFor('p');
              }
            "
          >
            <img
              src="/static/ai-writer-stars.png"
              width="26px"
              style="margin-right: 5px"
            />
            Generate main content
          </v-btn>

          <v-select
            v-if="user.isAdmin"
            style="flex: initial; margin-left: 20px;"
            outlined
            dense
            hide-details
            color="greenColor"
            :items="[
              { text: 'Variant 0 (Original individual paragraph prompts)', value: 0 },
              { text: 'Variant 1 (individual paragraph prompts)', value: 1 },
              { text: 'Variant 2 (batched H2+H3 prompts)', value: 2 },
            ]"
            :value="variant"
            @change="$emit('update:variant', $event)"
          >
          </v-select>
        </template>
      </div>
    </v-card>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";
import SelectParagraphBox from "@/components/FullPageSourceEditorV2/components/AiWriter/components/SelectParagraphBox.vue";

export default {
    name: 'Step7Paragraph',
    components: {
      SelectParagraphBox,
    },
    props: [
        'goNext',
        'isDemo',
        'generateResultFor',
        'needsPulsing',
        'fakeProgress',
        'user',
        'variant',

        'pageNotBuilt',
        'runningSection',
        'isLoading',
        'progress',
        'mainContentProgress',
        'subHeadingsAIResult',
        'pAIResult',
        'isInputParagraphByUser',
        'numberOfWords',
        'calculated_subHeadingsAIResultArr',
        'willSelectRevisionIndividually',
        'availableRevisions',
        'selectedRevisionForAllParagraphs',
        'userInputParagraphs',
        'userInputedParagraphWordCounts',
        'currentInputParagraphIndex',
        'selectedRevisions',
        'targetUrlParagraphs',
        'competitorsParagraphs',
    ],
    computed: {
        STEPS() {
            return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>
.p-settings-area {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  & > * {
    cursor: pointer;
    flex-grow: 1;
    flex-basis: 0;

    &.is-active {
      border: 1px solid var(--v-profileAnchorColor-base) !important;
    }

    .box-top-icon-wrapper {
      background: var(--v-gray7Color-base);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 10px;
      margin-bottom: 20px;

      .box-top-icon {
        font-size: 3rem;
        color: #98B1C3;
      }
    }
    .box-top-text {
      font-weight: 700;
      font-size: 1.05rem;
      color: var(--v-mainColor3-base);
    }

    .option-label {
      background-color: #F3F3F3;
      color: #7A7D84;
      text-transform: uppercase;
      border-radius: 3px;
      font-size: 0.7rem;
      font-weight: 700;
      padding: 2px 4px;
    }
  }
  ul {
    list-style: disc;
    li {
      margin-top: 10px;
    }
  }

}
</style>

<style lang="scss">
.radio-box-row {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  span {
    color: var(--v-mainColor3-base);
  }
  .checkbox-text {
    flex-grow: 1;
  }
}

.revision-selector {
  .revision-option {
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  
    &.revision-option-active {
      background-color: #FFF6F1;
      border: 1px solid #FF5C00;
    }
  }

  .recommended-label {
    background-color: #D6FBEE;
    color: #25A97A;
    text-transform: uppercase;
    border-radius: 3px;
    font-size: 0.7rem;
    font-weight: 700;
    padding: 2px 4px;
  }
}

.user-input-paragraph {
  background-color: #F7F7F7;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;

  .user-input-paragraph-textarea {
    & > .v-input__control > .v-input__slot fieldset {
      color: rgba(0,0,0,.2) !important;
      top: -10px;
    }
    & > .v-input__control > .v-input__slot textarea {
      margin-top: 4px !important;
    }
  }

  &.user-input-paragraph-H3 {
    margin-left: 40px;
  }
}

.tag-symbol-span {
  border-radius: 5px;
  margin-right: 10px;
  color: #6B6C6E;
  border: 0.5px solid #CCCCCC;
  background-color: var(--v-whiteColor-base);
  font-weight: 800;
  text-transform: uppercase;
  padding: 4px 8px;
}
</style>