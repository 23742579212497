<template>
  <div>
    <voltage-search-form
      :isForPageLevel="selectedScenario == 'SITE_URL_EXIST'"
      :selectedScenario="selectedScenario"
      :siteId="siteId"
    ></voltage-search-form>

    <div v-if="isLoadingHistory" class="mt-10">
      Loading last saved data...
    </div>
    <div v-else-if="Boolean(config.wizardResultsHistoryMetadata)" class="mt-10">
      Showing last saved data ({{ config.wizardResultsHistoryMetadata.createdAt }})
    </div>

    <voltage-table
      :isForPageLevel="selectedScenario == 'SITE_URL_EXIST'"
      :resultData="config.wizardResults"
      :showSiloExtraActions="false"
      :refreshData="() => loadHistory({silent: true})"
    ></voltage-table>
  
  </div>
  </template>
  
  
  <script>
  import VoltageSearchForm from './VoltageSearchForm.vue';
  import VoltageTable  from './VoltageTable.vue';
  import { chain, uniq } from 'lodash'
  
  export default {
    props: {
      selectedScenario: {
        default: '',
      },
      campaignId: {
        default: 0,
      },
      siteId: {
        default: 0,
      }
    },
    components: {
      VoltageSearchForm,
      VoltageTable,
    },
    computed: {
      campaign () {
        return this.$store.state.currentCampaign
      },
      config() {
        return this.$store.getters.insightKeywordsConfig;
      },
    },
    watch: {
      selectedScenario: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          if (this.resultData.length == 0) {
            this.loadHistory({})
          }
        }
      }
    },
    data () {
      return {
        isLoadingHistory: false,
      }
    },
    methods: {
      setConfig(key, value) {
        this.$store.commit("setInsightKeywordsConfig", { key, value });
      },
      loadHistory ({silent = false}) {
        if (!silent) {
          this.isLoadingHistory = true
          this.setConfig('wizardResults', null)
          this.setConfig('wizardResultsHistoryMetadata', null)
        }

        let data = {
          campaignId: this.campaignId,
          tabType: this.selectedScenario
        }
        return this.$store.dispatch('loadRecentKeywordWizardData', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            if (response.data.wizardData) {
              // put stored result in component state
              this.resultData = response.data.wizardData.result;
              const pageUrls = chain(this.resultData).map('page').uniq().value();
              this.setConfig('wizardPageUrls', pageUrls);
              this.setConfig('wizardResults', response.data.wizardData.result)

              this.setConfig('wizardLanguage', response.data.wizardData.languageCode)
              this.setConfig('wizardLocation', parseInt(response.data.wizardData.locationCode))

              // also put history meta data in component state
              const {result: _, ...historyMetaData} = response.data.wizardData
              this.setConfig('wizardResultsHistoryMetadata', historyMetaData)
            }
          }
        }).finally(() => {
          this.isLoadingHistory = false
        })
      },
    },
    mounted () {
      // ON MOUNTED
      // - init form with custom values from route params (if applicable)
      // - try loading data from backend (but only if global state is empty)
      // - then, check if we have a background monthly search data retrieval task (if we do show loading for that)

      if (this.$route.params.initialFormValue) {
        Object.keys(this.$route.params.initialFormValue).forEach(key => {
          if (key === 'wizardSelectedPageUrls') {
            const urls = this.$route.params.initialFormValue[key]

            // make sure urls exist as options (mainly needed when wizardPageUrls are not yet loaded)
            this.setConfig('wizardPageUrls', uniq([...this.config.wizardPageUrls, ...urls]))

            // set selected
            this.setConfig('wizardSelectedPageUrls', this.$route.params.initialFormValue[key])
          }
        })
      }

      (
        this.config.wizardResultsHistoryMetadata
          ?Promise.resolve()
          :this.loadHistory({})
      )
    },
    destroyed () {
      this.setConfig('wizardIsCalculating', false)
    }
  }
  </script>
  
  
  <style scoped lang="scss">
  
  </style>
