<template>
  <div class="ai-writer-head px-5 py-5">
    <div class="ai-writer-head-main d-flex align-center flex-wrap gap20">

      <div  style="flex: 1;">

        <div class="d-flex align-center gap10">
          <span class="page-title">{{ STPES_LONGER_LABEL[currentStep] }}</span>
          <v-tooltip bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <i
                class="material-icons info-icon-size"
                style="color: #B1BBC6;"
                v-bind="attrs"
                v-on="on"
              >info</i>
            </template>
            <div class="white-bg-tooltip">
              You are not updating your site content. Changes are only
              reflected here.
            </div>
          </v-tooltip>
          <span class="mode-label" v-if="mode === 'auto'">
            <img v-if="$vuetify.theme.dark" class="mr-1" src="/static/bolt_outlined_icon_light.svg" width="9" /><img v-else="$vuetify.theme.dark" class="mr-1" src="/static/bolt_outlined_icon_black.svg" width="9" /> {{ mode }}
          </span>
          <span class="mode-label" v-if="mode === 'guided'">
            <i class="material-icons-outlined">edit</i> {{ mode }}
          </span>
        </div>

        <div class="mt-4 d-flex align-center gap10">
          <div v-if="pageRun?.id">Report ID: {{ pageRun.id }}</div>
          <i v-if="pageRun?.id" class="material-icons" style="font-size: 0.6rem; color: var(--v-grayColor-base)">fiber_manual_record</i>
          <div v-if="pageRun?.id">Created on {{ formatDate(pageRun.createdAt) }}</div>
          <i v-if="pageRun?.id && page?.url" class="material-icons" style="font-size: 0.6rem; color: var(--v-grayColor-base)">fiber_manual_record</i>
          <div v-if="page?.url" class="page-url">
            <a :href="page.url" target="_blank">
              <span>{{ page.url.replace(page.siteBaseURL, '') }}</span>
              <i class="material-icons-outlined">open_in_new</i>
            </a>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <demo-plans :currentStep="currentStep" />
      </div>
      <span
        class="variation-tag font-weight-bold"
        style="border-radius: 4px;"
      >{{
        pageRun.keyword
      }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DemoPlans from "@/components/DemoPlans.vue";

import { STEPS, STPES_LABEL, STPES_LONGER_LABEL } from '@/components/FullPageSourceEditorV2/components/AiWriter/constants.js';


export default {
  name: 'AiWriterActionsHead',
  components: {
    DemoPlans,
  },
  props: [
    'isDemo',  
    'currentStep',
    'page',
    'pageRun',
    'goBack',
    'mode',
  ],
  data () {
    return {
      STEPS: STEPS,
      STPES_LABEL: STPES_LABEL,
      STPES_LONGER_LABEL: STPES_LONGER_LABEL,
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MMMM Do YYYY');
    }
  }
}
</script>

<style lang="scss" scoped>
.ai-writer-head-main {
  padding: 25px 30px;
  background: #E9F3FF;
  border-radius: 4px;

  .page-title {
    color: var(--v-mainColor3-base);
    font-size: 25px;
    font-weight: bold;
    line-height: 1;
  }

  .mode-label {
    border: 0.61px solid var(--v-mainColor3-base);
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 0.7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 4px;

    i {
      font-size: 0.85rem;
      margin-left: -2px;
    }
  }

  .page-url {

    display: flex;
    
    a {
      color: #0075FF;
      display: inline-flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;

      &:hover span {
        text-decoration: underline;
      }

      i {
        font-size: 0.8rem;
      }
    }
  }
}

.theme--dark {
  .ai-writer-head-main {
    background-color: #000;
  }
}
</style>