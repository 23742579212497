<template>
  <div class="ai-writer-root">
    <steps-indicator
      :availableSteps="availableSteps"
      :currentStep="currentStep"
      @goTo="(stepToGo) => goToSpecificPage(stepToGo)"
      :hasParagraphResult="pAIResult.length > 0"
    />

    <div 
      class="ai-writer-main-area"
      :class="{
        'final-result-bg':
          currentStep == STEPS.PARAGRAPH && pAIResult.length > 0,
      }"
    >
      <div
        ref="aiWriterRef"
        class="ai-writer-main-area-container"
      >

        <table-of-contents
          v-if="showLeftSidebar && pAIResult.length > 0"
          :calculated_pageTitleAIResult="calculated_pageTitleAIResult"
          :calculated_subHeadingsAIResultArr="calculated_subHeadingsAIResultArr"
          @close="showLeftSidebar = false;"
          @itemClick="gotoParagraphSection($event);"
        />

        <div class="main-except-sidebar">

          <ai-writer-actions-head
            :isDemo="isDemo"  
            :currentStep="currentStep"
            :page="page"
            :pageRun="pageRun"
            :goBack="goBack"
            :mode="mode"
            @startOver="$emit('startOver')"
          />

          <div class="px-5">

            <step0-start
              v-if="currentStep == STEPS.START"
              :goNext="goNext"
              :mode.sync="mode"
            />

            <step1-configs
              v-if="currentStep == STEPS.SETTING"
              :validations="v$"
              :goNext="goNext"
              :isLoading="isLoading"
              :mode="mode"

              :isDemo="isDemo"
              :pageRun="pageRun"
              :page="page"
              :isUsingOwnApiKey="isUsingOwnApiKey"
              :isFailedPageRun="isFailedPageRun"
              :countWords="countWords"
              :user="user"
              :popCreditCharges="popCreditCharges"

              :WORDCOUNT_OPTIONS="WORDCOUNT_OPTIONS"
              :currentWordCount="currentWordCount"
              :targetWordCount="targetWordCount"

              :selectedWordCountOption.sync="selectedWordCountOption"
              :numberOfWords.sync="numberOfWords"

              :competitorWordCount="competitorWordCount"
              :selectedCompetitors.sync="selectedCompetitors"

              :setDefaultRichContentFroWebPageType="setDefaultRichContentFroWebPageType"
              :webPageTypeOptions="webPageTypeOptions"
              :webPageType.sync="webPageType"

              :productDescription.sync="productDescription"
              :targetAudience.sync="targetAudience"
              :sampleContent.sync="sampleContent"
              :toneOfContent.sync="toneOfContent"
              :author.sync="author"
              :brand.sync="brand"

              :addNewExcludeTerm="addNewExcludeTerm"
              :handleRemoveExcludeTerm="handleRemoveExcludeTerm"
              :excludeTerms.sync="excludeTerms"

              :useMyCurrentTitle.sync="useMyCurrentTitle"
              :separateSearchEngineTitleAndTitle.sync="separateSearchEngineTitleAndTitle"

              :richContentItemsForAIWriter="richContentItemsForAIWriter"
              :CONTENT_PROMPT_SIGNALS_TOOLTIPS="CONTENT_PROMPT_SIGNALS_TOOLTIPS"
              :costedContentPromptSignals="costedContentPromptSignals"
              :getCostForContentPromptSignal="getCostForContentPromptSignal"
              :isValidInternalLink="isValidInternalLink"

              :selectedContentPromptsSignals.sync="selectedContentPromptsSignals"
              :selectedCountForPromptSignals.sync="selectedCountForPromptSignals"
              :topLevelInternalLink.sync="topLevelInternalLink"
              :supportingLevelInternalLink1.sync="supportingLevelInternalLink1"
              :supportingLevelInternalLink2.sync="supportingLevelInternalLink2"
            />

            <step4-search-page-title
              v-if="
                separateSearchEngineTitleAndTitle &&
                currentStep == STEPS.SEARCH_ENGINE_TITLE
              "

              :goNext="goNext"
              :isDemo="isDemo"
              :showWarningModalForGenerate="showWarningModalForGenerate"
              :generateResultFor="generateResultFor"
              :needsPulsing="needsPulsing"

              :pageNotBuilt="pageNotBuilt"
              :runningSection="runningSection"
              :isLoading="isLoading"
              :progress="progress"
              :optimizeCurrentTitle.sync="optimizeCurrentTitle"
              :optimizeCompetitorTitle.sync="optimizeCompetitorTitle"
              :useMyCurrentTitle.sync="useMyCurrentTitle"
              :useTitleInspirationText.sync="useTitleInspirationText"
              :titleInspirationText.sync="titleInspirationText"
              :titleAIResult="titleAIResult"
            />

            <step5-page-title
              v-if="currentStep == STEPS.PAGE_TITLE"
              
              :goNext="goNext"
              :showWarningModalForGenerate="showWarningModalForGenerate"
              :generateResultFor="generateResultFor"
              :needsPulsing="needsPulsing"
              :fakeProgress="fakeProgress"
              :progress="progress"

              :pageNotBuilt="pageNotBuilt"
              :runningSection="runningSection"
              :isLoading="isLoading"
              :titleProgress="titleProgress"
              :titleInspirationText="titleInspirationText"
              :pageTitlelabel="pageTitlelabel"
              :separateSearchEngineTitleAndTitle="separateSearchEngineTitleAndTitle"
              :optimizeCurrentPageTitle.sync="optimizeCurrentPageTitle"
              :optimizeCompetitorPageTitle.sync="optimizeCompetitorPageTitle"
              :usePageTitleInspirationText.sync="usePageTitleInspirationText"
              :useMyCurrentPageTitle.sync="useMyCurrentPageTitle"
              :pagetitleInspirationText.sync="pagetitleInspirationText"
              :pageTitleAIResult="pageTitleAIResult"
            />

            <step6-subheadings
              v-if="currentStep == STEPS.SUBHEADINGS"

              :goNext="goNext"
              :isDemo="isDemo"
              :showWarningModalForGenerate="showWarningModalForGenerate"
              :generateResultFor="generateResultFor"
              :needsPulsing="needsPulsing"
              :fakeProgress="fakeProgress"

              :pageNotBuilt="pageNotBuilt"
              :runningSection="runningSection"
              :isLoading="isLoading"
              :titleInspirationText="titleInspirationText"
              :progress="progress"
              :subHeadProgress="subHeadProgress"
              :optimizeCurrentH2s.sync="optimizeCurrentH2s"
              :optimizeCompetitorsH2s.sync="optimizeCompetitorsH2s"
              :useMySubheadings.sync="useMySubheadings"
              :subHeadingsAIResult="subHeadingsAIResult"
            />

            <step7-paragraph
              v-if="currentStep == STEPS.PARAGRAPH && !pAIResult?.length"

              :goNext="goNext"
              :isDemo="isDemo"
              :generateResultFor="generateResultFor"
              :needsPulsing="needsPulsing"
              :fakeProgress="fakeProgress"
              :user="user"
              :variant.sync="variant"

              :pageNotBuilt="pageNotBuilt"
              :runningSection="runningSection"
              :isLoading="isLoading"
              :progress="progress"
              :mainContentProgress="mainContentProgress"
              :subHeadingsAIResult="subHeadingsAIResult"
              :pAIResult="pAIResult"
              :isInputParagraphByUser.sync="isInputParagraphByUser"
              :numberOfWords="numberOfWords"
              :calculated_subHeadingsAIResultArr="calculated_subHeadingsAIResultArr"
              :willSelectRevisionIndividually.sync="willSelectRevisionIndividually"
              :availableRevisions="availableRevisions"
              :selectedRevisionForAllParagraphs.sync="selectedRevisionForAllParagraphs"
              :userInputParagraphs.sync="userInputParagraphs"
              :userInputedParagraphWordCounts="userInputedParagraphWordCounts"
              :currentInputParagraphIndex.sync="currentInputParagraphIndex"
              :selectedRevisions.sync="selectedRevisions"
              :targetUrlParagraphs="targetUrlParagraphs"
              :competitorsParagraphs="competitorsParagraphs"
            />

            <v-card
              v-if="(
                isLoading
                && !(currentStep >= STEPS.SEARCH_ENGINE_TITLE && currentStep < STEPS.SUBHEADINGS)
                && !((currentStep == STEPS.SUBHEADINGS &&
                    calculated_pageTitleAIResult) ||
                  (currentStep == STEPS.PARAGRAPH && pAIResult.length == 0) ||
                  (currentStep == STEPS.PARAGRAPH &&
                    isDemo &&
                    mainContentProgress < 100))
              )"
              class="settings-area white-common-box mt-10 d-flex flex-column align-center pt-4 pb-4"
            >
              <progress-bar
                :isDemo="isDemo"  
                :isLoading="isLoading"  
                :runningSection="runningSection"
                :progress="progress"
                :titleProgress="titleProgress"
                :subHeadProgress="subHeadProgress"
                :mainContentProgress="mainContentProgress"
              />
            </v-card>

            <div
              class="ai-results-area"
              :class="{
                'is-loading':
                  isLoading,
              }"
            >
              
              <result-4-and-5-page-title
                v-if="
                  currentStep >= STEPS.SEARCH_ENGINE_TITLE &&
                  currentStep < STEPS.SUBHEADINGS
                "
                :isDemo="isDemo"
                :currentStep="currentStep"

                :aiRun="aiRun"
                :pageTitleAIResult="pageTitleAIResult"
                :mouseenter="mouseenter"
                :mouseleave="mouseleave"
                :calculated_titleAIResult.sync="calculated_titleAIResult"
                :isTitleOrPageTitleInEditingMode="isTitleOrPageTitleInEditingMode"
                :editInOutlineInfo.sync="editInOutlineInfo"
                :focusInput="focusInput"
                :hoveredItem="hoveredItem"
                :calculated_pageTitleAIResult.sync="calculated_pageTitleAIResult"

                :showWarningModalForGenerate="showWarningModalForGenerate"
                :generateResultFor="generateResultFor"

                :isLoading="isLoading"
                :optimizeCurrentTitle="optimizeCurrentTitle"
                :optimizeCompetitorTitle="optimizeCompetitorTitle"
                :useMyCurrentTitle="useMyCurrentTitle"
                :useTitleInspirationText="useTitleInspirationText"
                :titleAIResultHistory="titleAIResultHistory"
                :showTitleHistoryModal.sync="showTitleHistoryModal"
                :downloadAIResult="downloadAIResult"

                :optimizeCurrentPageTitle="optimizeCurrentPageTitle"
                :optimizeCompetitorPageTitle="optimizeCompetitorPageTitle"
                :usePageTitleInspirationText="usePageTitleInspirationText"
                :useMyCurrentPageTitle="useMyCurrentPageTitle"
                :pageTitleAIResultHistory="pageTitleAIResultHistory"
                :showPageTitleHistoryModal.sync="showPageTitleHistoryModal"

                :runningSection="runningSection"
                :progress="progress"
                :titleProgress="titleProgress"
                :subHeadProgress="subHeadProgress"
                :mainContentProgress="mainContentProgress"
                
              />

              <result-6-subheadings-demo
                v-if="
                  isDemo &&
                  subHeadProgress >= 0 &&
                  subHeadProgress < 100 &&
                  currentStep == STEPS.SUBHEADINGS
                "
                :isDemo="isDemo"
                :currentStep="currentStep"

                :calculated_titleAIResult.sync="calculated_titleAIResult"
                :calculated_pageTitleAIResult.sync="calculated_pageTitleAIResult"
                :isTitleOrPageTitleInEditingMode="isTitleOrPageTitleInEditingMode"
                :cancelEditItemInOutlineFor="cancelEditItemInOutlineFor"
                :editInOutlineInfo.sync="editInOutlineInfo"
              />
              
              <result-6-subheadings
                v-if="
                  (currentStep == STEPS.SUBHEADINGS &&
                    calculated_pageTitleAIResult) ||
                  (currentStep == STEPS.PARAGRAPH && pAIResult.length == 0) ||
                  (currentStep == STEPS.PARAGRAPH &&
                    isDemo &&
                    mainContentProgress < 100)
                "

                :isDemo="isDemo"
                :isLoading="isLoading"
                :currentStep="currentStep"

                :showWarningModalForGenerate="showWarningModalForGenerate"
                :generateResultFor="generateResultFor"
                :calculated_titleAIResult="calculated_titleAIResult"
                :calculated_pageTitleAIResult="calculated_pageTitleAIResult"
                :cancelEditItemInOutlineFor="cancelEditItemInOutlineFor"
                :editInOutlineInfo.sync="editInOutlineInfo"
                :pAIResult="pAIResult"
                :calculated_subHeadingsAIResultArr.sync="calculated_subHeadingsAIResultArr"
                :maxRecommendedSubheadingsCount="maxRecommendedSubheadingsCount"
                :setActiveEditItem="setActiveEditItem"
                :isOutlineItemInEditingMode="isOutlineItemInEditingMode"
                :mouseenter="mouseenter"
                :mouseleave="mouseleave"
                :hoveredItem="hoveredItem"
                :editSubheading="editSubheading"
                :showDeleteConfirmModalAction="showDeleteConfirmModalAction"
                :deleteSubHeadingSelected="deleteSubHeadingSelected"
                :showAddSubheadingsModal.sync="showAddSubheadingsModal"
                :newSubheadingText.sync="newSubheadingText"
                :useMySubheadings="useMySubheadings"
                :optimizeCurrentH2s="optimizeCurrentH2s"
                :optimizeCompetitorsH2s="optimizeCompetitorsH2s"
                :showSubheadingsHistoryModal.sync="showSubheadingsHistoryModal"
                :downloadOutlines="downloadOutlines"
                :saveEditedSubheadings="saveEditedSubheadings"

                :runningSection="runningSection"
                :progress="progress"
                :titleProgress="titleProgress"
                :subHeadProgress="subHeadProgress"
                :mainContentProgress="mainContentProgress"
              />

              <result7-paragraph
                v-if="
                  currentStep == STEPS.PARAGRAPH &&
                  (isDemo ? mainContentProgress >= 100 : pAIResult.length > 0)
                "

                :isDemo="isDemo"
                :isLoading="isLoading"

                :pageRun="pageRun"
                :pAIResult="pAIResult"
                :calculated_titleAIResult="calculated_titleAIResult"
                :calculated_pageTitleAIResult="calculated_pageTitleAIResult"
                :editingParagraphIdxInResult.sync="editingParagraphIdxInResult"
                :handleParagraphEditDebounced="handleParagraphEditDebounced"
                :regenerateForParagraphOneSection="regenerateForParagraphOneSection"
                :seletedParagraphSection.sync="seletedParagraphSection"
                :showParagraphOneSectionHistoryModal.sync="showParagraphOneSectionHistoryModal"
                :textToCssId="textToCssId"
                :setNeedsToSave="setNeedsToSave"
                :getAllContentPromptDataForPSection="getAllContentPromptDataForPSection"
                :aiGeneratedImagesMap="aiGeneratedImagesMap"
                :imageAIModalParams.sync="imageAIModalParams"
                :deleteImageAIModalParams.sync="deleteImageAIModalParams"
                :showContnentPromptGenerationModal="showContnentPromptGenerationModal"
                :showEditPopupForRichItem="showEditPopupForRichItem"
                :showHistoryForRichItem="showHistoryForRichItem"

                @clickCheckOptimizationScore="$emit('onCalcChecklist', {
                  aiRunId: aiRun ? aiRun.id : 0,
                  htmlStr: fullHtml,
                  updateScore: true,
                })"
                @startOver="$emit('startOver')"
              />
            </div>

            <div class="pb-10">
              <!-- Do not remove this. this is for giving extra space at bottom. giving padding in parent will not work. -->
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showNextButtonWillUpdateScoreModal }"
            >
              <div
                class="modal-background"
                @click="showNextButtonWillUpdateScoreModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div class="subtitle-font mainColor3--text mb-5">
                      When you click "Next" your score will update automatically
                    </div>
                    <div class="text-right">
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none"
                        @click="showNextButtonWillUpdateScoreModal = false"
                      >
                        Ok
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showTitleHistoryModal }">
              <div
                class="modal-background"
                @click="showTitleHistoryModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root"></div>
                  <div class="modal-title-size mainColor3--text mb-3">
                    Search engine title history
                  </div>
                  <div
                    v-for="(item, idx) in titleAIResultHistory"
                    :key="idx"
                    class="history-box"
                  >
                    <div class="result">
                      <div class="timestamp">
                        <span>{{ item.timestamp }}</span>
                      </div>
                      {{ item.data }}
                    </div>
                    <div class="action">
                      <v-btn
                        rounded
                        x-small
                        color="profileAnchorColor"
                        class="text-none whiteColor--text"
                        @click="
                          () =>
                            loadAndSaveCurrentSelectionForSection(
                              'title',
                              idx,
                              item
                            )
                        "
                      >
                        <span>Load</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  @click="() => (showTitleHistoryModal = false)"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showPageTitleHistoryModal }"
            >
              <div
                class="modal-background"
                @click="showPageTitleHistoryModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div class="modal-title-size mainColor3--text mb-3">
                      Page title history
                    </div>
                    <div
                      v-for="(item, idx) in pageTitleAIResultHistory"
                      :key="idx"
                      class="history-box"
                    >
                      <div class="result">
                        <div class="timestamp">
                          <span>{{ item.timestamp }}</span>
                        </div>
                        {{ item.data }}
                      </div>
                      <div class="action">
                        <v-btn
                          rounded
                          x-small
                          color="profileAnchorColor"
                          class="text-none whiteColor--text"
                          @click="
                            () =>
                              loadAndSaveCurrentSelectionForSection(
                                'pageTitle',
                                idx,
                                item
                              )
                          "
                        >
                          <span>Load</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  @click="() => (showPageTitleHistoryModal = false)"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showSubheadingsHistoryModal }"
            >
              <div
                class="modal-background"
                @click="showSubheadingsHistoryModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div class="modal-title-size mainColor3--text mb-3">
                      Subheadings history
                    </div>
                    <div
                      v-for="(item, idx) in subheadingsAIResultHistory"
                      :key="`main_${idx}`"
                      class="history-box"
                    >
                      <div class="result">
                        <div class="timestamp">
                          <span>{{ item.timestamp }}</span>
                        </div>
                        <!-- <div v-html="item.data">
                      </div> -->
                        <div>
                          <div
                            v-for="(item1, idx1) in item.dataAsList"
                            :key="`sub_${idx1}`"
                          >
                            {{ item1 }}
                          </div>
                        </div>
                      </div>
                      <div class="action">
                        <v-btn
                          rounded
                          x-small
                          color="profileAnchorColor"
                          class="text-none whiteColor--text"
                          @click="
                            () =>
                              loadAndSaveCurrentSelectionForSection(
                                'subHeadings',
                                idx,
                                item
                              )
                          "
                        >
                          <span>Load</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  @click="() => (showSubheadingsHistoryModal = false)"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showParagraphOneSectionHistoryModal }"
            >
              <div
                class="modal-background"
                @click="showParagraphOneSectionHistoryModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div class="modal-title-size mainColor3--text mb-3">
                      Main Content history for section
                    </div>
                    <div
                      v-for="(
                        item, idx
                      ) in selectedParagraphSectionAIResultHistory"
                      :key="idx"
                      class="history-box"
                    >
                      <div class="result">
                        <div class="timestamp">
                          <span>{{ item.timestamp }}</span>
                        </div>
                        {{ item.answer }}
                      </div>
                      <div class="action">
                        <v-btn
                          rounded
                          x-small
                          color="profileAnchorColor"
                          class="text-none whiteColor--text"
                          @click="
                            () =>
                              loadAndSaveCurrentSelectionForSection(
                                'p',
                                idx,
                                item
                              )
                          "
                        >
                          <span>Load</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  @click="() => (showParagraphOneSectionHistoryModal = false)"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showEditAIResultModal }">
              <div
                class="modal-background"
                @click="showEditAIResultModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-title-size mainColor3--text">Edit</div>
                  <v-textarea
                    outlined
                    color="greenColor"
                    class="mt-5"
                    v-model="editResultText"
                  >
                  </v-textarea>

                  <div class="text-right">
                    <v-btn
                      rounded
                      outlined
                      color="mainColor3"
                      class="button-with-icon text-none mr-3"
                      @click="showEditAIResultModal = false"
                    >
                      <span>Cancel</span>
                    </v-btn>
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none"
                      @click="saveEditedAIResultAction"
                    >
                      <span>Save</span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showLogModal }">
              <div class="modal-background" @click="showLogModal = false"></div>
              <div class="modal-content">
                <div class="box modal-container" style="min-height: 90vh">
                  <div class="modal-title-size mainColor3--text">Prompt logs</div>

                  <v-textarea
                    outlined
                    :value="allLogs.join(`\n${'-'.repeat(60)}\n`)"
                    readonly
                    rows="20"
                  ></v-textarea>

                  <div class="text-right">
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none ml-2"
                      @click="showLogModal = false"
                    >
                      Close
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showRegenerateFreeWarnModal }"
            >
              <div
                class="modal-background"
                @click="showRegenerateFreeWarnModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="d-flex align-center">
                    <img
                      src="/static/ai-credits-1-icon.png"
                      style="width: 30px; height: auto"
                    />
                    <div class="ml-3 text-h5 font-weight-medium mainColor3--text">
                      Clicking regenerate requires 1 POP credit.
                    </div>
                  </div>
                  <div class="mainColor3--text mt-5">
                    You get 5 free regeneration clicks per article. Each click
                    after that requires 1 POP credit.
                  </div>
                  <div class="d-flex align-center mt-5">
                    <div class="flex-grow-1">
                      <v-checkbox
                        dense
                        v-model="regenerateFreeWarnAcknowledged"
                        hide-details
                        label="Do not show this again checkbox."
                      >
                      </v-checkbox>
                    </div>
                    <div>
                      <v-btn
                        rounded
                        outlined
                        color="mainColor3"
                        class="button-with-icon mainColor3--text text-none mr-2"
                        @click="showRegenerateFreeWarnModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none"
                        @click="proceedRegenerateFree"
                      >
                        Ok, proceed
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showRegenerateChargeWarnModal }"
            >
              <div
                class="modal-background"
                @click="showRegenerateChargeWarnModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div>
                    <div
                      class="mainColor3--text mb-5"
                      style="font-size: 20px; line-height: 25px"
                    >
                      You have used all of your free regeneration clicks for this
                      article. Each regeneration click will require 1 POP credit.
                    </div>
                  </div>
                  <div>
                    <v-checkbox
                      v-model="regenerateChargeWarnAcknowledged"
                      hide-details
                      label="Do not show this again checkbox."
                    ></v-checkbox>
                  </div>
                  <div class="text-right">
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none"
                      @click="proceedRegenerateCharge"
                    >
                      Proceed
                    </v-btn>
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none ml-2"
                      @click="showRegenerateChargeWarnModal = false"
                    >
                      Cancel
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showDisclaimerModal }">
              <div
                class="modal-background"
                @click="showDisclaimerModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div>
                      <div
                        class="mainColor3--text font-weight-bold mb-5"
                        style="font-size: 20px"
                      >
                        Disclaimer:
                      </div>
                      <div
                        class="mainColor3--text mb-5"
                        style="font-size: 16px; line-height: 18px"
                      >
                        We strongly advise against choosing a word count lower
                        than the target suggested. It is important to note that
                        target optimization ranges and the overall optimization
                        score will be influenced by the selected word count.
                        <br /><br />
                        Additionally, please be aware that if you optimize
                        according to a custom word count, the score generated by
                        the AI writer may differ from that of the score in the
                        Content Brief or Extension.
                      </div>
                    </div>
                    <div class="text-right">
                      <v-btn
                        rounded
                        outlined
                        color="mainColor3"
                        class="button-with-icon mainColor3--text text-none ml-2"
                        @click="showDisclaimerModal = false"
                      >
                        <span>Cancel</span>
                      </v-btn>
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none ml-2"
                        @click="
                          () => {
                            confirmedDisclaim = true;
                            showDisclaimerModal = false;
                            goNext(STEPS.SETTING);
                          }
                        "
                      >
                        <span>Next</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{
                'is-active':
                  showWarnForEditNowAllowdAfterParagraphGenerationModal,
              }"
            >
              <div
                class="modal-background"
                @click="
                  showWarnForEditNowAllowdAfterParagraphGenerationModal = false
                "
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="pad25">
                    <div>
                      <div
                        class="mainColor3--text font-weight-bold mb-5"
                        style="font-size: 20px"
                      >
                        Attention, Please!
                      </div>
                      <div
                        class="mainColor3--text mb-5"
                        style="font-size: 16px; line-height: 18px"
                      >
                        You are now about to proceed to the next step in your AI
                        workflow. Kindly note, once you click on the 'NEXT'
                        button, you will not be able to go back and make edits to
                        the titles or subheadings in the previous step.
                        <br /><br />
                        Please review all your entries and ensure you are
                        completely satisfied, as this action cannot be undone.
                        <br /><br />
                        Are you sure you want to proceed?
                      </div>
                    </div>
                    <div class="text-right">
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none ml-2"
                        @click="
                          () => {
                            showWarnForEditNowAllowdAfterParagraphGenerationModal = false;
                            goNext({})
                          }
                        "
                      >
                        <span>Ok</span>
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        color="mainColor3"
                        class="button-with-icon mainColor3--text text-none ml-2"
                        @click="
                          () => {
                            showWarnForEditNowAllowdAfterParagraphGenerationModal = false;
                          }
                        "
                      >
                        <span>Cancel</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showAddSubheadingsModal }">
              <div
                class="modal-background"
                @click="showAddSubheadingsModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="pad25">
                    <div>
                      <div class="title-font is-size-4 mainColor3--text">
                        Add new subheading
                      </div>
                      <v-select
                        outlined
                        dense
                        hide-details
                        class="mt-5"
                        color="greenColor"
                        :items="[
                          { text: 'H2', value: 'H2' },
                          { text: 'H3', value: 'H3' },
                        ]"
                        label="Please select the tag"
                        v-model="newSubHeadingTag"
                      >
                      </v-select>
                      <v-text-field
                        outlined
                        dense
                        hide-details="auto"
                        color="greenColor"
                        class="mt-5"
                        label="Please input subheading text you want to add"
                        v-model="newSubheadingText"
                      >
                      </v-text-field>
                    </div>
                    <div class="text-right mt-10">
                      <v-btn
                        rounded
                        outlined
                        color="mainColor3"
                        class="button-with-icon mainColor3--text text-none ml-2"
                        @click="showAddSubheadingsModal = false"
                      >
                        <span>Cancel</span>
                      </v-btn>
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none ml-2"
                        :disabled="!newSubHeadingTag || !newSubheadingText"
                        @click="addNewSubheadingsAction"
                      >
                        <span>Add</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showDeleteConfirmModal }">
              <div
                class="modal-background"
                @click="showDeleteConfirmModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="pad25">
                    <div>
                      <div class="title-font is-size-4 mainColor3--text">
                        {{ deleteConfirmMessage }}
                      </div>
                    </div>
                    <div class="text-right mt-10">
                      <v-btn
                        rounded
                        outlined
                        color="mainColor3"
                        class="button-with-icon mainColor3--text text-none ml-2"
                        @click="showDeleteConfirmModal = false"
                      >
                        <span>Cancel</span>
                      </v-btn>
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none ml-2"
                        @click="
                          () => {
                            if (deleteAction) {
                              deleteAction();
                            }
                          }
                        "
                      >
                        <span>Yes</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showRegenerateInspirationModal }"
            >
              <div
                class="modal-background"
                @click="showRegenerateInspirationModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div>
                    <div>
                      <div class="title-font is-size-4 mainColor3--text">
                        Please tell the AI how to improve
                      </div>
                      <div v-if="!createFreshPromptNotRegen">
                        <v-textarea
                          outlined
                          hide-details
                          color="greenColor"
                          class="mt-5"
                          label="Give instructions on what to add or avoid to make the output better."
                          v-model="regenerateGuideText"
                        >
                        </v-textarea>
                      </div>
                      <div v-if="regenerateSectionName != 'p'" class="d-flex gap15 align-center mt-6">
                        <vue-toggles
                          :value="createFreshPromptNotRegen"
                          width="40"
                          height="20"
                          :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                          :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                          @click="createFreshPromptNotRegen = !createFreshPromptNotRegen"
                        ></vue-toggles>
                        <div>
                          I want POP AI to use its own prompt.
                        </div>
                      </div>
                    </div>
                    <div class="text-right mt-5">
                      <v-btn
                        rounded
                        outlined
                        color="mainColor3"
                        class="button-with-icon mainColor3--text text-none ml-2"
                        @click="showRegenerateInspirationModal = false"
                      >
                        <span>Cancel</span>
                      </v-btn>
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none ml-2"
                        :disabled="
                          !regenerateGuideText && !createFreshPromptNotRegen
                        "
                        @click="
                          () => {
                            showRegenerateInspirationModal = false;
                            if (mustRunCallback) {
                              scrollToTop();
                              mustRunCallback();
                            }
                          }
                        "
                      >
                        <span>Regenerate</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showRichContentItemEditModal }"
            >
              <div
                class="modal-background"
                @click="showRichContentItemEditModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div
                    class="modal-content-root"
                    v-if="showRichContentItemEditModal"
                  >
                    <div>
                      <div class="modal-title-size mainColor3--text">
                        Edit rich content ( {{ richContentItemToEdit.dispName }} )
                      </div>
                      <div class="mt-5">
                        <!-- {{ JSON.stringify(richContentItemToEdit) }} -->
                        <v-textarea
                          outlined
                          v-model="richContentItemToEdit.currentResult"
                        ></v-textarea>
                      </div>
                    </div>
                    <div class="text-right mt-10">
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none"
                        @click="() => saveEditedRichContentItem()"
                      >
                        <span>Save</span>
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        color="mainColor3"
                        class="button-with-icon mainColor3--text text-none ml-2"
                        @click="showRichContentItemEditModal = false"
                      >
                        <span>Close</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showRichContentItemHistoryModal }"
            >
              <div
                class="modal-background"
                @click="showRichContentItemHistoryModal = false"
              ></div>
              <div class="modal-content" style="min-width: 80vw">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div>
                      <div class="modal-title-size mainColor3--text">
                        Rich content history
                      </div>
                      <div class="mt-5">
                        <div
                          v-for="(
                            item, idx
                          ) in selectedRichContentItemAIResultHistory"
                          :key="idx"
                          class="history-box"
                        >
                          <div class="result">
                            <div class="timestamp">
                              <span>{{ item.timestamp }}</span>
                            </div>
                            <div>
                              <!-- {{ JSON.stringify(item) }} -->
                              <textarea
                                :value="item.data"
                                :rows="item.rowsRequired || 5"
                                readonly
                              >
                              </textarea>
                              <!-- {{ item.data }} -->
                            </div>
                          </div>
                          <div class="action">
                            <v-btn
                              rounded
                              x-small
                              color="profileAnchorColor"
                              class="text-none whiteColor--text"
                              @click="
                                () =>
                                  loadAndSaveCurrentSelectionForRichContent(
                                    idx,
                                    item
                                  )
                              "
                            >
                              <span>Load</span>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-right mt-10">
                      <v-btn
                        rounded
                        outlined
                        color="mainColor3"
                        class="button-with-icon mainColor3--text text-none ml-2"
                        @click="showRichContentItemHistoryModal = false"
                      >
                        <span>Close</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="Boolean(imageAIModalParams)"
              class="modal ai-generated-image-modal"
              :class="{ 'is-active': true }"
            >
              <div
                class="modal-background"
                @click="!generatingImageWithAI?() => {imageAIModalParams = null}:()=>{}"
              ></div>
              <div class="modal-content" style="max-width: 650px;">
                <div class="box modal-container">
                  <div class="modal-content-root" style="margin-top: 10px;">
                    <div>
                      <div class="modal-title-size mainColor3--text">
                        Generate your image with AI prompt
                      </div>
                      <div class="mt-7">
                        <div v-if="imageAIModalUsePrompt">
                          <div class="mainColor3--text mb-2">Describe your desired image</div>
                          <v-textarea
                            color="#D7D7D7"
                            outlined
                            dense
                            v-model="imageAIModalPrompt"
                            placeholder="Type in your prompt"
                            counter="200"
                            :counter-value="countWords"
                            :rules="[(v) => countWords(v) <= 200 || `Must be less than 200 words`]"
                          >
                          </v-textarea>

                          <div class="mt-4" style="display: flex; flex-direction: row; gap: 12px; padding: 20px; border-radius: 5px; background: #F7F7F7;">
                            <i class="material-icons-outlined" style="color: #6A798C;">lightbulb</i>
                            <div style="flex: 1;">
                              <div class="mainColor3--text font-weight-bold">Here’s a good example of a prompt that uses 127 words:</div>
                              <div class="mainColor3--text mt-2">“A breathtaking, cinematic digital painting capturing a mystical forest at dawn, where ethereal light filters through towering ancient trees, casting a magical glow on the lush, moss-covered ground. The scene is alive with bioluminescent plants, glowing fungi, and delicate fireflies hovering in the misty air. A serene waterfall cascades into a crystal-clear pool, reflecting the vibrant hues of the enchanted landscape. The atmosphere exudes a sense of wonder and tranquility, with intricate details in the flora, realistic textures, and dynamic lighting enhancing the immersive experience. The perspective is a professionally composed wide-angle shot, designed to be visually captivating and suitable for editorial, book covers, or high-end fantasy illustrations. The artwork remains purely illustrative, strictly avoiding any typography, text, letters, or numbers, ensuring a clean and polished presentation.”</div>
                            </div>
                          </div>
                        </div>

                        <div class="mt-5" style="display: flex; flex-direction: row; gap: 12px; align-items: center;">
                          <vue-toggles
                            :value="
                              !imageAIModalUsePrompt
                            "
                            width="40"
                            height="20"
                            :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                            :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                            @click="
                              () => {
                                imageAIModalUsePrompt = !imageAIModalUsePrompt
                              }
                            "
                          ></vue-toggles>
                          <div class="mainColor3--text" style="flex: 1;">I don’t want to create my prompt. Let POP AI create its own prompt</div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-10" style="display: flex; flex-direction: row; gap: 12px; align-items: center;">
                      <div style="flex: 1;">
                        <div v-if="isUsingOwnApiKey" style="display: flex; flex-direction: row; gap: 8px; align-items: center;">
                          <i class="material-icons" style="color: #0DAA68;">paid</i> <div class="mainColor3--text font-weight-bold" style="flex: 1;">Cost: 0 credits (using own OpenAI API key)</div>
                        </div>
                        <div v-else style="display: flex; flex-direction: row; gap: 8px; align-items: center;">
                          <i class="material-icons" style="color: #0DAA68;">paid</i> <div class="mainColor3--text font-weight-bold" style="flex: 1;">Image generation costs 1 credit</div>
                        </div>
                        <div class="mainColor3--text mt-2">Your balance: <span class="font-weight-bold">{{ ownerAvailableCredits }} credits</span></div>
                      </div>
                      <div>
                        <v-btn
                          rounded
                          outlined
                          color="mainColor3"
                          class="button-with-icon mainColor3--text text-none ml-2"
                          :disabled="generatingImageWithAI"
                          @click="imageAIModalParams = null"
                        >
                          <span>Cancel</span>
                        </v-btn>
                        <v-btn
                          rounded
                          color="profileAnchorColor"
                          class="text-none whiteColor--text ml-2"
                          :disabled="ownerAvailableCredits < 1 || (imageAIModalUsePrompt && countWords(imageAIModalPrompt) > 200)"
                          :loading="generatingImageWithAI"
                          @click="generateImageWithAI(imageAIModalParams.pSectionIdx, imageAIModalParams.uniqueIndex, imageAIModalUsePrompt?imageAIModalPrompt:'')"
                        >
                          <span>Generate image</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  :disabled="generatingImageWithAI"
                  @click="imageAIModalParams = null"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <div
              v-if="Boolean(deleteImageAIModalParams)"
              class="modal"
              :class="{ 'is-active': true }"
            >
              <div
                class="modal-background"
                @click="!deletingAiGeneratedImage?() => {deleteImageAIModalParams = null}:()=>{}"
              ></div>
              <div class="modal-content" style="max-width: 600px;">
                <div class="box modal-container">
                  <div class="modal-content-root" style="margin-top: 10px;">
                    <div>
                      <div class="modal-title-size mainColor3--text">
                        Delete AI-generated image
                      </div>
                      <div class="mt-7">
                        <div class="mainColor3--text mb-2" style="font-size: 1rem; line-height: 1.7;">Are you sure you want to delete the AI-generated image. You will not be able to restore it.</div>

                      </div>
                    </div>
                    <div class="mt-10" style="text-align: right;">
                      <v-btn
                        rounded
                        outlined
                        color="mainColor3"
                        class="button-with-icon mainColor3--text text-none ml-2"
                        :disabled="deletingAiGeneratedImage"
                        @click="deleteImageAIModalParams = null"
                      >
                        <span>Cancel</span>
                      </v-btn>
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="text-none whiteColor--text ml-2"
                        :loading="deletingAiGeneratedImage"
                        @click="deleteAIGeneratedImage(deleteImageAIModalParams.pSectionIdx, deleteImageAIModalParams.uniqueIndex)"
                      >
                        <span>Delete image</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  :disabled="deletingAiGeneratedImage"
                  @click="deleteImageAIModalParams = null"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <div
              class="modal openai-error-modal"
              :class="{ 'is-active': showErrorModal }"
            >
              <div class="modal-background" @click="showErrorModal = false"></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div
                      class="modal-title-size font-weight-bold mainColor3--text"
                    >
                      <template v-if="isOpenAiError">
                        Action Required: OpenAI API Key Issue Detected
                      </template>
                      <template v-else>
                        Action Needed: Contact Support for Account Assistance
                      </template>
                    </div>
                    <div
                      class="mt-5 mainColor3--text"
                      v-if="isOpenAiError && errorMsg"
                    >
                      {{ errorMsg }}
                    </div>
                    <div class="mt-5 mainColor3--text">
                      <template v-if="isOpenAiError">
                        We've detected a system error related to the OpenAI API
                        key. Please take the following steps to resolve this
                        issue:
                        <ol class="mt-2">
                          <li>
                            Check Your OpenAI Account Limits: Log in to your
                            OpenAI account and verify that you haven't exceeded
                            your
                            <a
                              href="https://platform.openai.com/usage"
                              target="_blank"
                              >usage limits.</a
                            >
                          </li>
                          <li>
                            Generate a New API Key: If your limits are within
                            acceptable ranges, try
                            <a
                              href="https://platform.openai.com/api-keys"
                              target="_blank"
                              >generating a new GPT4 API key from your OpenAI
                              account settings.</a
                            >
                          </li>
                          <li>
                            Update Your API Key: Once you have a new key, update
                            it in Account Setting under the relevant settings
                            section and retry the operation.
                          </li>
                        </ol>
                      </template>
                      <template v-else>
                        We've encountered an issue with your account that needs
                        your immediate attention. This situation requires direct
                        support intervention and cannot be fixed with usual
                        troubleshooting methods. Please follow these steps:
                        <ol class="mt-2">
                          <li>
                            Contact Support: Use the "Contact Support" chat in
                            your account or email our support team directly.
                          </li>
                          <li>
                            Include Account Details: Mention your account email,
                            report ID and a brief description of the problem.
                          </li>
                          <li>
                            Wait for Instructions: Our support team will guide you
                            on how to resolve the issue.
                          </li>
                        </ol>
                        <div class="mt-5">
                          We're here to help get things back on track quickly.
                          Thank you for your prompt action.
                        </div>
                      </template>
                    </div>
                    <div class="text-right mt-5">
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none"
                        @click="showErrorModal = false"
                      >
                        Ok
                      </v-btn>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  @click="() => (showErrorModal = false)"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <content-prompt-one-signal-generator-popup
              ref="contnentPromptGenerationModal"
              :aiRunId="aiRun ? aiRun.id : null"
              @completed="
                () => {
                  loadAiRun();
                }
              "
            ></content-prompt-one-signal-generator-popup>
          </div>
        </div>
      </div>
    </div>
    <span
      class="show-table-of-content-icon"
      v-if="
        currentStep == STEPS.PARAGRAPH &&
        pAIResult.length > 0 &&
        !showLeftSidebar
      "
      @click="
        () => {
          showLeftSidebar = true;
        }
      "
    >
      <span class="material-icons-outlined">menu_open</span>
    </span>

    <ai-writer-footer
      :currentStep="currentStep"
      :isStepValid="isStepValid"
      :goNext="goNext"
      :isLoading="isLoading"
      :mode="mode"
      :generateResultFor="generateResultFor"

      :isSpecialAdminUser="isSpecialAdminUser"
      :showLogModal.sync="showLogModal"
      
      :selectedCostedRichContentItems="selectedCostedRichContentItems"
      :numberOfWords="numberOfWords"
      :estimatedArticleCreditsRequired="estimatedArticleCreditsRequired"
      :estimatedRichContentCreditsRequired="estimatedRichContentCreditsRequired"
      :estimatedCreditsRequired="estimatedCreditsRequired"
      :ownerAvailableCredits="ownerAvailableCredits"

      :isDemo="isDemo"  
      :page="page"
      :pageRun="pageRun"
      :aiRun="aiRun"
      :pAIResult="pAIResult"
      :downloadAIResult="downloadAIResult"
      :downloadAIResultAsZip="downloadAIResultAsZip"
      :downloadAIResultAsDocx="downloadAIResultAsDocx"
      :downloadOutlines="downloadOutlines"
      :goBack="goBack"
      @startOver="$emit('startOver')"

      :articleCredits="articleCredits"
      :richContentCreditsForArticle="richContentCreditsForArticle"
      :titleRegenerateCount="titleRegenerateCount"
      :pageTitleRegenerateCount="pageTitleRegenerateCount"
      :subHeadingsRegenerateCount="subHeadingsRegenerateCount"
      :paragraphRegenerateCount="paragraphRegenerateCount"
      :contentPromptSignalRegenerateCount="contentPromptSignalRegenerateCount"
      :totalCreditsSpent="totalCreditsSpent"
    />
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

import { useVuelidate } from '@vuelidate/core'
import { required, maxValue, maxLength } from '@vuelidate/validators'

import { toLocalDateString } from "@/utils";
import {
  beautifyHtmlString,
  downloadAsHtml,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/helper.js";
import {
  STEPS,
  CONTENT_PROMPT_SIGNALS_TOOLTIPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";
import { DEMO_PRICES } from "@/components/PageRun/constants.js";
import { mixin as cb_mixin } from "@/components/FullPageSourceEditorV2/components/side-pane/ContentBrief/cb_mixin";
// import draggable from "vuedraggable";

import StepsIndicator from "@/components/FullPageSourceEditorV2/components/AiWriter/components/StepsIndicator.vue";
import AiWriterActionsHead from '@/components/FullPageSourceEditorV2/components/AiWriter/components/AiWriterActionsHead.vue';
import AiWriterFooter from '@/components/FullPageSourceEditorV2/components/AiWriter/components/AiWriterFooter.vue';
import TableOfContents from "@/components/FullPageSourceEditorV2/components/AiWriter/components/TableOfContents.vue";
import ProgressBar from '@/components/FullPageSourceEditorV2/components/AiWriter/components/ProgressBar.vue';

import Step0Start from '@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step0Start.vue';
import Step1Configs from '@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/Index.vue';
import Step4SearchPageTitle from '@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step4SearchPageTitle.vue';
import Step5PageTitle from '@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step5PageTitle.vue';
import Step6Subheadings from '@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step6Subheadings.vue';
import Step7Paragraph from '@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step7Paragraph.vue';

import Result4And5PageTitle from '@/components/FullPageSourceEditorV2/components/AiWriter/results/Result4And5PageTitle.vue';
import Result6Subheadings from '@/components/FullPageSourceEditorV2/components/AiWriter/results/Result6Subheadings.vue';
import Result6SubheadingsDemo from '@/components/FullPageSourceEditorV2/components/AiWriter/results/Result6SubheadingsDemo.vue';
import Result7Paragraph from '@/components/FullPageSourceEditorV2/components/AiWriter/results/Result7Paragraph.vue';

import ContentPromptOneSignalGeneratorPopup from "@/components/FullPageSourceEditorV2/components/AiWriter/modals/ContentPromptOneSignalGeneratorPopup.vue";


const costedContentPromptSignals = [
  "list",
  "Frequently Asked Questions",
  "Table Total",
  "Image File total",
  "meta_description",
];

const defaultEnabledContentPromptSignalsForBlogPosts = [
  "intro_section",
  "Brief Overview",
  "Key Highlights",
  "end_section",
];

const channel = new BroadcastChannel('silo_ai_writer_channel');

export default {
  name: "AiWriter",
  setup () {
    return { v$: useVuelidate() }
  },
  mixins: [cb_mixin],
  props: [
    "aiRunId",
    "pageRunId",
    "pageRun",
    "runStrategies",
    "page",
    // 'initialData',
    // 'saveOpenAITabData', // function
    "currentWordCount",
    "targetWordCount",
    "forwardResultToEditor",
  ],
  components: {
    ContentPromptOneSignalGeneratorPopup,
    StepsIndicator,
    AiWriterActionsHead,
    AiWriterFooter,
    TableOfContents,
    ProgressBar,
    Step0Start,
    Step1Configs,
    Step4SearchPageTitle,
    Step5PageTitle,
    Step6Subheadings,
    Step7Paragraph,
    Result4And5PageTitle,
    Result6Subheadings,
    Result6SubheadingsDemo,
    Result7Paragraph,
  },
  computed: {
    defaultEnabledContentPromptSignalsForBlogPosts() {
      return defaultEnabledContentPromptSignalsForBlogPosts
    },
    popCharges() {
      return this.$store.state.popCharges;
    },
    popCreditCharges() {
      return this.$store.state.popCreditCharges;
    },
    articleCredits () {
        if (!this.aiRun) return 0;
        return this.aiRun.articleCredits;
    },
    richContentCreditsForArticle () {
        if (!this.aiRun) return 0;
        return this.aiRun.richContentCreditsForArticle;
    },
    titleRegenerateCount () {
        if (!this.aiRun) return 0;
        return this.aiRun.titleRegenerateCount;
    },
    pageTitleRegenerateCount () {
        if (!this.aiRun) return 0;
        return this.aiRun.pageTitleRegenerateCount;
    },
    subHeadingsRegenerateCount () {
        if (!this.aiRun) return 0;
        return this.aiRun.subHeadingsRegenerateCount;
    },
    paragraphRegenerateCount () {
        if (!this.aiRun) return 0;
        return this.aiRun.paragraphRegenerateCount;
    },
    contentPromptSignalRegenerateCount () {
        if (!this.aiRun) return 0;
        return this.aiRun.contentPromptSignalRegenerateCount;
    },
    totalCreditsSpent () {
        return this.articleCredits + this.richContentCreditsForArticle + this.titleRegenerateCount + this.pageTitleRegenerateCount + this.subHeadingsRegenerateCount + this.paragraphRegenerateCount + this.contentPromptSignalRegenerateCount
    },
    isAiWriter() {
      return this.$route.query.editorMode === 'AI_MODE';
    },
    projectURL() {
      return `/#/sites/${this.page.siteId}/pages`;
    },
    pageURL() {
      return `${this.projectURL}/${this.page.id}`;
    },
    aiWriterURL() {
      return `${this.pageURL}?tab=ai-writer`;
    },
    reportURL() {
      return `${this.pageURL}/pageruns/${this.pageRun.id}`;
    },
    isDemo() {
      return this.$store.getters.isDemoUser;
    },
    pAIResultClone() {
      return JSON.parse(JSON.stringify(this.pAIResult));
    },
    isOpenAiError() {
      return this.errorMsg.toLowerCase().includes("openai");
    },
    pageNotBuilt() {
      if (this.pageRun) {
        return this.pageRun.pageNotBuilt;
      }
      return false;
    },
    CONTENT_PROMPT_SIGNALS_TOOLTIPS() {
      return CONTENT_PROMPT_SIGNALS_TOOLTIPS;
    },
    DEMO_PRICES() {
      return DEMO_PRICES;
    },
    isFailedPageRun() {
      return this.pageRun.status == 'FAILURE'
    },
    availableSteps () {
      return Object.values(STEPS).filter(step => {
        if (step === STEPS.START) return false;
        if (step === STEPS.SEARCH_ENGINE_TITLE && !this.separateSearchEngineTitleAndTitle) return false;
        if (this.mode === 'auto') {
          if (step === STEPS.PAGE_TITLE) return false;
          if (step === STEPS.SUBHEADINGS && !this.separateSearchEngineTitleAndTitle) return false;
        }
        return true;
      })
    },
    tagTotalData() {
      if (!this.pageRun) {
        return [];
      }
      return this.pageRun.tagCounts;
    },
    scaleFactorForRichContent() {
      if (this.targetWordCount > 0) {
        return (this.numberOfWords * 1.0) / this.targetWordCount;
      }
      return 1;
    },
    richContentItemsForAIWriter() {
      const allowed_signals = [
        "Brief Overview",
        "Key Highlights",
        "Bold Tag Total",
        "Italic Tag Total",
        "Image File total",
        "list",
        "Table Total",
        "Form Total",
        "Frequently Asked Questions",
        "Table of content",
        "Internal links",
      ];
      const must_1_signals = [
        "Frequently Asked Questions",
        "Table of content",
        
        "meta_description",
        "Brief Overview",
        "Key Highlights",
        "intro_section",
        "end_section",
        "Internal links",
      ];

      const customDispNames = {
        list: "Lists",
        "Table Total": "Tables",
        "Table of content": "Table of Contents",
        "Frequently Asked Questions": "Frequently Asked Questions",
        meta_description: "Meta description",
        intro_section: "Introduction paragraph",
        "Brief Overview": "Brief Overview",
        "Key Highlights": "Key Highlights",
        end_section: "Conclusion paragraph",
        "Internal links": "Internal links",
      };

      let signalsFound = [];
      let rlt = this.pageStructureRecommendations.filter((item) => {
        let isAllowedSignal = allowed_signals.includes(item.signalName);
        if (isAllowedSignal) {
          signalsFound.push(item.signalName);
        }
        return isAllowedSignal;
      });

      let signalsToAddManuallyIfMissed = [
        "Brief Overview",
        "Key Highlights",
        "list",
        "Table Total",
        "Frequently Asked Questions",
        "Table of content",

        "meta_description",
        "intro_section",
        "end_section",
        "Internal links",
      ];

      signalsToAddManuallyIfMissed.forEach((signal) => {
        if (!signalsFound.includes(signal)) {
          rlt.push({
            signalName: signal,
            targetMin: 5,
            targetMax: 10,
          });
        }
      });

      rlt = rlt.map((item) => {
        let scaleFactor = this.scaleFactorForRichContent;

        const updatedItem = {
          ...item
        }

        updatedItem.targetMin = Math.ceil(item.targetMin * scaleFactor);
        updatedItem.targetMax = Math.ceil(item.targetMax * scaleFactor);

        if (must_1_signals.includes(item.signalName)) {
          updatedItem.isYesNoSignal = true;
          updatedItem.targetMin = 0;
          updatedItem.targetMax = 1;
        }
        if (item.signalName === 'Internal links') {
          updatedItem.targetMin = 0;
          updatedItem.targetMax = 3;
        }
        if (Object.keys(customDispNames).includes(item.signalName)) {
          updatedItem.dispName = customDispNames[item.signalName];
        }
        return updatedItem;
      });
      return this.reorderItems(rlt);
    },
    cleanedContentPromptUserInput() {
      let data = {};
      let isValid = true;
      this.selectedContentPromptsSignals.map((signalName) => {
        let targetVal = this.selectedCountForPromptSignals[signalName] || 0;
        let found = this.richContentItemsForAIWriter.find(
          (item) => item.signalName == signalName
        );
        if (!found || targetVal <= 0 || targetVal > found.targetMax) {
          isValid = false;
        }
        data[signalName] = targetVal;
      });
      if (!isValid) {
        return isValid;
      }
      return data;
    },
    aiGeneratedImagesMap() {
      // result is in the format:
      // {
      //   [p_section_index]: {
      //     [unique_index]: {
      //       filename: ..,
      //       url: ..
      //     },
      //     ..
      //   }
      //   ..
      // }
      
      const result = {};
      
      (this.aiRun?.aiGeneratedImages || []).forEach(item => {
        result[item.p_section_index] = {
          ...result[item.p_section_index] || {},
          [item.unique_index]: {
            filename: item.filename,
            url: `${API_URL}/ai-run/images/${item.filename}`
          }
        }
      })

      return result
    },
    pageTitlelabel() {
      if (this.separateSearchEngineTitleAndTitle) {
        return "Page title";
      } else {
        return "Search engine title and page title";
      }
    },
    isUsingOwnApiKey() {
      return this.isSubAccountRelationSelected
        ? this.ownerUser.openAIKey && this.ownerUser.useMyOwnApiKey
        : this.user.openAIKey && this.user.useMyOwnApiKey;
    },
    selectedCostedRichContentItems() {
      return this.richContentItemsForAIWriter
        .filter(item => {
          return this.selectedContentPromptsSignals.includes(item.signalName) && this.costedContentPromptSignals.includes(item.signalName)
        })
    },
    estimatedArticleCreditsRequired () {
        return this.isUsingOwnApiKey
        ? this.popCreditCharges.aiWriterCreditsFlatRateOwnApiKey
        : this.popCreditCharges.aiWriterCreditsPer1000Words * Math.ceil(this.numberOfWords / 1000);
    },
    estimatedRichContentCreditsRequired () {
      let cost = 0
      const countData = this.selectedCountForPromptSignals;
      if (!this.isUsingOwnApiKey) {
        this.selectedContentPromptsSignals
          .filter((signalName) =>
            this.costedContentPromptSignals.includes(signalName)
          )
          .forEach((signalName) => {
            let count = countData[signalName];
            if (typeof count == "number") {
              cost += count;
            }
          });
      }
      return cost
    },
    estimatedCreditsRequired() {
      return (
        this.estimatedArticleCreditsRequired
        + this.estimatedRichContentCreditsRequired
      )
    },
    user() {
      return this.$store.state.user;
    },
    onlyForViewPurpose() {
      if (this.user && this.aiRun) {
        return this.user.isAdmin && this.user.id != this.aiRun.userId && !this.isSubAccountRelationSelected;
      }
      return false;
    },
    isSubAccountRelationSelected() {
      return !!this.$store.state.selectedSubAccountRelation;
    },
    ownerUser() {
      return this.$store.state.selectedSubAccountRelation || this.user;
    },
    ownerAvailableCredits() {
      if (this.ownerUser) {
        return (
          this.ownerUser.monthlyCredits +
          this.ownerUser.remainedUniversalCredits
        );
      }
      return 0;
    },
    hasEnoughCredits() {
      try {
        return this.ownerAvailableCredits - this.estimatedCreditsRequired >= 0;
      } catch {
        return false;
      }
    },
    titleAIResultHistory() {
      if (this.aiRun) {
        return this.aiRun.searchEngineTitleResult;
      }
      return [];
    },
    pageTitleAIResultHistory() {
      if (this.aiRun) {
        return this.aiRun.pageTitleResult;
      }
      return [];
    },
    subheadingsAIResultHistory() {
      if (this.aiRun) {
        return this.aiRun.subheadingsResult.map((item) => {
          item.dataAsList = item.data.split("\n");
          return item;
        });
      }
      return [];
    },
    selectedParagraphSectionAIResultHistory() {
      if (
        this.aiRun &&
        this.aiRun.paragraphResult.length > this.seletedParagraphSection
      ) {
        return this.aiRun.paragraphResult[this.seletedParagraphSection];
      }
      return [];
    },

    selectedRichContentItemAIResultHistory() {
      if (!this.aiRun || !this.contentPromptAiResults) {
        return [];
      }
      try {
        let { key, uniqueIndex } = this.selectedRichContentItemData;
        let dataForOneSignal = this.contentPromptAiResults[key];
        if (!dataForOneSignal) {
          return [];
        }
        let found = dataForOneSignal.find(
          (item) => item.uniqueIndex == uniqueIndex
        );
        if (found) {
          return found.aiResults.map((item) => {
            item.data = beautifyHtmlString(item.data);
            let rowsRequired = item.data.split("\n").length;
            item.rowsRequired = rowsRequired > 10 ? 10 : rowsRequired;
            return item;
          });
        }
        return [];
      } catch (e) {
        console.log(e);
      }
      return [];
    },

    isSpecialAdminUser() {
      return this.$store.getters.isSpecialAdminUser;
    },
    cleanedContentBrief() {
      let found = (this.runStrategies || []).find(
        (item) => item.dashboardType == "contentBrief"
      );
      if (found) {
        return found;
      }
      return {};
    },
    fullHtml() {
      let titleTag = "";
      let pageTitleTag = "";
      if (this.separateSearchEngineTitleAndTitle) {
        titleTag = this.titleAIResult;
        pageTitleTag = this.pageTitleAIResult;
      } else {
        let tags = this.pageTitleAIResult.split("\n");
        titleTag = tags.length >= 1 ? tags[0] : "";
        pageTitleTag = tags.length >= 2 ? tags[1] : "";
      }

      let metaDescription = "";

      let subHeadingTags =
        this.pAIResult.length > 0 ? "" : this.subHeadingsAIResult;
      let sectionData = this.pAIResult.map((item, idx) => {
        if (item.metaSection) {
          metaDescription = item.answer;
          return "";
        }

        let rlt = "";

        if (item.introSection || item.endSection) {
          let sectionResult = item.answer.trim();
          if (!sectionResult.startsWith("<p>")) {
            rlt += `<p>${item.answer}</p>`;
          } else {
            rlt += `${item.answer}`;
          }
        } else {
          let subheadingTag = item["subheadingInfo"]["tagName"];
          let subheadingText = item["subheadingInfo"]["tagText"];
          let sectionResult = item.answer.trim();

          rlt += `<${subheadingTag}>${subheadingText}</${subheadingTag}>`;

          if (!sectionResult.startsWith("<p>")) {
            rlt += `<p>${item.answer}</p>`;
          } else {
            rlt += `${item.answer}`;
          }
        }

        rlt += "\n";
        let allContentPromptDataForSection =
          this.getAllContentPromptDataForPSection(idx);
        allContentPromptDataForSection.map((item) => {
          rlt += `<div>Rich content results: ${item.dispName}</div>\n`;
          switch (item.shortenKey) {
            case "form":
              rlt += `<form><!-- your form here --></form>\n`;
              break;
            case "image":
              rlt += `<img src="${this?.aiGeneratedImagesMap?.[idx]?.[item.uniqueIndex]?.filename || 'your image file link here'}" alt="your image alt text here" />\n`;
              break;
            case "list":
            case "table":
            case "faq":
              if (!item.isEmpty) {
                rlt += `${item.resultToDisplay || item.currentResult}\n`;
              }
              break;
            default:
              rlt += `${item.currentResult}\n`;
              break;
          }
          rlt += "\n";
        });
        return rlt;
      });

      let result = `
        <!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta http-equiv="X-UA-Compatible" content="IE=edge">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              ${titleTag}
              ${metaDescription}
            </head>
            <body>
              ${pageTitleTag}
              ${subHeadingTags}
              ${sectionData.join(`\n`)}
            </body>
          </html>
      `;
      return result;
    },
    contentPromoptPlaceHolderData() {
      // this is about content promopt signals which are just place holder
      if (this.aiRun) {
        return this.aiRun.contentPromoptPlaceHolderData;
      }
      return {};
    },
    contentPromptAiResults() {
      // content promopt signals which is not place holder (generates with ai)
      if (this.aiRun) {
        return this.aiRun.contentPromptAiResults;
      }
      return {};
    },
    shouldDisplayTableOfContent() {
      if (this.aiRun) {
        return this.aiRun.shouldDisplayTableOfContent;
      }
      return false;
    },
    userInputedParagraphWordCounts() {
      return this.userInputParagraphs.map((item) => {
        return item.split(/\s+/).filter(Boolean).length;
      });
    },
  },
  watch: {
    isLoading: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.$nextTick(() => {
          const found = document.querySelector('.progress-bar');
          if (found) {
            found.scrollIntoView({behavior: 'smooth'});
          }
        })
      }
    },
    currentStep: function (newVal, oldVal) {
      this.$store.commit("setAiWriterStep", newVal);
      
      if (newVal && newVal > (oldVal || 0)) {
        this.$nextTick(() => {
          let found = (
            document.querySelector('.step-scroll-target')
            || document.querySelector('.ai-writer-head')
          );
          if (found) {
            found.scrollIntoView({behavior: 'smooth'});
          }
        })
      }
    },
    aiRun: function (newVal, oldVal) {
      if (newVal?.creditsUsed !== oldVal?.creditsUsed) {
        // reload user to update ui with any credit usage
        this.$store.dispatch("loadUser").then(response => {
          this.$store.commit("setUser", response.data.user);
        });
      }
    },
    pAIResultClone: {
      handler(newVal, oldVal) {
        this.$store.commit("setAiWriterNeedsToSave", true);
        // this.handleParagraphEditDebounced(idx, data)

        if (Array.isArray(newVal) && Array.isArray(oldVal)) {
          newVal.map((item, idx) => {
            if (oldVal.length > idx && item.answer != oldVal[idx].answer) {
              this.handleParagraphEditDebounced(idx, item.answer);
            }
          });
        }
      },
      deep: true,
    },
    optimizeCurrentPageTitle: function (newVal, oldVal) {
      if (newVal) {
        this.usePageTitleInspirationText = false;
        this.useMyCurrentPageTitle = false;
      }
    },
    optimizeCompetitorPageTitle: function (newVal, oldVal) {
      if (newVal) {
        this.usePageTitleInspirationText = false;
        this.useMyCurrentPageTitle = false;
      }
    },
    usePageTitleInspirationText: function (newVal, oldVal) {
      if (newVal) {
        this.optimizeCurrentPageTitle = false;
        this.optimizeCompetitorPageTitle = false;
        this.useMyCurrentPageTitle = false;
      }
    },
    useMyCurrentPageTitle: function (newVal, oldVal) {
      if (newVal) {
        this.optimizeCurrentPageTitle = false;
        this.optimizeCompetitorPageTitle = false;
        this.usePageTitleInspirationText = false;
      }
    },

    optimizeCurrentTitle: function (newVal, oldVal) {
      if (newVal) {
        this.useTitleInspirationText = false;
        this.useMyCurrentTitle = false;
      }
    },
    optimizeCompetitorTitle: function (newVal, oldVal) {
      if (newVal) {
        this.useTitleInspirationText = false;
        this.useMyCurrentTitle = false;
      }
    },
    useTitleInspirationText: function (newVal, oldVal) {
      if (newVal) {
        this.optimizeCurrentTitle = false;
        this.optimizeCompetitorTitle = false;
        this.useMyCurrentTitle = false;
      }
    },
    useMyCurrentTitle: function (newVal, oldVal) {
      if (newVal) {
        this.optimizeCurrentTitle = false;
        this.optimizeCompetitorTitle = false;
        this.useTitleInspirationText = false;
      }
    },

    optimizeCurrentH2s: function (newVal, oldVal) {
      if (newVal) {
        this.useMySubheadings = false;
      }
    },
    optimizeCompetitorsH2s: function (newVal, oldVal) {
      if (newVal) {
        this.useMySubheadings = false;
      }
    },
    useMySubheadings: function (newVal, oldVal) {
      if (newVal) {
        this.optimizeCurrentH2s = false;
        this.optimizeCompetitorsH2s = false;
      }
    },

    fullHtml: function (newVal, oldVal) {
      // just emit html but don't update
      this.$emit("onCalcChecklist", {
        aiRunId: this.aiRun ? this.aiRun.id : 0,
        htmlStr: newVal,
        updateScore: false,
      });
    },
    calculated_subHeadingsAIResultArr: function (newVal, oldVal) {
      if (newVal) {
        this.userInputParagraphs = Array(
          this.calculated_subHeadingsAIResultArr.length
        ).fill("");
      }
    },
    isInputParagraphByUser: function (newVal, oldVal) {
      if (newVal) {
        this.userInputParagraphs = Array(
          this.calculated_subHeadingsAIResultArr.length
        ).fill("");
      }
    },
    selectedWordCountOption: function (newVal, oldVal) {
      let wordCount = 0;
      switch (newVal) {
        case "currentWordCount":
          wordCount = this.currentWordCount;
          break;
        case "targetWordCount":
          wordCount = this.targetWordCount;
          break;
        case "customWordCount":
          wordCount = 500;
          break;
      }
      this.numberOfWords = wordCount;
    },
    currentWordCount: function (newVal, oldVal) {
      if (this.selectedWordCountOption == "currentWordCount") {
        this.numberOfWords = newVal;
      }
    },

    taskId: function (newVal, oldVal) {
      if (oldVal != newVal && newVal) {
        if (this.taskInterval) {
          this.clearTaskInterval(true);
        }
        this.taskInterval = setInterval(() => {
          this.$store.dispatch("getTaskStatus", newVal).then((response) => {
            if (response.data && response.data.status) {
              if (response.data.status == "SUCCESS") {
                let sectionName = this.runningSection;
                if (this.currentSection === "p" || this.currentSection === "subHeadings") {
                  channel.postMessage('ai_writer_updated');
                }

                if (response.data.targetUrlSourceCode) {
                  this.targetUrlSourceCode = response.data.targetUrlSourceCode;
                }

                if (sectionName == "title") {
                  this.titleAIResult = response.data.aiAnswer;
                  this.allLogs.push(
                    ">>>>>Search engine title prompt\n" + response.data.prompt
                  );
                  this.allLogs.push(
                    ">>>>>Search engine title Answer\n" + response.data.aiAnswer
                  );
                } else if (sectionName == "pageTitle") {
                  this.pageTitleAIResult = response.data.aiAnswer;
                  this.allLogs.push(
                    ">>>>>Page title prompt\n" + response.data.prompt
                  );
                  this.allLogs.push(
                    ">>>>>Page title Answer\n" + response.data.aiAnswer
                  );
                } else if (sectionName == "subHeadings") {
                  this.subHeadingsAIResult = response.data.aiAnswer;
                  this.maxRecommendedSubheadingsCount =
                    response.data.maxRecommendedSubheadingsCount;
                  if (response.data.prompt) {
                    this.allLogs.push(
                      ">>>>>Subheadings prompt\n" + response.data.prompt
                    );
                    if (response.data.subheadingsPromptAdminMessages) {
                      this.allLogs.push(
                        "\n>>>>Subheadings admin prompts\n" +
                          response.data.subheadingsPromptAdminMessages
                            .map((item) => item?.content || JSON.stringify(item))
                            .join(`\n${".".repeat(10)}\n`)
                      );
                    }
                    this.allLogs.push(
                      ">>>>>Subheadings Answer\n" + response.data.aiAnswer
                    );
                  }
                } else if (sectionName == "p") {
                  if (Array.isArray(response.data.aiAnswer) || typeof response.data.aiAnswer === 'object') {
                    response.data.aiAnswer.forEach((item) => {
                      if (item?.briefOverview) {
                        this.allLogs.push(
                          ">>>>>Brief Overview prompt\n" + item.prompt
                        );
                        this.allLogs.push(
                          ">>>>>Brief Overview Answer\n" + item.answer
                        );
                      } else if (item?.keyTakeaways) {
                        this.allLogs.push(
                          ">>>>>Key Takeaways prompt\n" + item.prompt
                        );
                        this.allLogs.push(
                          ">>>>>Key Takeaways Answer\n" + item.answer
                        );
                      }
                    });
                  }
                  const pSectionId = response.data.pSectionId;
                  if (pSectionId > -1) {
                    this.pAIResult = this.pAIResult.map((item, idx) => {
                      if (idx == pSectionId) {
                        item["answer"] = response.data.aiAnswer;
                        if (response.data.prompt) {
                          this.allLogs.push(
                            ">>>>>Paragraph section retry prompt\n" +
                              response.data.prompt
                          );
                          this.allLogs.push(
                            ">>>>>Paragraph section retry answer\n" +
                              response.data.aiAnswer
                          );
                        }
                      }
                      return item;
                    });
                  } else {
                    this.pAIResult = response.data.aiAnswer;

                    if (this.isInputParagraphByUser) {
                      // collapse once we got result.
                      this.isExpandInputParagraphSection = false;
                    }

                    if (response.data.prompt1) {
                      this.allLogs.push(
                        ">>>>>Paragraph first phase prompt prompt\n" +
                          response.data.prompt1
                      );
                      this.allLogs.push(
                        ">>>>>Paragraph first phase AI answer\n" +
                          response.data.prompt1Result
                      );
                    }
                    if (response.data.prompt2) {
                      this.allLogs.push(
                        ">>>>>Paragraph second phase prompts\n" +
                          response.data.prompt2.map((prompt, index) => {
                            return [
                              `Paragraph ${index + 1} prompt:\n\n`,
                              prompt,
                              '\n\n',
                              `Paragraph ${index + 1} admin prompt:\n\n`,
                              `${response?.data?.promptAdminMessages?.[index]?.map?.((item) => item?.content || JSON.stringify(item))}`,
                            ].join('')
                          }).join(`\n${".".repeat(10)}\n`)
                      );
                    }

                    // this.allLogs.push('>>>>>Paragraph answers\n' + response.data.aiAnswer.join(`\n${ '.'.repeat(10) }\n`))
                  }
                }

                if (response.data.targetUrlSourceCode) {
                  this.targetUrlSourceCode = response.data.targetUrlSourceCode;
                }
                if (response.data.competitorsSourceCode) {
                  this.competitorsSourceCode =
                    response.data.competitorsSourceCode;
                }
                if (response.data.targetUrlParagraphs) {
                  this.targetUrlParagraphs = response.data.targetUrlParagraphs;
                }
                if (response.data.competitorsParagraphs) {
                  this.competitorsParagraphs =
                    response.data.competitorsParagraphs;
                }
                if (!response.data.pSectionId) {
                  this.scrollToBottom();
                }

                this.clearTaskInterval();
                if (response.data.error) {
                  this.showErrorModalAction(response.data.error);
                  this.allLogs.push(">>>>> Error\n" + response.data.error);
                } else {
                  this.$notify({
                    group: "info",
                    type: "success",
                    text: "Success!",
                  });
                  this.loadAiRun();
                }
              } else if (response.data && response.data.status == "FAILURE") {
                this.showErrorModalAction(response.data.msg);
                this.clearTaskInterval();
              } else {
                let newProgress = 0;
                if (response.data && response.data.value) {
                  if (typeof response.data.value == "number") {
                    newProgress = response.data.value;
                  }
                }
                if (newProgress > this.progress) {
                  this.progress = newProgress;
                } else {
                  newProgress = this.progress + this.progressIncreaseAmount;
                  if (newProgress >= 90) {
                    this.progressIncreaseAmount = 0.08;
                  }
                  if (newProgress > 98) newProgress = 98;
                  this.progress = newProgress;
                }
              }
            }
          });
        }, 3000);
      }
    },
    titleAIResult: function (newVal, oldVal) {
      if (this.separateSearchEngineTitleAndTitle) {
        this.calculated_titleAIResult = this.getTagInfoFromHtmlTag(newVal);
      }
    },
    pageTitleAIResult: function (newVal, oldVal) {
      if (this.separateSearchEngineTitleAndTitle) {
        this.calculated_pageTitleAIResult = this.getTagInfoFromHtmlTag(newVal);
      } else {
        let data = newVal.split("\n");
        if (data.length >= 2) {
          this.calculated_titleAIResult = this.getTagInfoFromHtmlTag(data[0]);
          this.calculated_pageTitleAIResult = this.getTagInfoFromHtmlTag(
            data[1]
          );
        } else {
          this.calculated_titleAIResult = "";
          this.calculated_pageTitleAIResult = "";
        }
      }
    },
    subHeadingsAIResult: function (newVal, oldVal) {
      let rlt = [];
      if (!newVal) {
        this.calculated_subHeadingsAIResultArr = [];
      } else {
        rlt = newVal.trim().split("\n");
        this.calculated_subHeadingsAIResultArr = rlt.map((item, idx) => {
          return {
            ...this.getTagInfoFromHtmlTag(item),
            originIndex: idx,
          };
        });
      }
    },
    userInputParagraphs: function (newVal, oldVal) {
      if (oldVal.length != newVal.length && newVal.length > 0) {
        this.selectedRevisions = Array(newVal.length).fill("medium");
      }
    },
    imageAIModalParams: function (newVal, oldVal) {
      if (oldVal != newVal) {
        this.imageAIModalPrompt = ''
        this.imageAIModalUsePrompt = true
      }
    },
  },
  data() {
    return {
      variant: 2,
      currentSection: '',
      // TINY_MCE_API_KEY: TINY_MCE_API_KEY,
      // wysiwygConfigTinyMce: {
      //   height: '200px',
      //   inline: false,
      //   fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 34px 38px 42px 48px 54px 60px",
      //   plugins: 'print preview powerpaste searchreplace autolink directionality advcode visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount tinymcespellchecker a11ychecker mediaembed linkchecker textpattern help',
      //   toolbar1: 'code fullscreen | fontsizeselect | bold italic | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | undo redo',
      //   image_advtab: true,
      //   // theme: 'dark',
      //   content_css: [
      //     '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
      //     '//www.tinymce.com/css/codepen.min.css'
      //   ],
      //   // content_css: 'dark',
      //   menubar: false,
      //   branding: false,
      //   verify_html : false,
      // },
      hoveredItem: null,
      activeEditSubHeading: null,

      costedContentPromptSignals: costedContentPromptSignals,

      aiRun: null,
      currentStep: 0,
      STEPS: STEPS,
      mode: null,  // 'auto', 'guided'
      WORDCOUNT_OPTIONS: [
        { value: "currentWordCount", label: "Current word count" },
        { value: "targetWordCount", label: "Target word count" },
        { value: "customWordCount", label: "Custom word count" },
      ],
      selectedWordCountOption: "currentWordCount",
      numberOfWords: 0,

      selectedCompetitors: [],
      selectedContentPromptsSignals: [],
      selectedCountForPromptSignals: {},

      topLevelInternalLink: "",
      supportingLevelInternalLink1: "",
      supportingLevelInternalLink2: "",

      webPageType: "",
      webPageTypeOptions: [
        {
          value: "article",
          text: "Article",
        },
        {
          value: "blog",
          text: "Blog post",
        },
        {
          value: "product",
          text: "Product page",
        },
        {
          value: "category",
          text: "Category page",
        },
        {
          value: "product-category",
          text: "Product category page",
        },
        {
          value: "service",
          text: "Service page",
        },
      ],
      excludeTerms: [],

      // these needed to avoid duplicated scraping.
      targetUrlSourceCode: "",
      competitorsSourceCode: [],

      targetUrlParagraphs: [],
      competitorsParagraphs: [],
      // targetUrlParagraphs: [
      //   'This is just for the testing thing as if blable ia 12',
      //   'This is just for the testing thing as if blable ia qwqwqw',
      //   'This is just for the testing thing as if blable ia aaa',
      // ],
      // competitorsParagraphs: [
      //   [
      //     'competitor 1 thing paragraph 1',
      //     'competitor 1 thing paragraph 2',
      //     'competitor 1 thing paragraph 3',
      //   ],
      //   [
      //     'competitor 2 thing paragraph 1',
      //     'competitor 2 thing paragraph 2',
      //     'competitor 2 thing paragraph 3',
      //   ],
      // ],

      separateSearchEngineTitleAndTitle: false,

      useTitleInspirationText: false,
      titleInspirationText: "",
      useMyCurrentTitle: false,
      optimizeCurrentTitle: true,
      optimizeCompetitorTitle: true,

      usePageTitleInspirationText: false,
      pagetitleInspirationText: "",
      useMyCurrentPageTitle: false,
      optimizeCurrentPageTitle: true,
      optimizeCompetitorPageTitle: true,

      useMySubheadings: false,
      optimizeCurrentH2s: true,
      optimizeCompetitorsH2s: true,

      isInputParagraphByUser: false, // true - user will input paragraph, and we will make prompt with it. false - for now temp
      isExpandInputParagraphSection: false,
      userInputParagraphs: [],
      toneOfContent: "",
      author: "",
      brand: "",

      targetAudience: "",
      productDescription: "",
      sampleContent: "",

      titlePrompt: "",
      pageTitlePrompt: "",
      subHeadingsPrompt: "",
      // pPromptPhase1: '',
      // pPromptPhase2: '',

      titleAIResult: "",
      pageTitleAIResult: "",
      subHeadingsAIResult: "",
      pAIResult: [],
      maxRecommendedSubheadingsCount: 0,
      // pageTitleAIResult: testPageTitleAIResult,
      // subHeadingsAIResult: testSubheadingsAIResult,
      // pAIResult: testPresult,

      calculated_titleAIResult: "",
      calculated_pageTitleAIResult: "",
      calculated_subHeadingsAIResultArr: [],

      isLoading: false,
      progress: 0,
      progressIncreaseAmount: 0,

      runningSection: "",
      taskId: "",
      taskInterval: null,
      showNextButtonWillUpdateScoreModal: false,

      showTitleHistoryModal: false,
      showPageTitleHistoryModal: false,
      showSubheadingsHistoryModal: false,
      showParagraphOneSectionHistoryModal: false,

      showEditAIResultModal: false,
      selectedSection: "",
      seletedParagraphSection: 0,
      editResultText: "",

      editInOutlineInfo: {},
      showDeleteConfirmModal: false,
      deleteConfirmMessage: "",
      deleteAction: null,
      showAddSubheadingsModal: false,
      newSubHeadingTag: "h2",
      newSubheadingText: "",

      collapsedSections: [], // in final page only

      allLogs: [],
      showLogModal: "",
      subHeadProgress: 0,
      titleProgress: 0,
      mainContentProgress: 0,

      regenerateFreeWarnAcknowledged: false,
      showRegenerateFreeWarnModal: false,

      showRegenerateInspirationModal: false,
      regenerateSectionName: "",
      regenerateGuideText: "",
      createFreshPromptNotRegen: false,

      regenerateChargeWarnAcknowledged: false,
      showRegenerateChargeWarnModal: false,

      mustRunCallback: null,

      showDisclaimerModal: false,
      confirmedDisclaim: false,
      showWarnForEditNowAllowdAfterParagraphGenerationModal: false,

      showRichContentItemEditModal: false,
      richContentItemToEdit: {},

      showRichContentItemHistoryModal: false,
      selectedRichContentItemData: {},

      imageAIModalParams: null,
      imageAIModalPrompt: "",
      imageAIModalUsePrompt: true,
      generatingImageWithAI: false,

      deleteImageAIModalParams: null,
      deletingAiGeneratedImage: false,

      availableRevisions: [
        {
          text: "Minimal revision (POP AI will just naturally add target keywords to the provided content)",
          label: "Minimum",
          value: "minimum",
        },
        {
          text: "Medium revision (POP AI will re-write provided content but will mainly keep the order and the structure of sentences)",
          label: "Medium",
          value: "medium",
          recommended: true,
        },
        {
          text: "Maximum revision (POP AI will use provided content only as a source of inspiration and will completely re-write it)",
          label: "Maximum",
          value: "maximum",
        },
      ],
      willSelectRevisionIndividually: false,
      selectedRevisionForAllParagraphs: "medium",
      selectedRevisions: {},
      currentInputParagraphIndex: -1,
      editingParagraphIdxInResult: -1,

      showLeftSidebar: false,

      showErrorModal: false,
      errorMsg: "",
    };
  },
  validations () {
    return {
      $validationGroups: {
        step1: ['numberOfWords', 'webPageType', 'cleanedContentPromptUserInput', 'sampleContent', 'selectedCompetitors'],
      },
      numberOfWords: {
        required,
        maxValue: maxValue(4000)
      },
      webPageType: {
        required,
      },
      cleanedContentPromptUserInput: {
        isClean: (value) => !!value
      },
      sampleContent: {
        countWords: (value) => this.countWords(value) <= 500
      },
      selectedCompetitors: {
        maxLength: maxLength(3)
      },
    }
  },
  methods: {
    reorderItems(items) {
      // Define the new order for the dispNames or label fields
      const newOrder = [
        "Meta description",
        "Introduction paragraph",
        "Table of content",
        "Brief Overview",
        "Key Highlights"
      ];

      const endItems = ["Frequently Asked Questions", "Conclusion paragraph", "Internal links"];

      // Create empty arrays for ordered and remaining items
      let orderedItems = [];
      let remainingItems = [];
      let closingItems = [];

      // Add items in the order specified by newOrder
      newOrder.forEach(name => {
        const item = items.find(i => i.dispName === name || i.label === name);
        if (item) orderedItems.push(item);
      });

      // Add remaining items that are not in newOrder or endItems
      remainingItems = items.filter(
        i =>
          !newOrder.includes(i.dispName) &&
          !newOrder.includes(i.label) &&
          !endItems.includes(i.dispName) &&
          !endItems.includes(i.label)
      );

      // Add "Frequently Asked Questions" and "Conclusion paragraph" at the end
      closingItems = items.filter(i => endItems.includes(i.dispName) || endItems.includes(i.label));

      // Combine ordered, remaining, and end items
      return [...orderedItems, ...remainingItems, ...closingItems];
    },
    setNeedsToSave() {
      this.$store.commit("setAiWriterNeedsToSave", true);
    },
    setActiveEditItem(_, name) {
      this.activeEditSubHeading = name;
      this.$store.commit("setAiWriterNeedsToSave", true);
    },
    handleClickOutside(e) {
      if (e.target.closest('.action')) {
        // this is a click an some action, no need to handleClickOutside 
        return;
      }
      if (
        e.target.className === "ai-subheading" ||
        this.activeEditSubHeading === "ai-outline-" + e.target.id
      ) {
        return;
      }
      if ("ai-title" === e.target.id || "ai-page-title" === e.target.id) {
        return;
      }

      this.cancelEditItemInOutlineFor("subHeadings");
      this.cancelEditItemInOutlineFor("pageTitle");
      this.cancelEditItemInOutlineFor("title");
      this.activeEditSubHeading = null;
    },
    mouseenter(id) {
      this.hoveredItem = id;
    },
    mouseleave() {
      this.hoveredItem = null;
    },
    focusInput() {
      setTimeout(() => {
        document.getElementById("input").focus();
      }, 10);
      this.$store.commit("setAiWriterNeedsToSave", true);
    },
    editSubheading(e, item) {
      if (this.editInOutlineInfo && this.editInOutlineInfo.section) {
        this.cancelEditItemInOutlineFor(this.editInOutlineInfo.section);
      }
      this.editInOutlineInfo = {
        section: "subHeadings",
        originIndex: item.originIndex,
      };
      this.focusInput();
    },
    showErrorModalAction(errorMsg) {
      this.errorMsg = errorMsg;
      this.showErrorModal = true;
    },
    fakeProgress(keyName, nextStep) {
      this.isLoading = true;

      this[keyName] = 1;
      const interval = setInterval(() => {
        this[keyName] += 20;
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        const stateKey = {
          titleProgress: "isDemoTitleDone",
          subHeadProgress: "isDemoHeadingsDone",
          mainContentProgress: "isDemoMainContentDone",
        }[keyName];
        this.$store.commit("setDemoStepDone", stateKey);
        this.isLoading = false;
        if (nextStep) this.currentStep = nextStep;
      }, 5100);
    },
    downloadAIResultAsZip() {

      let zip = new JSZip();

      const fullFileName = `ai-article-${toLocalDateString(
        this.aiRun.createdAt
      )}-${this.aiRun.id}.zip`;

      zip.file('index.html', this.fullHtml);

      const images = []

      Object.values(this.aiGeneratedImagesMap).forEach((i) => {
        Object.values(i).forEach(j => {
          images.push(j)
        })
      })

      Promise.all(
        images.map((item) => {
          return fetch(item.url).then(response => {
            return response.blob()
          }).then(blob => {
            zip.file(item.filename, blob);
            return Promise.resolve()
          })
        })
      ).then(() => {
        return zip
          .generateAsync({ type: "blob" })
          .then(function (content) {
            saveAs(content, fullFileName);
          });
      })

    },
    downloadAIResultAsDocx() {
      let data = {
        htmlStr: this.fullHtml,
      };
      this.$store.dispatch("downloadDocxForAiWriter", data).then((response) => {
        if (response.data && response.status == 200) {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          const url = URL.createObjectURL(blob);
          var element = document.createElement("a");
          element.setAttribute("href", url);
          let fullFileName = `ai-article-${toLocalDateString(
            this.aiRun.createdAt
          )}-${this.aiRun.id}.docx`;
          element.setAttribute("download", fullFileName);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        } else {

        }
      });
    },
    scrollToTop() {
      this.$nextTick(() => {
        this.$refs.aiWriterRef.scrollTop = 0;
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.aiWriterRef.scrollTop = this.$refs.aiWriterRef.scrollHeight;
      });
    },
    textToCssId(text) {
      // return text.replaceAll(' ', '').replaceAll('  ', '')
      return text.replace(/[^a-zA-Z0-9]/g, "_");
    },
    isValidInternalLink(v) {
      return v?.length ? v.startsWith(this.page?.siteBaseURL) : true
    },
    gotoParagraphSection(text) {
      this.$nextTick(() => {
        let found = document.querySelector(`#${this.textToCssId(text)}`);
        if (found) {
          this.$refs.aiWriterRef.scrollTop = found.offsetTop;
          let foundIndex = -1;
          for (let i = 0; i < this.pAIResult.length; i++) {
            const item = this.pAIResult[i];
            if (
              item["subheadingInfo"] &&
              item["subheadingInfo"]["tagText"] == text
            ) {
              foundIndex = i;
              break;
            }
          }

          if (foundIndex != -1) {
            this.editingParagraphIdxInResult = foundIndex;
          }
        }
      });
    },
    getCostForContentPromptSignal(signalName) {
      if (this.isUsingOwnApiKey) {
        return 0;
      }
      if (!this.costedContentPromptSignals.includes(signalName)) {
        return 0;
      }
      if (this.selectedContentPromptsSignals.includes(signalName)) {
        return this.selectedCountForPromptSignals[signalName] || 0;
      }
      return 0;
    },

    getAllContentPromptDataForPSection(idx) {
      function shortenKey(key) {
        let shortenKeys = {
          "Form Total": "form",
          "Image File total": "image",
          list: "list",
          "Table Total": "table",
          "Frequently Asked Questions": "faq",
        };
        if (Object.keys(shortenKeys).includes(key)) {
          return shortenKeys[key];
        }
        return key;
      }
      function dispName(key) {
        let shortenKeys = {
          "Form Total": "Form",
          "Image File total": "Image",
          list: "List",
          "Table Total": "Table",
          "Frequently Asked Questions": "FAQs",
        };
        if (Object.keys(shortenKeys).includes(key)) {
          return shortenKeys[key];
        }
        return key;
      }
      function contentPromptSignalWeightForOrdering(key) {
        let weights = {
          "Form Total": 1,
          "Image File total": 100,
          list: 100,
          "Table Total": 100,
          "Frequently Asked Questions": 0,
        };
        if (Object.keys(weights).includes(key)) {
          return weights[key];
        }
        return key;
      }

      let keys = Object.keys(this.contentPromoptPlaceHolderData || {});
      let rlt = [];
      for (const key of keys) {
        let count =
          this.contentPromoptPlaceHolderData[key].length > idx
            ? this.contentPromoptPlaceHolderData[key][idx]
            : 0;
        if (count > 0) {
          rlt.push({
            key: key,
            shortenKey: shortenKey(key),
            dispName: dispName(key),
            weight: contentPromptSignalWeightForOrdering(key),
            // count: count // this is max 1 so not needed currently
          });
        }
      }

      keys = Object.keys(this.contentPromptAiResults || {});
      for (const key of keys) {
        let dataForOneSignal = this.contentPromptAiResults[key];
        dataForOneSignal
          .filter((item) => item.pIndex == idx)
          .map((item) => {
            rlt.push({
              key: key,
              shortenKey: shortenKey(key),
              dispName: dispName(key),
              isEmpty: item.aiResults.length == 0,
              currentResult:
                item.aiResults.length > item.selectedIndex
                  ? item.aiResults[item.selectedIndex].data
                  : "",
              uniqueIndex: item.uniqueIndex,
            });
          });
      }

      // pre processing for display formatting
      rlt = rlt.map((item) => {
        if (item.shortenKey == "faq") {
          item.resultToDisplay = item.currentResult.replaceAll("\n", "<br />");
        }
        return item;
      });

      rlt.sort((a, b) => {
        return b.weight - a.weight;
      });

      return rlt;
    },
    getTagInfoFromHtmlTag(htmlStr) {
      const parser = new DOMParser();
      let doc = parser.parseFromString(htmlStr, "text/html");
      let tag = doc.querySelector("title, body > *");
      if (tag) {
        return {
          tagName: tag.tagName,
          shortenTagName: tag.tagName == "TITLE" ? "T" : "",
          text: tag.textContent || "H1 not found",
        };
      } else {
        return {};
      }
    },
    competitorWordCount(c) {
      if (!c) return 0;
      let data = c.rawKeywordCounts || [];
      let wordCount = data["word_count"] || 0;
      return wordCount;
    },
    getUserSettingData(key) {
      if (this.user) {
        const settings = Object.assign({}, this.user.settings);
        return settings[key];
      }
      return null;
    },
    addDataToUserSetting(key, value) {
      if (this.user) {
        const settings = Object.assign({}, this.user.settings);
        settings[key] = value;

        this.$store
          .dispatch("updateUserSettings", settings)
          .then((response) =>
            this.$store.commit("setUserSettings", response.data)
          )
          .finally(() => {});
      }
    },
    needsPulsing(step) {
      if (this.currentStep != step) {
        return false;
      }
      if (this.isLoading) {
        return false;
      }

      switch (step) {
        case this.STEPS.SEARCH_ENGINE_TITLE:
          if (this.titleAIResult) return false;
          break;
        case this.STEPS.PAGE_TITLE:
          if (this.pageTitleAIResult) return false;
          break;
        case this.STEPS.SUBHEADINGS:
          if (this.subHeadingsAIResult) return false;
          break;
        case this.STEPS.PARAGRAPH:
          if (this.pAIResult.length > 0) return false;
      }
      return true;
    },
    startOverAgain() {
      this.aiRun = null;
      this.mode = null;
      this.currentStep = 0;
      this.selectedTargetUrlParagraphs = [];
      this.separateSearchEngineTitleAndTitle = false;

      this.titleInspirationText = "";
      this.useMyCurrentTitle = false;
      if (this.pageRun?.pageNotBuilt) {
        this.optimizeCurrentTitle = false;
      } else {
        this.optimizeCurrentTitle = true;
      }

      this.optimizeCompetitorTitle = false;

      this.pagetitleInspirationText = "";
      this.useMyCurrentPageTitle = false;
      if (this.pageRun?.pageNotBuilt) {
        this.optimizeCurrentPageTitle = false;
      } else {
        this.optimizeCurrentPageTitle = true;
      }
      this.optimizeCompetitorPageTitle = true;

      this.useMySubheadings = false;
      if (this.pageRun?.pageNotBuilt) {
        this.optimizeCurrentH2s = false;
      } else {
        this.optimizeCurrentH2s = true;
      }
      this.optimizeCompetitorsH2s = true;

      this.targetUrlParagraphs = [];
      this.competitorsParagraphs = [];

      this.isInputParagraphByUser = false;
      this.userInputParagraphs = [];
      this.toneOfContent = "";
      this.author = "";
      this.brand = "";
      this.webPageType = "";
      this.excludeTerms = [];

      this.topLevelInternalLink = "";
      this.supportingLevelInternalLink1 = "";
      this.supportingLevelInternalLink2 = "";

      this.targetAudience = ""
      this.productDescription = ""
      this.sampleContent = ""

      this.willSelectRevisionIndividually = false;
      this.selectedRevisionForAllParagraphs = "medium";

      this.titleAIResult = "";
      this.pageTitleAIResult = "";
      this.subHeadingsAIResult = "";
      this.pAIResult = [];

      this.selectedCompetitors = [];
      this.selectedContentPromptsSignals = [];
      this.selectedCountForPromptSignals = {};
      this.currentInputParagraphIndex = -1;
      this.editingParagraphIdxInResult = -1;
      this.showLeftSidebar = false;

      this.isLoading = false;
      this.progress = 0;
      this.taskId = "";

      if (this.pageNotBuilt) {
        this.selectedWordCountOption = "targetWordCount";
        // selectedWordCountOption watch will not be executed. so need this.
        this.numberOfWords = this.targetWordCount;
      } else {
        this.selectedWordCountOption = "currentWordCount";
        // selectedWordCountOption watch will not be executed. so need this.
        this.numberOfWords = this.currentWordCount;
      }
      this.confirmedDisclaim = false;
      this.$store.commit("setCurrentAIRun", null);
    },
    setDefaultRichContentFroWebPageType(value) {
      if (['article', 'blog'].includes(value)) {
        if (!this.selectedContentPromptsSignals.length) {
          this.selectedContentPromptsSignals = [
            ...this.defaultEnabledContentPromptSignalsForBlogPosts
          ];
          this.selectedCountForPromptSignals = {
            ...Object.fromEntries(this.defaultEnabledContentPromptSignalsForBlogPosts.map((signal) => [signal, 1]))
          };
        }
      }
    },
    saveEditedTitle() {
      let rlt = "";
      let data = [];

      data.push(this.calculated_titleAIResult);
      if (!this.separateSearchEngineTitleAndTitle) {
        data.push(this.calculated_pageTitleAIResult);
      }
      rlt = data
        .map((item) => {
          let tagName = item.tagName.toLowerCase();
          let text = item.text;
          return `<${tagName}>${text}</${tagName}>`;
        })
        .join("\n");

      this.selectedSection = this.separateSearchEngineTitleAndTitle
        ? "title"
        : "pageTitle";
      this.editResultText = rlt;
      this.saveEditedAIResultAction();
    },
    saveEditedPageTitle() {
      let rlt = "";
      let data = [];
      if (!this.separateSearchEngineTitleAndTitle) {
        data.push(this.calculated_titleAIResult);
      }
      if (this.calculated_pageTitleAIResult) {
        data.push(this.calculated_pageTitleAIResult);
      }
      rlt = data
        .map((item) => {
          let tagName = item.tagName.toLowerCase();
          let text = item.text;
          return `<${tagName}>${text}</${tagName}>`;
        })
        .join("\n");

      this.selectedSection = "pageTitle";
      this.editResultText = rlt;

      this.saveEditedAIResultAction();
    },
    saveEditedSubheadings() {
      let rlt = this.calculated_subHeadingsAIResultArr
        .map((item) => {
          let tagName = item.tagName.toLowerCase();
          let text = item.text;
          return `<${tagName}>${text}</${tagName}>`;
        })
        .join("\n");
      this.selectedSection = "subHeadings";
      this.editResultText = rlt;
      this.saveEditedAIResultAction();
    },
    handleParagraphEdit(idx, data) {
      this.$store
        .dispatch("aiRunSaveEditedContent", {
          aiRunId: this.aiRun.id,
          sectionName: "p",
          editedContent: data,
          pSectionIndex: idx,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
            this.$store.commit("setAiWriterNeedsToSave", false);
          }
        });
    },
    loadAndSaveCurrentSelectionForSection(sectionName, selectedIndex, item) {
      this.showPageTitleHistoryModal = false;
      this.showParagraphOneSectionHistoryModal = false;

      switch (sectionName) {
        case "title":
          this.titleAIResult = item.data;
          break;
        case "pageTitle":
          this.pageTitleAIResult = item.data;
          break;
        case "subHeadings":
          this.subHeadingsAIResult = item.data;
          break;
        case "p":
          this.pAIResult[this.seletedParagraphSection]["answer"] = item.answer;
          break;
      }

      let data = {
        aiRunId: this.aiRun.id,
        sectionName,
        selectedIndex,
      };
      if (sectionName == "p") {
        data["pSectionIndex"] = this.seletedParagraphSection;
      }

      this.$store
        .dispatch("aiRunChangeCurrentSelection", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
          }
        });
    },
    showEditAIResultAction(sectionName, pIndex = null) {
      this.selectedSection = sectionName;
      switch (sectionName) {
        case "title":
          this.editResultText = this.titleAIResult;
          break;
        case "pageTitle":
          this.editResultText = this.pageTitleAIResult;
          break;
        case "subHeadings":
          this.editResultText = this.subHeadingsAIResult;
          break;
        case "p":
          this.editResultText = this.pAIResult[pIndex]["answer"];
          break;
      }
      if (pIndex) {
        this.seletedParagraphSection = pIndex;
      }
      this.showEditAIResultModal = true;
    },
    saveEditedAIResultAction() {
      if (this.onlyForViewPurpose) {
        return;
      }

      switch (this.selectedSection) {
        case "title":
          this.titleAIResult = this.editResultText;
          break;
        case "pageTitle":
          this.pageTitleAIResult = this.editResultText;
          break;
        case "subHeadings":
          this.subHeadingsAIResult = this.editResultText;
          break;
        case "p":
          this.pAIResult[this.seletedParagraphSection]["answer"] =
            this.editResultText;
          break;
      }
      this.$store
        .dispatch("aiRunSaveEditedContent", {
          aiRunId: this.aiRun.id,
          sectionName: this.selectedSection,
          editedContent: this.editResultText,
          pSectionIndex: this.seletedParagraphSection,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
            this.$store.commit("setAiWriterNeedsToSave", false);
          }
        });
      this.showEditAIResultModal = false;
    },
    generateImageWithAI(pSectionIndex, uniqueIndex, userPrompt) {
      this.generatingImageWithAI = true
      this.$store
        .dispatch("aiRunAIGeneratedImageCreate", {
          aiRunId: this.aiRun.id,
          pSectionIndex,
          uniqueIndex,
          userPrompt,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
            this.$store.commit("setAiWriterNeedsToSave", false);
            this.imageAIModalParams = null;
            return this.$store.dispatch("loadUser").then(response => {
              this.$store.commit("setUser", response.data.user);
            });
          }
        })
        .finally(() => {
          this.generatingImageWithAI = false
        });
    },
    deleteAIGeneratedImage(pSectionIndex, uniqueIndex) {
      this.deletingAiGeneratedImage = true
      this.$store
        .dispatch("aiRunAIGeneratedImageDelete", {
          aiRunId: this.aiRun.id,
          pSectionIndex,
          uniqueIndex,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
            this.$store.commit("setAiWriterNeedsToSave", false);
            this.deleteImageAIModalParams = null;
          }
        })
        .finally(() => {
          this.deletingAiGeneratedImage = false
        });
    },
    loadAiRun(isFirstTimeLoad = false) {
      this.$store
        .dispatch("loadAiRun", {
          pageRunId: this.pageRunId,
          aiRunId:
            this.aiRunId && isFirstTimeLoad
              ? this.aiRunId
              : this.aiRun
              ? this.aiRun.id
              : null,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            let aiRun = response.data.aiRun;
            this.$store.commit("setCurrentAIRun", aiRun);

            this.mode = aiRun.mode;

            this.targetUrlParagraphs = aiRun.targetUrlParagraphs;
            this.competitorsParagraphs = aiRun.competitorsParagraphs;

            this.separateSearchEngineTitleAndTitle =
              aiRun.isSeparateTitleAndPagetitle;

            this.selectedContentPromptsSignals = aiRun.selectedPromptSignals;
            this.selectedCountForPromptSignals = aiRun.selectedPromptSignalsWithCount;

            if (
              aiRun.searchEngineTitleResult.length >
              aiRun.selectedSearchEngineTitleIndex
            ) {
              this.titleAIResult =
                aiRun.searchEngineTitleResult[
                  aiRun.selectedSearchEngineTitleIndex
                ]["data"];
            }

            if (!this.separateSearchEngineTitleAndTitle) {
              this.titleAIResult =
                aiRun.pageTitleResult[aiRun.selectedPageTitleIndex]["data"];
            }
            if (aiRun.pageTitleResult.length > aiRun.selectedPageTitleIndex) {
              this.pageTitleAIResult =
                aiRun.pageTitleResult[aiRun.selectedPageTitleIndex]["data"];
            }
            if (
              aiRun.subheadingsResult.length > aiRun.selectedSubheadingsIndex
            ) {
              this.subHeadingsAIResult =
                aiRun.subheadingsResult[aiRun.selectedSubheadingsIndex]["data"];
            }

            if (aiRun.paragraphResult.length > 0) {
              this.pAIResult = aiRun.paragraphResult.map((item, idx) => {
                const selectedPos = aiRun.selectedParagraphIndexes[idx];
                return JSON.parse(JSON.stringify(item[selectedPos]));
              });
            }

            if (aiRun.wordCount > 0) {
              this.numberOfWords = aiRun.wordCount;
            }

            this.toneOfContent = aiRun.toneOfContent;
            this.author = aiRun.author;
            this.brand = aiRun.brand;

            this.targetAudience = aiRun.targetAudience;
            this.productDescription = aiRun.productDescription;
            this.sampleContent = aiRun.sampleContent;

            this.topLevelInternalLink = aiRun.topLevelInternalLink
            this.supportingLevelInternalLink1 = aiRun.supportingLevelInternalLink1
            this.supportingLevelInternalLink2 = aiRun.supportingLevelInternalLink2

            if (isFirstTimeLoad || aiRun.mode === 'auto') {
              // first load

              if (aiRun.mode === 'auto') {
                if (aiRun.lastStep < STEPS.PARAGRAPH) {
                  this.goToSpecificPage(STEPS.SETTING, true)
                } else {
                  this.goToSpecificPage(aiRun.lastStep, true);
                }
              } else {
                if ([STEPS.RICH_CONTENT, STEPS.SELECT_COMPETITORS].includes(aiRun.lastStep)) {
                  this.goToSpecificPage(STEPS.SETTING, true)
                } else {
                  this.goToSpecificPage(aiRun.lastStep, true);
                }
              }
              
              this.$nextTick(() => {
                this.$emit("onCalcChecklist", {
                  aiRunId: this.aiRun ? this.aiRun.id : 0,
                  htmlStr: this.fullHtml,
                  updateScore: true,
                });
              });

              if (aiRun.runningSection && aiRun.runningTaskId) {
                this.updateRunningSection(
                  aiRun.runningSection,
                  aiRun.runningTaskId
                );
              }
            }
            this.aiRun = aiRun;
          } else if (response.data && response.data.status == "FAILURE") {
            if (this.pageRun.pageNotBuilt) {
              this.optimizeCurrentTitle = false;
              this.optimizeCurrentPageTitle = false;
              this.optimizeCurrentH2s = false;
            }
          }
        });
    },
    calculateProgressAmount(sectionName, isImproveOneSection) {
      this.progress = 0;

      let amount = 0;
      switch (sectionName) {
        case "title":
        case "pageTitle":
          amount = 6;
          break;
        case "subHeadings":
          if (this.competitorsSourceCode.length > 1) {
            amount = 3;
          } else {
            amount = 1.2;
          }
          break;
        case "p":
          if (isImproveOneSection) {
            amount = 6;
          }
          if (this.competitorsSourceCode.length > 1) {
            amount = 0.8;
          } else {
            amount = 0.5;
          }
          break;
        default:
          amount = 1;
      }
      this.progressIncreaseAmount = amount;
    },
    clearProgress() {
      this.isLoading = false;
      this.progress = 0;
      this.taskId = "";
      this.runningSection = "";
    },
    clearTaskInterval(intervalOnly = false) {
      if (this.taskInterval) {
        clearInterval(this.taskInterval);
      }
      if (!intervalOnly) {
        this.clearProgress();
      }
    },
    updateRunningSection(sectionName, taskId, improveForOnesection) {
      this.calculateProgressAmount(sectionName, improveForOnesection);
      this.isLoading = true;
      this.progress = 0;
      this.runningSection = sectionName;
      this.taskId = taskId;
    },
    regenerateForParagraphOneSection(idx) {
      let item = this.pAIResult[idx];
      this.showWarningModalForGenerate("p", () =>
        this.generateResultFor("p", item, idx)
      );
    },
    isRegenerateForSection(sectionName) {
      switch (sectionName) {
        case "title":
          return this.titleAIResult ? true : false;
        case "pageTitle":
          return this.pageTitleAIResult ? true : false;
        case "subHeadings":
          return this.subHeadingsAIResult ? true : false;
        case "p":
          return this.pAIResult && this.pAIResult.length > 0 ? true : false;
      }
    },
    showRegenerateInspirationModalAction() {
      this.showRegenerateInspirationModal = true;
      this.regenerateGuideText = "";
      this.createFreshPromptNotRegen = false;
    },
    showWarningModalForGenerate(sectionName, callback) {
      if (!this.aiRun || !this.isRegenerateForSection(sectionName)) {
        callback();
        return;
      }
      this.regenerateSectionName = sectionName;
      this.mustRunCallback = callback;

      if (this.aiRun.useMyOwnApiKey) {
        // don't need to display the
        this.showRegenerateInspirationModalAction();
        return;
      }

      if (this.aiRun.remainedFreeRegenerate == 0) {
        if (
          !this.getUserSettingData("doNotDisplay_AIRegenerateChargeWarnModal")
        ) {
          this.showRegenerateChargeWarnModal = true;
        } else {
          this.showRegenerateInspirationModalAction();
        }
      } else {
        if (
          !this.getUserSettingData("doNotDisplay_AIRegenerateFreeWarnModal")
        ) {
          this.showRegenerateFreeWarnModal = true;
        } else {
          this.showRegenerateInspirationModalAction();
        }
      }
    },
    proceedRegenerateFree() {
      this.showRegenerateFreeWarnModal = false;
      if (this.regenerateFreeWarnAcknowledged) {
        this.addDataToUserSetting(
          "doNotDisplay_AIRegenerateFreeWarnModal",
          true
        );
      }
      this.showRegenerateInspirationModalAction();

      // if (this.mustRunCallback) {
      //   this.mustRunCallback();
      // }
    },
    proceedRegenerateCharge() {
      this.showRegenerateChargeWarnModal = false;
      if (this.regenerateChargeWarnAcknowledged) {
        this.addDataToUserSetting(
          "doNotDisplay_AIRegenerateChargeWarnModal",
          true
        );
      }
      this.showRegenerateInspirationModalAction();
      // if (this.mustRunCallback) {
      //   this.mustRunCallback();
      // }
    },
    generateResultFor(sectionName, pSection = null, pSectionId = -1) {
      if (this.onlyForViewPurpose) {
        return;
      }

      if (sectionName == "title") {
        this.titleInspirationText = this.titleInspirationText.trim();
      }
      if (sectionName == "pageTitle") {
        this.pagetitleInspirationText = this.pagetitleInspirationText.trim();
      }

      let contentPromptsUserInput = this.cleanedContentPromptUserInput;
      let openAIParamters = {
        titleInspirationText: this.useTitleInspirationText
          ? this.titleInspirationText
          : "",
        useMyCurrentTitle: this.useMyCurrentTitle,
        optimizeCurrentTitle: this.optimizeCurrentTitle,
        optimizeCompetitorTitle: this.optimizeCompetitorTitle,

        pagetitleInspirationText: this.usePageTitleInspirationText
          ? this.pagetitleInspirationText
          : "",
        useMyCurrentPageTitle: this.useMyCurrentPageTitle,
        optimizeCurrentPageTitle: this.optimizeCurrentPageTitle,
        optimizeCompetitorPageTitle: this.optimizeCompetitorPageTitle,

        useMySubheadings: this.useMySubheadings,
        optimizeCurrentH2s: this.optimizeCurrentH2s,
        optimizeCompetitorsH2s: this.optimizeCompetitorsH2s,

        pSection: pSection, // this means for improve for 1 section
        pSectionId: pSectionId,
        makeFreshRun: this.aiRun == null || sectionName === 'auto',
        aiRunId: this.aiRun && sectionName !== 'auto' ? this.aiRun.id : null,
        contentPromptsData: contentPromptsUserInput || {},

        isRegenerate: this.isRegenerateForSection(sectionName),
        regenerateGuideText: this.createFreshPromptNotRegen
          ? ""
          : this.regenerateGuideText,
        createFreshPromptNotRegen: this.createFreshPromptNotRegen,
      };

      const revisions = this.willSelectRevisionIndividually
        ? this.selectedRevisions
        : Array(this.userInputParagraphs.length).fill(
            this.selectedRevisionForAllParagraphs
          );
      let data = {
        ...openAIParamters,

        mode: this.mode,

        pageRunId: this.pageRunId,
        targetUrlSourceCode: this.targetUrlSourceCode,
        competitorsSourceCode: this.competitorsSourceCode,
        numberOfWords: this.numberOfWords > 4000 ? 4000 : this.numberOfWords,
        selectedCompetitors: this.selectedCompetitors,
        // rules: rules,
        sectionName: sectionName,
        separateSearchEngineTitleAndTitle:
          this.separateSearchEngineTitleAndTitle,

        titleAIResult: this.titleAIResult,
        pageTitleAIResult: this.pageTitleAIResult, // this will be used for subheadings and p prompt generation
        subHeadingsAIResult: this.subHeadingsAIResult,
        isInputParagraphByUser: this.isInputParagraphByUser,
        userInputParagraphs: this.isInputParagraphByUser
          ? this.userInputParagraphs
          : [],
        aiParagraphRevisions: revisions,
        toneOfContent: this.toneOfContent,
        author: this.author,
        brand: this.brand,
        webPageType: this.webPageType,
        excludeTerms: this.excludeTerms,

        ...this.selectedContentPromptsSignals.includes('Internal links')
          ?{
            topLevelInternalLink: this.topLevelInternalLink,
            supportingLevelInternalLink1: this.supportingLevelInternalLink1,
            supportingLevelInternalLink2: this.supportingLevelInternalLink2,
          }
          :{},

        targetAudience: this.targetAudience,
        productDescription: this.productDescription,
        sampleContent: this.sampleContent,

        justGiveAIAnswer: 1,
        currentStep: this.currentStep,
        variant: this.variant,
      };

      if (sectionName == "p" && !pSection) {
        this.$notify({
          group: "info",
          type: "success",
          duration: 60000,
          closeOnClick: true,
          text: "Depending on the length of your content, this section may take up to 3 minutes to complete.",
        });
      }

      this.runningSection = sectionName;
      this.isLoading = true; // this is needed to make progressbar appear asap
      this.$store
        .dispatch("getPromptForAI", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.currentSection = sectionName;
            this.updateRunningSection(
              sectionName,
              response.data.taskId,
              !!pSection
            );
          } else {
            this.clearProgress();
          }
        })
        .finally(() => {});
    },
    isStepValid(step) {

      if (step === undefined) {
        return true
      }

      if (this.isFailedPageRun) {
        return false
      }

      const previousStepIndex = this.availableSteps.indexOf(step) - 1
      const previousStep = this.availableSteps[previousStepIndex]

      if (step === STEPS.START) {
        return true
      }

      if (step > STEPS.START && !this.isStepValid(previousStep)) {
        return false;
      }

      // TODO(hichem): complete validations
      
      if (step === STEPS.SETTING) {
        if (!this.aiRun && !this.hasEnoughCredits) {
          return false;
        }

        return (
          !this.v$.$validationGroups[`step${step}`].$invalid
        )

      } else if (step === STEPS.SEARCH_ENGINE_TITLE) {
        return true

      } else if (step === STEPS.PAGE_TITLE) {
        return (
          (!this.isDemo || this.titleProgress >= 100)
          && this.pageTitleAIResult
        )
      } else if (step === STEPS.SUBHEADINGS) {
        return (
          !this.isDemo && this.subHeadingsAIResult
        )
      } else if (step === STEPS.PARAGRAPH) {
        return true
      }
    },
    goNext({showModalIfAny = false, shouldScrollTop = true}) {
      const currentStepIndex = this.availableSteps.indexOf(this.currentStep)
      const nextStep = this.availableSteps[currentStepIndex + 1];

      // [pre-step overrides]
      if (this.isDemo) {
        this.currentStep += this.currentStep === STEPS.SETTING ? 2 : 1;
        return;
      }
      
      if (showModalIfAny) {
        if (nextStep === STEPS.PARAGRAPH && !(this.subHeadProgress >= 100)) {
          this.showWarnForEditNowAllowdAfterParagraphGenerationModal = true
          return
        }
        if (
          this.currentStep === STEPS.SETTING
          && (
            this.numberOfWords < this.targetWordCount
            && !this.confirmedDisclaim
          )
        ) {
          this.showDisclaimerModal = true
          return
        }
      }

      // [pre-step actions]
      if (this.currentStep >= this.STEPS.SEARCH_ENGINE_TITLE) {
        this.$emit("onCalcChecklist", {
          aiRunId: this.aiRun ? this.aiRun.id : 0,
          htmlStr: this.fullHtml,
          updateScore: true,
        });
      }

      if (nextStep === this.STEPS.SETTING && !this.selectedCompetitors?.length) {
        // pre select comeptitors
        let preSelectFocusCompetitors = [];
        let preSelectFirst3Competitors = [];
        this.pageRun.competitors.map((c) => {
          if (c.isFocus) {
            preSelectFocusCompetitors.push(c.id);
          }
          if (preSelectFirst3Competitors.length < 3) {
            preSelectFirst3Competitors.push(c.id);
          }
        });
        this.selectedCompetitors =
          preSelectFocusCompetitors.length > 0
            ? preSelectFocusCompetitors
            : preSelectFirst3Competitors;
      }

      if (
        this.currentStep == this.STEPS.SEARCH_ENGINE_TITLE || this.currentStep == this.STEPS.PAGE_TITLE
      ) {
        if (
          !this.getUserSettingData(
            "doNotDisplay_ScoreAutoUpdateInNextClickOnAIModal"
          )
        ) {
          this.showNextButtonWillUpdateScoreModal = true;
          this.addDataToUserSetting(
            "doNotDisplay_ScoreAutoUpdateInNextClickOnAIModal",
            true
          );
        }
      }

      // [the step]
      this.currentStep = nextStep

      // [post-step actions]
      if (shouldScrollTop) {
        this.scrollToTop();
      }
    },
    goBack() {
      const previousStepIndex = this.availableSteps.indexOf(this.currentStep) - 1
      const previousStep = this.availableSteps[previousStepIndex]

      this.currentStep = previousStep || 0
      this.scrollToTop();
    },
    goToSpecificPage(targetStep, isFristTimeLoad = false) {
      
      // if (!this.isStepValid(targetStep)) {
      //   this.$notify({
      //     group: "info",
      //     type: "error",
      //     text: "Invalid form data. You cannot go to this step, yet.",
      //   });
      //   return;
      // }

      if (isFristTimeLoad) {
        this.currentStep = this.isDemo ? 0 : targetStep;
        return;
      }

      if (targetStep === this.currentStep) {
        this.$notify({
          group: "info",
          type: "warning",
          text: "You are already on this step.",
        });
        return;
      }
      if (this.aiRun && this.aiRun.lastStep + 1 < targetStep) {
        this.$notify({
          group: "info",
          type: "error",
          text: "You cannot go to this step directly.",
        });
        return;
      }
      if (
        this.currentStep == STEPS.PARAGRAPH
      ) {
        this.$notify({
          group: "info",
          type: "warning",
          text: "Once content is generated you cannot go back to this step.  To change settings, you will need to start over which will cost more credits.",
        });
        return;
      }
      this.currentStep = targetStep;
    },
    async downloadOutlines() {
      let data = "";
      if (this.separateSearchEngineTitleAndTitle) {
        data += this.titleAIResult;
        data += "\n" + this.pageTitleAIResult;
      } else {
        data += this.pageTitleAIResult;
      }
      data += "\n\n" + this.subHeadingsAIResult;

      if (data) {
        let fileName = `ai-article-outlines-${toLocalDateString(
          this.aiRun.createdAt
        )}-${this.aiRun.id}.txt`;
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(data)
        );
        element.setAttribute("download", fileName);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    },
    async downloadAIResult(sectionName, format = "") {
      let fullFileName = "";
      if (sectionName == "all") {
        if (format == "html") {
          fullFileName = `ai-article-${toLocalDateString(
            this.aiRun.createdAt
          )}-${this.aiRun.id}.html`;
          downloadAsHtml(fullFileName, this.fullHtml);
        } else if (["txt", "txt_plain"].includes(format)) {
          let data = "";
          if (format == "txt") {
            fullFileName = `ai-article-${toLocalDateString(
              this.aiRun.createdAt
            )}-${this.aiRun.id}-html txt.txt`;
            data = beautifyHtmlString(this.fullHtml);
          } else if (format == "txt_plain") {
            fullFileName = `ai-article-${toLocalDateString(
              this.aiRun.createdAt
            )}-${this.aiRun.id}-plain txt.txt`;
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.fullHtml, "text/html");
            data = doc.body.innerText;
            // const allTags = doc.body.querySelectorAll('*')
            // data += doc.title + '\n\n';
            // for (const tag of allTags) {
            //   data += tag.textContent
            //   const tagName = tag.tagName.toLowerCase()
            //   let delimiter = '';
            //   if (tagName == 'h1') {
            //     delimiter = '\n\n\n';
            //   } else if (tagName == 'p') {
            //     delimiter = '\n\n';
            //   } else {
            //     delimiter = '\n';
            //   }
            //   data += delimiter;
            // }
          }

          let element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(data)
          );
          element.setAttribute("download", fullFileName);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
        return;
      }

      let getPDataForDownload = () => {
        let pData = "";
        this.pAIResult.map((item) => {
          pData += `${item["subheadingInfo"]["tagName"]}: ${item["subheadingInfo"]["tagText"]}\n`;
          pData += `${item.answer}\n\n`;
        });
        return pData;
      };

      let data = "";

      switch (sectionName) {
        case "title":
          data = this.titleAIResult;
          break;
        case "pageTitle":
          data = this.pageTitleAIResult;
          break;
        case "subHeadings":
          data = this.subHeadingsAIResult;
          break;
        case "p":
          data = getPDataForDownload();
          break;
      }

      if (data) {
        let fileName = `ai-article-${sectionName}-${toLocalDateString(
          this.aiRun.createdAt
        )}-${this.aiRun.id}.txt`;
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(data)
        );
        element.setAttribute("download", fileName);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    },
    showContnentPromptGenerationModal(
      isFirstGenerate,
      key,
      shortenKey,
      uniqueIndex
    ) {
      this.$refs.contnentPromptGenerationModal.showModalAction(
        isFirstGenerate,
        key,
        shortenKey,
        uniqueIndex
      );
    },
    showEditPopupForRichItem(item) {
      this.showRichContentItemEditModal = true;
      this.richContentItemToEdit = item;
    },
    saveEditedRichContentItem() {
      let data = {
        aiRunId: this.aiRun ? this.aiRun.id : 0,
        signalName: this.richContentItemToEdit.key,
        uniqueIndex: this.richContentItemToEdit.uniqueIndex,
        editedContent: this.richContentItemToEdit.currentResult,
      };

      this.$store.commit("showLoading");
      this.$store
        .dispatch("aiRunUpdateEditedContentForRichItem", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
          } else {
            this.$notify({
              group: "info",
              type: "error",
              text: "Failed to saving your edited content",
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
          this.showRichContentItemEditModal = false;
        });
    },
    showHistoryForRichItem(key, shortenKey, uniqueIndex) {
      this.showRichContentItemHistoryModal = true;
      this.selectedRichContentItemData = {
        key,
        shortenKey,
        uniqueIndex,
      };
    },
    loadAndSaveCurrentSelectionForRichContent(selectedIndex, item) {
      let { key, uniqueIndex } = this.selectedRichContentItemData;
      let data = {
        aiRunId: this.aiRun.id,
        signalName: key,
        uniqueIndex,
        selectedIndex,
      };

      this.$store.commit("showLoading");
      this.$store
        .dispatch("aiRunChangeCurrentSelectionForRichItem", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
          } else {

          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
          this.showRichContentItemHistoryModal = false;
        });
    },
    isTitleOrPageTitleInEditingMode(sectionName) {
      return (
        this.editInOutlineInfo && this.editInOutlineInfo.section == sectionName
      );
    },
    isOutlineItemInEditingMode(sectionName, item) {
      return (
        this.editInOutlineInfo &&
        this.editInOutlineInfo.section == sectionName &&
        this.editInOutlineInfo.originIndex == item.originIndex
      );
    },
    cancelEditItemInOutlineFor(sectionName) {
      if (
        this.editInOutlineInfo &&
        this.editInOutlineInfo.section == sectionName
      ) {
        this.editInOutlineInfo = {};
      }

      if (sectionName == "title") {
        this.saveEditedTitle();
      }
      if (sectionName == "pageTitle") {
        this.saveEditedPageTitle();
      }
      if (sectionName == "subHeadings") {
        this.saveEditedSubheadings();
      }
    },
    showDeleteConfirmModalAction(msg, func) {
      this.showDeleteConfirmModal = true;
      this.deleteConfirmMessage = msg;
      this.deleteAction = func;
    },
    deleteSubHeadingSelected() {
      if (this.editInOutlineInfo) {
        this.calculated_subHeadingsAIResultArr =
          this.calculated_subHeadingsAIResultArr.filter(
            (item) => item.originIndex != this.editInOutlineInfo.originIndex
          );
      }
      this.saveEditedSubheadings();
      this.editInOutlineInfo = {};
      this.showDeleteConfirmModal = false;
    },
    addNewSubheadingsAction() {
      let originIndex = 0;
      this.calculated_subHeadingsAIResultArr.map((item) => {
        if (item.originIndex > originIndex) {
          originIndex = item.originIndex;
        }
      });
      originIndex++;

      this.calculated_subHeadingsAIResultArr.push({
        tagName: this.newSubHeadingTag,
        text: this.newSubheadingText,
        originIndex: originIndex,
      });
      this.saveEditedSubheadings();
      this.showAddSubheadingsModal = false;
    },

    addNewExcludeTerm(termsStr) {
      termsStr = termsStr.trim().toLowerCase();
      if (!termsStr) {
        return;
      }
      const excludeTerms = this.excludeTerms.slice();

      let terms = termsStr.split(",").map((item) => item.trim());

      terms.map((term) => {
        if (!excludeTerms.includes(term)) {
          excludeTerms.push(term);
        }
      });
      this.excludeTerms = excludeTerms;
    },
    handleRemoveExcludeTerm(p) {
      this.excludeTerms = this.excludeTerms.filter((term) => term != p);
    },
    countWords(value) {
      return value?.split?.(' ')?.filter?.(x => x !== '')?.length || 0
    }
  },
  beforeDestroy() {

    document.removeEventListener("click", this.handleClickOutside);

    // if (this.saveOpenAITabData) {
    //   this.saveOpenAITabData({
    //     temperature: this.temperature,
    //     maxTokens: this.maxTokens,
    //     topP: this.topP,
    //     frequencyPenalty: this.frequencyPenalty,
    //     presencePenalty: this.presencePenalty,
    //     // these needed to avoid duplicated scraping.
    //     targetUrlSourceCode: this.targetUrlSourceCode,
    //     competitorsSourceCode: this.competitorsSourceCode,

    //     pageTitlePrompt: this.pageTitlePrompt,
    //     subHeadingsPrompt: this.subHeadingsPrompt,
    // pPromptPhase1: this.pPromptPhase1,
    // pPromptPhase2: this.pPromptPhase2,

    //     pageTitleAIResult: this.pageTitleAIResult,
    //     subHeadingsAIResult: this.subHeadingsAIResult,
    //     pAIResultPhase1: this.pAIResultPhase1,
    //     pAIResultPhase2: this.pAIResultPhase2,
    //   })
    // }
  },
  mounted() {
    if (this.$route.query.selectTargetWC === 'true') {
      this.selectedWordCountOption = "targetWordCount";
    }

    if (
      this.selectedWordCountOption == "currentWordCount" &&
      this.currentWordCount == 0
    ) {
      this.selectedWordCountOption = "targetWordCount"
    }

    document.addEventListener("click", this.handleClickOutside);
    // if (this.initialData) {
    //   Object.keys(this.initialData).map(key => {
    //     if (key in this) {
    //       this[key] = this.initialData[key]
    //     }
    //   })
    // }

    if (this.isDemo) {
      this.selectedWordCountOption = "targetWordCount";
      this.webPageType = "blog";
      this.selectedContentPromptsSignals = this.richContentItemsForAIWriter.map(
        (row) => row.signalName
      );
      this.selectedContentPromptsSignals.forEach(
        (name) => (this.selectedCountForPromptSignals[name] = 1)
      );
      this.selectedCompetitors = this.pageRun.competitors
        .slice(0, 3)
        .map((run) => run.id);
      this.excludeTerms = ["embark", "embrace", "final thoughts", "conclusion"];
    }
    if (
      this.selectedWordCountOption == "currentWordCount" &&
      this.currentWordCount > 0
    ) {
      this.numberOfWords = this.currentWordCount;
    }
    this.loadAiRun(true);
    // this.$vuetify.theme.dark = true;
  },
  created() {
    this.handleParagraphEditDebounced = debounce(
      this.handleParagraphEdit,
      3000
    );
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>

<style lang="scss">
.step-title-label {
  font-size: 1rem;
  font-weight: 800;
  color: var(--v-mainColor3-base);
}

.main-content-normal {
  .text-part {
    a {
      color: var(--v-link1Color-base) !important;
      font-weight: 800;
      font-size: 1rem;
    }
  }
}
.ai-generated-image-modal {
  .v-textarea fieldset {
    border-color: #D7D7D7 !important;
  }
}
</style>
