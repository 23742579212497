<template>
  <div
    class="ai-result-full-as-google-doc-style"
  >
    
    <div class="article-actions-head">
      <div class="d-flex align-center gap10" style="flex: 1;">
        
      </div>
      <div class="d-flex align-center gap4">
        <div class="d-flex align-center gap4" style="background-color: var(--v-whiteColor-base); border-radius: 8px; border: 1px solid var(--v-gray15Color-base); padding: 0 10px;  height: 35px;">
          <vue-toggles
            small
            :value="isHighlightEnabled"
            width="30" height="15"
            :checkedBg="$vuetify.theme.currentTheme['green7Color']"
            :uncheckedBg="$vuetify.theme.currentTheme['gray35Color']"
            @click="isHighlightEnabled = !isHighlightEnabled"
            >
          </vue-toggles>
          <span class="ml-2" style="font-size: 0.8rem;">Highlight keywords</span>
        </div>
        <v-menu offset-y bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              small
              :disabled="isDemo"
              style="background-color: var(--v-whiteColor-base); border-color: var(--v-gray15Color-base); border-radius: 8px; height: 35px;"
              color="mainColor3"
              class="text-none ml-3 px-0"
              v-bind="attrs"
              v-on="on"
            >
              <i class="material-icons">more_horiz</i>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="() => $emit('startOver')">
              <v-list-item-title>Start over</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <template v-if="metaSectionItem">
      <template
        v-if="editingParagraphIdxInResult == metaSectionItem.idx"
      >
        <codemirror
          class="meta-editor"
          v-model="metaSectionItem.item.answer"
          :options="{
            ...cmOptions,
            theme: codeMirrorTheme,
            readOnly: !!isDemo,
          }"
          @input="
            (data) =>
              handleParagraphEditDebounced(metaSectionItem.idx, data)
          "
          v-click-outside="
            () => {
              $emit('update:editingParagraphIdxInResult', -1)
            }
          "
        />
        <div
          class="action-btns-row mt-2 mb-10"
        >
          <v-btn
            rounded
            color="profileAnchorColor"
            class="text-none whiteColor--text"
            v-clipboard:copy="metaSectionItem.item.answer"
            v-clipboard:success="
              () =>
                $notify({
                  group: 'info',
                  type: 'success',
                  text: 'Copied to clipboard',
                })
            "
            v-clipboard:error="
              () =>
                $notify({
                  group: 'info',
                  type: 'error',
                  text: 'can not copy to clipboard',
                })
            "
          >
            Copy
          </v-btn>
          <v-btn
            outlined
            rounded
            color="mainColor3"
            class="text-none mainColor3--text mr-3"
            @click="regenerateForParagraphOneSection(metaSectionItem.idx)"
          >
            <span class="material-icons">replay</span>
            <span>Regenerate</span>
          </v-btn>

          <v-btn
            outlined
            rounded
            color="mainColor3"
            class="text-none mainColor3--text mr-3"
            @click="
              () => {
                $emit('update:seletedParagraphSection', metaSectionItem.idx)
                $emit('update:showParagraphOneSectionHistoryModal', true)
              }
            "
          >
            View history
          </v-btn>
        </div>
      </template>
      <template v-else>
        <div class="meta-section">
          <div class="meta-section-label">Meta description</div>
          <div class="d-flex gap5">
            <div class="meta-section-content" style="flex: 1;" @click="
              () => {
                $emit('update:editingParagraphIdxInResult', metaSectionItem.idx)
              }
            ">
              {{ metaSectionItem.item.answer }}
            </div>
            <b-tooltip
              label="Copy to clipboard"
              type="is-dark"
            >
              <button
                class="action-btn"
                style="align-self: flex-start;"
                v-clipboard:copy="metaSectionItem.item.answer"
                v-clipboard:success="
                  () =>
                    $notify({
                      group: 'info',
                      type: 'success',
                      text: 'Copied to clipboard',
                    })
                "
                v-clipboard:error="
                  () =>
                    $notify({
                      group: 'info',
                      type: 'error',
                      text: 'Cannot copy to clipboard',
                    })
                "
              >
                <span class="material-icons-outlined action"
                  >file_copy</span
                >
              </button>
            </b-tooltip>
          </div>
        </div>
      </template>
    </template>

    <div class="with-tag-block">
      <div class="h1">T</div>
      <div class="main-content-normal mt-0">
        <div class="text-part">
          <div
            v-if="calculated_titleAIResult"
            class="main-content-title"
          >
            {{ calculated_titleAIResult.text }}
          </div>
        </div>
        <div class="action-part">
          <div>
            <span class="material-icons-outlined expand-collapse"
              >remove</span
            >
            <span class="block-type">meta title</span>
          </div>
        </div>
      </div>
    </div>

    <div class="with-tag-block" style="margin-top: 50px">
      <div class="h1">h1</div>
      <div class="main-content-normal mt-0">
        <div class="text-part">
          <div
            v-if="calculated_pageTitleAIResult"
            class="main-content-title"
          >
            {{ calculated_pageTitleAIResult.text }}
          </div>
        </div>
        <div class="action-part">
          <div>
            <span class="material-icons-outlined expand-collapse"
              >remove</span
            >
            <span class="block-type">title</span>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div
        v-for="(item, idx) in pAIResult"
        :key="`paragraph_block_${idx}`"
        class="result-one-section"
        :style="item.metaSection ? 'display: none;': ''"
      >
        <template
          v-if="
            item.metaSection ||
            item.introSection ||
            item.endSection
          "
        >
          <div class="with-tag-block">
            <div></div>
            <div class="one-grouped-block">
              <div
                :class="{
                  'editing-p-block':
                    editingParagraphIdxInResult == idx,
                }"
              >
                <div class="main-content-normal">
                  <div class="text-part">
                    <template v-if="!item.metaSection">
                      <template
                        v-if="editingParagraphIdxInResult == idx"
                      >
                        <VueTrix
                          :disabledEditor="isDemo"
                          :srcContent="item.answer"
                          @input="item.answer = $event.replace('<div>', '<p>').replace('</div>', '</p>')"
                          placeholder="You can edit paragraph"
                          v-click-outside="
                            () => {
                              $emit('update:editingParagraphIdxInResult', -1)
                              
                            }
                          "
                        />
                      </template>
                      <template v-else>
                        <div
                          :class="item.keyTakeaways ? 'key-takeaways-editor' : ''"
                          v-html="highlightKeywords(item.answer)"
                          @click="
                            () => {
                              $emit('update:editingParagraphIdxInResult', idx)
                            }
                          "
                        ></div>
                      </template>
                    </template>
                  </div>
                  <span class="action-part">
                    <div>
                      <span
                        class="material-icons-outlined expand-collapse"
                        >remove</span
                      >
                      <span class="block-type">
                        <template v-if="item.introSection"
                          >intro</template
                        >
                        <template v-else-if="item.endSection"
                          >conclusion</template
                        >
                      </span>
                    </div>
                  </span>
                </div>
                <div
                  class="action-btns-row mt-2"
                  v-if="editingParagraphIdxInResult == idx && !item.metaSection"
                >
                  <v-btn
                    rounded
                    color="profileAnchorColor"
                    class="text-none whiteColor--text"
                    v-clipboard:copy="item.answer"
                    v-clipboard:success="
                      () =>
                        $notify({
                          group: 'info',
                          type: 'success',
                          text: 'Copied to clipboard',
                        })
                    "
                    v-clipboard:error="
                      () =>
                        $notify({
                          group: 'info',
                          type: 'error',
                          text: 'can not copy to clipboard',
                        })
                    "
                  >
                    Copy
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    color="mainColor3"
                    class="text-none mainColor3--text mr-3"
                    @click="regenerateForParagraphOneSection(idx)"
                  >
                    <span class="material-icons">replay</span>
                    <span>Regenerate</span>
                  </v-btn>

                  <v-btn
                    outlined
                    rounded
                    color="mainColor3"
                    class="text-none mainColor3--text mr-3"
                    @click="
                      () => {
                        $emit('update:seletedParagraphSection', idx)
                        $emit('update:showParagraphOneSectionHistoryModal', true)
                      }
                    "
                  >
                    View history
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div
            :id="textToCssId(item['subheadingInfo']['tagText'])"
            class="one-grouped-block"
          >
            <div class="with-tag-block">
              <div
                :class="{
                  h2: item['subheadingInfo']['tagName'] == 'h2',
                  h3: item['subheadingInfo']['tagName'] == 'h3',
                }"
              >
                <template
                  v-if="item['subheadingInfo']['tagName'] == 'h2'"
                  >h2</template
                >
                <template
                  v-if="item['subheadingInfo']['tagName'] == 'h3'"
                  >h3</template
                >
              </div>
              <div
                :class="{
                  'main-content-h2':
                    item['subheadingInfo']['tagName'] == 'h2',
                  'main-content-h3':
                    item['subheadingInfo']['tagName'] == 'h3',
                }"
              >
                {{ item["subheadingInfo"]["tagText"] }}
              </div>
            </div>
            <div class="with-tag-block">
              <div></div>
              <div
                :class="{
                  'editing-p-block':
                    editingParagraphIdxInResult == idx,
                }"
              >
                <div class="main-content-normal">
                  <span class="text-part">
                    <template
                      v-if="editingParagraphIdxInResult == idx"
                    >
                      <VueTrix
                        :disabledEditor="isDemo"
                        :srcContent="item.answer"
                        @input="item.answer = $event.replace('<div>', '<p>').replace('</div>', '</p>')"
                        placeholder="You can edit paragraph"
                        v-click-outside="
                          () => {
                            $emit('update:editingParagraphIdxInResult', -1)
                          }
                        "
                      />
                    </template>
                    <template v-else>
                      <div
                        v-html="highlightKeywords(item.answer)"
                        @click="
                          () => {
                            $emit('update:editingParagraphIdxInResult', idx)
                            setNeedsToSave();
                          }
                        "
                      ></div>
                    </template>
                  </span>
                  <span class="action-part">
                    <div>
                      <span
                        class="material-icons-outlined expand-collapse"
                        >remove</span
                      >
                      <span
                        class="block-type"
                        @click="
                          () => {
                            $emit('update:editingParagraphIdxInResult', idx)
                          }
                        "
                      >
                        {{ item?.briefOverview ? 'brief overview' : item?.keyTakeaways ? 'key highlights' : 'paragraph' }}
                      </span>
                    </div>
                  </span>
                </div>
                <div
                  class="action-btns-row mt-2"
                  v-if="editingParagraphIdxInResult == idx"
                >
                  <v-btn
                    rounded
                    color="profileAnchorColor"
                    class="text-none whiteColor--text"
                    v-clipboard:copy="item.answer"
                    v-clipboard:success="
                      () =>
                        $notify({
                          group: 'info',
                          type: 'success',
                          text: 'Copied to clipboard',
                        })
                    "
                    v-clipboard:error="
                      () =>
                        $notify({
                          group: 'info',
                          type: 'error',
                          text: 'can not copy to clipboard',
                        })
                    "
                  >
                    Copy
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    color="mainColor3"
                    class="text-none mainColor3--text mr-3"
                    @click="regenerateForParagraphOneSection(idx)"
                  >
                    <span class="material-icons">replay</span>
                    <span>Regenerate</span>
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    color="mainColor3"
                    class="text-none mainColor3--text mr-3"
                    @click="
                      () => {
                        $emit('update:seletedParagraphSection', idx)
                        $emit('update:showParagraphOneSectionHistoryModal', true)
                      }
                    "
                  >
                    View history
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
          v-for="(item, idx1) in getAllContentPromptDataForPSection(
            idx
          )"
          :key="`${idx}_cp_placeholder${idx1}`"
          class="with-tag-block"
        >
          <div></div>
          <div>
            <div class="main-content-normal my-7">
              <div class="text-part">
                <div
                  v-if="item.shortenKey == 'form'"
                  class="content-prompt-placeholder"
                  :class="{ 'is-empty': true }"
                >
                  <div class="text-center">
                    <span class="material-icons main-icon"
                      >assignment</span
                    >
                  </div>
                  <div class="text-center">
                    This is where you can add a form on your page
                  </div>
                </div>
                <div
                  v-else-if="item.shortenKey == 'image' && !aiGeneratedImagesMap[idx]?.[item.uniqueIndex]"
                  class="content-prompt-placeholder image-placeholder"
                  :class="{ 'is-empty': true }"
                >
                  <div class="text-center mb-3">
                    <span class="material-icons main-icon"
                      >image</span
                    >
                  </div>
                  <div class="text-center">
                    This is where you can add an image on your page.<br/>
                    <v-btn
                      text
                      rounded
                      color="profileAnchorColor"
                      class="text-none profileAnchorColor--text mt-2"
                      @click="
                        () => {
                          $emit('update:imageAIModalParams', {
                            pSectionIdx: idx,
                            uniqueIndex: item.uniqueIndex
                          })
                        }
                      "
                    >
                      <i class="material-icons" style="margin-right: 10px;">auto_awesome</i>
                      <span class="font-weight-bold">Generate with AI</span>
                    </v-btn>
                  </div>
                </div>
                <div
                  v-else-if="item.shortenKey == 'image' && aiGeneratedImagesMap?.[idx]?.[item.uniqueIndex]"
                  class="ai-generated-image-container"
                >
                  <div>
                    <img :src="aiGeneratedImagesMap[idx][item.uniqueIndex].url" />
                    <div class="overlay">
                      <v-tooltip top color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            rounded
                            color="white"
                            class="text-none"
                            :href="aiGeneratedImagesMap[idx][item.uniqueIndex].url"
                            :download="aiGeneratedImagesMap[idx][item.uniqueIndex].url"
                            target="_blank"
                          >
                            <i class="material-icons">file_download</i>
                          </v-btn>
                        </template>
                        <div class="white-bg-tooltip">
                          Download
                        </div>
                      </v-tooltip>
                      <v-tooltip top color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            rounded
                            color="white"
                            class="text-none ml-4"
                            @click="
                              () => {
                                $emit('update:imageAIModalParams', {
                                  pSectionIdx: idx,
                                  uniqueIndex: item.uniqueIndex
                                })
                              }
                            "
                          >
                            <i class="material-icons">refresh</i>
                          </v-btn>
                        </template>
                        <div class="white-bg-tooltip">
                          Regenerate
                        </div>
                      </v-tooltip>
                      <v-tooltip top color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            rounded
                            color="white"
                            class="text-none ml-4"
                            @click="
                              () => {
                                $emit('update:deleteImageAIModalParams', {
                                  pSectionIdx: idx,
                                  uniqueIndex: item.uniqueIndex
                                })
                              }
                            "
                          >
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <div class="white-bg-tooltip">
                          Delete
                        </div>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="
                    ['list', 'table', 'faq'].includes(item.shortenKey)
                  "
                  class="content-prompt-ai-result-section"
                >
                  <div>
                    <div
                      v-if="item.isEmpty"
                      class="empty-case"
                      @click="
                        () =>
                          showContnentPromptGenerationModal(
                            true,
                            item.key,
                            item.shortenKey,
                            item.uniqueIndex
                          )
                      "
                    >
                      <span class="material-icons-outlined"
                        >add_circle</span
                      >
                      Rich content results:
                      <strong>{{
                        item.shortenKey | capitalize
                      }}</strong>
                    </div>
                    <div
                      v-else
                      class="content-promopt-one-signal-result"
                    >
                      <div
                        v-html="
                          item.resultToDisplay || item.currentResult
                        "
                      ></div>
                    </div>
                  </div>
                </div>
                <div v-else>{{ JSON.stringify(item) }}</div>
              </div>
              <div class="action-part">
                <div>
                  <span
                    class="material-icons-outlined expand-collapse"
                    >remove</span
                  >
                  <span class="block-type">
                    {{ item.shortenKey }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="
                ['list', 'table', 'faq'].includes(item.shortenKey)
              "
              class="action-btns-row"
            >
              <template v-if="!item.isEmpty">
                <v-btn
                  outlined
                  rounded
                  color="mainColor3"
                  class="text-none mainColor3--text"
                  :disabled="isDemo"
                  @click="() => showEditPopupForRichItem(item)"
                >
                  Edit
                </v-btn>
                <v-btn
                  outlined
                  rounded
                  color="mainColor3"
                  class="text-none mainColor3--text mr-3"
                  :disabled="isDemo"
                  @click="
                    () =>
                      showContnentPromptGenerationModal(
                        false,
                        item.key,
                        item.shortenKey,
                        item.uniqueIndex
                      )
                  "
                >
                  Regenerate
                </v-btn>
                <v-btn
                  outlined
                  rounded
                  color="mainColor3"
                  class="text-none mainColor3--text mr-3"
                  :disabled="isDemo"
                  @click="
                    () =>
                      showHistoryForRichItem(
                        item.key,
                        item.shortenKey,
                        item.uniqueIndex
                      )
                  "
                >
                  View history
                </v-btn>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

import { findAll as highlight } from "highlight-words-core";

// import TinyMceEditor from '@tinymce/tinymce-vue';
import VueTrix from "vue-trix";
// import { TINY_MCE_API_KEY } from '@/utils/config.js'

import { codemirror } from "vue-codemirror";
// import language js
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/css/css.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";

import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/html-hint.js";
import "codemirror/addon/hint/css-hint.js";
import "codemirror/addon/search/search.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/dialog/dialog.js";

// import base style
import "codemirror/lib/codemirror.css";
// import theme style
import "codemirror/theme/material.css";
// codemirror addon
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/dialog/dialog.css";
import "codemirror/addon/search/matchesonscrollbar.css";

export default {
    name: 'Result7Paragraph',
    components: {
      VueTrix,
      codemirror,
    },
    props: [
        'isDemo',
        'isLoading',

        'pageRun',
        'pAIResult',
        'calculated_titleAIResult',
        'calculated_pageTitleAIResult',
        'editingParagraphIdxInResult',
        'handleParagraphEditDebounced',
        'regenerateForParagraphOneSection',
        'seletedParagraphSection',
        'showParagraphOneSectionHistoryModal',
        'textToCssId',
        'setNeedsToSave',
        'getAllContentPromptDataForPSection',
        'aiGeneratedImagesMap',
        'imageAIModalParams',
        'deleteImageAIModalParams',
        'showContnentPromptGenerationModal',
        'showEditPopupForRichItem',
        'showHistoryForRichItem',
    ],
    data() {
      return {
        isHighlightEnabled: false,
      }
    },
    computed: {
        STEPS() {
            return STEPS
        },
        cmOptions() {
          return {
            tabSize: 4,
            // mode: "text/javascript",
            // mode: "text/html",
            mode: "htmlmixed",
            // theme: "material",
            theme: "default",
            lineNumbers: true,
            lineWrapping: true,
            line: true,
            height: "200px",
            // more CodeMirror options...
          }
        },
        codeMirrorTheme() {
          return "default" // default, "material", "abcdef"
        },
        metaSectionItem() {
          let found = null
          this.pAIResult.forEach((item, idx) => {
            if (item.metaSection) {
              found = {item, idx}
            }
          })
          return found
        }
    },
    methods: {
      highlightKeywords(textToHighlight) {
        if (!this.isHighlightEnabled || !this?.pageRun?.contentBrief?.length) {
          return textToHighlight
        }

        const chunks = highlight({
          searchWords: this.pageRun.contentBrief.map(item => item.term.phrase),
          textToHighlight
        });

        return chunks
          .map(chunk => {
            const { end, highlight, start } = chunk;
            const text = textToHighlight.substr(start, end - start);
            if (highlight) {
              return `<mark>${text}</mark>`;
            } else {
              return text;
            }
          })
          .join("");

      }
    },
    mounted() {
      this.$nextTick(() => {
        let found = (
          document.querySelector('.step-scroll-target')
          || document.querySelector('.ai-writer-head')
        );
        if (found) {
          found.scrollIntoView({behavior: 'smooth'});
        }
      })
    }
}
</script>

<style lang="scss" scoped>
.meta-section {
  border-radius: 5px;
  border: 1px dashed var(--v-grayColor-base);
  padding: 20px 30px;
  margin-bottom: 50px;

  .meta-section-label {
    padding: 0 10px;
    background-color: var(--v-whiteColor-base);
    color: var(--v-gray2Color-base);
    line-height: 20px;
    margin-top: -32px;
    width: max-content;
    margin-bottom: 12px;
  }

  .meta-section-content {
    line-height: 1.7;
  } 
}

.article-actions-head {
  background-color: var(--v-gray8Color-base);
  margin-bottom: 50px;
  padding: 15px;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

</style>
