<template>
    <div>

      <div class="step-title-label">Select your competitors</div>

      <div class="mt-3 mainColor3--text">
        Select up to three competitors with good content for the AI to use
        for inspiration.
      </div>

      <div class="mt-10">
        <table class="select-competitors-table">
          <thead>
            <tr>
              <th>SELECT</th>
              <th>TITLE</th>
              <th nowrap>WORD COUNT</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, idx) in pageRun.competitors" :key="idx">
              <td>
                <vue-toggles
                  :value="selectedCompetitors.includes(c.id)"
                  width="40"
                  height="20"
                  :disabled="
                    isDemo ||
                    (selectedCompetitors.length >= 3 &&
                      selectedCompetitors.indexOf(c.id) == -1)
                  "
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="
                    () => {
                      if (selectedCompetitors.includes(c.id)) {
                        $emit('update:selectedCompetitors', selectedCompetitors.filter(
                          (item) => item != c.id
                        ))
                      } else {
                        $emit('update:selectedCompetitors', [...selectedCompetitors, c.id])
                      }
                    }
                  "
                ></vue-toggles>
              </td>
              <td>
                <div>
                  <a class="link" :href="c.url" target="_blank">{{
                    c.url
                  }}</a>
                  <diV class="competitor-title mt-2">{{ c.title }}</diV>
                </div>
              </td>
              <td class="word-count-cell">
                {{ competitorWordCount(c) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigCompetitors',
    props: [
        'goNext',

        'isDemo',
        'isFailedPageRun',
        'pageRun',

        'selectedCompetitors',
        'competitorWordCount',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>
.select-competitors-table {
  width: 100%;
  th {
    background: var(--v-gray7Color-base);
    border-bottom: 1px solid var(--v-grayColor-base);
    color: var(--v-mainColor3-base);
    padding: 12px 10px;
    font-size: 0.8rem;
    font-weight: 500;
  }
  td {
    padding: 12px 10px;
    border-bottom: 1px solid var(--v-grayColor-base);
    vertical-align: middle;
  }
  .link {
    color: var(--v-link15Color-base);
  }
  .competitor-title {
    color: var(--v-mainColor3-base);
    font-weight: 700;
    font-size: 0.9rem;
  }

  .word-count-cell {
    text-align: center;
    color: var(--v-mainColor3-base);
    font-size: 0.8rem;
    font-weight: 700;
  }
}
</style>