<template>
    <div>
      {{ selectedCompetitors?.length || 0 }} competitors selected
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigPreviewCompetitors',
    props: [
        'selectedCompetitors',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>