<template>
  <div class="modal site-content-audit-form-modal" :class="{'is-active': active}">
    <div class="modal-background" @click.stop="$emit('close')"></div>
    <div class="modal-content">
      <div class="modal-container">
        
        <div class="form-sidebar">
          <div class="mb-6 mainColor--text font-weight-bold" style="font-size: 1.3rem;">
            Content audit form
          </div>

          <div class="mb-12 mainColor--text">
            Please provide as much details as possible in your answers. The information you provide will directly impact the accuracy and effectiveness of our audit.
          </div>
          
          <div class="steps-status mt-4 mb-8">
            <div
              v-for="(step, idx) in formSchema"
              :key="idx"
              :class="{
                'step-item': true,
                'active': currentStep === idx,
                'completed': isStepValid(idx) || activeAudit.status === 'form_submitted'
              }"
            >
              <div class="step-left">
                <div class="step-dot">
                  <i class="material-icons">check</i>
                </div>
                <div class="step-seperator" v-if="idx < (formSchema.length - 1)"></div>
              </div>
              <div class="step-right">
                <div class="mb-1 step-label">{{ step.label }}</div>
                <div class="step-description">{{ step.description }}</div>
              </div>
            </div>
          </div>
          
          <a href="#"
            class="mainColor--text font-weight-bold"
            style="display: inline-flex; align-items: center; gap: 4px;"
            @click.prevent.stop="() => {
              $emit('close');
            }">
            <i class="material-icons">arrow_back</i> Back to project
          </a>
        </div>

        <div class="form-main">
          
          <div class="progress-bar">
            <div
              class="progress-bar-percentage"
              :style="{
                width: `${progressValue}%`
              }"
            ></div>
          </div>
          
          <div class="step-controls">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="currentStep < 1"
                  v-bind="attrs"
                  v-on="on"
                  @click="currentStep -= 1"
                >
                  <v-icon>keyboard_arrow_up</v-icon>  
                </v-btn>
              </template>
              <span>Previous step</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="!isStepValid(currentStep) || currentStep >= (formSchema.length - 1)"
                  v-bind="attrs"
                  v-on="on"
                  @click="currentStep += 1"
                >
                  <v-icon>keyboard_arrow_down</v-icon>  
                </v-btn>
              </template>
              <span>Next step</span>
            </v-tooltip>
          </div>

          <div class="form-submit">
            <v-btn
              v-if="currentStep === 2 && isFormValid && activeAudit.status !== 'form_submitted'"
              elevation="1"
              rounded
              color="anchor1Color"
              class="button-with-icon text-none whiteColor--text"
              tag="a"
              @click="submitForm"
            >
              Submit form
            </v-btn>
          </div>

          <div
            v-if="activeAudit.status === 'form_submitted'"
            class="edit-bar"
          >
            <div class="edit-bar-text">You can view and edit your responses</div>
            <v-btn
              v-if="currentStep >= formSchema.length"
              elevation="1"
              rounded
              color="anchor1Color"
              class="button-with-icon text-none whiteColor--text"
              tag="a"
              @click="currentStep = 0"
            >
              <i class="material-icons mr-1" style="font-size: 1.2rem;">edit</i>  
              Edit responses
            </v-btn>
            <v-btn
              v-else
              elevation="1"
              rounded
              color="anchor1Color"
              class="button-with-icon text-none whiteColor--text"
              tag="a"
              :disabled="!isFormValid"
              @click="submitForm"
            >
              <i class="material-icons mr-1" style="font-size: 1.2rem;">autorenew</i>
              Re-submit responses
            </v-btn>
          </div>

          <div class="form-main-content">

            <div
              v-if="currentStep >= formSchema.length && activeAudit.status === 'form_submitted'"
              class="success-container"
            >
              <div class="success-icon">
                <i class="material-icons">check</i>
              </div>
              <div
                class="mainColor--text font-weight-bold"
                style="font-size: 1.2rem; max-width: 500px; text-align: center;"
              >We’ve received your answers</div>
              <div
                class="mainColor--text"
                style="max-width: 400px; text-align: center;"
              >For any questions, please contact us at support@pageoptimizer.pro</div>
              <v-btn
                elevation="1"
                rounded
                color="anchor1Color"
                class="button-with-icon text-none whiteColor--text"
                tag="a"
                @click="$emit('close');"
              >
                Continue onboarding
              </v-btn>
            </div>
            <template v-else>
              <div
                v-for="(step, idx) in formSchema"
                :key="idx"
                :ref="'step' + idx"
                :style="'max-width: 700px;' + (currentStep !== idx?'display:none;':'')"
                class="pb-10"
              >
                <div
                  v-for="field in step.fields" :key="field.key"
                  class="form-field"
                >
    
                  <template
                    v-if="!field.show_if || (field.show_if && formData['step' + idx][field.show_if.key] === field.show_if.value)"
                  >
    
                    <div
                      v-if="field.label"
                      class="pb-4"
                    >
                      <label
                        :for="field.key"
                        class="mainColor--text font-weight-bold"
                      >
                        {{ field.label }} <span v-if="field?.validation?.required" style="color:red;">*</span>
                      </label>

                      <div
                        v-if="field.extra_info_html"
                        class="extra-info"
                        v-html="field.extra_info_html"
                      ></div> 
                    </div>  
                    
                    <template v-if="field.type === 'input'">
                      <v-text-field
                        :id="field.key"
                        solo
                        v-model="v$.formData['step' + idx][field.key].$model"
                        :readonly="field.readonly"
                        :placeholder="field.placeholder + (!field?.validation?.required ? ' (Optional)' : '')"
                        :error-messages="v$.formData['step' + idx][field.key].$errors.map(e => e.$message)"
                      />
                    </template>
    
                    <template v-if="field.type === 'radio'">
                      <v-radio-group
                        :id="field.key"
                        v-model="v$.formData['step' + idx][field.key].$model"
                        :error-messages="v$.formData['step' + idx][field.key].$errors.map(e => e.$message)"
                      >
                        <v-radio
                          v-for="option in field.options"
                          color="#FF5C00"
                          :key="option.key"
                          :label="option.label"
                          :value="option.key"
                        ></v-radio>
                      </v-radio-group>
                    </template>

                    <template v-if="field.type === 'radio_buttons'">
                      <v-input
                        class="radio-buttons"
                        :error-messages="v$.formData['step' + idx][field.key].$errors.map(e => e.$message)"
                      >
                        <v-btn
                          v-for="option in field.options"
                          :key="option.key"
                          outlined
                          :color="v$.formData['step' + idx][field.key].$model === option.key ? '#FF5C00' : undefined"
                          :class="{
                            'active': v$.formData['step' + idx][field.key].$model === option.key
                          }"
                          @click="v$.formData['step' + idx][field.key].$model = option.key"
                        >{{ option.label }}</v-btn>
                      </v-input>
                      
                      
                    </template>
    
                  </template>
                  
                </div>
              </div>
            </template>

          </div>

        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click.stop="$emit('close')">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, url, helpers } from '@vuelidate/validators'
import { merge } from 'lodash'

export const oneOf = values => helpers.withParams(
  { type: 'oneOf', values },
  value => !helpers.req(value) || values.includes(value)
)

const formSchema = [
  {
    type: 'section',
    label: 'Site Info',
    description: 'Your website type and industry',
    fields: [
      {
        type: 'input',
        key: 'email',
        label: 'What’s your POP account email?',
        placeholder: 'name@example.com',
        readonly: true,
        validation: {
          required,
          email
        },
      },
      {
        type: 'input',
        key: 'site_url',
        label: 'Please provide your client’s site URL',
        placeholder: '',
        readonly: true,
        validation: {
          required,
          url
        },
      },
      {
        type: 'radio',
        key: 'site_type',
        label: 'What type of website is this?',
        placeholder: '',
        validation: {
          required,
          oneOf: oneOf(['local_business', 'ecommerce' ,'saas', 'other'])
        },
        options: [
          {key: 'local_business', label: 'Local business'},
          {key: 'ecommerce', label: 'eCommerce'},
          {key: 'saas', label: 'SaaS'},
          {key: 'other', label: 'Other (please specify)'},
        ],
        initial_value: 'local_business'
      },
      /* local_business fields */
      {
        type: 'input',
        key: 'local_business_desc',
        label: 'Please provide a description of the local business',
        placeholder: 'Description',
        show_if: {key: 'site_type', value: 'local_business'}
      },
      {
        type: 'radio_buttons',
        key: 'local_business_wants_a_location_based_audit',
        label: 'Would you like all your content audit to be location-based?',
        placeholder: '',
        validation: {
          oneOf: oneOf(['yes', 'no'])
        },
        options: [
          {key: 'yes', label: 'Yes'},
          {key: 'no', label: 'No'},
        ],
        initial_value: 'yes',
        show_if: {key: 'site_type', value: 'local_business'},
      },
      {
        type: 'input',
        key: 'local_business_locations_prioritized',
        label: 'If yes, please list the specific locations for your service areas, from highest to lowest priority',
        placeholder: 'List locations',
        show_if: {key: 'site_type', value: 'local_business'}
      },
      /* other fields */
      {
        type: 'input',
        key: 'other_business_description',
        label: 'Please provide a description of the business',
        placeholder: 'Description',
        show_if: {key: 'site_type', value: 'other'}
      },
      {
        type: 'input',
        key: 'other_business_type',
        label: 'Please specify the type of the website',
        placeholder: 'Description',
        show_if: {key: 'site_type', value: 'other'}
      },
    ]
  },

  {
    type: 'section',
    label: 'General Website Info',
    description: 'Tell us about your website and competitors',
    fields: [
      {
        type: 'input',
        key: 'pages_sections_to_prioritize',
        label: 'Do you have specific pages, sections, products or services that MUST be included in the content audit?',
        placeholder: 'Description',
      },
      {
        type: 'input',
        key: 'keywords_topics_to_focus_on',
        label: 'Are there any specific keywords / topics that MUST be included in the content audit?',
        placeholder: 'List keywords or topics',
        validation: {
          required,
        },
      },
      {
        type: 'input',
        key: 'new_top_level_topics_to_research',
        label: 'Do you want us to research any new top-level content? If yes, which topics should we focus on?',
        placeholder: 'List topics',
        validation: {
          required,
        },
      },
      {
        type: 'input',
        key: 'parts_not_to_focus_on',
        label: 'Are there any parts of the website you don’t want us to focus on? (certain types of pages / categories?)',
        placeholder: 'Description',
      },
      {
        type: 'input',
        key: 'godd_piece_of_content_url',
        label: 'Provide example of a good piece of info content currently on the client\'s site (URL)',
        placeholder: 'Description',
        validation: {
          url,
        },
      },
      {
        type: 'input',
        key: 'target_country',
        label: 'What is the site’s target country? (if none leave blank)',
        placeholder: 'Target country',
      },
      {
        type: 'input',
        key: 'target_cities',
        label: 'Do you need to target any specific cities / suburbs? (if so, please list)',
        placeholder: 'List cities or suburbs',
      },
      {
        type: 'input',
        key: 'topics_not_to_cover',
        label: 'Are there any topics you do not want us to cover? (if so, please list)',
        placeholder: 'Description',
      },
      {
        type: 'input',
        key: 'close_industry_competitors',
        label: 'Could you provide 2/3 close industry competitors?',
        placeholder: 'List competitors',
        validation: {
          required,
        },
      },
      {
        type: 'radio',
        key: 'approach',
        label: 'Guidance for Audit Approach',
        extra_info_html: `
        <div class="mt-2 mb-3 mainColor--text">Please select your preference for how we should conduct the analysis:</div>

        <div class="mb-1 mainColor--text font-weight-bold">Data-Driven Analysis with Priority Consideration</div>
        <div class="mb-3 mainColor--text">We will analyze data to uncover the best opportunities and incorporate your priorities. This approach balances data-driven insights with areas you have identified as essential.</div>

        <div class="mb-1 mainColor--text font-weight-bold">User-Specified Priorities Only</div>
        <div class="mb-3 mainColor--text">We will solely focus on the areas you've specified as essential, disregarding additional opportunities identified through data analysis.</div>
        `,
        placeholder: '',
        validation: {
          required,
          oneOf: oneOf(['data_driven', 'user_specified_prioritites_only'])
        },
        options: [
          {key: 'data_driven', label: 'Data-Driven Analysis with Priority Consideration'},
          {key: 'user_specified_prioritites_only', label: 'User-Specified Priorities Only'},
        ],
        initial_value: 'data_driven'
      },
      {
        type: 'radio',
        key: 'existing_new_opportunities',
        label: 'Existing vs. New Opportunities',
        extra_info_html: `
        <div class="mt-2 mb-3 mainColor--text">Our system prioritizes existing opportunities when a site has enough pages. However, if you are looking for new opportunities beyond your current scope, we can provide recommendations.</div>

        <div class="mb-1 mainColor--text font-weight-bold">Would you like us to focus on existing opportunities, or would you prefer to explore new ones too?</div>
        `,
        placeholder: '',
        validation: {
          required,
          oneOf: oneOf(['identify_best', 'focus_existing', 'mix'])
        },
        options: [
          {key: 'identify_best', label: 'Identify the best opportunities based on data analysis'},
          {key: 'focus_existing', label: 'Focus on existing opportunities only'},
          {key: 'mix', label: 'Provide a mix of existing and new opportunities'},
        ],
        initial_value: 'identify_best'
      },
    ]
  },

  {
    type: 'section',
    label: 'Publishing',
    description: 'Tell us about publishing process and CMS',
    fields: [
      {
        type: 'input',
        key: 'process_to_get_content_published',
        label: 'What is the process for you to get content published on the clients site? (e.g. Do you have access and permission to publish? Or is there a lengthy approval process?)',
        placeholder: 'Description',
        validation: {
          required,
        },
      },
      {
        type: 'input',
        key: 'need_assistance_publishing',
        label: 'Do you need assistance getting content published on the site?',
        placeholder: 'Description',
      },
      {
        type: 'input',
        key: 'cms_used',
        label: 'If so, what web builder or CMS is being used?',
        placeholder: 'Description',
      },
      {
        type: 'input',
        key: 'monthly_quotas',
        label: 'Do you have any monthly quantity quotas for how many pages / content pieces to publish per month for the client?',
        placeholder: 'Description',
      },
    ]
  }
]

const getInitialFormData = () => Object.fromEntries(
  formSchema.map((step, idx) => ([
    'step' + idx,
    Object.fromEntries(step.fields.map(field => [field.key, field?.initial_value]))
  ]))
)

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  props: ['site', 'activeAudit', 'active'],
  data () {
    return {
      currentStep: 0,
      formData: getInitialFormData(),
    }
  },
  computed: {
    formSchema: function () {
      return formSchema;
    },
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return null
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    isFormValid: function () {
      return !this.v$.formData.$invalid
    },
    progressValue: function () {
      return (
          formSchema.map((step, idx) => {
            const requiredFields = step.fields.filter(field => field?.validation?.required || field?.validation?.requiredIf)
            const maxStepPercentage = 1 / formSchema.length;

            let stepPercentage = 0

            if (!this.v$.formData['step' + idx].$invalid) {
              // step is fully valid
              stepPercentage = maxStepPercentage
            } else {
              // step is not valid, look at required fields
              stepPercentage = (
                requiredFields.filter(field => !this.v$.formData['step' + idx][field.key].$invalid).length / requiredFields.length
              ) * maxStepPercentage
            }

            // make the "next step" button count as 1/10 progress
            if (this.currentStep > idx) {
              return stepPercentage
            }
            return stepPercentage * 0.9
          }).reduce((a, b) => a + b, 0)
      ) * 100
    }
  },
  watch: {
    activeAudit: {
      handler: function(newVal, oldVal) {
        this.formData = merge(getInitialFormData(), newVal?.formData || {})
      },
      immediate: true,
    }
  },
  validations () {
    return {
      formData: Object.fromEntries(
        formSchema.map((step, idx) => ([
          'step' + idx,
          Object.fromEntries(step.fields.map(field => [field.key, field.validation || {}]))
        ]))
      )
    }
  },
  methods: {
    isStepValid(currentStep) {
      if (currentStep > 0 && !this.isStepValid(currentStep - 1)) {
        return false;
      }
      const stepValidationState = this.v$?.formData?.['step' + currentStep]
      return stepValidationState && !stepValidationState.$invalid;
    },
    submitForm() {
      this.$store.dispatch('updateSiteContentAudit', {
        auditId: this.activeAudit.id,
        body: {
          formData: this.formData,
          formSchema: JSON.stringify(this.formSchema),
        }
      })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.$emit('auditUpdated', response.data.audit)
            this.currentStep = this.formSchema.length
          }
        })
    }
  },
  mounted () {
    if (!this.formData.step0.email) {
      this.v$.formData.step0.email.$model = this?.selectedSubAccountRelation?.email || this.user.email
    }
    if (!this.formData.step0.site_url) {
      this.v$.formData.step0.site_url.$model = this.site.baseURL
    }
  },
}
</script>

<style lang="scss" scoped>

.site-content-audit-form-modal {
  .custom-modal-close-btn {
    top: 25px;
    right: 15px;
  }
  
  .modal-content {
    flex: 1;
    width: 100%;
    max-width: initial;
    max-height: initial;
    border-radius: initial;

    .modal-container {
      height: 100%;
      border-radius: 0;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;

      .form-sidebar {
        padding: 40px 30px;
        display: flex;
        flex-direction: column;
        width: 400px;

        .steps-status {
          display: flex;
          flex-direction: column;
          flex: 1;

          .step-item {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            &, * {
              cursor: default;
            }

            .step-right {
              padding-bottom: 30px;
              .step-label {
                font-weight: bold;
                color: var(--v-mainColor-base);
                font-size: 1rem;
              }
              .step-description {
                color: var(--v-mainColor-base);
              }
            }

            .step-left {
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 100%;

              .step-dot {
                box-sizing: border-box;
                border-radius: 100px;
                border: 1px solid #CCCCCC;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                line-height: 20px;
                
                .material-icons {
                  color: #CCCCCC;
                  font-size: 0.9rem;
                  font-weight: bold;
                }
              }

              .step-seperator {
                display:inline-block;
                width: 2px;
                flex: 1;
                background: #9D9D9D;
              }

            }

            &.active {
              .step-dot {
                border: 2px solid #FF5C00;
                .material-icons {
                  color: #FF5C00;
                }
              }
            }

            &.completed {
              .step-dot {
                border: none;
                background: #0CB370;
                .material-icons {
                  color: #fff;
                }
              }
              .step-seperator {
                background: #0CB370;
              }
            }
          }
        }

      }

      .form-main {
        background-color: #F9F9F9;
        display: flex;
        flex-direction: column;
        flex: 1;
        border-left: 1px solid #DDDDDD;
        position: relative;

        .edit-bar {
          background-color: var(--v-cardBg-base);
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 10px 40px;
          border-bottom: 1px solid #DDDDDD;

          .edit-bar-text {
            color: var(--v-mainColor-base);
          }
        }

        .progress-bar {

          width: 100%;

          background: var(--v-cardBg-base);
          border-bottom: 1px solid #DDDDDD;

          .progress-bar-percentage {
            height: 8px;
            background-color: #FF5C00;
          }
        }

        .step-controls {
          position: absolute;
          z-index: 2;
          bottom: 112px;
          right: 26px;
          display: flex;
          flex-direction: row;
          gap: 2px;

          .v-btn {
            background-color: #FF5C00;
            color: #fff;
            padding-left: 0;
            padding-right: 0;
            border-radius: 2px;
            min-width: 40px;

            &.v-btn--disabled {
              background-color: #D8D8D8 !important;
              .v-icon {
                color: #fff !important;
              }
            }
          }
        }

        .form-submit {
          position: absolute;
          z-index: 2;
          bottom: 30px;
          left: 40px;
        }

        .form-main-content {
          padding: 60px 40px;
          flex: 1;
          overflow-y: auto;

          .success-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
  
            .success-icon {
              margin-top: -100px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px;
              border-radius: 100px;
              background-color: #C7FCDF;
              color: #0CB370;
  
              .material-icons {
                font-size: 1.7rem;
                font-weight: bold;
              }
            }
          }
        }

      }
    }
  }
}

</style>

<style lang="scss">
.site-content-audit-form-modal {
  .form-main {
    .form-main-content {
      .v-btn {
        letter-spacing: initial !important;
      }
      
      .form-field {
        margin-bottom: 32px;

        label {
          font-size: 1rem !important;
        }

        .extra-info {
          * {
            font-size: 0.9rem;
          }
        }

        .v-input--selection-controls {
          margin-top: 0;
        }

        .v-text-field {
          .v-input__slot {
            border: 0.84px solid #DDDDDD;
            box-shadow: 0px 2px 4px 0px #0000001A !important;
          }

          input {
            font-size: 0.9rem !important;
          }

          &.v-input--is-readonly {
            * {
              cursor: not-allowed;
            }
          }
        }

        .v-radio {
          .v-label {
            color: var(--v-mainColor-base)
          }
        }

        // custom field
        .radio-buttons {
          .v-input__slot {
            display: flex;
            flex-direction: row;
            gap: 8px;
          }

          .v-btn {
            text-transform: initial;
            border-color: #DDDDDD;

            &.active {
              background-color: #FFF4EE;
              border-color: #FF5C00;
              font-weight: 700;
            }
          }
        }
      }
    }

  }
}
</style>