<template>
  <div>
    <v-card
      class="settings-area white-common-box mt-10"
      :class="{
        'step-scroll-target': calculated_subHeadingsAIResultArr?.length && currentStep < STEPS.PARAGRAPH
      }"
      v-if="
        !isDemo ||
        subHeadProgress >= 100 ||
        (currentStep == STEPS.PARAGRAPH &&
          mainContentProgress >= 0 &&
          mainContentProgress < 100)
      "
    >
      <div class="section-label mb-6" style="text-transform: uppercase;">Outline Builder</div>

      <div
        v-if="maxRecommendedSubheadingsCount > 0"
        class="notify-label"
      >
        <i class="material-icons">error_outline</i>
        <span>
          <!-- <div>Subheading Limit Notice</div> -->
          For the selected word count, the maximum suggested number of
          subheadings is {{ maxRecommendedSubheadingsCount }}. Adding
          more may impact the quality and coherence of the
          AI-generated content. Please consider this before
          proceeding.
        </span>
      </div>

      <div
        :class="{
          'read-only':
            currentStep == STEPS.PARAGRAPH || pAIResult.length > 0,
        }"
      >
        <div class="ai-result-outline">
          <div
            v-if="calculated_titleAIResult"
            class="outline-title ai-result-card mb-3 d-flex align-center ai-outline-item"
          >
            <span class="tag-symbol-span h1-tag">{{
              calculated_titleAIResult.shortenTagName
            }}</span>
            <b-tooltip
              class="mr-2"
              label="Title for search engines only"
              type="is-dark"
            >
              <img src="/static/search_engine_icon.png" alt="For Search Engines Only" style="width: 1rem; height: 1rem;" />
            </b-tooltip>
            <span class="text-part">
              {{ calculated_titleAIResult.text }}
            </span>
          </div>
          <div
            v-if="calculated_pageTitleAIResult"
            class="outline-title ai-result-card mb-3 ai-outline-item"
          >
            <span class="tag-symbol-span h1-tag">{{
              calculated_pageTitleAIResult.tagName
            }}</span>
            <span class="text-part">{{
              calculated_pageTitleAIResult.text
            }}</span>
          </div>

          <ul
            ref="sortableList"
            id="sortableList"
            class="d-flex flex-column flex-gap-10"
            v-if="showList"
          >
            <li
              v-for="(item, idx) in calculated_subHeadingsAIResultArr"
              :key="
                'ai-outline-' +
                (item.originIndex ? item.originIndex : idx)
              "
              @click="
                (e) => setActiveEditItem(e, 'ai-outline-' + idx)
              "
              :id="
                'ai-outline-' +
                (item.originIndex ? item.originIndex : idx)
              "
              :data-id="item.originIndex"
              class="d-flex"
            >
              <div
                style="min-height: 47px"
                :class="{
                  'outline-row-subheading ai-result-card ai-outline-item flex-grow-1': true,
                  editing: isOutlineItemInEditingMode(
                    'subHeadings',
                    item
                  ),
                  'h2-indent': item.tagName == 'H2',
                  'h3-indent': item.tagName == 'H3',
                }"
                @mouseover="mouseenter(idx)"
                @mouseleave="mouseleave()"
              >
                <div class="d-flex align-center">
                  <span
                    class="material-icons-outlined drag-indicator"
                    v-if="hoveredItem === idx"
                  >
                    drag_indicator
                  </span>
                </div>

                <span
                  :class="{
                    'tag-symbol-span': true,
                    'h2-tag': item.tagName == 'H2',
                    'h3-tag': item.tagName == 'H3',
                  }"
                  >{{ item.tagName }}</span
                >
                <span class="text-part">
                  <div
                    v-if="
                      isOutlineItemInEditingMode(
                        'subHeadings',
                        item
                      ) || hoveredItem === idx
                    "
                    class="d-flex align-center justify-space-between flex-gap-5"
                  >
                    <input
                      id="input"
                      type="text"
                      class="flex-grow-1 edit-ai-result-input"
                      :disabled="isDemo"
                      :value="calculated_subHeadingsAIResultArr[idx].text"
                      @input="$emit('update:calculated_subHeadingsAIResultArr', {
                        ...calculated_subHeadingsAIResultArr,
                        [idx]: {
                          ...calculated_subHeadingsAIResultArr[idx],
                          text: $event.target.value,
                        }
                      })"
                      v-if="
                        isOutlineItemInEditingMode(
                          'subHeadings',
                          item
                        )
                      "
                    />
                    <span
                      v-else
                      @click="editSubheading(e, item)"
                      class="ai-subheading"
                      >{{
                        calculated_subHeadingsAIResultArr[idx].text
                      }}</span
                    >
                    <div class="d-flex flex-gap-5">
                      <select
                        v-if="
                          isOutlineItemInEditingMode(
                            'subHeadings',
                            item
                          )
                        "
                        class="change-tag-select action"
                        :class="{
                          'h2-selected': item.tagName == 'H2',
                          'h3-selected': item.tagName == 'H3',
                        }"
                        @change="
                          (e) => {
                            item.tagName = e.target.value;
                          }
                        "
                      >
                        <option
                          value="H2"
                          :selected="item.tagName == 'H2'"
                        >
                          H2
                        </option>
                        <option
                          value="H3"
                          :selected="item.tagName == 'H3'"
                        >
                          H3
                        </option>
                      </select>
                      <button
                        class="action-btn"
                        v-clipboard:copy="item.text"
                        v-clipboard:success="
                          () =>
                            $notify({
                              group: 'info',
                              type: 'success',
                              text: 'Copied to clipboard',
                            })
                        "
                        v-clipboard:error="
                          () =>
                            $notify({
                              group: 'info',
                              type: 'error',
                              text: 'Cannot copy to clipboard',
                            })
                        "
                      >
                        <span class="material-icons-outlined action"
                          >file_copy</span
                        >
                      </button>
                      <!-- <button class="action-btn">
                      <span class="material-icons-outlined">autorenew</span>
                    </button> -->
                      <button
                        class="action-btn"
                        @click="
                          () => {
                            $emit('update:editInOutlineInfo', item)
                            showDeleteConfirmModalAction(
                              'Do you want to delete this subheadings',
                              deleteSubHeadingSelected
                            );
                          }
                        "
                      >
                        <span class="material-icons-outlined action"
                          >delete</span
                        >
                      </button>
                      <!-- <button
                        v-if="
                          isOutlineItemInEditingMode(
                            'subHeadings',
                            item
                          )
                        "
                        class="action-btn"
                        @click="
                          () =>
                            cancelEditItemInOutlineFor('subHeadings')
                        "
                      >
                        <span class="material-icons-outlined"
                          >done</span
                        >
                      </button> -->
                    </div>
                  </div>
                  <div v-else @click="editSubheading(e, item)">
                    {{ item.text }}
                  </div>
                </span>
                <!-- <span class="actions">
              </span> -->
              </div>
            </li>
          </ul>

        </div>

        <div class="d-flex align-center gap10">

          <b-tooltip
            v-if="
              currentStep == STEPS.SUBHEADINGS
              && calculated_subHeadingsAIResultArr.length > 0
            "
            label="New subheading"
            type="is-dark"
          >
            <button
              class="action-btn action-btn-lg"
              :disabled="isDemo"
              @click="
                () => {
                  $emit('update:showAddSubheadingsModal', true)
                  $emit('update:newSubheadingText', '')
                }
              "
            >
              <span class="material-icons-outlined action"
                >add</span
              >
            </button>
          </b-tooltip>

          <template
            v-if="
              currentStep == STEPS.SUBHEADINGS &&
              calculated_subHeadingsAIResultArr.length > 0 &&
              (isDemo || pAIResult.length == 0)
            "
          >
            <b-tooltip
              label="Regenerate all sub-headings"
              type="is-dark"
            >
              <button
                class="action-btn action-btn-lg"
                :disabled="
                  isDemo ||
                  isLoading ||
                  (!useMySubheadings &&
                    !optimizeCurrentH2s &&
                    !optimizeCompetitorsH2s)
                "
                @click="
                  () => {
                    showWarningModalForGenerate('subHeadings', () =>
                      generateResultFor('subHeadings')
                    );
                  }
                "
              >
                <span class="material-icons-outlined action"
                  >replay</span
                >
              </button>
            </b-tooltip>
  
            <b-tooltip
              label="Show history"
              type="is-dark"
            >
              <button
                class="action-btn action-btn-lg"
                :disabled="isDemo"
                @click="$emit('update:showSubheadingsHistoryModal', true)"
              >
                <span class="material-icons-outlined action"
                  >schedule</span
                >
              </button>
            </b-tooltip>
  
            <b-tooltip
              label="Download outline"
              type="is-dark"
            >
              <button
                class="action-btn action-btn-lg"
                :disabled="isDemo"
              @click="() => downloadOutlines()"
              >
                <span class="material-icons-outlined action"
                  >file_download</span
                >
              </button>
            </b-tooltip>
          </template>
        </div>
      </div>
      <progress-bar
        v-if="isLoading"
        class="mt-10"
        :isDemo="isDemo"  
        :isLoading="isLoading"  
        :runningSection="runningSection"
        :progress="progress"
        :titleProgress="titleProgress"
        :subHeadProgress="subHeadProgress"
        :mainContentProgress="mainContentProgress"
      />
    </v-card>
  </div>
</template>

<script>
import { Sortable, MultiDrag } from "sortablejs";
Sortable.mount(new MultiDrag());

import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";
import ProgressBar from '@/components/FullPageSourceEditorV2/components/AiWriter/components/ProgressBar.vue';

export default {
    name: 'Result6Subheadings',
    components: {
      ProgressBar,
    },  
    props: [
        'isDemo',
        'isLoading',
        'currentStep',

        'showWarningModalForGenerate',
        'generateResultFor',
        'calculated_titleAIResult',
        'calculated_pageTitleAIResult',
        'cancelEditItemInOutlineFor',
        'editInOutlineInfo',
        'pAIResult',
        'calculated_subHeadingsAIResultArr',
        'maxRecommendedSubheadingsCount',
        'setActiveEditItem',
        'isOutlineItemInEditingMode',
        'mouseenter',
        'mouseleave',
        'hoveredItem',
        'editSubheading',
        'showDeleteConfirmModalAction',
        'deleteSubHeadingSelected',
        'showAddSubheadingsModal',
        'newSubheadingText',
        'useMySubheadings',
        'optimizeCurrentH2s',
        'optimizeCompetitorsH2s',
        'showSubheadingsHistoryModal',
        'downloadOutlines',
        'saveEditedSubheadings',

        'runningSection',
        'progress',
        'titleProgress',
        'subHeadProgress',
        'mainContentProgress',
    ],
    data() {
      return {
        sortableActive: false,
        showList: true,
      }
    },
    computed: {
        STEPS() {
            return STEPS
        },
    },
    watch: {
      calculated_subHeadingsAIResultArr: function (newVal, oldVal) {
        if (newVal) {
          this.initSortableList();
        }
      },
    },
    methods: {
      initSortableList() {
        // if (!this.sortableActive)
        this.sortableActive = new Sortable(this.$refs.sortableList, {
          multiDrag: true,
          handle: ".drag-indicator",
          selectedClass: "selected", // CSS class for selected item
          animation: 150, // Animation duration in milliseconds
          // draggable: ".outline-row-subheading",
          // dataIdAttr: "data-id",

          onStart: () => {
            this.drag = true;
          },
          onEnd: async (evt) => {
            this.showList = false;
            this.sortableActive = null;

            const draggedItemsIDs = evt.items?.length
              ? evt.items.map((item) => item.id.split("-")[2])
              : [evt.item.id.split("-")[2]];
              
            const draggedItems = this.calculated_subHeadingsAIResultArr.filter(
              (item) => draggedItemsIDs.includes(item.originIndex.toString())
            );
            const tempData = this.calculated_subHeadingsAIResultArr.filter(
              (item) => !draggedItemsIDs.includes(item.originIndex.toString())
            );

            const finalData = [];
            if (evt.newIndex > tempData.length) {
              finalData.push(...tempData);
              finalData.push(...draggedItems);
            } else {
              tempData.forEach((item, i) => {
                if (i === evt.newIndex) {
                  finalData.push(...draggedItems);
                }
                finalData.push(item);
              });
            }
            
            this.$emit('update:calculated_subHeadingsAIResultArr', finalData.map((el, id) => ({
              ...el,
              originIndex: id,
            })))
 
            this.drag = false;
            await this.dragEndedForSubheadings();

            // this.loadAiRun(true);

            this.showList = true;
          },
        });
      },
      dragEndedForSubheadings() {
        this.saveEditedSubheadings();
      },
    },
    updated() {
      if (this.$refs.sortableList) this.initSortableList();
    },
    mounted() {
      if (this.$refs.sortableList) this.initSortableList();
    }
}
</script>

<style lang="scss" scoped>

</style>
