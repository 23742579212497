<template>
    <div class="d-flex gap20">

      <div style="flex: 2;">
        <div class="step-title-label">
          Connect your API key
        </div>
        <p class="mt-3">You can connect your API key and run your POP AI Writer reports at only {{popCreditCharges.aiWriterCreditsFlatRateOwnApiKey}} POP Credit flat rate.</p>
      </div>

      <div style="flex: 5;">
        <div>
          <div class="flex-grow-1 flex flex-column">

            <div class="d-flex align-center">
              <v-text-field
                class="gpt-key-text-field"
                dense hide-details
                placeholder="Input your GPT key"
                color="greenColor"
                v-model="currentAPIKey"
                outlined
              ></v-text-field>
              <v-btn
                color="profileAnchorColor"
                class="gpt-key-save-btn text-none whiteColor--text"
                :disabled="!isSomethingChanged"
                @click="saveOpenAiKey">
                <span>{{ user.openAIKey ? 'Update' : 'Connect' }}</span>
              </v-btn>
            </div>

            <div class="mt-2">
              Need help? Follow our&nbsp;
              <a href="https://help.pageoptimizer.pro/article/how-to-use-gpt-4-api-in-pop" target="_blank" style="text-decoration: underline; color: var(--v-mainColor3-base);">
                How to use GPT-4 API in POP Guide.
              </a>
            </div>

            <div class="mt-5 d-flex align-center" v-if="user.openAIKey">
              <vue-toggles
                :value="useMyOwnAPI"
                width="40" height="20"
                :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                :uncheckedBg="$vuetify.theme.currentTheme['gray35Color']"
                @click="useMyOwnAPI = !useMyOwnAPI"
                :disabled="$store.getters.isDemoUser"
                >
              </vue-toggles>
              <div class="ml-2 mainColor--text">
                {{ useMyOwnAPI ? 'Enabled': 'Disabled' }}
              </div>
            </div>
            <div class="mt-5">

              <v-btn
                v-if="user.openAIKey"
                color="redColor"
                class="text-none whiteColor--text"
                :disabled="$store.getters.isDemoUser"
                @click="deleteOpenAiKey">
                <span>Delete Key</span>
              </v-btn>
            </div>
          </div>


          <div class="modal" :class="{'is-active': showApiKeyErrorModal}">
            <div class="modal-background" @click="showApiKeyErrorModal = false"></div>
            <div class="modal-content">
              <div class="box modal-container">
                <div class="modal-content-root">
                  <div class="subtitle-font mainColor--text text-center">
                    Sorry, it looks like the API key you are trying to add doesn't have GPT 4 access yet.  This is a common issue.  You'll need to go back into your OpenAI and select GPT 4 specifically.
                    <div class="mt-5 subtitle mainColor--text font-weight-bold text-center">{{ errorMsg }}</div>
                  </div>
                  <div class="d-flex justify-end">
                    <v-btn rounded
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none"
                      @click="showApiKeyErrorModal = false">
                      Ok
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
</template>

<script>
export default {
  name: 'ConfigApiKey',
  props: [
      'isDemo',
      'user',

      'popCreditCharges',
      'isUsingOwnApiKey',
  ],
  data () {
    return {
      currentAPIKey: '',
      useMyOwnAPI: false,

      showApiKeyErrorModal: false,
      errorMsg: '',
    }
  },
  computed:  {
    isExistingApiKeyChanged () {
      if (!this.user || !this.user.openAIKey) {
        return false;
      }
      if (this.user.openAIKey != this.currentAPIKey) {
        return true;
      }
      return false;
    },
    isSomethingChanged () {
      if (!this.user.openAIKey && this.currentAPIKey) {
        return true;
      }
      return this.isExistingApiKeyChanged || this.useMyOwnAPI != this.user.useMyOwnApiKey
    }
  },
  methods: {
    updateDefaultValues(user) {
      this.currentAPIKey = user.openAIKey || ''
      this.useMyOwnAPI = user.useMyOwnApiKey
    },
    loadUser () {
      return this.$store.dispatch('loadUser')
      .then((response) => {
        if (response.data.user) {
          this.updateDefaultValues(response.data.user)
          this.$store.commit('setUser', response.data.user)
        }
      })
    },
    saveOpenAiKey () {
      this.$store.commit('showLoading')
      this.$store.dispatch('addOpenAiKey', {
        apiKey: this.currentAPIKey.trim(),
        useMyOwnAPI: this.useMyOwnAPI,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$notify({
            group: "info",
            type: "success",
            text: response.data.msg,
          });
          this.loadUser();
        } else if (response.data.status == 'FAILURE') {
          this.errorMsg = response.data.error
          this.showApiKeyErrorModal = true
          this.loadUser();
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    deleteOpenAiKey () {
      this.$store.commit('showLoading')
      this.$store.dispatch('addOpenAiKey', {
        deleteKey: true,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.$notify({
            group: "info",
            type: "success",
            text: response.data.msg,
          });
          this.loadUser();
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
  },
  mounted () {
    if (this.user) {
      this.updateDefaultValues(this.user);
    }
  }
}
</script>

<style lang="scss" scoped>
.gpt-key-text-field {
  margin-top: -4px !important;

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ::v-deep {
    fieldset {
      border-width: 1px !important;
      border-right: none;
      border-color: var(--v-gray12Color-base);
    }
    input {
      color: var(--v-mainColor3-base);
    }
  }
}

.gpt-key-save-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>