<template>
    <div class="three-rows-grid">
        <div class="wow_disaled slideInLeft">
            <i class="material-icons back-button darkGrayColor--text" aria-hidden="true" @click="$router.go(-1)">keyboard_backspace</i>
        </div>          
        <div>
            <div class="full-width-container">
                <div class="level1-size title-font wow_disaled slideInLeft" data-wow-delay="0s">
                What main word or phrase do you want optimized?
                </div>

                <div class="level2-size mt-5 wow_disaled slideInLeft" data-wow-delay="0.3s">
                    <span>Add main keyword or phrase</span>
                    <span class="is-pulled-right">At least 1 word required</span>
                </div>
                <div class="field mt-2" data-wow-delay="0.6s">
                    <div class="control is-expanded">
                        <!-- <input type="text" class="input site-url-input" id="keyword" ref="keyword" :value="kw" @input="handleInput" > -->
                        <v-text-field dense outlined
                            type="text" label="Keyword"
                            color="greenColor" hide-details="auto"
                            :value="kw" @input="handleInput"
                            @keyup.enter="handleGoNextPage"
                        ></v-text-field>
                    </div>
                </div>

                <div class="text-right">
                    <a class="profileAnchorColor--text font-weight-bold" @click="() => { $emit('toggleUseSecondaryKeywords') }">{{ useSecondaryKeywords ? '- Remove Secondary Keywords' : '+  Add Secondary Keywords' }}</a>
                </div>
                <div v-if="useSecondaryKeywords">
                    <div class="mb-2">Add secondary keywords</div>
                    <div class="columns">
                        <div class="column">
                            <!-- <input type="text" class="input" v-model="secondaryKeywords[0]" placeholder="Secondary Keyword 1" /> -->
                            <v-text-field dense outlined
                                type="text" label="Secondary Keyword 1"
                                color="greenColor" hide-details="auto"
                                v-model="secondaryKeywords[0]"
                            ></v-text-field>
                        </div>
                        <div class="column">
                            <!-- <input type="text" class="input" v-model="secondaryKeywords[1]" placeholder="Secondary Keyword 2" /> -->
                            <v-text-field dense outlined
                                type="text" label="Secondary Keyword 2"
                                color="greenColor" hide-details="auto"
                                v-model="secondaryKeywords[1]"
                            ></v-text-field>
                        </div>
                        <div class="column">
                            <!-- <input type="text" class="input" v-model="secondaryKeywords[2]" placeholder="Secondary Keyword 3" /> -->
                            <v-text-field dense outlined
                                type="text" label="Secondary Keyword 3"
                                color="greenColor" hide-details="auto"
                                v-model="secondaryKeywords[2]"
                            ></v-text-field>
                        </div>
                    </div>
                </div>

                <div class="notify-label mt-5">
                    <i class="material-icons">error_outline</i>
                    <span>It helps to be reasonably specific while determining your keyword. Some good examples: “best baby strollers for toddlers”, “plumbers dallas”. If you are optimizing for local, it's often best to add your city to your keyword phrase.</span>
                </div>

                <div class="mt-5">
                    <div class="full-width-container">
                        <div class="level1-size title-font mainColor--text mb-3 wow_disaled slideInLeft" data-wow-delay="0s">
                            Which page do you want to optimise?
                        </div>

                        <div class="mb-5 wow_disaled slideInLeft" data-wow-delay="0.3s">
                            <div class="level2-size mainColor--text mb15">
                                Copy & paste the full URL of the specific page
                                <span class="is-pulled-right">Required</span>
                            </div>
                            <div class="d-flex align-center">
                                <div style="min-width: unset;" class="mr-3">
                                    {{ cleanBaseUrl }}/
                                </div>
                                <div class="flex-grow-1">
                                    <v-text-field outlined dense
                                        color="greenColor"
                                        type="text" hide-details="auto"
                                        :value="targetUrl" @input="handleChangeTargetUrl" label="Page path (example: /products or leave empty for home page)" :disabled="disablePathInput"
                                    ></v-text-field>
                                </div>
                            </div>
                            <div class="mt-5">
                                <label class="checkbox">
                                    <input type="checkbox" v-model="switchProtocol" class="mainColor--text" @change="handleSwitchProtocol"/> Switch your target URLs from http to https or vice versa
                                    <v-tooltip bottom color="transparent">
                                        <template v-slot:activator="{ on, attrs }">
                                            <i class="material-icons info-icon-size ml-2 mainColor--text" v-bind="attrs" v-on="on" style="font-size: 14px;">info</i>
                                        </template>
                                        <div class="white-bg-tooltip small-tooltip">
                                            You would check this box if you have moved your site from http to https or vice versa after originally creating this project.
                                        </div>
                                    </v-tooltip>
                                </label>
                            </div>
                        </div>

                        <div class="wow_disaled slideInLeft" data-wow-delay="0.6s">
                            <div class="level2-size mainColor--text mb-5">
                                <b>OR select an option below</b>
                            </div>

                            <!-- <label class="checkbox">
                                <input type="checkbox" v-model="thisUseKeywordPrecisionSearch" class="mainColor--text" @change="handleUseKeywordPrecisionSearchChange" /> Keyword precision mode
                            </label> -->
                            <br />
                            <label class="checkbox">
                                <input type="checkbox" v-model="thisPageNotBuilt" class="mainColor--text" @change="handlePageNotBuiltChange" /> I haven't built this page yet
                            </label>
                            <br />
                            <template v-if="!thisPageNotBuilt">
                                <div v-if="!isExpressReport" class="mt-3">
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="targetSourcedDirectly" class="mainColor--text" @change="handleTargetSourcedDirectlyChange"/> I'd like to copy and paste in html or source code
                                    </label>
                                </div>
                                <textarea 
                                    v-if="targetSourcedDirectly"
                                    v-model="targetSourceCode"
                                    @input="handleTargetSourceCodeChange"
                                    class="textarea manual-copy-textarea"
                                    rows="10" 
                                    :placeholder="
`1. Go to: view-source:https://www.mydomain.com/my-target-page
2. Copy everything that appears on that page
3. Paste what you copied into this box`">
                                </textarea>
                            </template>
                        </div>
                        
                        <div v-if="!isRerunPage"
                            class="mt-5 wow_disaled slideInLeft" data-wow-delay="0.4s">
                            <div class="level2-size mb-3">
                            <b>Give the page a name</b>
                            <span class="is-pulled-right">Required</span>
                            </div>
                            <!-- <input type="text" class="input" :value="pageName" @input="handlePageNameChange" placeholder="Page name" /> -->
                            <v-text-field dense outlined type="text" color="greenColor"
                                :value="pageName" @input="handlePageNameChange" label="Page name"></v-text-field>
                        </div>
                    </div>
                </div>

                <!-- <div class="q-a-explain-block mt-5">
                    <img src="/static/q-a-icon.png" alt="Q/A" />
                    <div>
                        <div class="section-title">What word or phrase will people use to search in Google?</div>
                        <div class="mt-2">It helps to be <b>reasonably specific</b> - some good examples:</div>
                        <ul class="my-3">
                            <li>best baby strollers for toddlers</li>
                            <li>plumbers dallas</li>
                        </ul>
                        <div class="mt-3">If you are optimizing for local, <b>it's often best to add your city to your keyword phrase</b>. You can <b>run more keywords and pages</b> once you are set up.</div>
                    </div>
                </div> -->
            </div>
        </div>
        <div>
            <div class='has-text-centered font-weight-bold profileAnchorColor--text' v-if="errorMsg">{{ errorMsg }}</div>
            <div class="is-bottom-right">
                <v-btn rounded
                    color="profileAnchorColor"
                    class="button-with-icon whiteColor--text text-none" :disabled="!advanceable" @click="handleGoNextPage">
                    <span>Next</span>
                    <i class="material-icons">arrow_forward</i>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'value', 'useSecondaryKeywords', 'secondaryKeywordsArray', 'advanceable',
        'url', 'switchedSiteProtocol', 'pageNotBuilt', 'useKeywordPrecisionSearch', 'name',
        'submittingRun', 'isTargetUrlSourceDirectly', 'targetUrlSourceCode', 'cleanBaseUrl', 'disablePathInput', 'isExpressReport',
        'isRerunPage',
        'errorMsg',
    ],
    data () {
        return {
            kw: this.value || '',
            secondaryKeywords: this.secondaryKeywordsArray || [],

            targetUrl: this.url,
            thisPageNotBuilt: this.pageNotBuilt,
            thisUseKeywordPrecisionSearch: this.useKeywordPrecisionSearch,
            switchProtocol: this.switchedSiteProtocol,
            pageName: this.name,
            targetSourcedDirectly: this.isTargetUrlSourceDirectly,
            targetSourceCode: this.targetUrlSourceCode
        }
    },
    watch: {
        'value': function(kw) {
            this.kw = kw
        },
        'secondaryKeywordsArray': function(data) {
            this.secondaryKeywords = data
        },
        'secondaryKeywords': function (data) {
            console.log('test test')
            // this.$emit('secondarykeywordschange', [...data])
        },
        'url': function(newUrl) {
            this.targetUrl = newUrl
        },
        'name': function(n) {
            this.pageName = n
        }
    },
    methods: {
        handleInput (value) {
            this.kw = value.replace(/\u200b/g, '');
            this.$emit('input', value)
        },
        handleGoNextPage () {
            if (this.secondaryKeywords.includes(this.kw)) {
                window.alert('You can not use target keyword as secondary keyword')
                return;
            }
            this.$emit('nextpage')
        },
        handleChangeTargetUrl (value) {
            this.$emit('urlchange', value)
        },
        handlePageNotBuiltChange (e) {
            this.$emit('pagenotbuiltchange', this.thisPageNotBuilt)
        },
        handleUseKeywordPrecisionSearchChange (e) {
            this.$emit('usekeywordprecisionsearchchange', this.thisUseKeywordPrecisionSearch)
        },
        handleSwitchProtocol (e) {
            this.$emit('protocolchange', this.switchProtocol)
        },
        handlePageNameChange (value) {
            this.$emit('pagenamechange', value)
        },
        handleTargetSourcedDirectlyChange (e) {
            this.$emit('targetsourcedchange', this.targetSourcedDirectly)
        },
        handleTargetSourceCodeChange (e) {
            this.$emit('targetsourcecodechange', e.target.value)
        }

    },
    mounted () {
        setTimeout(() => {
            if (this.$refs.keyword) { this.$refs.keyword.focus() }
        }, 100);
    }
}
</script>

<style lang="scss" scoped>
div {
    color: var(--v-mainColor-base);
}
</style>