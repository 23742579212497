<template>
    <div class="d-flex gap20">

      <div style="flex:1;">
        <div class="font-weight-bold">
          Exclude words
          <v-tooltip top color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <span
                class="material-icons info-icon-size mainColor3--text"
                v-bind="attrs"
                v-on="on"
                >info</span
              >
            </template>
            <div class="white-bg-tooltip small-tooltip">
              By specifying words to exclude, you can ensure that the AI
              will not use those words in the generated content. This is
              useful for avoiding specific terms or phrases that may not
              fit your brand or content strategy. For example, you might
              want to exclude words like "cheap," "best," "guaranteed,"
              "conclusion," "embark," or "final thoughts." Make sure the
              words to exclude are not part of POP's recommendations from
              the report.
            </div>
          </v-tooltip>
        </div>
        <div class="mt-2">Exclude specific words or phrases you don't want to appear in your web page.</div>
      </div>

      <div style="flex:2;">
        <div :class="{ 'demo-highlight-f': isDemo }">
          <multiselect
            :value="excludeTerms"
            :disabled="isDemo"
            :options="[]"
            :multiple="true"
            :taggable="true"
            :hide-selected="true"
            placeholder="cheap, best, guaranteed, conclusion, embark, final thoughts"
            tag-placeholder="Press enter to add"
            @tag="addNewExcludeTerm"
            @remove="handleRemoveExcludeTerm"
          >
          </multiselect>
        </div>
      </div>

    </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: 'ConfigExcludeTerms',
    components: {
      Multiselect
    },
    props: [
        'isDemo',

        'addNewExcludeTerm',
        'handleRemoveExcludeTerm',
        'excludeTerms',
    ],
}
</script>

<style lang="scss" scoped>

</style>