<template>
  <div class="ai-writer-info-footer">
    <div class="d-flex"> 
      <div class="flex-grow-1 d-flex align-center">
        <v-btn
          v-if="currentStep >= STEPS.SEARCH_ENGINE_TITLE && isSpecialAdminUser"
          rounded
          small
          color="purpleColor1"
          class="text-none whiteColor--text"
          @click="
            () => {
              $emit('update:showLogModal', true)
            }
          "
        >
          <span>Show log</span>
        </v-btn>
      </div>
      <div class="d-flex align-center" style="gap: 40px;">
        <b-dropdown class="credits-breakdown-dropdown" aria-role="list" position="is-top-left" :triggers="['hover']">
            <template #trigger>
              <div class="credits-overview">
                <div class="d-flex gap5">

                  <span v-if="!aiRun && estimatedCreditsRequired > ownerAvailableCredits"><i class="fa fa-exclamation-triangle" style="color: red;"></i> Not enough credits.</span>
                  <span v-else class="dollar-icon"><i class="material-icons-outlined">attach_money</i></span>

                  <span v-if="aiRun" class="font-weight-bold">Credits used: {{ totalCreditsSpent }} </span>
                  <span v-else class="font-weight-bold">Credits required: {{ estimatedCreditsRequired }} </span>

                </div>
                <span>Balance: {{ ownerAvailableCredits }} credits</span>
              </div>
            </template>

            <b-dropdown-item custom style="width: 350px;">
              <credits-breakdown-used
                v-if="aiRun"
                :articleCredits="articleCredits"
                :richContentCreditsForArticle="richContentCreditsForArticle"
                :titleRegenerateCount="titleRegenerateCount"
                :pageTitleRegenerateCount="pageTitleRegenerateCount"
                :subHeadingsRegenerateCount="subHeadingsRegenerateCount"
                :paragraphRegenerateCount="paragraphRegenerateCount"
                :contentPromptSignalRegenerateCount="contentPromptSignalRegenerateCount"
                :totalCreditsSpent="totalCreditsSpent"
              />
              <credits-breakdown-required
                v-else
                :selectedCostedRichContentItems="selectedCostedRichContentItems"
                :numberOfWords="numberOfWords"
                :estimatedArticleCreditsRequired="estimatedArticleCreditsRequired"
                :estimatedRichContentCreditsRequired="estimatedRichContentCreditsRequired"
                :estimatedCreditsRequired="estimatedCreditsRequired"
              />
            </b-dropdown-item>
        </b-dropdown>
        <div v-if="currentStep < STEPS.PARAGRAPH" class="d-flex gap10">
          <v-btn
            v-if="mode === 'guided'"
            color="profileAnchorColor"
            class="button-with-icon text-none whiteColor--text mr-3"
            :disabled="isLoading || !isStepValid(currentStep)"
            style="border-radius: 8px;"
            @click="goNext({showModalIfAny: true})"
          >
            Next
            <i class="material-icons-outlined">arrow_forward</i>
          </v-btn>
          <v-btn
            v-else-if="mode === 'auto'"
            color="profileAnchorColor"
            class="button-with-icon text-none whiteColor--text mr-3"
            :disabled="isLoading || !isStepValid(currentStep)"
            style="border-radius: 8px;"
            @click="generateResultFor('auto')"
          >
            Generate
            <i class="material-icons-outlined">arrow_forward</i>
          </v-btn>
        </div>
        <div>
          <v-menu offset-y bottom v-if="pAIResult.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                v-show="!isDemo || currentStep > 6"
                :disabled="isDemo"
                color="mainColor3"
                class="text-none"
                v-bind="attrs"
                v-on="on"
              >
                <i class="material-icons-outlined" style="font-size: 1rem;">file_download</i>
                <span>Download as</span>
                <i class="material-icons-outlined">expand_more</i>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title
                  @click="downloadAIResult('all', 'txt_plain')"
                  >plain.txt</v-list-item-title
                >
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="downloadAIResult('all', 'txt')"
                  >html.txt</v-list-item-title
                >
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="downloadAIResult('all', 'html')"
                  >.html</v-list-item-title
                >
              </v-list-item>
              <v-list-item v-if="aiRun?.aiGeneratedImages?.length" link>
                <v-list-item-title @click="downloadAIResultAsZip()"
                  >.zip (.html + images)</v-list-item-title
                >
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="downloadAIResultAsDocx()"
                  >.docx</v-list-item-title
                >
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="downloadOutlines()"
                  >Outline only .txt</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { STEPS, STPES_LABEL } from '@/components/FullPageSourceEditorV2/components/AiWriter/constants.js';
import CreditsBreakdownRequired from '@/components/FullPageSourceEditorV2/components/AiWriter/components/CreditsBreakdownRequired.vue';
import CreditsBreakdownUsed from '@/components/FullPageSourceEditorV2/components/AiWriter/components/CreditsBreakdownUsed.vue';


export default {
  name: 'AiWriterFooter',
  components: {
    CreditsBreakdownRequired,
    CreditsBreakdownUsed,
  },
  props: [
    'currentStep',
    'isStepValid',
    'goNext',
    'isLoading',
    'mode',
    'generateResultFor',

    'isSpecialAdminUser',
    'showLogModal',
    
    'selectedCostedRichContentItems',
    'numberOfWords',
    'estimatedArticleCreditsRequired',
    'estimatedRichContentCreditsRequired',
    'estimatedCreditsRequired',
    'ownerAvailableCredits',

    'isDemo',  
    'page',
    'pageRun',
    'aiRun',
    'pAIResult',
    'downloadAIResult',
    'downloadAIResultAsZip',
    'downloadAIResultAsDocx',
    'downloadOutlines',
    'goBack',

    'articleCredits',
    'richContentCreditsForArticle',
    'titleRegenerateCount',
    'pageTitleRegenerateCount',
    'subHeadingsRegenerateCount',
    'paragraphRegenerateCount',
    'contentPromptSignalRegenerateCount',
    'totalCreditsSpent',
  ],
  data () {
    return {
      STEPS: STEPS,
      STPES_LABEL: STPES_LABEL,
    }
  },
}
</script>

<style lang="scss" scoped>
.credits-overview {
  padding: 7px;
  border-radius: 5px;

  &:hover {
    background-color: var(--v-gray7Color-base);
  }
}

.dollar-icon {
  background-color: #25A97A;
  border-radius: 50px;
  display: inline-flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;

  i {
    font-size: 0.9rem;
    color: #fff;
    line-height: 16px;
    margin-left: 1px;
  }
}

.credits-breakdown-dropdown {
  ::v-deep {
    .dropdown-content {
      background: var(--v-whiteColor-base);
    }
  }
}
</style>