<template>
<div>
  <div class="card-big-title mb-4">
    Keyword Insight
    <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
      @click="() => {
        $store.commit('setCurrentVideoTutorialToDisplay', {
          title: 'How to Use the Keyword Insight Tool',
          videoLink: 'https://www.youtube.com/embed/O9gBX9m3r4I',
          description: 'In this video, you\'ll get a step-by-step walkthrough of how to use POP\'s Keyword Insight tool. This tool allows you to analyze an existing list of up to 100 keywords in one click using new POP metrics making it easy to identify those hidden gems.',
          time: '5m 45s'
        });
      }">
      videocam
    </span>
  </div>
  <div class="mainColor--text" style="max-width: 1100px; line-height: 1.4rem;">
    The Keyword Insight Tool in POP helps you analyze up to 100 keywords at once with advanced SEO metrics and AI analysis, offering insights like search volume, ranking difficulty, and keyword trends. It highlights top opportunities using the Keyword Score and SEO Competitiveness Index, simplifying content planning by organizing keywords into content silos.
  </div>
  <div class="mt-10">
    <div class="d-flex align-center gap10">
      <div class="card-small-title">
        Add keywords <span class="profileAnchorColor--text" >({{ config.keywords.length }}/100)</span>
      </div>
      <div>
        <!-- <i class="material-icons keyword-paste-icon" @click="showEasyKeywordPastePopupAction">content_paste</i>           -->
      </div>
    </div>
    <div class="mt-3 limited-width-box">
      <v-textarea outlined hide-details color="greenColor" v-model="keywordsText" />
    </div>
  </div>
  <div class="mt-10 location-lang-domain-area">
    <div>
      <div class="card-small-title mb-5">
        Select location
      </div>
      <!-- <multiselect class="mt-5 customized-multiselect1"
        v-model="selectedLocation"
        :options="locationOptions"
        placeholder="Select location"
        >
      </multiselect> -->
      <v-autocomplete outlined dense hide-details="auto"
        :items="availableLocations"
        item-text="location_name"
        item-value="location_code"
        :value="config.selectedLocation"
        @input="value => setConfig('selectedLocation', value)"
        color="grayColor">
      </v-autocomplete>
    </div>
    <div>
      <div class="card-small-title mb-5">
        Select language
      </div>
      <!-- <multiselect class="mt-5 customized-multiselect1"
        v-model="selectedLanguage"
        :options="targetLanguages"
        placeholder="Select language"
        >
      </multiselect> -->
      <v-autocomplete outlined dense hide-details
        :items="targetLanguages"
        :value="config.selectedLanguage"
        @input="value => setConfig('selectedLanguage', value)"
        item-text="language_name"
        item-value="language_code"
        color="grayColor">
      </v-autocomplete>
    </div>
    <div>
      <div class="card-small-title mb-5">
        Domain name
      </div>
      <v-text-field outlined dense
        v-model="domain"
        readonly
        color="grayColor"
      ></v-text-field>
    </div>
  </div>
  <div class="mt-5 d-flex align-center gap10">
    <v-btn rounded
      color="profileAnchorColor"
      class="text-none whiteColor--text"
      :disabled="!canProceed || (isWhiteGloveUser && !isMissionControlSession)"
      @click="() => hasUnlimitedAccess?getDataAction():(() => {showCostModal = true})()">
      <span>Get data</span>
    </v-btn>
  </div>

  <keyword-research-result
    v-if="config.researchReport && !config.isCalculating && !isAwaitingAsyncTaskStatus && !currentAsyncTask?.is_pending_or_in_progress"
    title="Keyword Insight"
    reportType="KeywordResearch"
    :showSiloExtraActions="false"
    :report="config.researchReport"
    :researchHistory="config.researchHistory"
    :hasUnlimitedAccess="hasUnlimitedAccess"

    :showModal="showModal"
    :modalTitle="modalTitle"
    :modalType="modalType"
    :reportTitle="reportTitle"

    @onChangeResearchId="(id) => setSelectedResearchReportId(id)"
    @refreshReport="() => loadResearchReport()"
    @onAIResultReady="() => loadResearchReport()"
    @onKeywordLabelUpdateSuccess="onKeywordLabelUpdateSuccess"
    @requestedAdvancedData="(keywords) => getAdvancedDataAction(keywords)"
    
    @rename="onShowModal(`Rename ${$event.name}`, 1, $event.name, $event.id)"
    @toggle-favorite="updateFavoriteStatus($event)"
    @delete="onShowModal(`Delete ${$event.name}`, 2, '', $event.id)"

    @cancel="onCancelModal"
    @close="onCloseModal"
    @update-title="updateReportTitle"
  >
  </keyword-research-result>

  <div class="modal keyword-data-cost-modal" :class="{'is-active': showCostModal}">
    <div class="modal-background" @click.stop="showCostModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text">Get keyword data</p>
          <div class="mainColor--text message-text">
            <div class="mb-2">You’ve selected selected <span class="font-weight-bold">{{ config.keywords.length }} keyword{{config.keywords.length >1 ?'s':''}}</span>.</div>
            <div class="mb-1">We’ll retrieve detailed metrics for {{config.keywords.length >1 ?'each':'the'}} keyword, including:</div>
            <ul class="mb-2" style="list-style: initial;">
              <li>Keyword Difficulty Score</li>
              <li>Search Volume</li>
              <li>Search Volume trend</li>
              <li>Ranking URL and Position</li>
            </ul>
            
            This action will consume <span class="font-weight-bold">{{ Math.ceil(config.keywords.length / 100) }} POP Credit{{Math.ceil(config.keywords.length / 100) >1 ?'s':''}}</span>. Click <span class="font-weight-bold">Get Data</span> to proceed.
          </div>
          <div class="d-flex justify-end gap10 mt-5">
            <v-btn rounded outlined color="mainColor"
              class="text-none"
              @click.stop="() => {
                showCostModal = false;
              }">
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded color="profileAnchorColor"
              class="whiteColor--text text-none"
              @click.stop="getDataAction">
              <span>Get data</span>
            </v-btn>
            
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showCostModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  <keyword-research-modal
    :showModal="showModal"
    :modalTitle="modalTitle"
    :modalType="modalType"
    :reportTitle="reportTitle"
    @cancel="onCancelModal"
    @close="onCloseModal"
    @update-title="updateReportTitle"
  />
</div>
</template>

<script>
import { getHostName, getPureServicePkgName } from '@/utils';
import languages from '@/consts/dataforseo_languages.json';
import dataForSeoLocations from '@/consts/dataforseo_locations.json';
import KeywordResearchResult from '@/components/Campaigns/KeywordResearchResult.vue';
import KeywordResearchModal from '@/common/KeywordResearchModal.vue';
import {
  whiteGlovePlans,
} from "@/components/plans_constants"


const MAX_KEYWORDS_LIMIT = 100

export default {
  props: ['campaignId', 'hasUnlimitedAccess'],
  components: {
    KeywordResearchResult,
    KeywordResearchModal,
  },
  data () {
    return {
      showEasyKeywordPastePopup: false,
      keywordsText: '',

      domain: '',

      campaign: null,  // TODO(hichem): check if this can be removed (are we relying on global state?)

      showCostModal: false,

      isAwaitingAsyncTaskStatus: false,

      showModal: false,
      reportTitle: '',
      modalTitle: '',
      modalType: 1,
      currentSelectedReportId: 0,
      selectedTab: 'clusters',
      searchQuery: '',
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    isMissionControlSession () {
      return this.$store.state.selectedSubAccountRelation?.isMissionControlSubAccount
    },
    config() {
      return this.$store.getters.insightKeywordsConfig;
    },
    availableLocations () {
      return dataForSeoLocations.locations;
    },
    targetLanguages () {
      return languages;
    },
    site () {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId)) || {}
    },
    canProceed () {
      if (this.config.isCalculating || this.isAwaitingAsyncTaskStatus || this.currentAsyncTask?.is_pending_or_in_progress) {
        return false;
      }
      return this.config.keywords.length > 0 && this.config.selectedLocation && this.config.selectedLanguage
    },
    selectedReportId () {
      return this.config.selectedResearchReportId
    },
    asyncTasks() {
      return this.$store.state.asyncTasks
    },
    currentAsyncTask() {
      return this.asyncTasks.filter(task => task.id === this.config?.researchReport?.taskId)[0]
    },
  },
  watch: {
    keywordsText: function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.keywordsText.indexOf('?') >= 0) {
          this.$nextTick(() => {
            this.keywordsText = this.keywordsText.replace('?', '')
          })
        } else {
          let keywords = this.keywordsText.split('\n').reduce((acc, curVal) => {
            if (curVal.trim()) {
              acc.push(curVal.trim())
            }
            return acc
          }, [])
  
          this.setConfig("keywords", keywords.slice(0, MAX_KEYWORDS_LIMIT))
        }
        
      }
    },
    campaignId: function (newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.getCampaignById()
        this.loadKeywordResearchHistoryForCampaign(true)
        // this.$nextTick(() => {
        // })
      }
    },
    currentAsyncTask: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.isAwaitingAsyncTaskStatus = false;
      }
      if (newVal?.is_completed && newVal?.is_completed !== oldVal?.is_completed) {
        this.loadKeywordResearchHistoryForCampaign(true)
      }
    }
  },
  methods: {
    updateReportTitle(value) {
      this.reportTitle = value;
    },
    onShowModal(title, type, currentTitle = '', reportId = 0) {
      this.reportTitle = currentTitle;
      this.showModal = true;
      this.modalTitle = title;
      this.modalType = type;
      this.currentSelectedReportId = reportId;
    },
    onCancelModal() {
      this.showModal = false;
      this.modalTitle = '';
      this.modalType = 1;
      this.reportTitle = '';
      this.currentSelectedReportId = 0;
    },
    onCloseModal() {
      if (this.modalType == 1) {
        this.updateReportItem(
          this.currentSelectedReportId,
          'name',
          this.reportTitle,
          () => {
            this.onCancelModal()
          }
        );
      } else {
        this.deleteKeywordTrackReport(this.currentSelectedReportId, () => {
          this.onCancelModal()
        });
      }
    },
    updateFavoriteStatus(item) {
      this.updateReportItem(item.id, 'isFavorite', !item.isFavorite, () => {})
    },
    updateReportItem(reportId, key, value, onComplete = () => {}) {
      this.$store.commit('showLoading')
      this.$store.dispatch('updateKeywordResearchReport', {
        data: {
          researchId: reportId,
          key,
          value
        },
        subAccountRelationId: this.subAccountRelationId
      }).then(() => {
        this.loadKeywordResearchHistoryForCampaign()
      }).catch((e) => {
        console.log('Error starring/unstarring keyword wizard report', e)
        // do nothing
      }).finally(() => {
        this.$store.commit('hideLoading')
        onComplete()
      })
    },
    deleteKeywordTrackReport(reportId, onComplete = () => {}) {
      this.$store.commit('showLoading')
      this.$store.dispatch('deleteKeywordResearchReport', {
        reportId,
        subAccountRelationId: this.subAccountRelationId
      }).then(() => {
        this.loadKeywordResearchHistoryForCampaign(true)
      }).catch((e) => {
        console.log('Error deleting keyword wizard report', e)
        // do nothing
      }).finally(() => {
        this.$store.commit('hideLoading')
        onComplete()
      })
    },
    setConfig(key, value) {
      this.$store.commit("setInsightKeywordsConfig", { key, value });
    },
    setSelectedResearchReportId(id) {
      this.setConfig("selectedResearchReportId", id)
      this.loadResearchReport()
    },
    loadResearchReport () {
      return this.$store.dispatch('loadKeywordResearchData', {
        researchId: this.config.selectedResearchReportId,
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          const researchReport = response.data.research
          this.setConfig("researchReport", researchReport);
          this.keywordsText = researchReport.keywords.join("\n")
          this.setConfig("selectedLanguage", researchReport.languageCode)
          this.setConfig("selectedLocation", parseInt(researchReport.locationCode || 0))
        }
      })
    },
    showEasyKeywordPastePopupAction () {
      this.easyKeywordsContent = this.config.keywords.join('\n');
      this.showEasyKeywordPastePopup = true;
    },
    getCampaignById () {
      this.$store.dispatch('getCampaignById', this.campaignId)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            const campaign = response.data.campaign
            this.setConfig("campaign", campaign)
            this.$store.commit('setCurrentCampaign', this.campaign)
            
            this.domain = getHostName(campaign.targetUrl)
            this.setConfig("selectedLanguage", campaign.languageCode || 'en');
            if (campaign.locationCode) {
              this.setConfig("selectedLocation", parseInt(campaign.locationCode));
            }
          }
        })
    },
    loadKeywordResearchHistoryForCampaign( updateSelectedReportId = false, scrollBottom = false ) {
      this.$store.commit('showLoading')
      // reload user to update ui with any credit usage
      this.$store.dispatch("loadUser").then(response => {
        this.$store.commit("setUser", response.data.user);
      });
      
      return this.$store.dispatch('getKeywordResearchHistoryForCampaign', {
        campaignId: this.campaignId
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          const researchHistory = response.data.researches
          this.setConfig("researchHistory", researchHistory);
          if (researchHistory.length > 0) {
            if (updateSelectedReportId) {
              this.setSelectedResearchReportId(researchHistory[0].id)
            }
          }
          if (scrollBottom) {
            setTimeout(() => {
              let divObj = document.getElementById("mainBodyScrollArea");
              divObj.scrollTo({
                top: divObj.scrollHeight,
                behavior: 'smooth'
              })
            }, 500);
          }
        }
      }) .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    getDataAction () {
      this.showCostModal = false;
      this.setConfig("isCalculating", true)
      this.setConfig("researchReport", null)

      const data = {
        campaignId: this.campaignId,
        keywords: this.config.keywords,
        domain: this.domain,
        locationCode: this.config.selectedLocation,
        languageCode: this.config.selectedLanguage,
      }
      this.$store.dispatch('createKeywordInsightResearch', data)
      .then(response => {
        if (response.data) {
          if (response.data.status == 'SUCCESS') {
            this.$store.commit('triggerAsyncTasksRefresh');
            return this.loadKeywordResearchHistoryForCampaign(true)
              .then(() => {
                this.isAwaitingAsyncTaskStatus = true;
              })
          } else if (response.data.status == 'FAILURE') {
            this.$notify({
              group: 'info',
              type: 'warning',
              text: response.data.msg || 'Please provide valid information'
            })
          }
        }
      })
      .finally(() => {
        this.setConfig("isCalculating", false);
      })
    },
    getAdvancedDataAction (keywords) {
      this.showCostModal = false;
      this.setConfig("isCalculating", true)

      const data = {
        campaignId: this.campaignId,
        reportId: this.selectedReportId,
        keywords: keywords,
        advanced_mode: true,
      }
      this.$store.dispatch('createKeywordInsightResearch', data)
      .then(response => {
        if (response.data) {
          if (response.data.status == 'SUCCESS') {
            this.$store.commit('triggerAsyncTasksRefresh');
            return this.loadResearchReport()
              .then(() => {
                this.isAwaitingAsyncTaskStatus = true;
              })
          } else if (response.data.status == 'FAILURE') {
            this.$notify({
              group: 'info',
              type: 'warning',
              text: response.data.msg || 'Please provide valid information'
            })
          }
        }
      })
      .finally(() => {
        this.setConfig("isCalculating", false);
      })
    },
    onKeywordLabelUpdateSuccess(keyword, label) {
      this.setConfig("researchReport", {
        ...this.config.researchReport,
        result: this.config.researchReport.result.map((row) => {
          if (label === 'T' && row.isTopLevelKeyword) {
            return {
              ...row,
              isTopLevelKeyword: false
            }
          }
          if (row.keyword === keyword) {
            return {
              ...row,
              isTopLevelKeyword: label === 'T',
              isSupportingKeyword: label === 'S',
            }
          }
          return row
        })
      })
    }
  },
  mounted () {
    Promise.resolve()
      .then(() => {
        if (!this.config.campaign)
          return this.getCampaignById()
        this.domain = getHostName(this.config.campaign.targetUrl)
        return Promise.resolve()
      })
      .then(() => {
        this.loadKeywordResearchHistoryForCampaign(true)
      })

    if (!this.keywordsText && this.config.keywords.length) this.keywordsText = this.config.keywords.join("\n")
  }
}
</script>

<style lang="scss" scoped>
@import "../keyword-research.scss";

.keyword-data-cost-modal {
  .message-text, .message-text * {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>