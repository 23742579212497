<template>
    <div class="d-flex gap20">

      <div style="flex:1;">
        <div class="step-title-label">Word count</div>
        <div class="mt-2">Minimum word count you can select for a generation task is 500 words.</div>
      </div>

      <div style="flex:1;">
        <v-radio-group
          :value="selectedWordCountOption"
          @change="$emit('update:selectedWordCountOption', $event)"

          :row="true"
          hide-details
        >
          <v-radio
            :disabled="isDemo || isFailedPageRun"
            class="mr-3 mt-2"
            style="width: 100%"
            v-for="(item, idx) in WORDCOUNT_OPTIONS"
            :key="idx"
            :value="item.value"
            color="profileAnchorColor"
          >
            <span slot="label" class="mainColor3--text">
              {{ item.label }}
              <template v-if="item.value == 'currentWordCount'">
                ( {{ currentWordCount }} )
              </template>
              <template v-if="item.value == 'targetWordCount'">
                ( {{ targetWordCount || 0 }} ) <span class="recommended-label">Recommended</span>
              </template>
            </span>
          </v-radio>
        </v-radio-group>
        <div
          class="d-flex mt-2"
          v-if="selectedWordCountOption == 'customWordCount'"
        >
          <div style="max-width: 220px;">
            <v-text-field
              class="number-of-words-field"
              outlined
              dense
              hide-details
              color="greenColor"
              type="number"
              :max="4000"
              :value="numberOfWords"
              @input="$emit('update:numberOfWords', Number($event))"

              label="# of words"
              :disabled="selectedWordCountOption != 'customWordCount'"
            >
            </v-text-field>
          </div>
          <div class="flex-grow-1"></div>
        </div>
      </div>

      <div class="silo-tip" style="flex:1;">
        <div class="d-flex gap5 align-center"><i class="material-icons" style="color: #316BFF;">info</i><span class="font-weight-bold">Word count for silo pages</span></div>
        <div class="mt-2" style="font-size: 0.8rem;">For top-level pages, it is best to stick to POP’s target word count recommendation, while for supporting pages word count is not strictly defined and the best practice is to have around 500-1000 words.</div>
        <div class="info-url mt-2">
          <a href="https://help.pageoptimizer.pro/article/seo-reverse-silo-content" target="_blank">
            <span>Learn more about silo pages</span>
            <i class="material-icons-outlined">open_in_new</i>
          </a>
        </div>
      </div>

    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigWordCount',
    props: [
        'validations',
        'goNext',

        'isDemo',
        'isFailedPageRun',

        'WORDCOUNT_OPTIONS',
        'currentWordCount',
        'targetWordCount',

        'selectedWordCountOption',
        'numberOfWords',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.silo-tip {
  border: 0.5px solid #316BFF;
  background: #E9F3FF;
  padding: 10px;
  border-radius: 4px;
}

.theme--dark {
  .silo-tip {
    border: 0.5px solid #52555C;
    background: #1B252F;
    color: var(--v-mainColor3-base);
  }
}

.recommended-label {
  background-color: #D6FBEE;
  color: #25A97A;
  text-transform: uppercase;
  border-radius: 3px;
  font-size: 0.7rem;
  font-weight: 700;
  padding: 2px 4px;
}

.info-url {

  display: flex;

  a {
    color: #316BFF;
    display: inline-flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;

    &:hover span {
      text-decoration: underline;
    }

    i {
      font-size: 0.8rem;
    }
  }
}

::v-deep {
  .number-of-words-field {
    fieldset {
      top: 0 !important;
      border: 2px solid #DEDEDE;
    }
    .v-input__slot {
      min-height: 38px !important;
    }
    legend {
      display: none;
    }
    label {
      position: initial !important;
      transform: initial !important;
      text-overflow: initial !important;
      max-width: initial !important;
      overflow: initial !important;
      height: 34px;
      max-height: initial;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 0.8rem;
      padding-right: 8px;
      margin-right: 8px;
      border-right: 2px solid #DEDEDE;
    }
  }
}
</style>