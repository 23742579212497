<template>
    <div>

      <v-card class="settings-area white-common-box">
        <div class="font-weight-bold mb-6 d-flex align-center" style="font-size: 1rem;">
          Select how you want to refine and optimize your current title

          <v-tooltip bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <i
                class="material-icons info-icon-size ml-2 gray9Color--text"
                v-bind="attrs"
                v-on="on"
                >info</i
              >
            </template>
            <div class="white-bg-tooltip small-tooltip">
              <template v-if="separateSearchEngineTitleAndTitle">
                If your current Page Title is already optimized, you can
                keep your title as is. You can manually write in your own
                title by clicking "Edit" or you can have AI generate your
                title by clicking "Generate." You can also edit the title
                that AI generates. Once you are happy with your title,
                click "Next" advance to the H2/H3 section.
              </template>
              <template v-else>
                We recommend using the same Search Engine Title and Page
                Title for best SEO results. If your current titles are
                already optimized, you can keep your titles as is.<br />
                You can manually write in your own titles by clicking
                "Edit" or you can have AI generate your titles by clicking
                "Generate." You can also edit the titles that AI
                generates. Once you are happy with your titles, click
                "Next" advance to the H2 and H3 section.
              </template>
            </div>
          </v-tooltip>
        </div>
        
        <div
          class="pa-5 settings-option-box"
        >
          <div class="section-label d-flex align-center gap15">
            <div
              :class="{
                'section-selector': true,
                'section-selector-active': !useMyCurrentPageTitle,
                'section-selector-disabled': isLoading || isDemo,
              }"
              @click="!(isLoading || isDemo) ? $emit('update:useMyCurrentPageTitle', !useMyCurrentPageTitle) : undefined"
            ></div>
            {{
              separateSearchEngineTitleAndTitle
                ? "I want to generate new or rewrite my page title"
                : "I want to generate new or rewrite my search engine and page titles"
            }}
          </div>

          <template v-if="!useMyCurrentPageTitle">
            <div class="ml-9">

              <div class="mainColor3--text mt-3 mb-3">
                You can select both options below at the same time.
              </div>

              <div class="d-flex gap15 align-center">
                <vue-toggles
                  :value="optimizeCurrentPageTitle"
                  width="40"
                  height="20"
                  :disabled="pageNotBuilt || isLoading || isDemo"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="
                    $emit('update:optimizeCurrentPageTitle', !optimizeCurrentPageTitle)
                  "
                ></vue-toggles>
                <div class="flex-grow-1 mainColor3--text font-weight-bold d-flex align-center" :style="pageNotBuilt?'opacity: 0.7;':''">
                  Use my title for AI inspiration
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 gray9Color--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      Select this option to let AI refine and rewrite your
                      {{
                        separateSearchEngineTitleAndTitle
                          ? ""
                          : "Search Engine Title or"
                      }}
                      &nbsp;Page Title, aligning them with the POP
                      recommendations.
                    </div>
                  </v-tooltip>
                </div>
              </div>
              <div class="d-flex gap15 align-center mt-2">
                <vue-toggles
                  :value="optimizeCompetitorPageTitle"
                  width="40"
                  height="20"
                  :disabled="isLoading || isDemo"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="
                    $emit('update:optimizeCompetitorPageTitle', !optimizeCompetitorPageTitle)
                  "
                ></vue-toggles>
                <div class="flex-grow-1 mainColor3--text font-weight-bold d-flex align-center">
                  Use my competitors' title for AI inspiration
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 gray9Color--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip" style="max-width: 500px">
                      By selecting this option, the AI will utilize your
                      leading competitors'
                      {{
                        separateSearchEngineTitleAndTitle
                          ? ""
                          : "Search Engine Title or"
                      }}
                      Page Title to generate and optimize a fresh set of
                      {{
                        separateSearchEngineTitleAndTitle
                          ? ""
                          : "Search Engine Title or"
                      }}
                      &nbsp;Page Title for your page. This is an ideal choice
                      if your current page lacks optimized titles or if you're
                      looking to restructure your content.
                    </div>
                  </v-tooltip>
                </div>
              </div>

              <div class="d-flex gap15 align-center mt-2">
                <vue-toggles
                  :value="usePageTitleInspirationText"
                  width="40"
                  height="20"
                  :disabled="isLoading || isDemo"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="
                    $emit('update:usePageTitleInspirationText', !usePageTitleInspirationText)
                  "
                >
                </vue-toggles>
                <div class="flex-grow-1 mainColor3--text font-weight-bold d-flex align-center">
                  Give the AI an example search engine title / page title for inspiration to write your title
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 gray9Color--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip" style="max-width: 500px">
                      Enter a sample title that reflects the AI topic you're
                      interested in. This will serve as inspiration for
                      generating a more creative and engaging headline for
                      your article, blog post, or page.
                    </div>
                  </v-tooltip>
                </div>
              </div>
              <div class="mt-5">
                <template
                  v-if="!useMyCurrentPageTitle && usePageTitleInspirationText"
                >
                  <div class="font-weight-bold mainColor3--text mt-3">
                    Type an example to inspire the AI
                  </div>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    color="greenColor"
                    class="mainColor3--text mt-2"
                    :value="pagetitleInspirationText"
                    @input="$emit('update:pagetitleInspirationText', $event)"
                    label="Give the AI an example title for inspiration to write your title"
                  >
                  </v-text-field>
                </template>
              </div>

            </div>

            <div class="mt-5" v-if="!useMyCurrentPageTitle">
              <v-btn
                v-if="!pageTitleAIResult"
                color="profileAnchorColor"
                style="border-radius: 8px;"
                class="text-none whiteColor--text font-weight-bold"
                :class="{
                  'pulsing-yellow-button': needsPulsing(STEPS.PAGE_TITLE),
                }"
                :disabled="
                  isLoading ||
                  (!useMyCurrentPageTitle &&
                    !optimizeCurrentPageTitle &&
                    !optimizeCompetitorPageTitle &&
                    !usePageTitleInspirationText) ||
                  (usePageTitleInspirationText && !pagetitleInspirationText)
                "
                @click="
                  () => {
                    showWarningModalForGenerate('pageTitle', () =>
                      generateResultFor('pageTitle')
                    );
                  }
                "
              >
                <img
                  v-if="!useMyCurrentPageTitle && pageTitleAIResult"
                  src="/static/ai-writer-refresh.png"
                  width="19px"
                  style="margin-right: 1rem"
                />
                Generate page title
              </v-btn>
              <template v-else-if="isDemo">
                <v-btn
                  v-if="titleProgress <= 0"
                  color="profileAnchorColor"
                  style="border-radius: 8px;"
                  class="text-none whiteColor--text font-weight-bold"
                  :class="{ 'pulsing-yellow-button': true }"
                  @click="fakeProgress('titleProgress')"
                >
                  Generate
                </v-btn>
              </template>
            </div>

          </template>
        </div>
        <div
          v-if="!pageNotBuilt"
          class="mt-5 pa-5 settings-option-box"
        >
          <div class="section-label d-flex align-center gap15">
            <div
              :class="{
                'section-selector': true,
                'section-selector-active': useMyCurrentPageTitle,
                'section-selector-disabled': isLoading || isDemo,
              }"
              @click="!(isLoading || isDemo) ? $emit('update:useMyCurrentPageTitle', !useMyCurrentPageTitle) : undefined"
            ></div>
            I don't want to generate new optimized{{!separateSearchEngineTitleAndTitle ? ' search engine and':''}} page title with AI (leave as is)
            <v-tooltip bottom color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <i
                  class="material-icons info-icon-size gray9Color--text"
                  v-bind="attrs"
                  v-on="on"
                  >info</i
                >
              </template>
              <div class="white-bg-tooltip small-tooltip">
                <template v-if="separateSearchEngineTitleAndTitle">
                  If the current Page Title on your page is already
                  optimized, we recommend using it as is. Check this box
                  if you prefer not to make any changes to your existing
                  Page Title.
                </template>
                <template v-else>
                  If the current Search Engine Title and Page Title on
                  your page is already optimized, we recommend using it as
                  is. Check this box if you prefer not to make any changes
                  to your existing titles.
                </template>
              </div>
            </v-tooltip>
          </div>
          <div class="mt-5" v-if="useMyCurrentPageTitle">
            <div class="ml-9">
              <div class="d-flex align-center mb-3  mainColor3--text">
                {{
                  separateSearchEngineTitleAndTitle
                    ? "Your page title is already optimized and you don’t want to change it."
                    : "Your search engine title and page title are already optimized and you don’t want to change them."
                }}
              </div>
              <div class="d-flex align-center mb-5  mainColor3--text">
                POP will visit your page and extract your page titles as is— an ideal option if they're already optimized.
              </div>
            </div>
            <v-btn
              color="profileAnchorColor"
              style="border-radius: 8px;"
              class="text-none whiteColor--text font-weight-bold"
              :class="{
                'pulsing-yellow-button': needsPulsing(STEPS.PAGE_TITLE),
              }"
              :disabled="isLoading"
              @click="
                () => {
                  showWarningModalForGenerate('pageTitle', () =>
                    generateResultFor('pageTitle')
                  );
                }
              "
            >
              <img
                v-if="!useMyCurrentPageTitle && pageTitleAIResult"
                src="/static/ai-writer-refresh.png"
                width="19px"
                style="margin-right: 1rem"
              />
              {{
                separateSearchEngineTitleAndTitle
                  ? "Extract search engine title and page title"
                  : "Extract page titles"
              }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'Step5PageTitle',
    props: [
        'goNext',
        'isDemo',
        'showWarningModalForGenerate',
        'generateResultFor',
        'needsPulsing',
        'fakeProgress',
        'progress',

        'pageNotBuilt',
        'runningSection',
        'isLoading',
        'titleProgress',
        'titleInspirationText',
        'pageTitlelabel',
        'separateSearchEngineTitleAndTitle',
        'optimizeCurrentPageTitle',
        'optimizeCompetitorPageTitle',
        'usePageTitleInspirationText',
        'useMyCurrentPageTitle',
        'pagetitleInspirationText',
        'pageTitleAIResult',
    ],
    computed: {
        STEPS() {
            return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
