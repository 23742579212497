<template>
    <div class="d-flex gap20">

      <div style="flex: 1;">
        <div class="step-title-label">Select web page type</div>
        <div class="mt-2">Let POP AI know what type of web page you prefer. AI will structure the content outline to fit that page type.</div>
      </div>

      <div style="flex: 2;">
        <v-select
          outlined
          dense
          hide-details
          :full-width="false"
          color="green7Color"
          :disabled="isDemo"
          :value="webPageType"
          :items="webPageTypeOptions"
          item-text="text"
          item-value="value"
          placeholder="None"
          style="max-width: 300px;"
          @change="$emit('update:webPageType', $event); setDefaultRichContentFroWebPageType($event)"
        >
        </v-select>
      </div>

    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigWebPageType',
    props: [
        'isDemo',

        'setDefaultRichContentFroWebPageType',
        'webPageTypeOptions',
        'webPageType',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>