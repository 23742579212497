<template>
    <div>
      {{ productDescription || sampleContent ? 'Description provided' : 'No description provided' }}
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigPreviewProductDescription',
    props: [
        'productDescription',
        'sampleContent',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>