<template>
    <div>

      <v-card class="settings-area white-common-box">
        <div class="font-weight-bold mb-6 d-flex align-center" style="font-size: 1rem;">
          Select how you want to write your sub-headings

          <v-tooltip bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <i
                class="material-icons info-icon-size ml-2 gray9Color--text"
                v-bind="attrs"
                v-on="on"
                >info</i
              >
            </template>
            <div class="white-bg-tooltip small-tooltip">
              Why H2s AND H3s? <br />
              The usage of H2s and H3s is determined by the AI. You can
              use them as the AI suggests or you could just use them all
              as H2s if you don't want to use H3s on your page.
              <br />
              You can manually write in your own H2s or H3s by clicking
              "Edit" or have AI generate them for your by clicking
              "Generate." You can instruct the AI on where to get
              inspiration for the subheadings that it writes by choosing
              one or both of the inspiration options below. After the H2s
              and H3s have been generated you can edit them or add more in
              by clicking "Edit." Once you are happy with your H2s and H3s
              click "Next" to advanced to the main content section.
            </div>
          </v-tooltip>
        </div>
        
        <div
          class="settings-option-box pa-5"
        >
          <div class="section-label d-flex align-center gap15">
            <div
              :class="{
                'section-selector': true,
                'section-selector-active': !useMySubheadings,
                'section-selector-disabled': isLoading || isDemo,
              }"
              @click="!(isLoading || isDemo) ? $emit('update:useMySubheadings', !useMySubheadings) : undefined"
            ></div>

            I want the AI to rewrite my H2 and H3 sub-headings using POP suggestions
          </div>

          <template v-if="!useMySubheadings">
            <div class="ml-9">
              <div class="mainColor3--text mt-3 mb-3">
                You can select both options below at the same time.
              </div>

              <div class="d-flex gap15 align-center">
                <vue-toggles
                  :value="optimizeCurrentH2s"
                  width="40"
                  height="20"
                  :disabled="isLoading || isDemo"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="$emit('update:optimizeCurrentH2s', !optimizeCurrentH2s)"
                ></vue-toggles>
                <div class="flex-grow-1 mainColor3--text font-weight-bold d-flex align-center">
                  Use my H2s for AI inspiration
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 gray9Color--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip" style="max-width: 500px">
                      Select this option to let AI refine and rewrite your
                      subheadings, aligning them with the POP recommendations.
                    </div>
                  </v-tooltip>
                </div>
              </div>
              <div class="d-flex gap15 align-center mt-2">
                <vue-toggles
                  :value="optimizeCompetitorsH2s"
                  width="40"
                  height="20"
                  :disabled="isLoading || isDemo"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="$emit('update:optimizeCompetitorsH2s', !optimizeCompetitorsH2s)"
                ></vue-toggles>
                <div class="flex-grow-1 mainColor3--text font-weight-bold d-flex align-center">
                  Use my competitors' H2s for AI inspiration
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 gray9Color--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip" style="max-width: 500px">
                      By selecting this option, the AI will utilize your
                      leading competitors' H2s to generate and optimize a
                      fresh set of H2/H3 subheadings for your page. This is an
                      ideal choice if your current page lacks optimized
                      subheadings or if you're looking to restructure your
                      content.
                    </div>
                  </v-tooltip>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <template v-if="isDemo">
                <v-btn
                  v-if="subHeadProgress <= 0"
                  color="profileAnchorColor"
                  style="border-radius: 8px;"
                  class="text-none whiteColor--text font-weight-bold pulsing-yellow-button"
                  @click="fakeProgress('subHeadProgress')"
                >
                  Generate sub-headings
                </v-btn>
              </template>
              <v-btn
                v-else-if="!subHeadingsAIResult"
                color="profileAnchorColor"
                style="border-radius: 8px;"
                class="text-none whiteColor--text font-weight-bold"
                :class="{
                  'pulsing-yellow-button': needsPulsing(STEPS.SUBHEADINGS),
                }"
                :disabled="
                  isLoading ||
                  (!useMySubheadings &&
                    !optimizeCurrentH2s &&
                    !optimizeCompetitorsH2s)
                "
                @click="
                  () => {
                    showWarningModalForGenerate('subHeadings', () =>
                      generateResultFor('subHeadings')
                    );
                  }
                "
              >
                <img
                  v-if="!useMySubheadings && subHeadingsAIResult"
                  src="/static/ai-writer-refresh.png"
                  width="19px"
                  style="margin-right: 1rem"
                />
                Generate sub-headings
              </v-btn>
            </div>
          </template>
        </div>
        <div
          v-if="!pageNotBuilt"
          class="mt-5 settings-option-box pa-5"
        >
          <div class="section-label d-flex align-center gap15">
            <div
              :class="{
                'section-selector': true,
                'section-selector-active': useMySubheadings,
                'section-selector-disabled': isLoading || isDemo,
              }"
              @click="!(isLoading || isDemo) ? $emit('update:useMySubheadings', !useMySubheadings) : undefined"
            ></div>
            I don't want to generate new H2 and H3 sub-headings with AI (leave them as is)
            <v-tooltip bottom color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <i
                  class="material-icons info-icon-size gray9Color--text"
                  v-bind="attrs"
                  v-on="on"
                  >info</i
                >
              </template>
              <div class="white-bg-tooltip" style="max-width: 500px">
                If the current H2/H3 on your page is already optimized, we
                recommend using it as is. Check this box if you prefer not
                to make any changes to your existing H2/H3.
              </div>
            </v-tooltip>
          </div>

          <div class="mt-5" v-if="useMySubheadings">
            <div class="ml-9">
              <div class="d-flex align-center mb-5 mainColor3--text">
                Use my H2 and H3 sub-headings as is
              </div>
            </div>
            
            <v-btn
              color="profileAnchorColor"
              style="border-radius: 8px;"
              class="text-none whiteColor--text font-weight-bold"
              :class="{
                'pulsing-yellow-button': needsPulsing(STEPS.SUBHEADINGS),
              }"
              :disabled="
                isLoading ||
                (!useMySubheadings &&
                  !optimizeCurrentH2s &&
                  !optimizeCompetitorsH2s)
              "
              @click="
                () => {
                  showWarningModalForGenerate('subHeadings', () =>
                    generateResultFor('subHeadings')
                  );
                }
              "
            >
              <img
                v-if="!useMySubheadings && subHeadingsAIResult"
                src="/static/ai-writer-refresh.png"
                width="19px"
                style="margin-right: 1rem"
              />
              Extract my sub-headings
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'Step6Subheadings',
    props: [
        'goNext',
        'isDemo',
        'showWarningModalForGenerate',
        'generateResultFor',
        'needsPulsing',
        'fakeProgress',

        'pageNotBuilt',
        'runningSection',
        'isLoading',
        'titleInspirationText',
        'progress',
        'subHeadProgress',
        'optimizeCurrentH2s',
        'optimizeCompetitorsH2s',
        'useMySubheadings',
        'subHeadingsAIResult'
    ],
    computed: {
        STEPS() {
            return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
