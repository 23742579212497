<template>
    <v-card>
        <div class="pa-4">
            <div class="font-large mb-4">Breakdown:</div>
            <div class="mt-4">
                <ul class="credits-breakdown-ul">
                    <li>
                        <span class="font-weight-bold">{{ estimatedArticleCreditsRequired }} credits:</span> generate content ({{ numberOfWords }} words)
                    </li>
                    <li>
                        <span class="font-weight-bold">{{ estimatedRichContentCreditsRequired }} credits:</span> add rich content <template v-if="selectedCostedRichContentItems.length">({{ selectedCostedRichContentItems.map(item => item.dispName).join(', ') }})</template>
                    </li>
                </ul>
            </div>

            <v-btn
                outlined
                color="mainColor3"
                class="button-with-icon text-none mt-8"
                style="border-radius: 6px; border-color: var(--v-grayColor-base); width: 100%; letter-spacing: initial;"
                target="_blank"
                href="https://help.pageoptimizer.pro/article/how-pop-credits-work-with-the-pop-ai-writer"
            >
            How do credits work?
            </v-btn>

        </div>
    </v-card>
</template>

<script>

export default {
    props: [
        'selectedCostedRichContentItems',
        'numberOfWords',
        'estimatedArticleCreditsRequired',
        'estimatedRichContentCreditsRequired',
    ],
    data() {
        return {
            slider_options: {
                dotSize: 16,
                width: 'auto',
                height: 4,
                min: 0,
                max: 100,
                interval: 1,
                dotStyle: { border: '1px solid #D2D3D9' },
                processStyle: { background: '#FF5700' },
            },
        };
    },
};
</script>
<style lang="scss" scoped>
.font-large {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
}

.credits-breakdown-ul {
    list-style: initial;

    li:not(:last-child) {
        margin-bottom: 8px;
    }
}

</style>