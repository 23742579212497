<template>
    <div style="display: flex; flex-direction: column; min-height: 100vh; height: 100vh;">
        <editor-header
            v-if="isAuthenticated && isAiWriter"
            :currentPage="currentPage"
            :currentPageRun="currentPageRun"
        />
        <splitpanes class="main-layout default-theme" vertical="vertical" v-if="isReportExists"
            @resize="handleResize($event[0].size)">
            <pane min-size="20" max-size="100" :size="isShowResultPane ? 67 : 100" style="display: flex;">
                <div class="editor-area">
                    <editor-header
                        v-if="isAuthenticated && !isAiWriter"
                        :currentPage="currentPage"
                        :currentPageRun="currentPageRun"
                    />
                    <div class="show-hide-right-side-area" v-if="!shouldHideResultPaneForAIMODE">
                        <span class="material-icons-outlined" v-if="isShowResultPane"
                            @click="() => (isShowResultPane = false)">keyboard_double_arrow_right</span>
                        <span class="material-icons-outlined" v-else
                            @click="() => (isShowResultPane = true)">keyboard_double_arrow_left</span>
                    </div>
                    <div class="px-5 py-10" v-if="currentEditorMode != EDITOR_MODES.AI_MODE">
                        <div class="d-flex align-center">
                            <div class="d-flex align-center gap10">
                                <span class="page-title"> Content Editor </span>
                                <v-tooltip bottom color="transparent">
                                    <template v-slot:activator="{ on, attrs }">
                                        <i class="material-icons info-icon-size mainColor3--text" v-bind="attrs"
                                            v-on="on">info</i>
                                    </template>
                                    <div class="white-bg-tooltip">
                                        You are not updating your site content. Changes are only
                                        reflected here.
                                    </div>
                                </v-tooltip>
                                <span class="material-icons video-tutorial-icon video-tutorial-icon-size" @click="() => {
                                        $store.commit('setCurrentVideoTutorialToDisplay', {
                                            title:
                                                'How to Use the Content Editor in PageOptimizer Pro',
                                            videoLink:
                                                'https://www.youtube.com/embed/IWUw2wj9eEE?si=APifAI6tCiy5Feg-',
                                            description:
                                                'In this video we go over the basics of how to use the content editor in POP.',
                                            time: '2m 56s',
                                        });
                                    }
                                    ">
                                    videocam
                                </span>
                            </div>
                            <div class="flex-grow-1 pl-3">
                                <span class="variation-tag font-weight-bold">{{
                                    currentPageRun.keyword
                                    }}</span>
                            </div>
                            <div v-if="isSiloKeyword && canDownloadReport">
                                <label class="checkbox">
                                    <input type="checkbox" :checked="replaceAIReport" @click="updateSiloFlag">
                                    Replace Silo AI Writer Content
                                </label>
                            </div>
                            <div v-if="isSiloKeyword" class="select ml10">
                                <select class="main-color" v-model="sourceType" @change="onSourceTypeChange">
                                    <option value="1">AI Generated Draft</option>
                                    <option value="2">Human Edited Draft</option>
                                    <option value="3">Outline</option>
                                </select>
                            </div>
                            <div v-click-outside="handleClickOutside" v-if="canDownloadReport"
                                class="dropdown head-editor-button" :class="{ 'is-active': downloadDropdown }"
                                ref="dropdown">
                                <div class="dropdown-trigger">
                                    <span @click="() => { toggleDownloadDropdown() }" class="head-editor-button">
                                        <i class="material-icons-outlined">file_download</i>
                                        <span>Download</span>
                                    </span>
                                </div>
                                <div class="dropdown-menu" role="menu">
                                    <div class="dropdown-content">
                                        <div class="dropdown-item projects-section"
                                            @click="() => { downloadReport(sourceType, 'pdf') }">
                                            <a style="color: var(--v-profileAnchorColor-base);">PDF</a>
                                        </div>
                                        <div class="dropdown-item projects-section"
                                            @click="() => { downloadReport(sourceType, 'word') }">
                                            <a style="color: var(--v-profileAnchorColor-base);">Word</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="head-editor-button" @click="() => (showHeadEditor = !showHeadEditor)">
                                <img src="/static/editor-icon.png" />
                                <span>{{ showHeadEditor ? "Hide" : "Show" }} Head Editor</span>
                            </div>
                        </div>
                        <div class="mainColor3--text d-flex justify-space-between align-center">
                            To pull in your source code to the editor automatically, please
                            use the POP Chrome Extension.
                            <a class="get-it-here" target="_blank"
                                href="https://pageoptimizer.pro/pageoptimizer-pro-chrome-extension-guide/">Download</a>
                        </div>
                        <div v-if="currentEditorMode == EDITOR_MODES.EXPERT_MODE" class="text-right">
                            <button class="button is-small code-mirror-toolbutton"
                                @click.stop="toggleCodeMirrorTheme">
                                {{
                                    codeMirrorTheme == "default" ? "Dark Mode" : "Light Mode"
                                }}&nbsp;&nbsp;
                                <i v-if="codeMirrorTheme == 'default'" class="fa fa-moon-o" aria-hidden="true"></i>
                                <i v-else class="fa fa-sun-o" aria-hidden="true"></i>
                            </button>
                            <button class="button is-small code-mirror-toolbutton" @click.stop="beautifyCode">
                                Beautify Code&nbsp;&nbsp;
                                <i class="fa fa-github" aria-hidden="true"></i>
                            </button>
                            <button class="button is-small code-mirror-toolbutton"
                                @click.stop="showPreviewModal = true">
                                Preview&nbsp;&nbsp;
                                <i class="fa fa-eye" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>

                    <div class="editor-wrapper">
                        <codemirror v-if="currentEditorMode == EDITOR_MODES.EXPERT_MODE" v-model="wysiwygBody"
                            :options="{ ...cmOptions, theme: codeMirrorTheme }" />
                        <tinymce-editor v-else-if="currentEditorMode == EDITOR_MODES.WYSIWYG_MODE"
                            :key="'tiny-mce-editor'" v-model="wysiwygBody" :api-key="TINY_MCE_API_KEY"
                            :init="wysiwygConfig" model-events="change keydown blur focus paste">
                        </tinymce-editor>
                        <ai-writer ref="openAIEditor"
                            v-if="currentEditorMode == EDITOR_MODES.AI_MODE" :pageRunId="currentPageRun.id"
                            :pageRun="currentPageRun" :aiRunId="aiRunId" :page="page"
                            :runStrategies="currentPageRun.runStrategies" :currentWordCount="currentPageRun.pageNotBuilt
                                    ? 0
                                    : currentWordCountFromReport > 4000
                                        ? 4000
                                        : currentWordCountFromReport
                                " :targetWordCount="contentBriefGoalWordCount > 4000
                    ? 4000
                    : contentBriefGoalWordCount
                " @startOver="() => (showAIStartOverWarnModal = true)"
                            @onCalcChecklist="updateChecklistForAIResult"></ai-writer>
                    </div>

                    <div v-if="currentEditorMode != EDITOR_MODES.AI_MODE" class="bottom-area">
                        <div class="flex-grow-1">
                            <v-btn small color="green10Color" :class="{
                                'text-none': true,
                                'font-weight-bold': true,
                                'mainColor3--text': !needsToSave,
                                'button-with-icon': true,
                            }" @click="saveSourceCode">
                                <i v-if="needsToSave" class="material-icons redColor--text">priority_high</i>
                                <i v-else class="material-icons green11Color--text">check_circle</i>
                                <span>{{ needsToSave ? "Save" : "Saved!" }}</span>
                            </v-btn>
                            <v-btn small color="green10Color" :class="{
                                'text-none': true,
                                'font-weight-bold': true,
                                'mainColor3--text': !needsToSave,
                                'button-with-icon': true,
                                'ml-2': true,
                            }" @click="saveOneRevisionHistory">
                                <i class="material-icons green11Color--text">history</i>
                                <span>Save revision</span>
                            </v-btn>
                        </div>
                        <div class="d-flex align-center gap10">
                            <button v-if="!willUseSecretKey"
                                class="icon-button is-tooltip-left tooltip is-tooltip-multiline"
                                data-tooltip="AI Writer" @click.stop="currentEditorMode = EDITOR_MODES.AI_MODE">
                                <i class="material-icons">psychology</i>
                            </button>
                            <button class="icon-button is-tooltip-left tooltip is-tooltip-multiline"
                                data-tooltip="View revision history" @click.stop="showRevisionHistoryModal = true">
                                <i class="material-icons">history</i>
                            </button>

                            <button class="icon-button cleanup-btn" style="position: relative" :class="{
                                'tooltip is-tooltip-top is-tooltip-multiline':
                                    !showCleanupPopup,
                            }" data-tooltip="Click this button to remove tags that are irrelevant for POP analysis. This will tidy up your content for better readability so you can focus on optimizing your keywords."
                                @click.stop="() => {
                                        makeOnlyContentBriefRelatedTags();
                                    }
                                    ">
                                <div class="badge-container">
                                    <v-badge v-if="showCleanupPopup" :color="`${showCleanupPopup ? 'anchor1Color' : 'anchorColor'
                                        }`" dot class="blinking-badge"></v-badge>
                                </div>
                                <i class="material-icons-outlined">cleaning_services</i>
                                <div class="cleaning-popup" v-if="showCleanupPopup" v-click-outside="() => {
                                        showCleanupPopup = false;
                                    }
                                    ">
                                    <div class="top-part">
                                        <div class="text-center">
                                            <img src="/static/clean-up.png" />
                                        </div>
                                        <div class="title-part">Clean up code</div>
                                        <div class="desc-part">
                                            Click this button to remove tags that are irrelevant for
                                            POP analysis. This will tidy up your content for better
                                            readability so you can focus on optimizing your keywords.
                                        </div>
                                    </div>
                                    <div class="action-part">
                                        <button @click="() => {
                                                showCleanupPopup = false;
                                                disableAutoDisplayCleanupPopup();
                                            }
                                            ">
                                            <span class="material-icons">block</span>
                                            Do not show this again
                                        </button>
                                    </div>
                                </div>
                                <div class="green-dot" v-if="showCleanupGreenDot">
                                    <span class="c-circle"></span>
                                </div>
                            </button>

                            <button v-show="recoveryHtml && wysiwygModel != recoveryHtml"
                                class="icon-button is-tooltip-left tooltip"
                                data-tooltip="Click here to restore an unsaved version of your edits"
                                @click.stop="showConfirmLoadSourceTempSaved = true"
                                :disabled="!recoveryHtml && wysiwygModel == recoveryHtml">
                                <i class="material-icons">repartition</i>
                            </button>
                            <button class="icon-button is-tooltip-left tooltip is-tooltip-multiline"
                                data-tooltip="POP will fetch the most current version of your web page from your URL and load it into the editor."
                                @click.stop="showConfirmLoadSourceFromUrl = true" :disabled="pageNotBuilt == 1"
                                v-show="!pageNotBuilt">
                                <i class="material-icons">sync</i>
                            </button>
                            <button class="icon-button is-tooltip-left tooltip"
                                data-tooltip="Click here to clear out the editor and start from a blank editor"
                                @click.stop="showConfirmClearHtmlModal = true">
                                <i class="material-icons">code</i>
                            </button>
                            <button class="icon-button is-tooltip-left tooltip" @click.stop="downloadHtml"
                                data-tooltip="Download edited code">
                                <i class="material-icons-outlined">file_download</i>
                            </button>
                            <button class="icon-button is-tooltip-left tooltip" @click.stop="() => {
                                    if (currentEditorMode == EDITOR_MODES.WYSIWYG_MODE) {
                                        currentEditorMode = EDITOR_MODES.EXPERT_MODE;
                                    } else if (currentEditorMode == EDITOR_MODES.EXPERT_MODE) {
                                        currentEditorMode = EDITOR_MODES.WYSIWYG_MODE;
                                    } else {
                                        currentEditorMode = EDITOR_MODES.WYSIWYG_MODE;
                                    }
                                }
                                " data-tooltip="Code Mode">
                                <i class="material-icons-outlined">integration_instructions</i>
                            </button>
                        </div>
                    </div>
                </div>
            </pane>
            <pane
                v-if="!shouldHideResultPaneForAIMODE"
                :size="isShowResultPane ? 33 : 0"
            >
                <div class="recommendation-area" style="background-color: var(--v-whiteColor-base)"
                    ref="recommendationArea">
                    <div v-if="isTooSmallWidthForResultArea">
                        Please increase the width to see.
                    </div>
                    <template v-else>
                        <div class="text-center">
                            <v-btn rounded v-if="
                                (dispResultType != 'contentBrief' || !hasChecklistData) &&
                                currentEditorMode != EDITOR_MODES.AI_MODE &&
                                (!isDemo || $store.getters.aiWriterStep > 5)
                            " outlined color="mainColor3" class="text-none mainColor3--text"
                                :disabled="!isReportExists || isCalculating || isDemo"
                                @click="() => calculateEditedSource({ needContentSave: true })">
                                <span v-show="isCalculating" class="calc-loader">
                                    <fulfilling-square-spinner :animation-duration="4000" :size="30"
                                        color="#FF9B2D" />
                                </span>
                                {{
                                    hasChecklistData
                                        ? "Update optimization score"
                                        : "Click to start"
                                }}
                            </v-btn>
                        </div>

                        <div>
                            <div class="checklist-result-area">
                                <div v-if="
                                    currentEditorMode != EDITOR_MODES.AI_MODE &&
                                    dispResultType == 'signals' &&
                                    currentPageRun &&
                                    hasChecklistData
                                ">
                                    <!-- {{ JSON.stringify(checklistResult) }} -->
                                    <div class="d-flex justify-center mb-5">
                                        <radial-progress-bar :diameter="70" :completed-steps="checklistResult.score"
                                            :total-steps="100" :strokeWidth="8"
                                            :startColor="pageScoreTintColor(checklistResult.score)"
                                            :stopColor="pageScoreTintColor(checklistResult.score)"
                                            :innerStrokeColor="'#eeeeee'" strokeLinecap="square">
                                            <div class="score-number" :style="{ color: 'blue' }"
                                                v-if="strategy == 'custom'">
                                                N/A
                                            </div>
                                            <div class="score-number" :style="{
                                                color: pageScoreTintColor(checklistResult.score),
                                            }" v-else>
                                                {{ checklistResult.score | round(1) }}
                                            </div>
                                        </radial-progress-bar>
                                    </div>
                                    <div class="control">
                                        <label class="radio">
                                            <input type="radio" name="signalType" value="word_count"
                                                v-model="signalType" />
                                            Word Count
                                        </label>
                                        <label class="radio">
                                            <input type="radio" name="signalType" value="keyword"
                                                v-model="signalType" />
                                            Keyword
                                        </label>
                                        <label class="radio">
                                            <input type="radio" name="signalType" value="variations"
                                                v-model="signalType" />
                                            Variations
                                        </label>
                                        <label class="radio">
                                            <input type="radio" name="signalType" value="lsi_terms"
                                                v-model="signalType" />
                                            LSI
                                        </label>
                                        <label class="radio">
                                            <input type="radio" name="signalType" value="pageStructure"
                                                v-model="signalType" />
                                            Page Structure
                                        </label>
                                    </div>
                                    <v-card class="white-common-box pa-5 mt-5">
                                        <div v-if="signalType == 'keyword'">
                                            <span class="has-text-weight-bold mainColor3--text mr-2">Keyword</span>
                                            <span class="variation-tag">{{
                                                currentPageRun.keyword
                                                }}</span>
                                        </div>
                                        <div v-else-if="signalType == 'variations'">
                                            <div class="d-flex align-center">
                                                <span class="has-text-weight-bold mainColor3--text mr-2">
                                                    Variations
                                                </span>
                                                <span class="flex-grow-1">
                                                    <span class="selection-tag blue-active-tag mr10"
                                                        @click="showVariations = !showVariations">{{
                                                            showVariations
                                                                ? "Hide Variations"
                                                        : "Show Variations"
                                                        }}</span>
                                                    <span class="selection-tag blue-active-tag mr10"
                                                        @click="showWeights = !showWeights" v-if="showVariations">{{
                                                            showWeights ? "Hide Weights" : "Show Weights"
                                                        }}</span>
                                                </span>
                                                <v-btn outlined small color="mainColor3" class="text-none"
                                                    @click.stop="downloadVariationOrLSI">
                                                    Download as .txt
                                                </v-btn>
                                            </div>
                                            <div class="tags mt-5">
                                                <template v-if="hasLsaForVariations">
                                                    <span v-for="(item, idx) in sortedLsaVariations" :key="idx"
                                                        class="variation-tag" v-show="showVariations">
                                                        {{ item.phrase }}
                                                        <span v-if="showWeights">&nbsp;( {{ (item.weight *
                                                            100).toFixed(2) + "%"
                                                            }}{{
                                                                item["targetCount"] != undefined
                                                                    ? ", C: " +
                                                                    item["targetCount"] +
                                                                    ", A: " +
                                                            item["averageCount"]
                                                            : ""
                                                            }}
                                                            )</span>
                                                    </span>
                                                </template>
                                                <template v-else>
                                                    <span v-for="v in variations" :key="v" class="variation-tag">{{
                                                        v }}</span>
                                                </template>
                                            </div>
                                        </div>
                                        <div v-else-if="signalType == 'lsi_terms'">
                                            <div class="d-flex align-center">
                                                <span class="has-text-weight-bold mainColor3--text mr-2">LSI</span>
                                                <span class="flex-grow-1">
                                                    <span class="selection-tag blue-active-tag mr10"
                                                        @click="showVariations = !showVariations">{{
                                                            showVariations ? "Hide LSI" : "Show LSI"
                                                        }}</span>
                                                    <span class="selection-tag blue-active-tag mr10"
                                                        @click="showWeights = !showWeights" v-if="showVariations">{{
                                                            showWeights ? "Hide Weights" : "Show Weights"
                                                        }}</span>
                                                </span>
                                                <v-btn outlined small color="mainColor3" class="text-none"
                                                    @click.stop="downloadVariationOrLSI">
                                                    Download as .txt
                                                </v-btn>
                                            </div>
                                            <div class="tags mt-5">
                                                <template v-if="sortedLsaPhrases.length > 0">
                                                    <span v-for="(item, idx) in sortedLsaPhrases" :key="idx"
                                                        class="lsi-tag" v-show="showVariations">
                                                        {{ item.phrase }}
                                                        <span v-if="showWeights">&nbsp;( {{ (item.weight *
                                                            100).toFixed(2) + "%"
                                                            }}{{
                                                                item["targetCount"] != undefined
                                                                    ? ", C: " +
                                                                    item["targetCount"] +
                                                                    ", A: " +
                                                            item["averageCount"]
                                                            : ""
                                                            }}
                                                            )</span>
                                                    </span>
                                                </template>
                                            </div>
                                        </div>

                                        <div v-if="signalType == 'word_count'" class="pt15">
                                            <word-count-view v-if="currentWordCount != 0"
                                                :currentCount="currentWordCount"
                                                :targetCount="goalWordCountByStrategy"
                                                :recommendationText="wordCountRecommendation"></word-count-view>
                                        </div>
                                        <div v-else>
                                            <source-editor-checklist v-if="hasChecklistData" :pageId="pageId"
                                                @setBallonSignal="setBallonSignal" :signalType="signalType"
                                                :pageNotBuilt="pageNotBuilt" :checklist="checklist"
                                                :isThisExtensionEmbedPage="true"
                                                :willUseSecretKey="willUseSecretKey"
                                                :secretKey="willUseSecretKey ? secretKey : ''" />
                                        </div>
                                    </v-card>
                                </div>

                                <api-key-on
                                    v-if="
                                        aiRun?.useMyOwnApiKey
                                    "
                                />
                                
                                <regenerate-credits-history
                                    v-if="
                                        $store?.getters?.aiWriterStep > 4
                                    "
                                />

                                <div v-if="
                                    (currentEditorMode == EDITOR_MODES.AI_MODE ||
                                        dispResultType == 'contentBrief') &&
                                    currentPageRun &&
                                    hasContentBriefData
                                ">
                                    <content-brief v-show="isDemo && $store.getters.aiWriterStep > 5  || !shouldHideScoreForAIMODE"
                                        ref="contentBrief" :createdAt="currentPageRun.createdAt"
                                        :pageRun="currentPageRun" :url="currentPageRun.url ? currentPageRun.url : currentPage.url
                                            " :keyword="currentPageRun.keyword" :secondaryKeywords="currentPageRun.secondaryKeywords"
                                        :lsaPhrases="currentPageRun.lsaPhrases" :pageRunId="currentPageRun.id"
                                        :pageNotBuilt="pageNotBuilt" :page="currentPage"
                                        :ignoreSpaces="currentPageRun.ignoreSpaces"
                                        :willUseSecretKey="willUseSecretKey" :secretKey="currentPageRun.secretKey"
                                        :contentBrief="contentBrief" :runStrategies="currentPageRun.runStrategies"
                                        :tagTotalData="tagTotalData" :wordCountData="[
                                            currentWordCount,
                                            contentBriefGoalWordCount,
                                            getWordCountRecommendation(
                                                currentWordCount,
                                                contentBriefGoalWordCount
                                            ),
                                        ]" :sectionCount="currentPageRun.subHeadingsCount" :gCompetitors="currentPageRun.gCompetitors"
                                        :relatedSearches="currentPageRun.relatedSearches"
                                        :relatedQuestions="currentPageRun.relatedQuestions"
                                        :contentBriefFile="currentPageRun.contentBriefDataFile"
                                        :isFromExtension="true" :showAsMobileView="true" :fakeScore="fakeScore"
                                        @updateCurrentPage="updateCurrentPage" :hideActionButtons="false"
                                        :isAIMode="currentEditorMode == EDITOR_MODES.AI_MODE"
                                        :isCalculating="isCalculating"
                                        @needChecklistUpdate="calculateEditedSource" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <!-- add rerun button here if needed. it will need some logic though -->
                        </div>
                    </template>
                </div>
            </pane>
        </splitpanes>
        <template v-else>
            <div class="status-message">
                {{ errorMsg ? errorMsg : "Initializing ..." }}
            </div>
        </template>

        <div class="ballon-layer" v-if="signalExplainData[ballonSignal]" @click.stop="ballonSignal = ''">
            <div class="ballon-content" @click.stop="">
                <div class="has-text-right">
                    <span class="material-icons clickable" @click.stop="ballonSignal = ''">highlight_off</span>
                </div>
                <div v-html="signalExplainData[ballonSignal]"></div>
            </div>
        </div>

        <!-- modals -->
        <div class="modal" :class="{ 'is-active': showHeadEditor }">
            <div class="modal-background" @click="showHeadEditor = false"></div>
            <div class="modal-content">
                <div class="box modal-container" style="overflow: hidden">
                    <div class="modal-content-root">
                        <div class="head-modal-title">Head Editor</div>
                        <div class="head-modal-explain">
                            You can edit search engine title (meta title) of the page in Head
                            Editor. In Body Editor you can edit all other signals.
                        </div>
                        <div class="codemirror-on-modal">
                            <codemirror v-if="showHeadEditor" v-model="wysiwygHead"
                                :options="{ ...cmOptions, theme: codeMirrorTheme }" />
                        </div>
                        <div class="control d-flex mt-5">
                            <div class="flex-grow-1"></div>
                            <v-btn rounded color="profileAnchorColor" class="whiteColor--text text-none"
                                @click="showHeadEditor = false">
                                Save
                            </v-btn>
                        </div>
                    </div>
                </div>
                <button class="custom-modal-close-btn" aria-label="close" @click="showHeadEditor = false">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>

        <!-- modals -->
        <div class="modal" :class="{ 'is-active': showConfirmClearHtmlModal }">
            <div class="modal-background" @click="showConfirmClearHtmlModal = false"></div>
            <div class="modal-content">
                <div class="box modal-container" style="overflow: hidden">
                    <div class="modal-content-root">
                        <div class="title-font is-size-4 mb20 mainColor3--text">
                            Please Confirm
                        </div>
                        <div class="body-font is-size-6 mainColor3--text">
                            By clicking yes you will remove the current webpage content from
                            the editor inside POP and will start from a blank page.<br />
                            Your live webpage will not be affected.
                        </div>
                        <div class="control flex mt-5">
                            <div class="flex-grow-1"></div>
                            <v-btn rounded color="profileAnchorColor" class="whiteColor--text text-none"
                                @click="clearHtml">
                                <i class="fa fa-eraser" aria-hidden="true"></i>&nbsp; Yes
                            </v-btn>
                        </div>
                    </div>
                </div>
                <button class="custom-modal-close-btn" aria-label="close"
                    @click="showConfirmClearHtmlModal = false">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': showConfirmLoadSourceFromUrl }">
            <div class="modal-background" @click="showConfirmLoadSourceFromUrl = false"></div>
            <div class="modal-content">
                <div class="box modal-container" style="overflow: hidden">
                    <div class="title-font is-size-4 mb20 mainColor3--text">
                        Please Confirm
                    </div>
                    <div class="body-font mainColor3--text">
                        By clicking yes you will load the current content from your webpage.
                        <br />
                        You will lose any work currently saved in the content editor.
                    </div>

                    <div class="control flex mt-5">
                        <div class="flex-grow-1"></div>
                        <v-btn rounded color="profileAnchorColor" class="whiteColor--text text-none"
                            @click="loadFromTargetUrl">
                            <i class="fa fa-check" aria-hidden="true"></i>&nbsp; Yes
                        </v-btn>
                    </div>
                </div>
                <button class="custom-modal-close-btn" aria-label="close"
                    @click="showConfirmLoadSourceFromUrl = false">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': showConfirmLoadSourceTempSaved }">
            <div class="modal-background" @click="showConfirmLoadSourceTempSaved = false"></div>
            <div class="modal-content" style="width: 95vw">
                <div class="box modal-container" style="overflow: hidden">
                    <div class="modal-content-root">
                        <div class="title-font is-size-4 mb20 mainColor3--text">
                            Restore Autosaved Draft
                        </div>
                        <div class="body-font is-size-6 mainColor3--text">
                            There is an autosaved draft of your content editor.<br />
                            Would you like to restore the autosaved draft? Restoring an
                            autosaved draft cannot be undone.
                            <div v-html="recoveryHtml" class="preview-tag"></div>
                        </div>
                        <div class="control d-flex mt-5">
                            <div class="flex-grow-1"></div>
                            <v-btn rounded color="profileAnchorColor" class="whiteColor--text text-none"
                                @click="loadRecoverHtml">
                                <i class="fa fa-check" aria-hidden="true"></i>&nbsp; Yes
                            </v-btn>
                            <v-btn rounded color="profileAnchorColor" class="whiteColor--text text-none"
                                @click="showConfirmLoadSourceTempSaved = false">
                                <i class="fa fa-times mr5"></i>
                                Cancel
                            </v-btn>
                        </div>
                    </div>
                </div>
                <button class="custom-modal-close-btn" aria-label="close"
                    @click="showConfirmLoadSourceTempSaved = false">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': showPreviewModal }">
            <div class="modal-background" @click="showPreviewModal = false"></div>
            <div class="modal-content" style="width: 95vw">
                <div class="box modal-container" style="overflow: hidden">
                    <div class="modal-content-root">
                        <div class="title-font is-size-4 mb-5 mainColor3--text">Preview</div>
                        <div id="preview-wrapper"></div>
                    </div>
                </div>
                <button class="custom-modal-close-btn" aria-label="close" @click="showPreviewModal = false">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': showAIStartOverWarnModal }">
            <div class="modal-background" @click="showAIStartOverWarnModal = false"></div>
            <div class="modal-content">
                <div class="box modal-container">
                    <div class="modal-content-root">
                        <div class="title-font is-size-4 mainColor3--text">
                            Starting over will erase your existing data. <br />
                        </div>
                        <div class="subtitle-font mainColor3--text mt-5">
                            We highly recommend downloading your current data prior to
                            resetting the AI writer.
                        </div>
                        <div class="d-flex justify-end gap10 mt-10">
                            <v-btn rounded outlined color="mainColor3" class="mainColor3--text text-none"
                                @click="showAIStartOverWarnModal = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-btn rounded color="profileAnchorColor" class="whiteColor--text text-none"
                                @click="startOverAIRun">
                                <i class="fa fa-check" aria-hidden="true"></i>&nbsp;
                                <span>Yes, Start over</span>
                            </v-btn>
                        </div>
                    </div>
                </div>
                <button class="custom-modal-close-btn" aria-label="close" @click="showAIStartOverWarnModal = false">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>

        <div class="modal" :class="{ 'is-active': showRevisionHistoryModal }">
            <div class="modal-background" @click="showRevisionHistoryModal = false"></div>
            <div class="modal-content" style="min-width: 80vw">
                <div class="box modal-container">
                    <div class="modal-content-root">
                        <div class="title-font is-size-4 mb20 mainColor3--text">
                            Revision History
                        </div>
                        <div class="notify-label">
                            <i class="material-icons">error_outline</i>
                            You can have 10 history at max. once you save new revision, most
                            old one will be removed automatically.
                        </div>
                        <div>
                            <div v-for="(item, idx) in revisionHistory" :key="idx"
                                class="revision-history-item pa-3">
                                <div class="view-as-short">
                                    {{ item.sourceCode }}
                                </div>
                                <div class="mt-3 d-flex align-center">
                                    <div class="flex-grow-1">
                                        <span class="font-italic revision-date ml-3">
                                            {{ toLocalDateTimeString(item.createdAt) }}
                                        </span>
                                    </div>
                                    <div>
                                        <v-btn small rounded color="lightYellowColor"
                                            class="redColor--text text-none"
                                            @click="() => viewDiffForRevision(idx)">
                                            <span>View diff</span>
                                        </v-btn>
                                        <v-btn small rounded color="profileAnchorColor"
                                            class="text-none whiteColor--text ml-2"
                                            @click="() => loadForSpecificRevision(idx)">
                                            <span>Load</span>
                                        </v-btn>
                                    </div>
                                </div>
                                <div class="view-full-version" v-if="expandedRevisionIndex == idx">
                                    <div class="mainColo--text font-weight-bold">
                                        Diff with current code
                                    </div>
                                    <div class="code-diff-part mt-2">
                                        <code-diff :old-string="prevRevision ? prevRevision.sourceCode : ''"
                                            :new-string="currentRevision ? currentRevision.sourceCode : ''
                                                " context="2" output-format="side-by-side" />
                                        <!-- <codemirror
                    :value="item.sourceCode"
                    :options="{ ...cmOptions, theme: codeMirrorTheme }"
                    /> -->
                                    </div>
                                    <div class="text-right mt-5">
                                        <v-btn small rounded color="lightYellowColor"
                                            class="redColor--text text-none" @click="expandedRevisionIndex = -1">
                                            <span>Back to history</span>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-end mt-10"></div>
                    </div>
                </div>
                <button class="custom-modal-close-btn" aria-label="close" @click="showRevisionHistoryModal = false">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>

        <div class="lottie-congrats-layer" v-show="showCongratsAnimation">
            <lottie-vue-player :src="`/static/great_job_animation.json`" :autoplay="true" :loop="true"
                :theme="greatJobLottieOption.theme" :player-size="greatJobLottieOption.playerSize"
                :player-controls="false" style="width: 100vw; height: 100vh">
            </lottie-vue-player>
        </div>

        <div class="modal" :class="{ 'is-active': showGreatJobModal }">
            <div class="modal-background" @click="closeGreateJobModalAction"></div>
            <div class="modal-content" style="max-width: 350px">
                <div class="box modal-container">
                    <div class="modal-content-root great-job-modal">
                        <div class="text-center">
                            <img src="/static/great_job_image.png" />
                        </div>
                        <div class="great-job-title mt-5">Great job!</div>
                        <div class="great-job-desc mt-5">
                            You’ve just reached
                            {{
                                cleanedContentBrief && cleanedContentBrief.pageScore
                                    ? cleanedContentBrief.pageScore.pageScore
                            : ""
                            }}
                            <br />
                            Keep up the good work!
                        </div>

                        <div class="text-center mt-5">
                            <v-btn rounded color="profileAnchorColor" class="whiteColor--text text-none ml-3"
                                @click="() => closeGreateJobModalAction()">
                                <i class="fa fa-check" aria-hidden="true"></i>&nbsp;
                                <span>Continue</span>
                            </v-btn>
                        </div>
                        <div class="d-flex justify-center">
                            <v-checkbox v-model="shouldDisableWellDonePopup"
                                label="Don’t show this again"></v-checkbox>
                        </div>
                    </div>
                </div>
                <button class="custom-modal-close-btn" aria-label="close" @click="closeGreateJobModalAction">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";

import { FulfillingSquareSpinner } from "epic-spinners";
import TinyMceEditor from "@tinymce/tinymce-vue";

import { codemirror } from "vue-codemirror";
import { downloadEditorReport } from "@/utils/download-reports.js";
import { whiteGlovePlans } from "@/components/plans_constants";
import { getPureServicePkgName } from "@/utils";

// import language js
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/css/css.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";

import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/html-hint.js";
import "codemirror/addon/hint/css-hint.js";
import "codemirror/addon/search/search.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/dialog/dialog.js";

// import base style
import "codemirror/lib/codemirror.css";
// import theme style
import "codemirror/theme/material.css";
// codemirror addon
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/dialog/dialog.css";
import "codemirror/addon/search/matchesonscrollbar.css";

// const htmlBeautifiy = require('js-beautify').html
import { html as htmlBeautifiy } from "js-beautify";
import * as cheerio from "cheerio";

import RadialProgressBar from "vue-radial-progress";
import SourceEditorChecklist from "@/components/PageRun/SourceEditorChecklist.vue";
import ContentBrief from "@/components/FullPageSourceEditorV2/components/side-pane/ContentBrief/ContentBrief.vue";
import { mixin } from "@/components/export_mixin";
import { mixin as ContentBriefMixin } from "@/components/content_brief_mixin";
import {
    toLocalDateString,
    toLocalDateTimeString,
    pageScoreTintColor,
} from "@/utils";
import explainData from "@/components/PageRun/ExplainData";
import tagTotalsExplainData from "@/components/PageRun/TagTotalsExplainData";

import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

import AiWriter from "@/components/FullPageSourceEditorV2/components/AiWriter/Index.vue";
import EditorHeader from "@/components/FullPageSourceEditorV2/components/EditorHeader.vue";

import { TINY_MCE_API_KEY } from "@/utils/config.js";
// import * as greatJobAnimationJson from '@/components/IndependentPages/great_job_animation.json'
import WordCountView from "@/components/IndependentPages/components/WordCountView.vue";
import { ReportTypes } from "@/components/PageRun/constants";

import RegenerateCreditsHistory from '@/components/FullPageSourceEditorV2/components/side-pane/RegenerateCreditsHistory.vue';
import ApiKeyOn from '@/components/FullPageSourceEditorV2/components/side-pane/ApiKeyOn.vue';

const EDITOR_MODES = {
    AI_MODE: 0,
    WYSIWYG_MODE: 1,
    EXPERT_MODE: 2,
};

const channel = new BroadcastChannel('silo_content_editor_channel');

export default {
    name: 'FullPageSourceEditorV2',
    mixins: [mixin, ContentBriefMixin],
    components: {
        RadialProgressBar,
        FulfillingSquareSpinner,
        "tinymce-editor": TinyMceEditor,
        SourceEditorChecklist,
        ContentBrief,
        Splitpanes,
        Pane,
        codemirror,
        AiWriter,

        WordCountView,
        EditorHeader,
        RegenerateCreditsHistory,
        ApiKeyOn,
    },
    data() {
        return {
            // DEFAULT_PANE_SIZE: [67, 33],
            // paneSize: DEFAULT_PANE_SIZE,
            isShowResultPane: true,
            shouldDisableWellDonePopup: false,

            TINY_MCE_API_KEY: TINY_MCE_API_KEY,
            EDITOR_MODES: EDITOR_MODES,

            wysiwygHead: "",
            wysiwygBody: "",
            originHtmlSource: "",
            revisionHistory: [],
            prevRevision: null,
            currentRevision: null,
            recoveryHtml: "",
            wysiwygConfig: {
                // height: 'calc(100vh - 85px)',
                height: "100%",
                inline: false,
                // theme: 'modern',
                fontsize_formats:
                    "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 34px 38px 42px 48px 54px 60px",
                plugins:
                    "print preview powerpaste searchreplace autolink directionality advcode visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount tinymcespellchecker a11ychecker mediaembed linkchecker textpattern help",
                // removed premium plugins  linkchecker mediaembed a11ychecker advcode
                // plugins: 'print preview code searchreplace autolink directionality visualblocks visualchars fullscreen image link template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern help',
                toolbar1:
                    "code fullscreen | formatselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | undo redo",
                image_advtab: true,
                contextmenu: "cut copy paste | image table link lists",
                // templates: [
                // { title: 'Test template 1', content: 'Test 1' },
                // { title: 'Test template 2', content: 'Test 2' }
                // ],
                skin: "",
                content_css: "",
                // content_css: [
                //   '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
                //   '//www.tinymce.com/css/codepen.min.css'
                // ],
                menubar: true,
                branding: false,
                verify_html: false,
            },
            cmOptions: {
                tabSize: 4,
                // mode: "text/javascript",
                // mode: "text/html",
                mode: "htmlmixed",
                // theme: "material",
                theme: "default",
                lineNumbers: true,
                line: true,
                // more CodeMirror options...
            },
            codeMirrorTheme: "default", // default, "material", "abcdef"
            showHeadEditor: false,
            currentEditorMode: EDITOR_MODES.WYSIWYG_MODE,
            showPreviewModal: false,

            isAvailableChromeExtension: false,
            isCalculating: false,

            showWeights: false,
            showVariations: false,

            pageRunId: 0,
            secretKey: "",
            siloKeywordID: 0,
            willUseSecretKey: false,
            currentPageRun: {},
            currentPage: {},
            editedCurrentWordCount: 0,
            signalType: "keyword",
            dispResultType: "signals", // signals/contentBrief
            checklistResult: {},
            cleanedContentBrief: {},
            currentTagCounts: {},
            ballonSignal: "",

            aiProducedHTML: "",

            errorMsg: "",
            needsToSave: true,

            storageName: "",
            isTooSmallWidthForResultArea: false,
            showConfirmClearHtmlModal: false,
            showConfirmLoadSourceFromUrl: false,
            showConfirmLoadSourceTempSaved: false,

            showAIStartOverWarnModal: false,

            showRevisionHistoryModal: false,
            expandedRevisionIndex: -1,

            showCongratsAnimation: false,
            showGreatJobModal: false,
            // greatJobLottieOption: {
            //   animationData: greatJobAnimationJson
            // },
            greatJobLottieOption: {
                minimizable: false,
                playerSize: "standard",
                // backgroundColor: 'red',
                backgroundStyle: "transparent",
                theme: {
                    controlsView: "standard",
                    active: "light",
                    light: {
                        color: "#3D4852",
                        backgroundColor: "red",
                        opacity: "0.7",
                    },
                    dark: {
                        color: "#fff",
                        backgroundColor: "red",
                        opacity: "0.7",
                    },
                },
            },

            relId: null, // this is to save subaccount relation id from parameter.
            aiRunId: null,

            showCleanupPopup: false,
            showCleanupGreenDot: false,

            downloadDropdown: false,
            sourceType: 0,

            replaceAIReport: false,

        };
    },
    watch: {
        "$route.query.startOver": function (newVal, oldVal) {
            if (newVal && oldVal != newVal) {
                this.actionsForStartOver();
                let params = JSON.parse(JSON.stringify(this.$route.query || {}));
                if (params.startOver) {
                    delete params.startOver;
                }
                this.$router.replace({
                    query: params,
                });
            }
        },
        originHtmlSource: function (val, oldVal) {
            const $ = cheerio.load(val);

            this.wysiwygBody = $("body").html();
            this.wysiwygHead = $("head").html();
            this.needsToSave = true;
            if (val) {
                const found = $("img, footer");
                if (found.length > 0) {
                    // means it's not cleared yet.
                    if (!this.userSetting.doNotDisplayCleanupPopup) {
                        this.showCleanupPopup = true;
                    } else {
                        this.showCleanupGreenDot = true;
                    }
                }
            }
        },
        wysiwygModel: function (val) {
            this.needsToSave = true;
            this.saveSourceCodeDebounce(true);
        },
        // needsToSave: function(val) {
        // },
        showPreviewModal: function (val) {
            if (val == true) {
                let wrapper = document.getElementById("preview-wrapper");
                if (wrapper) {
                    let iframe = document.createElement("iframe");
                    iframe.setAttribute("id", "preview-iframe");

                    while (wrapper.firstChild) {
                        wrapper.removeChild(wrapper.firstChild);
                    }

                    wrapper.appendChild(iframe);
                    iframe.contentWindow.document.write(this.wysiwygModel);
                    iframe.setAttribute(
                        "style",
                        `width: 100%;
                                          border: 1px solid gray;
                                          min-height: 80vh;
                                          background: #DDDDDD;`
                    );
                }
            }
        },
        showRevisionHistoryModal: function (newVal, oldVal) {
            if (newVal && newVal != oldVal) {
                this.expandedRevisionIndex = -1;
            }
        },
        "$vuetify.theme.dark": function (newVal, oldVal) {
            let wysiwygConfig = Object.assign({}, this.wysiwygConfig);
            if (newVal) {
                wysiwygConfig["skin"] = "oxide-dark";
                wysiwygConfig["content_css"] = "dark";
            } else {
                wysiwygConfig["skin"] = "";
                wysiwygConfig["content_css"] = "";
            }

            this.wysiwygConfig = wysiwygConfig;
        },
    },
    computed: {
        isAiWriter() {
            return this.$route.query.editorMode === 'AI_MODE';
        },
        isSiloKeyword() {
            return !!this.siloKeywordID;
        },
        isWhiteGloveUser() {
            if (!this.user) return false;
            if (whiteGlovePlans.includes(getPureServicePkgName(this.user.accountType))) {
                return true;
            }
            return false;
        },
        selectedSubAccountRelation() {
            return this.$store.state.selectedSubAccountRelation
        },
        isSubAccount() {
            return !!this.$store.state.selectedSubAccountRelation;
        },
        isMissionControlEmail() {
            return this.user.email === 'missioncontrol@pageoptimizer.pro';
        },
        canDownloadReport() {
            return this.isSiloKeyword && (this.isWhiteGloveUser || this.user.isAdmin || this.isSubAccount || this.isMissionControlEmail)
        },
        fakeScore() {
            if (!this.$store.getters.isDemoUser) return;

            const aiStep = this.$store.getters.aiWriterStep;
            return aiStep < 7 || !this.$store.state.isDemoMainContentDone
                ? _.min([aiStep * 6.5, 100])
                : undefined;
        },
        isDemo() {
            return this.$store.getters.isDemoUser;
        },
        wysiwygModel: function () {
            return `
          <html>
            <head>${this.wysiwygHead}</head>
            <body>${this.wysiwygBody}</body>
          </html>
        `;
        },
        signalExplainData: function () {
            return {
                ...explainData,
                ...tagTotalsExplainData,
            };
        },
        jwt() {
            return this.$store.getters.getToken;
        },
        isAuthenticated() {
            return this.$store.getters.isAuthenticated;
        },
        user() {
            return this.$store.state.user;
        },
        userSetting() {
            const settings = Object.assign({}, this.user ? this.user.settings : {});
            return settings;
        },
        isSpecialAdminUser() {
            return this.$store.getters.isSpecialAdminUser;
        },
        isReportExists() {
            return this.currentPageRun && Object.keys(this.currentPageRun).length > 0;
        },
        aiRun () {
            return this.$store.state.currentAIRun
        },
        pageId: function () {
            return this.currentPageRun.pageId;
        },
        page() {
            if (this.currentPage) {
                return this.currentPage;
            }
            return {};
        },
        pageNotBuilt: function () {
            if (this.currentPageRun) {
                return this.currentPageRun.pageNotBuilt;
            }
            return false;
        },
        variationsStringArr: function () {
            if (!this.currentPageRun) return [];
            return this.currentPageRun.variations;
        },
        lsiStringArr: function () {
            if (!this.currentPageRun) return [];
            return this.currentPageRun.lsaPhrases.map((item) => item.phrase);
        },
        hasChecklistData() {
            return (
                this.checklistResult && Object.keys(this.checklistResult).length > 0
            );
        },
        checklist() {
            switch (this.signalType) {
                case "keyword":
                    return this.checklistResult.keyword;
                case "variations":
                    return this.checklistResult.variations;
                case "lsi_terms":
                    return this.checklistResult.lsiTerms;
                case "pageStructure":
                    return this.checklistResult.pageStructure;
                default:
                    return [];
            }
        },
        hasLsaForVariations() {
            return (
                this.currentPageRun.lsaVariations.length > 0 &&
                this.currentPageRun.lsaVariations[0].phrase
            );
        },
        variations() {
            if (!this.currentPageRun) return [];
            return this.currentPageRun.variations;
        },
        sortedLsaVariations: function () {
            if (!this.currentPageRun) return [];
            if (
                this.currentPageRun.lsaVariations.length > 0 &&
                this.currentPageRun.lsaVariations[0].phrase
            ) {
                return this.currentPageRun.lsaVariations;
            }
            return [];
        },
        sortedLsaPhrases: function () {
            if (this.currentPageRun && this.currentPageRun.lsaPhrases)
                return this.currentPageRun.lsaPhrases;
            return [];
        },
        strategy() {
            let strategy =
                this.page && this.page.strategySetting
                    ? this.page.strategySetting.strategy
                    : "target";
            return strategy;
        },
        approach() {
            let approach =
                this.page && this.page.strategySetting
                    ? this.page.strategySetting.approach
                    : "regular";
            return approach;
        },
        currentWordCountFromReport() {
            if (!this.currentPageRun || !this.currentPageRun.runStrategies) return 0;
            let found = this.currentPageRun.runStrategies.find((item) => {
                if (this.dispResultType == "signals")
                    return item.dispResultType == this.dispResultType;
                else
                    return (
                        item.approach == this.approach && item.strategy == this.strategy
                    );
            });
            if (!found) {
                found = this.currentPageRun.keywordCounts;
            }
            if (found && found.rows) {
                found = found.rows.find((item) => item.signal == "Word Count");
                return found?.signalData?.targetKeywordCnt;
            }
            return 0;
        },
        currentWordCount() {
            if (this.editedCurrentWordCount > 0) return this.editedCurrentWordCount;
            return this.currentWordCountFromReport;
        },
        avgCompetitorWordCount() {
            if (this.currentPageRun.hasOwnProperty("keywordCounts")) {
                const wordCount = this.currentPageRun.keywordCounts.rows?.find(
                    (wc) => wc.signal === "Word Count"
                );
                return Math.round(wordCount?.signalData?.competitorsAvg);
            }
            return 0;
        },
        contentBriefGoalWordCount() {
            let currentWordCount =
                this.currentEditorMode == this.EDITOR_MODES.AI_MODE
                    ? this.currentWordCountFromReport
                    : this.currentWordCount;
            return this.calcContentBriefGoalWordCount(
                currentWordCount,
                this.avgCompetitorWordCount
            );
        },
        strategyWordCountTarget() {
            if (!this.currentPageRun || !this.currentPageRun.runStrategies) return 0;

            if (
                (this.strategy == "adjusted" && this.approach == "regular") ||
                this.strategy == "custom"
            ) {
                return this.currentWordCount;
            }

            let found = this.currentPageRun.runStrategies.find((item) => {
                return item.approach == this.approach && item.strategy == this.strategy;
            });
            if (found) {
                found = found.rows?.find((item) => item.signal == "Word Count");
                return found?.signalData?.competitorsAvg;
            }
            return 0;
        },
        goalWordCountByStrategy() {
            return this.strategyWordCountTarget;
        },
        wordCountRecommendation() {
            return this.getWordCountRecommendation(
                this.currentWordCount,
                this.goalWordCountByStrategy
            );
        },
        contentBrief() {
            return this.cleanedContentBrief;
        },
        hasContentBriefData() {
            return (
                this.cleanedContentBrief &&
                Object.keys(this.cleanedContentBrief).length > 0
            );
        },
        tagTotalData() {
            if (!this.currentPageRun) return [];
            let data = this.currentPageRun.tagCounts.map((row) => {
                row["signalCnt"] = 0;
                if (row.tagLabel in this.currentTagCounts)
                    row["signalCnt"] = this.currentTagCounts[`${row.tagLabel}`];
                return row;
            });
            return data;
        },
        shouldHideResultPaneForAIMODE() {
            return (this.currentEditorMode === this.EDITOR_MODES.AI_MODE && (!this.aiRun || this.$store?.getters?.aiWriterStep || 0) < 4)
        },
        shouldHideScoreForAIMODE() {
            return (this.currentEditorMode === this.EDITOR_MODES.AI_MODE && (!this?.aiRun?.paragraphResult?.length || this.$store?.getters?.aiWriterStep || 0) < 7)
        }
        // uniqueUrlForEditor () {
        //   let routeData = this.$router.resolve({name: 'FullPageSourceEditor', query: {secretKey: this.currentPageRun.secretKey }});
        //   return window.location.origin + "/" + routeData.href
        // },
    },
    methods: {
        toggleDownloadDropdown() {
            this.downloadDropdown = !this.downloadDropdown;  // Toggle the dropdown
        },
        handleClickOutside() {
            this.downloadDropdown = false;  // Close the dropdown
        },
        onSourceTypeChange(event) {
            this.sourceType = event.target.value;
            this.loadSourceCode();
        },
        updateSiloFlag() {
            let flag = "useAiWriter"
            if (this.sourceType == ReportTypes.AI_DRAFT) {
                flag = "useAiWriter"
            } else if (this.sourceType == ReportTypes.HUMAN_DRAFT) {
                flag = "useHumanWriter"
            } else {
                flag = "useOutlineWriter"
            }
            this.updateSiloFlagAPI(flag)
        },
        updateSiloFlagAPI(flag) {
            let data = {
                siloKeywordId: this.siloKeywordID,
                flag: flag
            }
            this.$store.commit("showLoading");
            this.$store
                .dispatch("updateSiloKeywordFlag", data)
                .then(
                    this.replaceAIReport = !this.replaceAIReport
                )
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.$store.commit("hideLoading");
                    this.loadSourceCode()
                    channel.postMessage('flag_updated');
                });
        },
        disableWellDonePopupSettingAction() {
            let settings = Object.assign({}, this.userSetting);
            settings.disabledWellDonePopup = true;

            this.$store.commit("showLoading");
            this.$store
                .dispatch("updateUserSettings", settings)
                .then((response) => {
                    this.$store.commit("setUserSettings", response.data);
                })
                .finally(() => {
                    this.$store.commit("hideLoading");
                });
        },
        disableAutoDisplayCleanupPopup() {
            let settings = Object.assign({}, this.userSetting);
            settings.doNotDisplayCleanupPopup = true;

            this.$store.commit("showLoading");
            this.$store
                .dispatch("updateUserSettings", settings)
                .then((response) => {
                    this.$store.commit("setUserSettings", response.data);
                })
                .finally(() => {
                    this.$store.commit("hideLoading");
                });
        },
        closeGreateJobModalAction() {
            this.showGreatJobModal = false;
            if (this.shouldDisableWellDonePopup) {
                this.disableWellDonePopupSettingAction();
            }
        },
        pageScoreTintColor(score) {
            return pageScoreTintColor(score);
        },
        isEmptyHtml(htmlStr) {
            if (htmlStr && htmlStr.length > 500) return false;
            if (!htmlStr) return true;
            const $ = cheerio.load(htmlStr);
            const bodyChildren = $("body").children();
            if (bodyChildren.length > 0) {
                return false;
            }
            return true;
        },
        actionsForStartOver() {
            this.checklistResult = {};
            this.cleanedContentBrief = {};
            this.aiRunId = null;
            this.$refs.openAIEditor.startOverAgain();
            this.showAIStartOverWarnModal = false;
        },
        startOverAIRun() {
            this.showAIStartOverWarnModal = false;

            let params = JSON.parse(JSON.stringify(this.$route.query || {}));
            if (params.aiRunId) {
                delete params.aiRunId;
            }
            params["startOver"] = 1;

            this.$router.push({
                query: params,
            });
        },
        toggleCodeMirrorTheme() {
            if (this.codeMirrorTheme == "default") {
                this.codeMirrorTheme = "material";
            } else {
                this.codeMirrorTheme = "default";
            }
        },
        beautifyCode() {
            const options = {
                indent_size: "2",
                indent_char: " ",
                max_preserve_newlines: "5",
                preserve_newlines: true,
                keep_array_indentation: false,
                break_chained_methods: false,
                indent_scripts: "normal",
                brace_style: "collapse,preserve-inline",
                space_before_conditional: true,
                unescape_strings: false,
                jslint_happy: true,
                end_with_newline: false,
                wrap_line_length: "0",
                indent_inner_html: true,
                comma_first: false,
                e4x: true,
                indent_empty_lines: false,
            };
            let beautifiedCode = htmlBeautifiy(this.wysiwygModel, options);
            const $ = cheerio.load(beautifiedCode);
            this.wysiwygBody = $("body").html();
            this.wysiwygHead = $("head").html();
        },
        makeOnlyContentBriefRelatedTags() {
            try {
                const tagsToKeep = ["h1", "h2", "h3", "p", "ul", "table", "ol"];

                let htmlStr = this.wysiwygBody;
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlStr, "text/html");

                let footers = doc.querySelectorAll("footer"); // remove footer
                footers.forEach((footer) => {
                    if (footer) {
                        footer.remove();
                    }
                });

                const allElements = doc.querySelectorAll(tagsToKeep.join(", "));

                let rlt = [...allElements].map((el) => el.outerHTML).join("\n");

                this.wysiwygBody = rlt;
                this.showCleanupGreenDot = false;
            } catch (e) {
                console.log(e);
            }
        },
        gotoLoginPage() {
            let routeData = this.$router.resolve({ name: "Login" });
            window.open(window.location.origin + "/" + routeData.href, "_self");
        },
        downloadVariationOrLSI() {
            let fileName = "";
            let data = "";
            if (this.signalType == "variations") {
                fileName = "variations.txt";
                if (this.hasLsaForVariations) {
                    this.sortedLsaVariations.map((item) => {
                        data =
                            data +
                            item.phrase +
                            "(" +
                            (item.weight * 100).toFixed(2) +
                            "%" +
                            ", C: " +
                            item["targetCount"] +
                            ", A: " +
                            item["averageCount"] +
                            ")" +
                            "\r\n";
                    });
                } else {
                    this.variations.map((item) => {
                        data = data + item + "\r\n";
                    });
                }
            } else if (this.signalType == "lsi_terms") {
                fileName = "lsi.txt";
                this.sortedLsaPhrases.map((item) => {
                    data =
                        data +
                        item.phrase +
                        "(" +
                        (item.weight * 100).toFixed(2) +
                        "%" +
                        ", C: " +
                        item["targetCount"] +
                        ", A: " +
                        item["averageCount"] +
                        ")" +
                        "\r\n";
                });
            }

            if (data) {
                fileName = `Report_${this.currentPageRun.id}_` + fileName;
                var element = document.createElement("a");
                element.setAttribute(
                    "href",
                    "data:text/plain;charset=utf-8," + encodeURIComponent(data)
                );
                element.setAttribute("download", fileName);

                element.style.display = "none";
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            }
        },
        showOopsMessage() {
            if (this.willUseSecretKey) {
                this.errorMsg =
                    "Ooops, You do not have permission to this page. Please check your url again.";
            } else {
                this.errorMsg =
                    "Ooops, You do not have permission to this page. Please login and try again.";
                this.gotoLoginPage();
            }
            this.$store.commit("hideLoading");
        },
        loadUser() {
            this.$store.commit("showLoading");
            this.$store
                .dispatch("loadUser", true)
                .then((response) => {
                    if (response.data && response.data.status == "SUCCESS") {
                        let user = response.data.user;
                        this.$store.commit("setUser", user);
                        if (this.relId && user.ownerAccounts) {
                            let subAccountRelation = user.ownerAccounts?.find(
                                (subAccRelation) => {
                                    return subAccRelation.id == this.relId;
                                }
                            );
                            if (subAccountRelation) {
                                this.$store.commit(
                                    "setCurrentSubAccount",
                                    subAccountRelation
                                );
                            }
                        }

                        this.loadPageRun();
                    } else {
                        this.showOopsMessage();
                        this.$store.commit("hideLoading");
                    }
                })
                .catch((err) => {
                    if (this.willUseSecretKey) {
                        this.loadPageRun();
                    } else {
                        this.showOopsMessage();
                    }
                });
        },
        loadRecoverHtml() {
            this.wysiwygModel = this.recoveryHtml;
            this.showConfirmLoadSourceTempSaved = false;
        },
        loadFromTargetUrl() {
            let url = this.currentPageRun.url;
            if (this.isAvailableChromeExtension) {
                this.dispatch_custom_event("validate_urls", {
                    url_arr: [url],
                });
            } else {
                this.$store.commit("showLoading");
                let proxyLocation = this.currentPageRun.googleLocation
                    ? this.currentPageRun.googleLocation
                    : "United States";
                this.$store
                    .dispatch("validateUrlForEditor", {
                        url: url,
                        proxyLocation: proxyLocation,
                        siteBaseUrl: url,
                        secretKey: this.willUseSecretKey ? this.secretKey : "",
                    })
                    .then((response) => {
                        if (response.data && response.data.status === "SUCCESS") {
                            let item = response.data.result;
                            if (item && item.validated) {
                                this.originHtmlSource = item.source;
                            }
                        } else if (response.status === "FAILURE") {
                        }
                    })
                    .finally(() => this.$store.commit("hideLoading"));
            }
            this.showConfirmLoadSourceFromUrl = false;
        },
        loadSourceCode() {
            let data = {
                pageRunId: this.currentPageRun.id,
                secretKey: this.willUseSecretKey ? this.secretKey : "",
                sourceType: this.sourceType,
                siloKeywordID: this.siloKeywordID
            };

            this.$store.commit("showLoading");
            this.$store
                .dispatch("loadSavedSourceCode", data)
                .then((response) => {
                    let sourceCode = "";
                    let isValidSource = false;
                    if (response.data.status_code == 404) {
                        this.$router.push("/")
                    }
                    if (response.data && response.data.status == "SUCCESS") {
                        this.revisionHistory = response.data.revisionHistory;
                        sourceCode = response.data.sourceCode;
                        isValidSource = !this.isEmptyHtml(sourceCode);
                        this.wysiwygBody = sourceCode;
                        this.replaceAIReport = response.data.replaceAIReport
                        if (isValidSource) {
                            this.originHtmlSource = sourceCode;
                            setTimeout(() => {
                                this.needsToSave = false;
                            }, 100);
                            return;
                        }
                    }
                    if (!isValidSource && !this.pageNotBuilt && !this.siloKeywordID) {
                        this.loadFromTargetUrl();
                    } else {
                        this.$store.commit("hideLoading");
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.$store.commit("hideLoading");
                });
        },
        downloadReport(sourceType, reportingFormatType) {
            if (!this.wysiwygBody) return
            downloadEditorReport(this.$store, sourceType, reportingFormatType, this.siloKeywordID, this.secretKey)
        },
        viewDiffForRevision(idx) {
            this.$store.commit("showLoading");
            this.$store
                .dispatch("loadForSpecificRevisionData", {
                    pageRunId: this.currentPageRun.id,
                    revisionIndex: idx,
                    secretKey: this.willUseSecretKey ? this.secretKey : "",
                })
                .then((response) => {
                    if (response.data && response.data.status == "SUCCESS") {
                        this.prevRevision = response.data.prevRevision;
                        this.currentRevision = response.data.currentRevision;
                        this.expandedRevisionIndex = idx;
                    } else {
                        this.$notify({
                            group: "info",
                            type: "error",
                            text: "Failed to load revision.",
                        });
                    }
                })
                .finally(() => {
                    this.$store.commit("hideLoading");
                });
        },
        loadForSpecificRevision(idx) {
            this.$store.commit("showLoading");
            this.$store
                .dispatch("loadForSpecificRevisionData", {
                    pageRunId: this.currentPageRun.id,
                    revisionIndex: idx,
                    currentRevisionOnly: true,
                    secretKey: this.willUseSecretKey ? this.secretKey : "",
                })
                .then((response) => {
                    if (response.data && response.data.status == "SUCCESS") {
                        let currentRevision = response.data.currentRevision;
                        this.originHtmlSource = currentRevision.sourceCode;
                        this.showRevisionHistoryModal = false;
                    } else {
                        this.$notify({
                            group: "info",
                            type: "error",
                            text: "Failed to load revision.",
                        });
                    }
                })
                .finally(() => {
                    this.$store.commit("hideLoading");
                });
        },
        loadPageRun() {
            let data = {};
            if (this.willUseSecretKey) {
                data = {
                    secretKey: this.secretKey,
                };
            } else {
                data = {
                    pageRunId: this.pageRunId,
                };
            }

            this.$store
                .dispatch("fetchPageRunBySecretKeyOrPageRunId", data)
                .then((response) => {
                    if (response.data && response.data.status == "SUCCESS") {
                        this.currentPageRun = response.data.pageRun;
                        this.updateCurrentPage(response.data.page);
                        if (this.currentEditorMode != EDITOR_MODES.AI_MODE) {
                            this.loadSourceCode();
                        }
                        if (response.data.isDarkMode) {
                            this.$vuetify.theme.dark = true;
                        }
                    } else {
                        this.showOopsMessage();
                    }
                })
                .catch((error) => {
                    this.showOopsMessage();
                })
                .finally(() => this.$store.commit("hideLoading"));
        },
        updateCurrentPage(page) {
            this.currentPage = page;
            if (
                this.page &&
                this.page.strategySetting &&
                this.page.strategySetting.dashboardType
            ) {
                this.dispResultType = this.page.strategySetting.dashboardType;
            } else if (this.hasContentBriefData) {
                this.dispResultType = "contentBrief";
            } else {
                this.dispResultType = "signals";
            }
        },
        updateChecklistForAIResult({ aiRunId, htmlStr, updateScore }) {
            this.aiRunId = aiRunId;
            this.aiProducedHTML = htmlStr;
            if (updateScore) {
                this.calculateEditedSource({ html: htmlStr });
            }
        },
        calculateEditedSource(options = {}) {
            let checkHtml = "";
            let { html, needContentSave } = options;
            if (html) {
                checkHtml = html;
            } else {
                if (this.currentEditorMode == this.EDITOR_MODES.AI_MODE) {
                    checkHtml = this.aiProducedHTML;
                } else {
                    checkHtml = this.wysiwygModel;
                }
            }

            if (checkHtml.length > 1000000) {
                this.$notify({
                    group: "info",
                    type: "warning",
                    text: "Your page is too big to proceed in content editor.",
                });
                return;
            }

            let isAIWorkCheck = this.currentEditorMode == this.EDITOR_MODES.AI_MODE;

            let data = {
                pageRunId: this.currentPageRun.id,
                html: checkHtml,
                isAIWorkCheck: isAIWorkCheck,
                aiRunId: this.aiRunId,
                keyword: this.currentPageRun.keyword
                    ? this.currentPageRun.keyword
                    : this.page.keyword,
                variations: this.variationsStringArr,
                lsiPhrases: this.lsiStringArr,
                ignoreSpaces: this.currentPageRun.ignoreSpaces ? 1 : 0,
                willCheckWithGoogleDoc: 0,
                needContentSave: needContentSave,
            };

            this.isCalculating = true;
            if (isAIWorkCheck) {
                this.$store.commit("showLoading");
            }
            this.$store
                .dispatch("getChecklistForExtension", data)
                .then((response) => {
                    if (response.data && response.data.status === "SUCCESS") {
                        this.checklistResult = response.data.result.checklistResult;
                        this.cleanedContentBrief = response.data.result.cleanedContentBrief;
                        this.editedCurrentWordCount =
                            response.data.result.editedCurrentWordCount;
                        this.currentTagCounts = response.data.result.currentTagCounts;
                        this.updateCurrentPage(response.data.result.page);
                        this.errorMsg = "";

                        if (needContentSave) {
                            // needContentSave this will be true only in case you click 'check optimization score' from content editor,   not ai writer, no extension
                            this.needsToSave = false;
                        }

                        let score = this.cleanedContentBrief.pageScore.pageScore;
                        // disabledWellDonePopup
                        if (this.user && !this.userSetting.disabledWellDonePopup) {
                            if (score >= 97) {
                                if (!this.isDemo) {
                                    this.showGreatJobModal = true;
                                    this.showCongratsAnimation = true;
                                    setTimeout(() => {
                                        this.showCongratsAnimation = false;
                                    }, 4000);
                                }
                            } else if (score >= 80) {
                                this.$notify({
                                    group: "info",
                                    type: "success",
                                    title: "Success!",
                                    text: "Your on-page optimization score keeps improving.",
                                    duration: 5000,
                                });
                            }
                        }

                        // this.checkForDisplaySameContentBriefScore()
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isCalculating = false;
                    this.$store.commit("hideLoading");
                });
        },
        toLocalDateString(dateStr) {
            return toLocalDateString(dateStr);
        },
        toLocalDateTimeString(dateStr) {
            return toLocalDateTimeString(dateStr);
        },
        setBallonSignal(signal) {
            this.ballonSignal = signal;
        },
        saveSourceCode(saveInSilence = false) {
            if (!this.needsToSave) {
                return;
            }
            if (!saveInSilence) {
                this.$store.commit("showLoading");
            }
            this.$store
                .dispatch("saveEditedSourceCode", {
                    pageRunId: this.currentPageRun.id,
                    secretKey: this.willUseSecretKey ? this.secretKey : "",
                    sourceCode: this.wysiwygModel,
                    sourceType: this.sourceType,
                })
                .then((response) => {
                    if (response.data && response.data.status == "SUCCESS") {
                        this.needsToSave = false;
                    }
                })
                .finally(() => {
                    if (!saveInSilence) {
                        this.$store.commit("hideLoading");
                    }
                });
        },
        saveOneRevisionHistory() {
            this.$store.commit("showLoading");
            this.$store
                .dispatch("saveRevisionHistoryForContentEditor", {
                    pageRunId: this.currentPageRun.id,
                    secretKey: this.willUseSecretKey ? this.secretKey : "",
                    sourceCode: this.wysiwygModel,
                    sourceType: this.sourceType
                })
                .then((response) => {
                    if (response.data && response.data.status == "SUCCESS") {
                        this.revisionHistory = response.data.revisionHistory;
                    }
                })
                .finally(() => {
                    this.$store.commit("hideLoading");
                });
        },
        checkExtensionIsInstalled() {
            if (
                document.readyState === "interactive" ||
                document.readyState === "complete"
            ) {
                if (
                    document.documentElement.dataset
                        .page_optimizer_browser_extension_version
                ) {
                    this.isAvailableChromeExtension = true;
                    console.log("Adding event listener for POP extension!!");
                    document.addEventListener(
                        "page_optimizer_browser_extension_event",
                        this.extensionEventListener
                    );
                } else {
                    this.isAvailableChromeExtension = false;
                    console.log("can not find extension in this browser");
                }
            }
        },
        dispatch_custom_event: function (name, data) {
            if (!this.isAvailableChromeExtension) {
                return window.alert(
                    "Can not detect extension. Please click 'hard refresh' button for it."
                );
            }

            // the web application should fire this event to trigger the extension
            // the extension will listen for this event

            var event = new CustomEvent("web_app_event", {
                detail: {
                    name: name,
                    data: data,
                },
                bubbles: true,
                cancelable: true,
            });

            document.dispatchEvent(event);
        },
        extensionEventListener(event) {
            var name = event.detail.name;
            var data = event.detail.data;

            if (name === "validate_urls_result") {
                var resData = data.validate_urls_result;
                let { status, total_urls, processed } = resData;

                if (status == "PENDING" || status == "PROGRESS") {
                    // let progress = Math.round(processed * 100 / total_urls)
                    // if (progress < 1) progress = 1
                } else if (status == "FAILURE") {
                    this.$store.commit("hideLoading");
                } else if (status == "SUCCESS") {
                    this.$store.commit("hideLoading");
                    let data = resData.results[0];
                    this.originHtmlSource = data.source;
                }
            }
        },
        getWordCountRecommendation(currentWordCount, goalWordCount) {
            return this.calcWordCountRecommendation(currentWordCount, goalWordCount);
        },
        clearHtml() {
            this.originHtmlSource = "";
            this.showConfirmClearHtmlModal = false;
        },
        downloadHtml() {
            const now = new Date();
            const fileName =
                "EditedCode_" +
                now.getUTCFullYear() +
                "_" +
                (now.getUTCMonth() + 1) +
                "_" +
                now.getUTCDate() +
                ".html";

            this.exportToHtml(this.wysiwygModel, fileName);
        },
        handleResize: _.debounce(function () {
            if (this.$refs.recommendationArea) {
                let width = this.$refs.recommendationArea.clientWidth;
                if (width < 300) {
                    this.isTooSmallWidthForResultArea = true;
                } else {
                    this.isTooSmallWidthForResultArea = false;
                }
            }
        }, 500),
    },
    mounted() {
        let relId = this.$route.query.relId;
        if (relId) {
            this.relId = parseInt(relId);
        }
        if (this.$route.query.siloKeywordId) {
            this.siloKeywordID = this.$route.query.siloKeywordId;
            this.isShowResultPane = false;
            this.sourceType = 1
        }
        if (this.$route.query.pageRunId) {
            this.pageRunId = this.$route.query.pageRunId;
            this.storageName = `${this.pageRunId}`;
            let aiRunId = this.$route.query.aiRunId;

            if (this.$route.query.editorMode) {
                switch (this.$route.query.editorMode) {
                    case "AI_MODE":
                        this.currentEditorMode = EDITOR_MODES.AI_MODE;

                        if (relId) {
                            this.relId = parseInt(relId);
                        }
                        if (aiRunId) {
                            this.aiRunId = parseInt(aiRunId);
                        }
                        break;
                }
            }
        } else if (this.$route.query.secretKey) {
            this.willUseSecretKey = true;
            this.secretKey = this.$route.query.secretKey;
            this.storageName = this.secretKey;
        } else {
            return;
        }

        document.addEventListener("DOMContentLoaded", (event) => {
            //do work
            this.checkExtensionIsInstalled();
        });

        this.loadUser();
    },

    created() {
        this.saveSourceCodeDebounce = _.debounce(this.saveSourceCode, 5000);
    },
    beforeDestroy() {
        if (
            document.documentElement.dataset.page_optimizer_browser_extension_version
        ) {
            document.removeEventListener(
                "page_optimizer_browser_extension_event",
                this.extensionEventListener
            );
        }
    },
};
</script>

<style lang="scss" scoped>
@import  './index.scss';
</style>
