<template>
<div>
  <v-btn
    class="orders-cart-button"
    icon
    small
    @click="$store.commit('setShowSiloContentReportsOrdersCart', true)"
  >
    <i class="material-icons-outlined">shopping_cart</i>
    <span class="orders-cart-count" v-if="!loading && orders?.length">{{ orders.length }}</span>
  </v-btn>

  <div v-if="$store.state.showSiloContentReportsOrdersCart" class="modal is-active is-sticky-right silo-reports-cart-modal">
    <div class="modal-background" @click.stop="$store.commit('setShowSiloContentReportsOrdersCart', false)"></div>
    <div class="modal-content" style="width: 687px; max-width: 95vw;">
      <div class="box modal-container  mainColor--text">
        <div class="d-flex pad25">
          <div class="modal-title">Your cart</div>
        </div>

        <div class="main-part">
          <template v-if="!loading">
            <template v-if="orders.length">
              <div class="orders-list">
                <div v-for="order in sortedOrders" :key="order.id" class="order mb-4">
                  <div class="order-summary">
                    <div class="mb-2"><span class="font-weight-bold" style="font-size: 1.1rem;">Silo: {{ order.silo.name }}</span></div>
                    <div style="font-size: 0.9rem;">
                      {{ (order.selected_top_level_keyword_id?1:0) + order.selected_supporting_keyword_ids.length }} silo reports ({{ order.delivery_period_days }} days delivery)
                    </div>
                  </div>
                  <div class="order-controls">
                    <div><span class="font-weight-bold" style="font-size: 1.1rem;">{{ formatCurrency(getOrderPrice(order)) }} USD</span></div>
                    <div>
                      <v-btn
                        icon
                        small
                        color="mainColor"
                        @click="updateOrder(order, {set_user_editing: true})"
                      >
                        <i class="material-icons-outlined" style="font-size: 20px;">delete</i>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-controls">
                <div v-if="isRecurringApplicable">
                  <div class="mb-4"><span class="font-weight-bold">Select payment frequency</span></div>
                  <div class="payment-frequency-options mb-6">
                    <b-radio
                      class="custom-radio-color"
                      v-model="payment_frequency"
                      name="payment_frequency"
                      native-value="one_time"
                      type="is-danger"
                    >One-time payment</b-radio>
                    <b-radio
                      class="custom-radio-color"
                      v-model="payment_frequency"
                      name="payment_frequency"
                      native-value="recurring"
                      type="is-danger"
                    >Recurring payment</b-radio>
                  </div>
                </div>
                <div class="total-price mb-6">
                  <div class="price-line mb-4">
                    <div><span class="font-weight-bold">Total amount</span></div>
                    <div><span class="font-weight-bold">{{ formatCurrency(this.getTotalPrice()) }} USD</span></div>
                  </div>
                  <div v-if="payment_frequency == 'recurring'" class="price-line">
                    <div><span class="font-weight-bold">Due today</span></div>
                    <div><span class="font-weight-bold">{{ formatCurrency(this.getDueTodayOrders()) }} USD</span></div>
                  </div>
                </div>
                <v-btn
                  rounded
                  block
                  color="profileAnchorColor"
                  class="text-none whiteColor--text"
                  @click="checkout()"
                >
                  <span>Go to checkout</span>
                </v-btn>
              </div>
            </template>
            <template v-else>
              <div class="empty-cart">
                <div class="empty-cart-main">
                  <h3 class="mb-3">Your cart is empty</h3>
                  <p>As a white glove POP user, you can order content silo reports for our team to complete.</p>
                </div>
                <div v-if="siloDashboardUrl" class="empty-cart-form-controls">
                  <v-btn
                    rounded
                    block
                    large
                    color="profileAnchorColor"
                    class="text-none whiteColor--text"
                    @click="$router.push(siloDashboardUrl); $store.commit('setShowSiloContentReportsOrdersCart', false)"
                  >
                    <span>Select silos</span>
                  </v-btn>

                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="loading-cart">
              <div>
                <b-icon
                  pack="fa"
                  icon="spinner"
                  custom-class="fa-spin">
                </b-icon>
              </div>
            </div>
          </template>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="$store.commit('setShowSiloContentReportsOrdersCart', false)"><i class="material-icons" style="font-size: 1.7rem;">close</i></button>
    </div>
  </div>

</div>
</template>

<script>
import { sortBy } from 'lodash'
import { getPureServicePkgName, formatCurrency } from '@/utils';
import {
  whiteGlovePlans,
  ALLOWED_SILO_ORDER_DELIVERY_PERIODS,
  SILO_ORDER_SPEEDY_DELIVERY_PERIOD,
  SILO_ORDER_SPEEDY_DELIVERY_MULTIPLIER
} from "@/components/plans_constants"


export default {
  props: [],
  components: {

  },
  data() {
    return {
      loading: false,
      packages: [],
      orders: [],
      payment_frequency: 'one_time',
    };
  },
  watch: {
    '$store.state.showSiloContentReportsOrdersCart': function (newVal, oldVal) {
      if (oldVal !== newVal && newVal) {
        this.loadData();
      }
    },
    '$store.state.refreshSiloContentReportsOrdersCart': function () {
      this.loadData();
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    AVAILAIBLE_TOP_LEVEL_SILO_REPORTS() {
      return this.packages.filter(p => p.type === 'top_level');
    },
    AVAILAIBLE_SUPPORTING_SILO_REPORTS() {
      return this.packages.filter(p => p.type === 'supporting');
    },
    sortedOrders() {
      return sortBy(this.orders, 'delivery_period_days');
    },
    siloDashboardUrl () {
      const { siteId, campaignId } = this.$route.params
      if (campaignId && siteId) {
        return `/sites/${siteId}/campaign/${campaignId}/silo`
      }
      return null
    },
    isRecurringApplicable() {
      return Boolean(this.orders.filter(order => [60, 90].includes(order.delivery_period_days)).length)
    }
  },
  methods: {
    formatCurrency: formatCurrency,
    getSiloUrl(siloKeyword) {
      return siloKeyword.keywordData.page || siloKeyword.keywordData.currentRankingUrl;
    },
    getSelectedTopLevelOption(order) {
      return this.AVAILAIBLE_TOP_LEVEL_SILO_REPORTS.filter(p => p.key === order.selected_top_level_option_key)[0];
    },
    getSelectedSupportingOption(order) {
      return this.AVAILAIBLE_SUPPORTING_SILO_REPORTS.filter(p => p.key === order.selected_supporting_option_key)[0];
    },
    getOrderPrice(order) {
      let top_level = 0
      let supporting = 0
      let speedyDelivery = 0
      if (order.selected_top_level_keyword_id) {
        top_level = this.getSelectedTopLevelOption(order).price
      }
      if (order.selected_supporting_keyword_ids.length) {
        supporting = this.getSelectedSupportingOption(order).price * order.selected_supporting_keyword_ids.length
      }
      if (order.delivery_period_days === SILO_ORDER_SPEEDY_DELIVERY_PERIOD) {
        speedyDelivery = (top_level + supporting) * SILO_ORDER_SPEEDY_DELIVERY_MULTIPLIER
      }
      return top_level + supporting + speedyDelivery;
    },
    getTotalPrice() {
      return this.orders.map(order => this.getOrderPrice(order)).reduce((a, b) => a + b)
    },
    getDueTodayOrders() {
      const firstPeriodOrders = this.orders.filter(order => order.delivery_period_days === ALLOWED_SILO_ORDER_DELIVERY_PERIODS[0])

      if (!firstPeriodOrders.length) {
        return 0
      }

      return firstPeriodOrders.map(order => this.getOrderPrice(order)).reduce((a, b) => a + b)
    },
    updateOrder(order, payload) {
      this.$store.commit('showLoading');
      this.$store.dispatch('updateSiloContentReportOrder', {
        siloId: order.silo.id,
        body: payload
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          return this.loadOrders()
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
    loadOrders() {
      return this.$store.dispatch('getSiloContentReportOrders', {in_cart_only: 1})
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.orders = response.data.data;
          return Promise.resolve()
        }
      })
    },
    checkout() {
      this.$store.commit('showLoading');
      this.$store.dispatch('createStripePayment', {
        packageType: 'SILO_CONTENT_REPORTS_PURCHASE',
        userID: this.user.id,
        isCartCheckout: true,
        cartCheckoutType: this.payment_frequency,
      })
      .then((response) => {
        if (response?.data?.url) {
          window.open(response.data.url, '_self')
        } else if (response?.data?.no_immediately_due_items) {
          return this.loadOrders()
            .then(() => this.$store.commit('setShowSiloContentReportsOrdersCart', false))
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
    loadData () {
      this.loading = true;
      this.$store.dispatch('getAvailableSiloContentReportPackages')
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.packages = response.data.packages;
          return Promise.resolve()
        }
      })
      .then(() => this.loadOrders())
      .finally(() => {
        this.loading = false;
      })
    }
  },
  mounted () {
    this.loadData()
  }
};
</script>

<style lang="scss" scoped>
.orders-cart-button {
  position: relative;

  .orders-cart-count {
    position: absolute;
    display: inline-block;
    top: -5px;
    right: -5px;
    background: #FF3232;
    color: #fff;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 16px;
    font-size: 10px;
    font-weight: 700;
  }
}

.silo-reports-cart-modal {
  
  overflow: hidden;

  color: var(--v-mainColor-base);

  * {
    font-size: 1rem;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  
    .modal-title {
      font-size: 1.3rem;
      line-height: 1.7rem;
    }
  }
  
  .main-part {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  .orders-list {
    flex: 1;
    padding: 0 20px 20px;
    overflow-y: auto;

    .order {
      display: flex;
      flex-direction: row;
      gap: 8px;
      padding: 20px;
      border: 1px solid #CCCCCC;
      border-radius: 8px;

      .order-summary {
        flex: 1;
      }

      .order-controls {
        text-align: right;
      }
    }
  }

  .form-controls {
    padding: 20px;
    border-top: 1px solid #CCCCCC;

    .payment-frequency-options {
      display: flex;
      flex-direction: row;
      gap: 12px;

      .b-radio.radio {
        flex: 1;
        border: 1px solid #CCCCCC;
        border-radius: 8px;
        padding: 12px;
        font-weight: bold;
      }
    }

    .price-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 12px;
    }

    button {
      height: 50px;
      border-radius: 50px;

      .v-btn__content span {
        font-size: 18px;
        font-weight: 700;
        line-height: 32.63px;
      }
    }
  }

}

.b-radio.custom-radio-color, .b-radio.custom-radio-color:hover {
  input[type="radio"]:checked, input[type="radio"]:hover {
    &+ .check {
      border-color: #FF5700 !important;
      &::before {
        background-color: #FF5700 !important;
      }
    }
  }
}

.empty-cart {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: var(--v-mainColor-base);

  .empty-cart-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    text-align: center;
    padding: 60px 70px 80px;

    h3 {
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 1.7rem;
    }

    p {
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
  }

  .empty-cart-form-controls {
    padding: 20px;
    border-top: 1px solid #CCCCCC;

    button {
      height: 50px;
      border-radius: 50px;

      .v-btn__content span {
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 1.5rem;
      }
    }
  }
}

.loading-cart {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .div {
    text-align: center;
  }
}
</style>
