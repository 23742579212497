<template>
    <div>
      <v-card class="settings-area white-common-box">
        <div class="font-weight-bold mb-6 d-flex align-center" style="font-size: 1rem;">
          Select how you want to refine and optimize your search engine title

          <v-tooltip bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <i
                class="material-icons info-icon-size ml-2 gray9Color--text"
                v-bind="attrs"
                v-on="on"
                >info</i
              >
            </template>
            <div class="white-bg-tooltip small-tooltip">
              If your current Search Engine Title is already optimized,
              you can keep your title as is. You can manually write in
              your own title by clicking "Edit" or you can have AI
              generate your title by clicking "Generate." You can also
              edit the title that AI generates. Once you are happy with
              your title, click "Next" advance to the H1 section.
            </div>
          </v-tooltip>
        </div>
        
        <div
          class="pa-5 settings-option-box"
        >
          <div class="section-label d-flex align-center gap15">
            <div
              :class="{
                'section-selector': true,
                'section-selector-active': !useMyCurrentTitle,
                'section-selector-disabled': isLoading || isDemo,
              }"
              @click="!(isLoading || isDemo) ? $emit('update:useMyCurrentTitle', !useMyCurrentTitle) : undefined"
            ></div>
            I want to generate new or rewrite my search engine title
          </div>
          
          <template v-if="!useMyCurrentTitle">
            <div class="ml-9">
              <div class="mainColor3--text mt-3 mb-3">
                You can select both options below at the same time.
              </div>

              <div class="d-flex align-center gap15">
                <vue-toggles
                  :value="optimizeCurrentTitle"
                  width="40"
                  height="20"
                  :disabled="pageNotBuilt || isLoading"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="$emit('update:optimizeCurrentTitle', !optimizeCurrentTitle)"
                ></vue-toggles>
                <div class="flex-grow-1 mainColor3--text font-weight-bold d-flex align-center" :style="pageNotBuilt?'opacity: 0.7;':''">
                  Use my search engine title for AI inspiration
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 gray9Color--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      Select this option to let AI refine and rewrite your
                      search title, aligning them with the POP
                      recommendations.
                    </div>
                  </v-tooltip>
                </div>
              </div>
              <div class="d-flex align-center gap15 mt-2">
                <vue-toggles
                  :value="optimizeCompetitorTitle"
                  width="40"
                  height="20"
                  :disabled="isLoading"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="$emit('update:optimizeCompetitorTitle', !optimizeCompetitorTitle)"
                ></vue-toggles>
                <div class="flex-grow-1 mainColor3--text font-weight-bold d-flex align-center">
                  Use my competitors' search title for AI inspiration
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 gray9Color--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip" style="max-width: 500px">
                      By selecting this option, the AI will utilize your
                      leading competitors' search title to generate and
                      optimize a fresh set of search title subheadings for
                      your page. This is an ideal choice if your current page
                      lacks optimized subheadings or if you're looking to
                      restructure your content.
                    </div>
                  </v-tooltip>
                </div>
              </div>
              <div class="d-flex align-center gap15 mt-2">
                <vue-toggles
                  :value="useTitleInspirationText"
                  width="40"
                  height="20"
                  :disabled="isLoading"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="$emit('update:useTitleInspirationText', !useTitleInspirationText)"
                >
                </vue-toggles>
                <div class="flex-grow-1 mainColor3--text font-weight-bold d-flex align-center">
                  Give the AI an example search engine title for inspiration to write your title
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 gray9Color--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</i
                      >
                    </template>
                    <div
                      class="white-bg-tooltip"
                      style="max-width: 500px"
                    >
                      Enter a sample title that reflects the AI topic you're
                      interested in. This will serve as inspiration for
                      generating a more creative and engaging headline for
                      your article, blog post, or page.
                    </div>
                  </v-tooltip>
                </div>
              </div>
              <div class="mt-5">
                <template
                  v-if="!useMyCurrentTitle && useTitleInspirationText"
                >
                  <div class="font-weight-bold mainColor3--text mt-3">
                    Type an example to inspire the AI
                  </div>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    color="greenColor"
                    class="mainColor3--text mt-2"
                    :value="titleInspirationText"
                    @input="$emit('update:titleInspirationText', $event)"
                    label="Give the AI an example title for inspiration to write your title"
                  >
                  </v-text-field>
                </template>
              </div>
            </div>

            <div class="mt-5" v-if="!useMyCurrentTitle">
              <v-btn
                color="profileAnchorColor"
                style="border-radius: 8px;"
                class="text-none whiteColor--text font-weight-bold"
                :class="{
                  'pulsing-yellow-button': needsPulsing(
                    STEPS.SEARCH_ENGINE_TITLE
                  ),
                }"
                :disabled="
                  isLoading ||
                  (!useMyCurrentTitle &&
                    !optimizeCurrentTitle &&
                    !optimizeCompetitorTitle &&
                    !useTitleInspirationText) ||
                  (useTitleInspirationText && !titleInspirationText)
                "
                @click="
                  () => {
                    showWarningModalForGenerate('title', () =>
                      generateResultFor('title')
                    );
                  }
                "
              >
                Generate
              </v-btn>
            </div>
          </template>
        </div>
        <div
          v-if="!pageNotBuilt"
          class="mt-5 pa-5 settings-option-box"
        >
          <div class="section-label d-flex align-center gap15">
            <div
              :class="{
                'section-selector': true,
                'section-selector-active': useMyCurrentTitle,
                'section-selector-disabled': isLoading || isDemo,
              }"
              @click="!(isLoading || isDemo) ? $emit('update:useMyCurrentTitle', !useMyCurrentTitle) : undefined"
            ></div>
            I don't want to generate new optimized search engine title with AI (leave as is)
            <v-tooltip bottom color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <i
                  class="material-icons info-icon-size ml-2 gray9Color--text"
                  v-bind="attrs"
                  v-on="on"
                  >info</i
                >
              </template>
              <div class="white-bg-tooltip small-tooltip">
                If the current Search engine Title on your page is already
                optimized, we recommend using it as is. Check this box if
                you prefer not to make any changes to your existing
                titles.
              </div>
            </v-tooltip>
          </div>
          <div class="mt-5" v-if="useMyCurrentTitle">
            <div class="ml-9">
              <div class="d-flex align-center mb-3  mainColor3--text">
                Your search engine title is already optimized and you don’t want to change it.
              </div>
              <div class="d-flex align-center mb-5  mainColor3--text">
                POP will visit your page and extract your search engine title as is— an ideal option if it's already optimized.
              </div>
            </div>
            
            <v-btn
              color="profileAnchorColor"
              style="border-radius: 8px;"
              class="text-none whiteColor--text font-weight-bold"
              :class="{
                'pulsing-yellow-button': needsPulsing(
                  STEPS.SEARCH_ENGINE_TITLE
                ),
              }"
              :disabled="isLoading"
              @click="
                () => {
                  showWarningModalForGenerate('title', () =>
                    generateResultFor('title')
                  );
                }
              "
            >
              <img
                v-if="!useMyCurrentTitle && titleAIResult"
                src="/static/ai-writer-refresh.png"
                width="19px"
                style="margin-right: 1rem"
              />
              Extract my search engine title
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'Step4SearchPageTitle',
    props: [
        'goNext',
        'showWarningModalForGenerate',
        'generateResultFor',
        'needsPulsing',

        'pageNotBuilt',
        'runningSection',
        'isLoading',
        'isDemo',
        'progress',
        'optimizeCurrentTitle',
        'optimizeCompetitorTitle',
        'useMyCurrentTitle',
        'useTitleInspirationText',
        'titleInspirationText',
        'titleAIResult',
    ],
    computed: {
        STEPS() {
            return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
