<template>
  <div class="modal" :class="{'is-active': showNow && !$store.getters.isDemoUser }">
      <div class="modal-background"></div>
      <div class="modal-content" style="min-width: 60vw;">
        <div class="box modal-container" style="overflow: hidden;">
          <div class="modal-content-root">
            <div class="mb20">
              <div>
                <div class="title-font is-size-4 mainColor--text">Best Practices</div>
              </div>
              <div class="best-practice-list mt10 is-size-5 mainColor--text">
                <ol>
                  <li>We strongly recommend using the Content Brief.  The Content Brief makes difficult SEO judgement calls for you on which important terms to use and where to use them.  Advanced Strategies are for those that are comfortable making judgement calls on their own for particular SEO situations, strategies, and approaches.</li>
                  <li>The Optimization Score for the Content Brief is based on getting all the Content Brief sections done correctly.  The best practice is to get to at least 75-80% to start.  Watch how your page performs, and then eventually get to 90% or more.  It is usually possible and desirable to get to 100%.</li>
                  <li>The Advanced Strategies Optimization Score is based on what your competitors have done for your chosen strategy and approach.  It is not always possible or wise to get to 100% as your competitors may have made some mistakes and you'll need to make judgement calls.  Often even just getting a score of 60% can bring SEO improvement.  Most Advanced Strategies users get good ranking improvement somewhere between 70-80%.  If you are unsure on what approach or strategy to take, we strongly recommend using the Content Brief.  The Content Brief takes out the guesswork.</li>
                  <li>Do not blindly follow any recommendations. Look at your competitors for why the tool is making the recommendation and make informed decisions for your on page optimization.</li>
                  <li>If you are already ranking well, be smart about your changes. Only change a couple of things at a time.</li>
                  <li>If you have an established page we do not recommend changing your URL to include your primary keyword. If you change a URL you can lose established rank. Only change URLs on very new pages.</li>
                  <li>Page structure suggestions are not part of the on page score. They are there to help you build a page that can rank well, but may not be direct ranking factors.</li>
                  <li>You will often see movement in a matter of days. That said, it is wise to wait 10-21 days in between groups of tweaks to measure positive or negative movement.</li>
                  <li>The tool does not do any caching of web pages. Every time the tool runs it does a fresh crawl of your page. If you made changes and the tool isn't picking up the changes, it's likely that your hosting or CDN is caching your pages.</li>
                  <li>Choosing the correct competitors to use in the tool is critically important. POP does a very good job of choosing your competitors for the best chance at success.  If you decide to choose your own competitors make sure you are comfortable with how to select your competitors.  If you are unsure on what to do, watch the video on how to choose competitors.</li>
                  <li>If using Advance Strategies, first work on the signals where you need to Increase usage. Do not Decrease any signals until you have finished Increasing. See how your page responds to the increase before decreasing anything in any signal.</li>
                  <li>If using Advanced Strategies, start with the Exact Match recommendations and work your way towards the recommendations on Variations and Contextual Terms.</li>
                  <li>If using Advanced Strategies, the signals are listed in order of importance from top to bottom.</li>
                  <li>If using Advanced Strategies, if your current word count is less than the target, the best practice is to increase it.</li>
                  <li>If using Advanced Strategies, if your word count is over the target, the best practice is to start with adjusted recommendations. Follow the best practices on time and see if you get movement. Once you have exhausted the adjusted recommendations and aren’t getting movement, then consider reducing word count.</li>
                  <li>If you didn't understand the best practices for the Advanced Strategies mentioned above, use the Content Brief.</li>
                  <li>We have comprehensive FAQs, instructional videos, and a free course on SEO. Please take advantage of these resources.  You will become a better SEO.</li>
                </ol>
              </div>
            </div>
            <div class="control flex mt20">
              <div class="field">
                <div class="control">
                  <label class="checkbox mainColor--text">
                    <input type="checkbox" v-model='acknowledged'>
                    I acknowledge that I have read the above
                  </label>
                </div>
                <div class="control">
                  <label class="checkbox mainColor--text">
                    <input type="checkbox" v-model='dontShowAgain'>
                    Do not show again as a pop up (you can access these suggestions by clicking the blue button with the question mark)
                  </label>
                </div>
              </div>
              <div class="flex-grow-1">
              </div>
              <v-btn rounded small
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                :disabled="!acknowledged" @click.stop="submit">
                <i class="fa fa-book mr5"></i>
                <span>Submit</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      acknowledged: false,
      dontShowAgain: false,
      showNow: false
    }
  },
  methods: {
    submit () {
      if (!this.acknowledged) {  return; }
      const settings = Object.assign({}, this.user.settings)
      settings.showAnalysisBestPractices = this.dontShowAgain === false
      if (!settings.bestPracticesAcknowledged) {
        settings.bestPracticesAcknowledged = new Date().valueOf()
      }
      this.$store.dispatch('updateUserSettings', settings)
        .then((response) => this.$store.commit('setUserSettings', response.data))
        .finally(() => this.showNow = false)
    },
    show () {
      this.showNow = true
    },
    checkAutoDisplay () {
      this.showNow = this.showPageRunBestPractices
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    showPageRunBestPractices () {
      if (!this.user) {
        return true
      } else if (!this.user.settings) {
        return true
      }
      if (this.user.settings.showAnalysisBestPractices == null) {
        return true
      }
      return this.user.settings.showAnalysisBestPractices
    }
  },
  mounted () {
    // this.showNow = this.showPageRunBestPractices
  }
}
</script>

<style lang="scss" scoped>
  .h-padding {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .best-practice-list {
    max-height: 60vh;
    overflow-y: auto;
    list-style-position: inside;
    border: 1px solid #DDD;
    border-radius: 5px;
    ol {
      li {
        padding: 0.7rem;
      }

      li:nth-child(odd) {
        background: var(--v-grayColor-base);
        /* background: #EEE; */
      }
    }
  }
</style>