<template>
  <div class="ai-stepper" v-if="currentStep > STEPS.START">

    <div class="step" v-for="(step, idx) in availableSteps" :key="step" :class="{ 'succeed': step <= currentStep }">
      <div class="spot"
        :class="getSpotClasses(step)"
        @click="() => {
          if (currentStep == STEPS.PARAGRAPH) return;
          $emit('goTo', step)
        }">
        <template v-if="step < currentStep || (currentStep == STEPS.PARAGRAPH && ($store.getters.isDemoUser ? $store.state.isDemoMainContentDone : hasParagraphResult))">
          <span class="material-icons">done</span>
        </template>
        <template v-else>
          {{ idx + 1 }}
        </template>
        <!-- #{{ step }}# -->
      </div>
      <div
        class="spot-label"
        @click="() => {
          if (currentStep == STEPS.PARAGRAPH) return;
          $emit('goTo', step)
        }"
      >{{ STPES_LABEL[step] }}</div>
      <i v-if="idx < availableSteps.length - 1" class="material-icons-outlined">keyboard_arrow_right</i>
    </div>


  </div>

</template>

<script>
import { STEPS, STPES_LABEL } from '@/components/FullPageSourceEditorV2/components/AiWriter/constants.js';


export default {
  name: 'StepsIndicator',
  props: ['currentStep', 'hasParagraphResult', 'availableSteps'],
  data () {
    return {
      STEPS: STEPS,
      STPES_LABEL: STPES_LABEL,
    }
  },
  methods: {
    getSpotClasses (step) {
      if (step == this.currentStep) {
        if (this.currentStep == this.STEPS.PARAGRAPH) {
          return {
            'succeed': this.$store.getters.isDemoUser ? this.$store.state.isDemoMainContentDone : this.hasParagraphResult,
            'current': this.$store.getters.isDemoUser ? !this.$store.state.isDemoMainContentDone : !this.hasParagraphResult
          }
        }
      }

      return {
        'succeed': step < this.currentStep,
        'current': step == this.currentStep
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ai-stepper {
  padding: 20px;
  border-bottom: 1px solid var(--v-grayColor-base);
  background: var(--v-whiteColor-base);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  justify-content: center;
}
.step {
  display: flex;
  flex-direction: row;
  gap: 12px;

  .spot {
    // background: red;
    cursor: pointer;
    border: 1px solid var(--v-grayColor-base);
    width: 26px;
    height: 26px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: var(--v-grayColor-base);


    &.succeed {
      background: var(--v-green7Color-base);
      border: none;
    }

    &.current {
      border: 1px solid var(--v-profileAnchorColor-base);
      color: var(--v-profileAnchorColor-base);
    }

    .material-icons {
      font-size: 20px;
      color: var(--v-whiteColor-base);
    }

  }

  .spot-label {
    font-size: 14px;
    color: var(--v-mainColor3-base);
    line-height: 1.6;
    cursor: pointer;
  }

}
</style>