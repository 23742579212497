<template>
  <div
    v-if="!isLoading"
  >
    <b-collapse
      class="configs-collapsible card"
      animation="slide"
      v-for="(item, index) in configComponents"
      :key="`Config${item.key}`"
      :open="isOpen === index"
      @open="isOpen = index"
    >
      <template #trigger="props">
          <div
              class="card-header"
              role="button"
              :aria-controls="'contentIdForA11y5-' + index"
              :aria-expanded="props.open"
          >
              <div class="card-header-title">
                  <div class="config-left">
                    <div :class="{
                      'config-status': true,
                      [`is-${item.status}`]: true,
                    }">
                      <i v-if="item.status === 'selected'" class="material-icons-outlined">done</i>
                      <i v-else-if="item.status === 'error'"  class="material-icons-outlined">priority_high</i>
                    </div>
                    <span>{{ item.label }}</span>
                    <span v-if="item.required" class="required-label">
                      Required
                    </span>
                  </div>

                  <component
                    :is="`ConfigPreview${item.key}`"

                    :validations="validations"
                    
                    :isDemo="isDemo"
                    :isFailedPageRun="isFailedPageRun"
                    :page="page"
                    :isUsingOwnApiKey="isUsingOwnApiKey"
                    :user="user"
                    :popCreditCharges="popCreditCharges"

                    :WORDCOUNT_OPTIONS="WORDCOUNT_OPTIONS"
                    :currentWordCount="currentWordCount"
                    :targetWordCount="targetWordCount"

                    :selectedWordCountOption="selectedWordCountOption"
                    @update:selectedWordCountOption="$emit('update:selectedWordCountOption', $event)"

                    :numberOfWords="numberOfWords"
                    @update:numberOfWords="$emit('update:numberOfWords', $event)"

                    :competitorWordCount="competitorWordCount"
                    :selectedCompetitors="selectedCompetitors"
                    @update:selectedCompetitors="$emit('update:selectedCompetitors', $event)"

                    :setDefaultRichContentFroWebPageType="setDefaultRichContentFroWebPageType"
                    :webPageTypeOptions="webPageTypeOptions"
                    :webPageType="webPageType"
                    @update:webPageType="$emit('update:webPageType', $event)"

                    :productDescription="productDescription"
                    :targetAudience="targetAudience"
                    :sampleContent="sampleContent"
                    :toneOfContent="toneOfContent"
                    :author="author"
                    :brand="brand"
                    :excludeTerms="excludeTerms"
                    :useMyCurrentTitle="useMyCurrentTitle"
                    :separateSearchEngineTitleAndTitle="separateSearchEngineTitleAndTitle"

                    :richContentItemsForAIWriter="richContentItemsForAIWriter"
                    :CONTENT_PROMPT_SIGNALS_TOOLTIPS="CONTENT_PROMPT_SIGNALS_TOOLTIPS"
                    :costedContentPromptSignals="costedContentPromptSignals"
                    :getCostForContentPromptSignal="getCostForContentPromptSignal"
                    :isValidInternalLink="isValidInternalLink"

                    :selectedContentPromptsSignals="selectedContentPromptsSignals"
                    :selectedCountForPromptSignals="selectedCountForPromptSignals"
                    :topLevelInternalLink="topLevelInternalLink"
                    :supportingLevelInternalLink1="supportingLevelInternalLink1"
                    :supportingLevelInternalLink2="supportingLevelInternalLink2"
                  />

              </div>
              <a class="card-header-icon">
                <i class="material-icons-outlined">{{ props.open ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</i>
              </a>
          </div>
      </template>
      <div class="card-content">
          <div class="content">
              <component
                :is="`Config${item.key}`"

                :validations="validations"
                
                :isDemo="isDemo"
                :isFailedPageRun="isFailedPageRun"
                :pageRun="pageRun"
                :page="page"
                :countWords="countWords"
                :isUsingOwnApiKey="isUsingOwnApiKey"
                :user="user"
                :popCreditCharges="popCreditCharges"

                :WORDCOUNT_OPTIONS="WORDCOUNT_OPTIONS"
                :currentWordCount="currentWordCount"
                :targetWordCount="targetWordCount"

                :selectedWordCountOption="selectedWordCountOption"
                @update:selectedWordCountOption="$emit('update:selectedWordCountOption', $event)"

                :numberOfWords="numberOfWords"
                @update:numberOfWords="$emit('update:numberOfWords', $event)"

                :competitorWordCount="competitorWordCount"
                :selectedCompetitors="selectedCompetitors"
                @update:selectedCompetitors="$emit('update:selectedCompetitors', $event)"

                :setDefaultRichContentFroWebPageType="setDefaultRichContentFroWebPageType"
                :webPageTypeOptions="webPageTypeOptions"
                :webPageType="webPageType"
                @update:webPageType="$emit('update:webPageType', $event)"

                :productDescription="productDescription"
                @update:productDescription="$emit('update:productDescription', $event)"

                :targetAudience="targetAudience"
                @update:targetAudience="$emit('update:targetAudience', $event)"

                :sampleContent="sampleContent"
                @update:sampleContent="$emit('update:sampleContent', $event)"

                :toneOfContent="toneOfContent"
                @update:toneOfContent="$emit('update:toneOfContent', $event)"

                :author="author"
                @update:author="$emit('update:author', $event)"

                :brand="brand"
                @update:brand="$emit('update:brand', $event)"

                :addNewExcludeTerm="addNewExcludeTerm"
                :handleRemoveExcludeTerm="handleRemoveExcludeTerm"
                :excludeTerms="excludeTerms"
                @update:excludeTerms="$emit('update:excludeTerms', $event)"

                :useMyCurrentTitle="useMyCurrentTitle"
                :separateSearchEngineTitleAndTitle="separateSearchEngineTitleAndTitle"
                @update:useMyCurrentTitle="$emit('update:useMyCurrentTitle', $event)"
                @update:separateSearchEngineTitleAndTitle="$emit('update:separateSearchEngineTitleAndTitle', $event)"

                :richContentItemsForAIWriter="richContentItemsForAIWriter"
                :CONTENT_PROMPT_SIGNALS_TOOLTIPS="CONTENT_PROMPT_SIGNALS_TOOLTIPS"
                :costedContentPromptSignals="costedContentPromptSignals"
                :getCostForContentPromptSignal="getCostForContentPromptSignal"
                :isValidInternalLink="isValidInternalLink"

                :selectedContentPromptsSignals="selectedContentPromptsSignals"
                @update:selectedContentPromptsSignals="$emit('update:selectedContentPromptsSignals', $event)"
                :selectedCountForPromptSignals="selectedCountForPromptSignals"
                @update:selectedCountForPromptSignals="$emit('update:selectedCountForPromptSignals', $event)"
                :topLevelInternalLink="topLevelInternalLink"
                @update:topLevelInternalLink="$emit('update:topLevelInternalLink', $event)"
                :supportingLevelInternalLink1="supportingLevelInternalLink1"
                @update:supportingLevelInternalLink1="$emit('update:supportingLevelInternalLink1', $event)"
                :supportingLevelInternalLink2="supportingLevelInternalLink2"
                @update:supportingLevelInternalLink2="$emit('update:supportingLevelInternalLink2', $event)"
              />
          </div>
      </div>
    </b-collapse>
    
  </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

import ConfigWordCount from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/word-count/ConfigWordCount.vue";
import ConfigPreviewWordCount from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/word-count/ConfigPreviewWordCount.vue";

import ConfigCompetitors from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/competitors/ConfigCompetitors.vue";
import ConfigPreviewCompetitors from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/competitors/ConfigPreviewCompetitors.vue";

import ConfigWebPageType from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/web-page-type/ConfigWebPageType.vue";
import ConfigPreviewWebPageType from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/web-page-type/ConfigPreviewWebPageType.vue";

import ConfigProductDescription from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/product-description/ConfigProductDescription.vue";
import ConfigPreviewProductDescription from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/product-description/ConfigPreviewProductDescription.vue";

import ConfigTargetAudience from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/target-audience/ConfigTargetAudience.vue";
import ConfigPreviewTargetAudience from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/target-audience/ConfigPreviewTargetAudience.vue";

import ConfigTone from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/tone/ConfigTone.vue";
import ConfigPreviewTone from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/tone/ConfigPreviewTone.vue";

import ConfigExcludeTerms from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/exclude-terms/ConfigExcludeTerms.vue";
import ConfigPreviewExcludeTerms from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/exclude-terms/ConfigPreviewExcludeTerms.vue";

import ConfigSearchPageTitle from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/search-page-title/ConfigSearchPageTitle.vue";
import ConfigPreviewSearchPageTitle from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/search-page-title/ConfigPreviewSearchPageTitle.vue";

import ConfigRichContent from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/rich-content/ConfigRichContent.vue";
import ConfigPreviewRichContent from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/rich-content/ConfigPreviewRichContent.vue";

import ConfigApiKey from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/api-key/ConfigApiKey.vue";
import ConfigPreviewApiKey from "@/components/FullPageSourceEditorV2/components/AiWriter/steps/Step1Configs/configs/api-key/ConfigPreviewApiKey.vue";


const configComponents = [
  {
    label: 'Word count',
    key: 'WordCount',
    component: ConfigWordCount,
    previewComponent: ConfigPreviewWordCount,
    required: true,
  },
  {
    label: 'Competitors',
    key: 'Competitors',
    component: ConfigCompetitors,
    previewComponent: ConfigPreviewCompetitors,
    required: true,
  },
  {
    label: 'Web page type',
    key: 'WebPageType',
    component: ConfigWebPageType,
    previewComponent: ConfigPreviewWebPageType,
    required: true,
  },
  {
    label: 'API Key',
    key: 'ApiKey',
    component: ConfigApiKey,
    previewComponent: ConfigPreviewApiKey,
  },
  {
    label: 'Rich content',
    key: 'RichContent',
    component: ConfigRichContent,
    previewComponent: ConfigPreviewRichContent,
  },
  {
    label: 'Product description',
    key: 'ProductDescription',
    component: ConfigProductDescription,
    previewComponent: ConfigPreviewProductDescription,
  },
  {
    label: 'Target audience',
    key: 'TargetAudience',
    component: ConfigTargetAudience,
    previewComponent: ConfigPreviewTargetAudience,
  },
  {
    label: 'Tone of voice',
    key: 'Tone',
    component: ConfigTone,
    previewComponent: ConfigPreviewTone,
  },
  {
    label: 'Exclude specific words',
    key: 'ExcludeTerms',
    component: ConfigExcludeTerms,
    previewComponent: ConfigPreviewExcludeTerms,
  },
  {
    label: 'Search and page titles',
    key: 'SearchPageTitle',
    component: ConfigSearchPageTitle,
    previewComponent: ConfigPreviewSearchPageTitle,
  }
]

export default {
    name: 'Step1Configs',
    components: {
      ...Object.fromEntries(configComponents.map(item => [`Config${item.key}`, item.component])),
      ...Object.fromEntries(configComponents.map(item => [`ConfigPreview${item.key}`, item.previewComponent]))
    },
    props: [
        'goNext',
        'validations',

        'isDemo',
        'isLoading',
        'mode',
        'isFailedPageRun',
        'page',
        'pageRun',
        'countWords',
        'isUsingOwnApiKey',
        'user',
        'popCreditCharges',

        'WORDCOUNT_OPTIONS',
        'currentWordCount',
        'targetWordCount',

        'selectedWordCountOption',
        'numberOfWords',

        'selectedCompetitors',
        'competitorWordCount',

        'setDefaultRichContentFroWebPageType',
        'webPageTypeOptions',
        'webPageType',

        'productDescription',
        'targetAudience',
        'sampleContent',
        'toneOfContent',
        'author',
        'brand',

        'addNewExcludeTerm',
        'handleRemoveExcludeTerm',
        'excludeTerms',

        'separateSearchEngineTitleAndTitle',
        'useMyCurrentTitle',

        'richContentItemsForAIWriter',
        'CONTENT_PROMPT_SIGNALS_TOOLTIPS',
        'costedContentPromptSignals',
        'getCostForContentPromptSignal',
        'isValidInternalLink',

        'selectedContentPromptsSignals',
        'selectedCountForPromptSignals',
        'topLevelInternalLink',
        'supportingLevelInternalLink1',
        'supportingLevelInternalLink2',
    ],
    computed: {
      STEPS() {
        return STEPS
      },
      configStatuses () {
        // 'selected', 'not-selected', 'error', undefined
        return {
          'WordCount': this.validations.numberOfWords.$invalid ? 'error' : 'selected',
          'Competitors': this.selectedCompetitors?.length ? 'selected' : 'not-selected',
          'WebPageType': this.validations.webPageType.$invalid ? 'error' : 'selected',
          'ApiKey': this.isUsingOwnApiKey ? 'selected' : 'not-selected',
          'RichContent': this.selectedContentPromptsSignals?.length ? (this.validations.cleanedContentPromptUserInput.$invalid ? 'error' : 'selected') : 'not-selected',
          'ProductDescription': this.productDescription || this.sampleContent ? 'selected' : 'not-selected',
          'TargetAudience': this.targetAudience ? 'selected' : 'not-selected',
          'Tone': this.toneOfContent || this.author || this.brand ? 'selected' : 'not-selected',
          'ExcludeTerms': this.excludeTerms?.length ? 'selected' : 'not-selected',
          'SearchPageTitle': this.separateSearchEngineTitleAndTitle ? 'selected' : 'not-selected',
        }
      },
      configComponents() {
        return configComponents
          .filter((item) => {
            if (this.mode === 'auto' && item.key === 'SearchPageTitle') {
              // hide separate SearchPageTitle config in auto mode
              return false
            }
            return true
          })
          .map((item) => ({
            ...item,
            status: this.configStatuses[item.key]
          }))
      },
    },
    data() {
      return {
        isOpen: null,
      }
    }
}
</script>

<style lang="scss" scoped>
.configs-collapsible {

  box-shadow: none;
  border: 0.5px solid var(--v-grayColor-base);
  background: var(--v-whiteColor-base);

  border-radius: 0.25rem;

  &:not(:last-child) {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  

  .card-header {
    background-color: var(--v-whiteColor-base) !important;
    box-shadow: none;

    &[aria-expanded="true"] {
      background-color: var(--v-whiteColor-base) !important;
      color: var(--v-mainColor3-base);
    }
  }
  
  .card-header-title {
    padding: 1rem 1rem;
    color: var(--v-mainColor3-base);
    .config-left {
      width: 310px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      .required-label {
        background-color: var(--v-gray7Color-base);
        color: var(--v-gray2Color-base);
        text-transform: uppercase;
        border-radius: 3px;
        font-size: 0.7rem;
        padding: 2px 4px;
      }
    }
  }
}

.config-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid var(--v-gray12Color-base);

  i {
    color: #fff;
    font-size: 0.8rem;
  }

  &.is-selected {
    background-color: #38C98C;
    border: none;
  }

  &.is-error {
    background-color: #FF1F2D;
    border: none;
  }
}

.is-loading {
  &::before {
    position: absolute;
    content: "";
    border-radius: 10px;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // background: var(--v-mainColor3-base);
    opacity: 0.2;
  }
}
</style>