<template>
<div>
  <div class="select-paragraph-box" v-if="hasParagraphData" v-click-outside="(event) => {
    closeIfEllapsedTimeAfterOpen(event)
  }">
    <div v-if="currentSubheadingInfo" class="subheading-info mt-3">
      <span>{{ currentSubheadingInfo.tagName }}</span>
      <span>{{ currentSubheadingInfo.text }}</span>
    </div>
    <div class="tab-root mt-5">
      <div v-for="item in allParagraphsData" :key="item.label"
        class="clickable"
        :class="{ 'is-active': currentTab == item.label }"
        @click="() => {
          currentTab = item.label
        }">
        {{ item.label }}
      </div>
      <div class="flex-grow-1"></div>
      <div>
        <span class="material-icons close-btn"
          @click="() => closeAction()">close</span>
      </div>
    </div>

    <div class="mt-3 paragraphs">
      <div v-for="(item, idx) in currentTabParagraphs" :key="'current-paragraph-block' + idx"
        @click="() => selectParagraphAction(item.pText || item)">
        <template v-if="item.pText"> <!-- new format will have this -->
          <div class="sub-heading-row">{{ item.subHeading }}</div>
          <div>{{ item.pText }}</div>
        </template>
        <template v-else> <!-- for old -->
          <div>{{ item }}</div>
        </template>
      </div>
    </div>    
  </div>
</div>
</template>

<script>
export default {
  name: 'SelectParagraphBox',
  props: [
    'currentSubheadingInfo', 'pageNotBuilt',
    'userInputParagraphs', 'currentText', 'targetUrlParagraphs', 'competitorsParagraphs',
    'currentSelectedRevision'
  ],
  data () {
    return {
      currentTab: 'Your paragraphs',
      openedAt: '',
    }
  },
  computed: {
    usedParagraphsText () {
      return this.userInputParagraphs.join('\n')
    },
    allParagraphsData () {
      let rlt = []
      if (!this.pageNotBuilt) {
        rlt.push({
          label: 'Your paragraphs',
          paragraphs: this.targetUrlParagraphs
        })
      }
      this.competitorsParagraphs.map((item, idx) => {
        rlt.push({
          label: `Competitor ${ idx + 1 }`,
          paragraphs: item
        })
      })
      return rlt;
    },
    currentTabParagraphs () {
      let found = this.allParagraphsData.find(item => item.label == this.currentTab)
      
      return found.paragraphs.filter(p => {
        if (this.usedParagraphsText) {
          return !this.usedParagraphsText.includes(p.pText || p)
        }
        return true;
      })
    },
    hasParagraphData () {
      for (const item of this.allParagraphsData) {
        if (item.paragraphs.length > 0) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {

  },
  methods: {
    selectParagraphAction (text) {
      this.$emit('clickedParagraphAction', text)
    },
    closeIfEllapsedTimeAfterOpen (event) {
      if (event.target.nodeName == 'TEXTAREA') {
        return;
      }
      if (this.openedAt) {
        const millis = Date.now() - this.openedAt;
        if ( millis > 500 ) {
          this.closeAction()
        }
      }
    },
    closeAction () {
      this.$emit('closeAction')
    }
  },
  mounted () {
    this.openedAt = Date.now()
    if (this.pageNotBuilt) {
      this.currentTab = 'Competitor 1'
    }
  }
}
</script>

<style lang="scss" scoped>
.subheading-info {
  display: flex;
  align-items: center;
  gap: 5px;
  & > span:nth-child(1) {
    background: var(--v-gray7Color-base);
    border-radius: 5px;
    padding: 5px;
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--v-mainColor3-base);
  }
  & > span:nth-child(2) {
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--v-mainColor3-base);
  }
}
.select-paragraph-box {
  margin-top: 5px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 7px 0 var(--v-gray2Color-base);
}

.close-btn {
  font-size: 1.3rem;
  font-weight: 700;
  cursor: pointer;
  color: var(--v-profileAnchorColor-base);
}

.tab-root {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid var(--v-grayColor-base);
  & > div {
    color: var(--v-mainColor3-base);
    font-size: 1rem;
    padding: 5px;
    &.is-active {
      border-bottom: 2px solid var(--v-profileAnchorColor-base);
      font-weight: 700;
    }
  }
}

.paragraphs {
  min-height: 300px;
  max-height: 40vh;
  overflow-y: auto;
  & > div {
    padding: 12px 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    color: var(--v-mainColor3-base);
    background: var(--v-gray7Color-base);
  }
  .sub-heading-row {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 10px;
    color: var(--v-mainColor3-base);
  }
}

</style>