<template>
  <div id="content-brief" class="pb-15">
    <div v-if="!hasContentBriefData">
      <div class="box is-size-5">
        Please rerun to see this data.
        <div>Select "make some changes" and re-run all the steps</div>
      </div>
    </div>
    <div v-else class="show-as-mobileview">
      <div class="summary-section-on-editor">
        <v-card class="is-fullheight flex flex-column white-common-box">
          <div class="pa-5 mainColor3--text">
            
            <div class="d-flex gap10 align-center">
              <div class="text-center" style="flex: 1;">
                <div>
                  <vue-ellipse-progress
                    :progress="fakeScore || score"
                    :half="true"
                    :angle="0"
                    :size="150"
                    :thickness="13"
                    :emptyThickness="13"
                    :color="pageScoreTintColor(fakeScore || score)"
                    :legend-formatter="({ currentValue }) => {
                      return `
                        <div class='mainColor3--text font-weight-bold' style='font-size:1.5rem;'>${ currentValue }</div>
                      `;
                    }"
                    >
                      <p v-if="!pageNotBuilt"
                        slot="legend-caption" :style="{ 'font-size': '11.5px', 'color': pageScoreTintColor(score) }"
                        class="mt-2"
                      >
                        {{ fakeScore ? scoreToText(fakeScore) : scoreStatusMsg }}
                      </p>
                  </vue-ellipse-progress>
                </div>
                <div class="score-label1 d-flex align-center gap10 justify-center">
                  Optimization Score
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size"
                        style="color: #B1BBC6;"
                        v-bind="attrs"
                        v-on="on"
                      >info</i>
                    </template>
                    <div class="white-bg-tooltip">
                      Improve this score by getting each section into the target range. The goal is to get to 100%
                    </div>
                  </v-tooltip>
                </div>
              </div>

              <div class="optimization-progress" style="flex: 1;">
                <div class="mb-3">
                  <div class="mb-1">Search Engine Title</div>
                  <v-progress-linear
                      rounded
                      :class="getStopLightStateTextForSection('title')[1]"
                      :value="100* (titleTotal['current'] / titleTotal['max'])" :height="10"></v-progress-linear>
                </div>
                <div class="mb-3">
                  <div class="mb-1">Page Title</div>
                  <v-progress-linear
                      rounded
                      :class="getStopLightStateTextForSection('pageTitle')[1]"
                      :value="100* (pageTitleTotal['current'] / pageTitleTotal['max'])" :height="10"></v-progress-linear>
                </div>
                <div class="mb-3">
                  <div class="mb-1">Sub-headings</div>
                  <v-progress-linear
                      rounded
                      :class="getStopLightStateTextForSection('subHeadings')[1]"
                      :value="100* (subTitleTotal['current'] / subTitleTotal['max'])" :height="10"></v-progress-linear>
                </div>
                <div class="mb-3">
                  <div class="mb-1">Main content</div>
                  <v-progress-linear
                      rounded
                      :class="getStopLightStateTextForSection('p')[1]"
                      :value="100* (contentTotal['current'] / contentTotal['max'])" :height="10"></v-progress-linear>
                </div>
              </div>
            </div>
            

            <div class="d-flex align-center gap10 mt-5">

              <v-btn
                color="profileAnchorColor"
                class="text-none whiteColor--text font-weight-bold"
                style="flex: 1; border-radius: 8px;"
                :disabled="isCalculating || isDemo"
                @click="() => {
                  $emit('needChecklistUpdate')
                }"
                >
                <span v-show="isCalculating" class="calc-loader">
                  <fulfilling-square-spinner
                    :animation-duration="4000"
                    :size="30"
                    color="#FF9B2D"
                  />
                </span>
                Update optimization score
              </v-btn>

              <v-menu 
                :close-on-content-click="false"
                bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grayColor"
                    v-bind="attrs"
                    v-on="on"
                    style="border-radius: 8px;"
                    >
                    <i class="material-icons-outlined">more_horiz</i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-content style="flex-direction: column; align-items: initial; padding: 16px;">
                    <div class="font-weight-bold">
                      <span class="darkGrayColor--text">Report ID: </span>{{ pageRunId }}
                    </div>
                    <div class="mt-1 font-weight-bold">
                      <span class="darkGrayColor--text">Time: </span>{{ getDateAsLocalString(createdAt) }}
                    </div>

                    <div class="mt-1 font-weight-bold">
                      <span class="darkGrayColor--text">
                        Slug:
                      </span>
                        <a
                          class="item-label has-text-grey is-underline"
                          @click="openUrlInNewTab(url)"
                          >{{ getSubFolderPartFromUrl(url) }}</a
                        >
                    </div>
                  </v-list-item-content>
                  <v-list-item
                  >
                    <div class="item-label d-flex align-center" v-if="secretKey">
                      <img src="/static/copyicon.png" class="copy-to-clipboard-button"
                          v-clipboard:copy="secretKey"
                          v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
                          v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })"
                      />
                        Secret Key:
                      <span class="item-label has-text-grey">{{ secretKey }}</span>
                    </div>
                  </v-list-item>
                  <v-list-item>
                    <div class="item-label d-flex align-center" v-if="secretKey">
                      <img src="/static/copyicon.png" class="copy-to-clipboard-button"
                          v-clipboard:copy="uniqueUrlForEditor"
                          v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
                          v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })"
                      />
                      Content Editor Url:
                      <a
                        class="item-label has-text-grey is-size-7 word-break-all is-underline"
                        @click="openUrlInNewTab(uniqueUrlForEditor)"
                        >{{ uniqueUrlForEditor }}</a
                      >
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>

            </div>
          </div>
        </v-card>
      </div>      
      <div class ='my-6'>
        <v-card class="white-common-box">
          <div class="pa-4">
              <div class="font-large">Word count</div>
              <div class="mt-6">
                <div class="d-flex justify-space-between gap10">
                  <div>
                    <span class="font-large">{{ fakeWC() || wordCountData[0] }}</span> <span class="font-weight-bold">/ {{ wordCountData[1] }}</span>
                  </div>
                  <div class="darkGrayColor--text">{{this.calcWordCountRecommendation(fakeWC() || wordCountData[0],wordCountData[1])}}</div>
                </div>
                <div class="mt-3">
                    <v-progress-linear
                      rounded
                      :color="pageScoreTintColor((fakeWC() || wordCountData[0]) * 100 / wordCountData [1])"
                      :value="100* (fakeWC()|| wordCountData[0])/wordCountData[1]" :height="10"></v-progress-linear>
                </div>
              </div>
          </div>
      </v-card>
      </div>
      <div class="sort-filter-block flex">
        <div>
          <span class="view-type-selector">
            <v-tooltip top color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <i
                  class="material-icons"
                  :class="{ 'is-active': termDispType == 'table' }"
                  v-bind="attrs" v-on="on"
                  @click="termDispType = 'table'"
                >format_list_bulleted</i>
              </template>
              <div class="white-bg-tooltip">
                View as tags
              </div>
            </v-tooltip>
            <v-tooltip top color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <i
                  class="material-icons"
                  :class="{ 'is-active': termDispType == 'list' }"
                  v-bind="attrs" v-on="on"
                  @click="termDispType = 'list'"
                >apps</i>
              </template>
              <div class="white-bg-tooltip">
                View as list
              </div>
            </v-tooltip>
          </span>
        </div>
        <div class="flex-grow-1 is-size-7 pl10 mainColor3--text">
          <b-dropdown aria-role="list">
            <template #trigger>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    rounded icon
                    color="mainColor3"
                  >
                  <i class="material-icons" style="font-size: 1.2rem;">sort</i>
                </v-btn>
                </template>
                <div class="white-bg-tooltip">
                  Sort by
                </div>
              </v-tooltip>
            </template>
            <b-dropdown-item :class="{'font-weight-bold': sortMethod === 'importance'}" style="color: #000;" aria-role="listitem" @click.native.stop="sortMethod = 'importance'">Most Important</b-dropdown-item>
            <b-dropdown-item :class="{'font-weight-bold': sortMethod === 'deficit'}" style="color: #000;" aria-role="listitem" @click.native.stop="sortMethod = 'deficit'">Deficit</b-dropdown-item>
            <b-dropdown-item :class="{'font-weight-bold': sortMethod === 'alphabetic'}" style="color: #000;" aria-role="listitem" @click.native.stop="sortMethod = 'alphabetic'">Alphabetic</b-dropdown-item>
            <b-dropdown-item :class="{'font-weight-bold': sortMethod === 'status'}" style="color: #000;" aria-role="listitem" @click.native.stop="sortMethod = 'status'">Status</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div class="content-brief-box">
        <div class="d-flex">
          <!-- <hr class="flex-grow-1" style="margin: 0.6rem 0;"/> -->
          <div v-if="isCollapseSearchEngineTitleArea" class="flex-grow-1">
            <div class="d-flex align-center">
              <div class="section-title">Search Engine Title</div>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size ml-2 gray9Color--text" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip">
                  In your website editor, this is your tag, sometimes called the meta title.
                </div>
              </v-tooltip>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons ml-2" :style="`color: ${getStopLightStateTextForSection('title')[3]}`" v-bind="attrs" v-on="on">{{ getStopLightStateTextForSection('title')[2] }}</i>
                </template>
                <div class="white-bg-tooltip">
                  Search Engine Title is {{ getStopLightStateTextForSection('title')[0] }}
                </div>
              </v-tooltip>
            </div>
          </div>
          <div v-else class="flex-grow-1">
            <div class="d-flex align-center">
              <div>
                <div class="d-flex align-center">
                  <div class="section-title">Search Engine Title</div>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="material-icons info-icon-size ml-2 gray9Color--text" v-bind="attrs" v-on="on">info</i>
                    </template>
                    <div class="white-bg-tooltip">
                      In your website editor, this is your tag, sometimes called the meta title.
                    </div>
                  </v-tooltip>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="material-icons ml-2" :style="`color: ${getStopLightStateTextForSection('title')[3]}`" v-bind="attrs" v-on="on">{{ getStopLightStateTextForSection('title')[2] }}</i>
                    </template>
                    <div class="white-bg-tooltip">
                      Search Engine Title is {{ getStopLightStateTextForSection('title')[0] }}
                    </div>
                  </v-tooltip>
                </div>
                <!-- Complete / Incomplete -->
                <div class="control mt-1 darkGrayColor--text">
                  <label class="radio">
                    <input
                      type="radio"
                      name="title_status"
                      :disabled="isDemo"
                      :checked="contentBriefStatus['title'] == 'Completed'"
                      @click.stop="
                        updateContentBriefSectionStatus('title', 'Completed')
                      "
                    />
                    Complete
                  </label>
                  <label class="radio">
                    <input
                      type="radio"
                      name="title_status"
                      :disabled="isDemo"
                      :checked="contentBriefStatus['title'] == 'InCompleted'"
                      @click.stop="
                        updateContentBriefSectionStatus('title', 'InCompleted')
                      "
                    />
                    Incomplete
                  </label>
                </div>              
              </div>
            </div>
            <div
              v-if="showNoSecondaryKeywordsInSection('title')"
              class="no-secondary-keywords pl-0">Secondary keywords found.
            </div>

            <div
              v-if="!isCollapseSearchEngineTitleArea"
              class="mt-2 mb10"
            >
              <a
                class="show-hide-link"
                @click.stop="showSeatchEngineTitleExplainText = !showSeatchEngineTitleExplainText"
                ><i class="material-icons-outlined">{{showSeatchEngineTitleExplainText ? 'arrow_drop_down' : 'arrow_right'}}</i> <span>How to optimize your search engine title</span></a
              >  
              <div v-show="showSeatchEngineTitleExplainText"
                class="has-text-grey has-text-weight-light is-italic">
                Make sure that your title contains your important terms in the
                suggested range. You do not need to use every important term. You
                should select the ones you like and use them to reach the target
                range for the section.<br/>
                Use your competitor titles in Content prompts for inspiration.<br/>
                Your Search Engine Title, the title that search engines see, and
                your H1, the title that humans see, should be as identical as
                possible.
              </div>
              
            </div>

            <div class="d-flex gap5 justify-end">
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    small
                    color="gray50Color"
                    style="padding: 0 8px; min-width: initial; width: 2rem;"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="isDemo ? null : displayNoteModalFor('title')"
                  >
                    <i class="material-icons">border_color</i>
                  </v-btn>
                </template>
                <div class="white-bg-tooltip small-tooltip">Add notes</div>
              </v-tooltip>

              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    small
                    color="gray50Color"
                    style="min-width: initial; width: 2rem;"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="downloadAsTxt('title')"
                  >
                    <i class="material-icons-outlined">file_download</i>
                  </v-btn>
                </template>
                <div class="white-bg-tooltip small-tooltip">Download as .txt</div>
              </v-tooltip>
            </div>
            
            <div class="d-flex">
              <div class="flex-grow-1">
                <span>
                  <template v-if="termDispType == 'list'">
                    ( {{ titleTotal["current"] }} / {{ titleTotal["min"] }} - {{ titleTotal["max"] }} )
                  </template>
                  <div v-else class="section-count-disp">
                    <div>
                      <div class="section-count-number">{{ titleTotal["current"] }} terms</div>
                      <div class="section-count-label">Current count</div>
                    </div>
                    <div>
                      <div class="section-count-number">{{ titleTotal["min"] }} - {{ titleTotal["max"] }} terms</div>
                      <div class="section-count-label">Important terms range</div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <a
            v-if="!isCollapseSearchEngineTitleArea"
            @click.stop="isCollapseSearchEngineTitleArea = true">
            <span class="material-icons fold_icon">expand_less</span>
          </a>
          <a v-else @click.stop="isCollapseSearchEngineTitleArea = false">
            <span class="material-icons fold_icon">expand_more</span>
          </a>
        </div>
        <div v-if="!isCollapseSearchEngineTitleArea">
          <div v-if="termDispType == 'list'">
            <span
              v-for="(item, idx) in titleData"
              :key="idx"
              class="content-brief-tag"
              :style="{
                'border-color': getStopLightColorForItem('title', item)
              }"
            >
              <span>{{ item.term.phrase }}</span>
              <span
                >{{ item.contentBrief.current }} /
                {{
                  item.term.type == "keyword"
                    ? Math.round(
                        item.contentBrief.target *
                          TARGET_MIN_PERCENTAGE["title"]
                      ) +
                      " - " +
                      item.contentBrief.target
                    : item.contentBrief.targetMin +
                      " - " +
                      item.contentBrief.targetMax
                }}</span
              >
            </span>
          </div>
          <div v-else-if="termDispType == 'table'">
            <table class="signal-table mt-10" style="table-layout: fixed;">
              <thead>
                <tr>
                  <th style="width: 45%;">Important Term</th>
                  <th>Current</th>
                  <th>Target</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in titleData" :key="idx">
                  <td>
                    <input :disabled="isDemo" type="checkbox" class="term-checkbox"
                      v-model="selectedCBItems"
                      :value="{
                        sectionName: 'title',
                        item: item
                      }"
                       />
                    {{ item.term.phrase }}
                  </td>
                  <td>
                    {{ item.contentBrief.current }}
                    
                    <v-tooltip left color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="stoplights-box"
                          :style="{ background: getStopLightColorForItem('title', item) }"
                          v-bind="attrs"
                          v-on="on"
                        ></span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForItem('title', item))}}</div>
                    </v-tooltip>
                  </td>
                  <td>
                    <span class="target-range">
                      {{
                        item.term.type == "keyword"
                          ? Math.round(
                              item.contentBrief.target *
                                TARGET_MIN_PERCENTAGE["title"]
                            ) +
                            " - " +
                            item.contentBrief.target
                          : item.contentBrief.targetMin +
                            " - " +
                            item.contentBrief.targetMax
                      }}
                    </span>
                    <span
                      v-if="item.term.type == 'keyword'"
                      class="cb-tag must-use clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Using your exact keyword is critical to SEO success"
                      >Must Use
                    </span>
                    <span
                      v-if="isSecondaryKeyword(item.term.phrase)"
                      class="cb-tag secondary-kw clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Use your secondary keyword"
                      >Secondary KW
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            Unsupported View Type
          </div>
        </div>
      </div>
      <div class="content-brief-box">
        <div class="d-flex">
          <!-- <hr class="flex-grow-1" style="margin: 0.6rem 0;"/> -->
          <div v-if="isCollapsePageTitleArea" class="flex-grow-1">
            <div class="d-flex align-center">
              <div class="section-title">Page Title</div>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size ml-2 gray9Color--text" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip">
                  In your website editor this is your H1 tag
                </div>
              </v-tooltip>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons ml-2" :style="`color: ${getStopLightStateTextForSection('pageTitle')[3]}`" v-bind="attrs" v-on="on">{{ getStopLightStateTextForSection('pageTitle')[2] }}</i>
                </template>
                <div class="white-bg-tooltip">
                  Page Title is {{ getStopLightStateTextForSection('pageTitle')[0] }}
                </div>
              </v-tooltip>
            </div>
          </div>
          <div v-else class="flex-grow-1">
            <div class="d-flex align-center">
              <div>
                <div class="d-flex align-center">
                  <div class="section-title">Page Title</div>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="material-icons info-icon-size ml-2 gray9Color--text" v-bind="attrs" v-on="on">info</i>
                    </template>
                    <div class="white-bg-tooltip">
                      In your website editor this is your H1 tag
                    </div>
                  </v-tooltip>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="material-icons ml-2" :style="`color: ${getStopLightStateTextForSection('pageTitle')[3]}`" v-bind="attrs" v-on="on">{{ getStopLightStateTextForSection('pageTitle')[2] }}</i>
                    </template>
                    <div class="white-bg-tooltip">
                      Page Title is {{ getStopLightStateTextForSection('pageTitle')[0] }}
                    </div>
                  </v-tooltip>
                </div>
                <!-- Complete / Incomplete -->
                <div class="control mt-1 darkGrayColor--text">
                  <label class="radio">
                    <input
                      type="radio"
                      name="pageTitle_status"
                      :disabled="isDemo"
                      :checked="contentBriefStatus['pageTitle'] == 'Completed'"
                      @click.stop="
                        updateContentBriefSectionStatus('pageTitle', 'Completed')
                      "
                    />
                    Complete
                  </label>
                  <label class="radio">
                    <input
                      type="radio"
                      name="pageTitle_status"
                      :disabled="isDemo"
                      :checked="contentBriefStatus['pageTitle'] == 'InCompleted'"
                      @click.stop="
                        updateContentBriefSectionStatus(
                          'pageTitle',
                          'InCompleted'
                        )
                      "
                    />
                    Incomplete
                  </label>
                </div>
              </div>
            </div>
            <div>
              <span
                v-if="showNoSecondaryKeywordsInSection('pageTitle')"
                class="no-secondary-keywords pl-0">Secondary keywords found.
              </span>
            </div>

            <div
              v-if="!isCollapsePageTitleArea"
              class="mt-2 mb10"
            >
              <a
                class="show-hide-link"
                @click.stop="showPageTitleExplainText = !showPageTitleExplainText"
                ><i class="material-icons-outlined">{{showPageTitleExplainText ? 'arrow_drop_down' : 'arrow_right'}}</i> <span>How to optimize your page title</span></a
              >    
              <div v-show="showPageTitleExplainText"
                class="has-text-grey has-text-weight-light is-italic">
                Make sure that your page title contains your important terms in the
                suggested range. You do not need to use every important term. You
                should select the ones you like and use them to reach the target
                range for the section.<br/>
                Use your competitor titles in the Content prompts for inspiration.<br/>
                Your Search Engine title, the title that search engines see, and
                your H1, the title that humans see, should be as identical as
                possible. You should only have one H1, one title visible on your
                page.
              </div>
            </div>

            <div class="d-flex gap5 justify-end">
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    small
                    color="gray50Color"
                    style="padding: 0 8px; min-width: initial; width: 2rem;"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="isDemo ? null : displayNoteModalFor('pageTitle')"
                  >
                    <i class="material-icons">border_color</i>
                  </v-btn>
                </template>
                <div class="white-bg-tooltip small-tooltip">Add notes</div>
              </v-tooltip>

              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    small
                    color="gray50Color"
                    style="min-width: initial; width: 2rem;"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="downloadAsTxt('pageTitle')"
                  >
                    <i class="material-icons-outlined">file_download</i>
                  </v-btn>
                </template>
                <div class="white-bg-tooltip small-tooltip">Download as .txt</div>
              </v-tooltip>
            </div>
            
            <div class="d-flex">
              <div class="flex-grow-1">
                <span class="term-range-size mainColor3--text">
                  <template v-if="termDispType == 'list'">
                    ( {{ pageTitleTotal["current"] }} / {{ pageTitleTotal["min"] }} - {{ pageTitleTotal["max"] }} )
                  </template>
                  <div v-else class="section-count-disp">
                    <div>
                      <div class="section-count-number">{{ pageTitleTotal["current"] }} terms</div>
                      <div class="section-count-label">Current count</div>
                    </div>
                    <div>
                      <div class="section-count-number">{{ pageTitleTotal["min"] }} - {{ pageTitleTotal["max"] }} terms</div>
                      <div class="section-count-label">Important terms range</div>
                    </div>
                  </div>
                </span>
                
              </div>
              
            </div>
          </div>
          <a
            v-if="!isCollapsePageTitleArea"
            @click.stop="isCollapsePageTitleArea = true"
            ><span class="material-icons fold_icon">expand_less</span></a
          >
          <a v-else @click.stop="isCollapsePageTitleArea = false"
            ><span class="material-icons fold_icon">expand_more</span></a
          >
        </div>

        <div v-if="!isCollapsePageTitleArea">
          <div v-if="termDispType == 'list'">
            <span
              v-for="(item, idx) in pageTitleData"
              :key="idx"
              class="content-brief-tag"
              :style="{
                'border-color': getStopLightColorForItem('pageTitle', item)
              }"
            >
              <span>{{ item.term.phrase }}</span>
              <span
                >{{ item.contentBrief.current }} /
                {{
                  item.term.type == "keyword"
                    ? Math.round(
                        item.contentBrief.target *
                          TARGET_MIN_PERCENTAGE["pageTitle"]
                      ) +
                      " - " +
                      item.contentBrief.target
                    : item.contentBrief.targetMin +
                      " - " +
                      item.contentBrief.targetMax
                }}</span
              >
            </span>
          </div>
          <div v-else-if="termDispType == 'table'">
            <table class="signal-table mt-10" style="table-layout: fixed;">
              <thead>
                <tr>
                  <th style="width: 45%;">Important Term</th>
                  <th>Current</th>
                  <th>Target</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in pageTitleData" :key="idx">
                  <td>
                    <input :disabled="isDemo" type="checkbox" class="term-checkbox"
                      v-model="selectedCBItems"
                      :value="{
                        sectionName: 'pageTitle',
                        item: item
                      }"
                       />
                    {{ item.term.phrase }}
                  </td>
                  <td>
                    {{ item.contentBrief.current }}
                    
                    <v-tooltip left color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="stoplights-box"
                          :style="{ background: getStopLightColorForItem('pageTitle', item) }"
                          v-bind="attrs"
                          v-on="on"
                        ></span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForItem('pageTitle', item))}}</div>
                    </v-tooltip>
                  </td>
                  <td>
                    <span class="target-range">
                      {{
                        item.term.type == "keyword"
                          ? Math.round(
                              item.contentBrief.target *
                                TARGET_MIN_PERCENTAGE["pageTitle"]
                            ) +
                            " - " +
                            item.contentBrief.target
                          : item.contentBrief.targetMin +
                            " - " +
                            item.contentBrief.targetMax
                      }}
                    </span>
                    <span
                      v-if="item.term.type == 'keyword'"
                      class="cb-tag must-use clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Using your exact keyword is critical to SEO success"
                      >Must Use
                    </span>
                    <span
                      v-if="isSecondaryKeyword(item.term.phrase)"
                      class="cb-tag secondary-kw clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Use your secondary keyword"
                      >Secondary KW
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            Unsupported View Type
          </div>
          <div
            v-if="!hasPageTitleData"
            class="notification is-warning is-italic"
          >
            Please Re-Run and select "Make Changes" to see this new feature.
          </div>
        </div>
      </div>
      <div class="content-brief-box">
        <div class="d-flex">
          <div v-if="isCollapseSubHeadingsArea" class="flex-grow-1">
            <div class="d-flex align-center">
              <div class="section-title">Sub-headings</div>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size ml-2 gray9Color--text" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip">
                  In your website editor these are H2 or H3 tags
                </div>
              </v-tooltip>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons ml-2" :style="`color: ${getStopLightStateTextForSection('subHeadings')[3]}`" v-bind="attrs" v-on="on">{{ getStopLightStateTextForSection('subHeadings')[2] }}</i>
                </template>
                <div class="white-bg-tooltip">
                  Sub-headings are {{ getStopLightStateTextForSection('subHeadings')[0] }}
                </div>
              </v-tooltip>
            </div>
          </div>
          <div v-else class="flex-grow-1">
            <div class="d-flex align-center">
              <div>
                <div class="d-flex align-center">
                  <div class="section-title">Sub-headings</div>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="material-icons info-icon-size ml-2 gray9Color--text" v-bind="attrs" v-on="on">info</i>
                    </template>
                    <div class="white-bg-tooltip">
                      In your website editor these are H2 or H3 tags
                    </div>
                  </v-tooltip>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="material-icons ml-2" :style="`color: ${getStopLightStateTextForSection('subHeadings')[3]}`" v-bind="attrs" v-on="on">{{ getStopLightStateTextForSection('subHeadings')[2] }}</i>
                    </template>
                    <div class="white-bg-tooltip">
                      Sub-headings are {{ getStopLightStateTextForSection('subHeadings')[0] }}
                    </div>
                  </v-tooltip>
                </div>
                <!-- Complete / Incomplete -->
                <div class="control mt-1 darkGrayColor--text">
                  <label class="radio">
                    <input
                      type="radio"
                      name="subheadings_status"
                      :disabled="isDemo"
                      :checked="contentBriefStatus['subHeadings'] == 'Completed'"
                      @click.stop="
                        updateContentBriefSectionStatus(
                          'subHeadings',
                          'Completed'
                        )
                      "
                    />
                    Complete
                  </label>
                  <label class="radio">
                    <input
                      type="radio"
                      name="subheadings_status"
                      :disabled="isDemo"
                      :checked="
                        contentBriefStatus['subHeadings'] == 'InCompleted'
                      "
                      @click.stop="
                        updateContentBriefSectionStatus(
                          'subHeadings',
                          'InCompleted'
                        )
                      "
                    />
                    Incomplete
                  </label>
                </div>
                <div>
                  <span v-if="showNoSecondaryKeywordsInSection('subHeadings')"
                    class="no-secondary-keywords pl-0">Secondary keywords found.
                  </span>
                </div>
              </div>
            </div>

            <div
              v-if="!isCollapseSubHeadingsArea"
              class="mt-2 mb10"
            >
              <a
                class="show-hide-link"
                @click.stop="showSubtitlesExplainText = !showSubtitlesExplainText"
                ><i class="material-icons-outlined">{{showSubtitlesExplainText ? 'arrow_drop_down' : 'arrow_right'}}</i> <span>How to optimize your sub-headings</span></a
              >  
              <div v-show="showSubtitlesExplainText"
                class="has-text-grey has-text-weight-light is-italic">
                No matter how many subheadings/subtitles are on your page makes sure
                those subheadings/subtitles include the important terms below. You
                do not have to use every important term. You do not have to get
                every important term inside its individual target range.<br />
                The goal is to use as many of the important terms below as needed so
                that the Subheadings Section as a whole gets inside the section's
                target range.<br />
                Use the relevant related keywords at the bottom of the dashboard for
                inspiration on your subtitles.
                <b>
                  If you see competitor brand names in this list we highly
                  recommend that you re-run and remove them.
                </b>
              </div>
            </div>

            <div class="d-flex gap5 justify-end">
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    small
                    color="gray50Color"
                    style="padding: 0 8px; min-width: initial; width: 2rem;"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="isDemo ? null : displayNoteModalFor('subHeadings')"
                  >
                    <i class="material-icons">border_color</i>
                  </v-btn>
                </template>
                <div class="white-bg-tooltip small-tooltip">Add notes</div>
              </v-tooltip>

              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    small
                    color="gray50Color"
                    style="min-width: initial; width: 2rem;"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="downloadAsTxt('subHeadings')"
                  >
                    <i class="material-icons-outlined">file_download</i>
                  </v-btn>
                </template>
                <div class="white-bg-tooltip small-tooltip">Download as .txt</div>
              </v-tooltip>
            </div>

            <div class="d-flex">
              <div class="flex-grow-1">
                <span class="term-range-size mainColor3--text">
                  <template v-if="termDispType == 'list'">
                    ( {{ subTitleTotal["current"] }} / {{ subTitleTotal["min"] }} - {{ subTitleTotal["max"] }} )
                  </template>
                  <div v-else class="section-count-disp">
                    <div>
                      <div class="section-count-number">{{ subTitleTotal["current"] }} terms</div>
                      <div class="section-count-label">Current count</div>
                    </div>
                    <div>
                      <div class="section-count-number">{{ subTitleTotal["min"] }} - {{ subTitleTotal["max"] }} terms</div>
                      <div class="section-count-label">Important terms range</div>
                    </div>
                  </div>
                </span>
                
              </div>
              
            </div>
          </div>
          <a
            v-if="!isCollapseSubHeadingsArea"
            @click.stop="isCollapseSubHeadingsArea = true"
            ><span class="material-icons fold_icon">expand_less</span></a
          >
          <a v-else @click.stop="isCollapseSubHeadingsArea = false"
            ><span class="material-icons fold_icon">expand_more</span></a
          >
        </div>
        
        <div class="" v-if="!isCollapseSubHeadingsArea">
          <div v-if="termDispType == 'list'">
            <span
              v-for="(item, idx) in subHeadingsData"
              :key="idx"
              class="content-brief-tag"
              :style="{
                'border-color': getStopLightColorForItem('subHeadings', item)
              }"
            >
              <span>{{ item.term.phrase }}</span>
              <span
                >{{ item.contentBrief.current }} /
                {{
                  item.term.type == "keyword"
                    ? Math.round(
                        item.contentBrief.target *
                          TARGET_MIN_PERCENTAGE["subHeadings"]
                      ) +
                      " - " +
                      item.contentBrief.target
                    : item.contentBrief.targetMin +
                      " - " +
                      item.contentBrief.targetMax
                }}</span
              >
            </span>
          </div>
          <div v-else-if="termDispType == 'table'">
            <table class="signal-table mt-10" style="table-layout: fixed;">
              <thead>
                <tr>
                  <th style="width: 45%;">Important Term</th>
                  <th>Current</th>
                  <th>Target</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in subHeadingsData" :key="idx">
                  <td>
                    <input :disabled="isDemo" type="checkbox" class="term-checkbox"
                      v-model="selectedCBItems"
                      :value="{
                        sectionName: 'subHeadings',
                        item: item
                      }"
                       />
                    {{ item.term.phrase }}
                  </td>
                  <td>
                    {{ item.contentBrief.current }}
                    
                    <v-tooltip left color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="stoplights-box"
                          :style="{ background: getStopLightColorForItem('subHeadings', item) }"
                          v-bind="attrs"
                          v-on="on"
                        ></span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForItem('subHeadings', item))}}</div>
                    </v-tooltip>
                  </td>
                  <td>
                    <span class="target-range">
                      {{
                        item.term.type == "keyword"
                          ? Math.round(
                              item.contentBrief.target *
                                TARGET_MIN_PERCENTAGE["subHeadings"]
                            ) +
                            " - " +
                            item.contentBrief.target
                          : item.contentBrief.targetMin +
                            " - " +
                            item.contentBrief.targetMax
                      }}
                    </span>
                    <span
                      v-if="
                        item.term.type == 'keyword' &&
                          item.contentBrief.target > 0
                      "
                      class="cb-tag must-use clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Using your exact keyword is critical to SEO success"
                      >Must Use
                    </span>
                    <span
                      v-if="isSecondaryKeyword(item.term.phrase)"
                      class="cb-tag secondary-kw clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Use your secondary keyword"
                      >Secondary KW
                    </span>
                    <span
                      v-if="isNLPTerm(item.term.phrase)"
                      class="cb-tag nlp-term clickable tooltip is-tooltip-left"
                      data-tooltip="Entities from POP's Natural Language Processing list"
                      >NLP
                    </span>
                    <span
                      v-if="item.term.isGoogleNLPTerm"
                      class="cb-tag google-nlp-term clickable tooltip is-tooltip-left"
                      data-tooltip="Entities from Google's Natural Language Processing list"
                      >Google NLP
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            Unsupported View Type
          </div>
        </div>
      </div>
      <div class="content-brief-box">
        <div class="d-flex">
          <div v-if="isCollapseMainContentArea" class="flex-grow-1">
            <div class="d-flex align-center">
              <div class="section-title">Main Content</div>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons info-icon-size ml-2 gray9Color--text" v-bind="attrs" v-on="on">info</i>
                </template>
                <div class="white-bg-tooltip">
                  Any text on your page that is in a paragraph, list, or table.
                </div>
              </v-tooltip>
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <i class="material-icons ml-2" :style="`color: ${getStopLightStateTextForSection('p')[3]}`" v-bind="attrs" v-on="on">{{ getStopLightStateTextForSection('p')[2] }}</i>
                </template>
                <div class="white-bg-tooltip">
                  Main Content is {{ getStopLightStateTextForSection('p')[0] }}
                </div>
              </v-tooltip>
            </div>
          </div>
          <div v-else class="flex-grow-1">
            <div class="d-flex align-center">
              <div>
                <div class="d-flex align-center">
                  <div class="section-title">Main Content</div>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="material-icons info-icon-size ml-2 gray9Color--text" v-bind="attrs" v-on="on">info</i>
                    </template>
                    <div class="white-bg-tooltip">
                      Any text on your page that is in a paragraph, list, or table.
                    </div>
                  </v-tooltip>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i class="material-icons ml-2" :style="`color: ${getStopLightStateTextForSection('p')[3]}`" v-bind="attrs" v-on="on">{{ getStopLightStateTextForSection('p')[2] }}</i>
                    </template>
                    <div class="white-bg-tooltip">
                      Main Content is {{ getStopLightStateTextForSection('p')[0] }}
                    </div>
                  </v-tooltip>
                </div>
                <!-- complete / incomplete -->
                <div class="control mt-1 darkGrayColor--text">
                  <label class="radio">
                    <input
                      type="radio"
                      name="maincontent_status"
                      :disabled="isDemo"
                      :checked="contentBriefStatus['p'] == 'Completed'"
                      @click.stop="
                        updateContentBriefSectionStatus('p', 'Completed')
                      "
                    />
                    Complete
                  </label>
                  <label class="radio">
                    <input
                      type="radio"
                      :disabled="isDemo"
                      name="maincontent_status"
                      :checked="contentBriefStatus['p'] == 'InCompleted'"
                      @click.stop="
                        updateContentBriefSectionStatus('p', 'InCompleted')
                      "
                    />
                    Incomplete
                  </label>
                </div>
              </div>
            </div>
            <div>
              <span
                v-if="showNoSecondaryKeywordsInSection('p')"
                class="no-secondary-keywords pl-0">Secondary keywords found.
              </span>
            </div>

            <div v-if="!isCollapseMainContentArea"
              class="mt-2 mb10">
              <a
                class="show-hide-link"
                @click.stop="showMainContentExplainText = !showMainContentExplainText"
                ><i class="material-icons-outlined">{{showMainContentExplainText ? 'arrow_drop_down' : 'arrow_right'}}</i> <span>How to optimize your main content</span></a
              > 
              <div v-show="showMainContentExplainText"
                class="has-text-grey has-text-weight-light is-italic">
                Use the suggested terms below to optimize the main content on your
                page. You do not have to use every important term. You do not have
                to get every important term inside its individual target range.<br />
                The goal is to use as many of the important terms below as needed so
                that the Main Content Section as a whole gets inside the section's
                target range.<br />
                A good approach is to provide information that answers the questions
                your readers have about your target keyword. It's often good to
                write your main content first and then edit for the important terms.
                Use the related questions at the bottom of the dashboard for
                inspiration on the questions your readers may need answered.
                <b>
                  If you see competitor brand names in this list we highly
                  recommend that you re-run and remove them.
                </b>
              </div>
            </div>

            <div class="d-flex gap5 justify-end">
              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    small
                    color="gray50Color"
                    style="padding: 0 8px; min-width: initial; width: 2rem;"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="isDemo ? null : displayNoteModalFor('p')"
                  >
                    <i class="material-icons">border_color</i>
                  </v-btn>
                </template>
                <div class="white-bg-tooltip small-tooltip">Add notes</div>
              </v-tooltip>

              <v-tooltip top color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    small
                    color="gray50Color"
                    style="min-width: initial; width: 2rem;"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="downloadAsTxt('p')"
                  >
                    <i class="material-icons-outlined">file_download</i>
                  </v-btn>
                </template>
                <div class="white-bg-tooltip small-tooltip">Download as .txt</div>
              </v-tooltip>
              
            </div>
            
            <div class="d-flex">
              <div class="flex-grow-1">
                <span class="term-range-size mainColor3--text">
                  <template v-if="termDispType == 'list'">
                    ( {{ contentTotal["current"] }} / {{ contentTotal["min"] }} -
                    {{ contentTotal["max"] }} )
                  </template>
                  <div v-else class="section-count-disp">
                    <div>
                      <div class="section-count-number">{{ contentTotal["current"] }} terms</div>
                      <div class="section-count-label">Current count</div>
                    </div>
                    <div>
                      <div class="section-count-number">{{ contentTotal["min"] }} - {{ contentTotal["max"] }} terms</div>
                      <div class="section-count-label">Important terms range</div>
                    </div>
                  </div>
                </span>
                
              </div>
              
            </div>
          </div>
          <a
            v-if="!isCollapseMainContentArea"
            @click.stop="isCollapseMainContentArea = true"
            ><span class="material-icons fold_icon">expand_less</span></a
          >
          <a v-else @click.stop="isCollapseMainContentArea = false"
            ><span class="material-icons fold_icon">expand_more</span></a
          >
        </div>
        
        <div v-if="!isCollapseMainContentArea">
          <div v-if="termDispType == 'list'">
            <span
              v-for="(item, idx) in bodyContentData"
              :key="idx"
              class="content-brief-tag"
              :style="{ 'border-color': getStopLightColorForItem('p', item) }"
            >
              <span>{{ item.term.phrase }}</span>
              <span
                >{{ item.contentBrief.current }} /
                {{
                  item.term.type == "keyword"
                    ? Math.round(
                        item.contentBrief.target * TARGET_MIN_PERCENTAGE["p"]
                      ) +
                      " - " +
                      item.contentBrief.target
                    : item.contentBrief.targetMin +
                      " - " +
                      item.contentBrief.targetMax
                }}</span
              >
            </span>
          </div>
          <div v-else-if="termDispType == 'table'">
            <table class="signal-table mt-10" style="table-layout: fixed;">
              <thead>
                <tr>
                  <th style="width: 45%;">Important Term</th>
                  <th>Current</th>
                  <th>Target</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in bodyContentData" :key="idx">
                  <td>
                    <input :disabled="isDemo" type="checkbox" class="term-checkbox"
                      v-model="selectedCBItems"
                      :value="{
                        sectionName: 'p',
                        item: item
                      }"
                       />
                    {{ item.term.phrase }}
                  </td>
                  <td>
                    {{ item.contentBrief.current }}
                    <v-tooltip left color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="stoplights-box"
                          :style="{ background: getStopLightColorForItem('p', item) }"
                          v-bind="attrs"
                          v-on="on"
                        ></span>
                      </template>
                      <div class="white-bg-tooltip small-tooltip">{{getTooltipForColor(getStopLightColorForItem('p', item))}}</div>
                    </v-tooltip>
                  </td>
                  <td>
                    <span class="target-range">
                      {{
                        item.term.type == "keyword"
                          ? Math.round(
                              item.contentBrief.target *
                                TARGET_MIN_PERCENTAGE["p"]
                            ) +
                            " - " +
                            item.contentBrief.target
                          : item.contentBrief.targetMin +
                            " - " +
                            item.contentBrief.targetMax
                      }}
                    </span>
                    <span
                      v-if="
                        item.term.type == 'keyword' &&
                          item.contentBrief.target > 0
                      "
                      class="cb-tag must-use clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Using your exact keyword is critical to SEO success"
                      >Must Use
                    </span>
                    <span
                      v-if="isSecondaryKeyword(item.term.phrase)"
                      class="cb-tag secondary-kw clickable tooltip is-tooltip-left is-tooltip-multiline"
                      data-tooltip="Use your secondary keyword"
                      >Secondary KW
                    </span>
                    <span
                      v-if="isNLPTerm(item.term.phrase)"
                      class="cb-tag nlp-term clickable tooltip is-tooltip-left"
                      data-tooltip="Entities from POP's Natural Language Processing list"
                      >NLP
                    </span>
                    <span
                      v-if="isGoogleNLPTerm(item.term.phrase)"
                      class="cb-tag google-nlp-term clickable tooltip is-tooltip-left"
                      data-tooltip="Entities from Google's Natural Language Processing list"
                      >Google NLP
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            Unsupported View Type
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{ 'is-active': showNoteModal }">
      <div class="modal-background" @click="hideNoteModalAction"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="d-flex">
            <p class="title-font is-size-5" style="flex-grow: 1;">
              Notes (
              {{ selectedSecitonDisplayName }}
              )
            </p>
            <i
              class="fa fa-close"
              @click="hideNoteModalAction"
              style="cursor: pointer; font-size: 1.5rem;"
            ></i>
          </div>
          <div>
            <textarea
              class="textarea"
              v-model="noteText"
              placeholder="Write Note here.."
              :rows="2"
            >
            </textarea>
            <div v-if="currentNote" class="mt5">
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon text-none white--text"
                :disabled="noteText.trim() == ''"
                @click="updateNote"
              >
                Update Note
              </v-btn>
              <v-btn rounded
                color="grayColor"
                class="button-with-icon text-none white--text ml10"
                @click="cancelEditNoteMode">
                Cancel
              </v-btn>
            </div>
            <div v-else class="mt15">
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon text-none white--text"
                @click="addNote"
                :disabled="noteText.trim() == ''"
              >
                Add note
            </v-btn>
            </div>
          </div>
          <div
            v-if="currentContentBriefNotes.length > 0"
            class="mt5 box" style="max-height: 400px; overflow: auto;">
            <article
              class="message is-info"
              v-for="row in currentContentBriefNotes"
              :key="row.id"
            >
              <div class="message-header">
                <p>
                  {{ row.createdAt }}
                  <span v-if="row.isEdited" class="is-italic is-size-7">
                    ( edited: {{ row.editedAt }} )</span
                  >
                </p>
                <div>
                  <button
                    class="button is-small"
                    aria-label="delete"
                    @click="setEditNoteMode(row)"
                    v-if="!row.isAutoNote"
                  >
                    Edit
                  </button>
                  <button
                    v-if="!hideActionButtons"
                    class="button is-small is-dark"
                    aria-label="delete"
                    @click="deleteNote(row.id)"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div class="message-body">
                <p v-html="row.note.replace(/\n/g, '<br/>')"></p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal"
      :class="{ 'is-active': showRemoveRecoverTermConfirmModal }"
    >
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden;">
          <div class="pad25">
            <div class="title-font is-size-4 mb20">Confirm</div>
            <div class="subtitle">
              {{
                selectedCBData && selectedCBData.action == "remove"
                  ? "Do you really want to remove the term?"
                  : "Do you really want to recover the term?"
              }}
            </div>
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="donotShowAgainWarningForRemoveRecoverCBTerm"
              />
              Please do not show this warning again.
            </label>
          </div>
          <div class="has-background-light pad25 has-text-right">
            <button
              class="button ready-button"
              @click="performRemoveRecoverAction"
              :disabled="!selectedCBData"
            >
              {{
                selectedCBData && selectedCBData.action == "remove"
                  ? "Remove it"
                  : "Recover it"
              }}
            </button>
            <button
              class="button is-light"
              @click="() => (showRemoveRecoverTermConfirmModal = false)"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <page-setting-modal ref="pageSettingModal" :disableDeletePage="true" />

    <div v-if="selectedCBItems.length > 0" class="action-bar">
      <div class="left-area">
        {{ selectedCBItems.length }}
      </div>
      <div class="middle-area">
        <div class="item-selected-text">
          {{ selectedCBItems.length > 1 ? 'items': 'item' }} selected
        </div>
        <div
          class="remove-btn"
          @click="removeTerms">
          <span class="material-icons-outlined">delete_sweep</span>
          <span>Remove</span>
        </div>
        
      </div>
      <div class="right-area">
        <span class="material-icons close-button"
          @click="() =>{
            selectedCBItems = []
          }">
          close
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { FulfillingSquareSpinner } from 'epic-spinners'
import { mixin as cb_mixin } from "./cb_mixin";
import { StopLightBGColorOptions } from '@/components/PageRun/ContentBrief/content_brief_constants.js'
import PageSettingModal from "@/components/PageSettingModal.vue";
import { TARGET_MIN_PERCENTAGE } from "@/components/PageRun/constants";
import { mixin as ContentBriefMixin } from '@/components/content_brief_mixin'

let stateColors = [
  "#FE525C",
  "#FD777F",
  "#FCA2A8",
  "#96E8B3",
  "#64DF90",
  "#96E8B3",
  "#FCD4A8",
  "#FDC17F",
  "#FEB15C"
];
let borderColors = [
  "#FF1F2D",
  "#FF1F2D",
  "#FF1F2D",
  "#00CD49",
  "#00CD49",
  "#00CD49",
  "#FF9B2D",
  "#FF9B2D",
  "#FF9B2D"
];

let stoplightColor = [
  "#FF1F2D",
  "#FF6266",
  "#FF8F91",
  "#22DC7C",
  "#00CD49",
  "#22DC7C",
  "#FF9B2D",
  "#FFB95A",
  "#FF9B2D"
];

let TYPE_ORDER = { keyword: 0, variation: 1, lsi: 2 };

export default {
  props: [
    "selectedMainTab",
    "createdAt",
    "url",
    "fakeScore",
    "keyword",
    "secondaryKeywords",
    "lsaPhrases",
    "pageRunId",
    "pageNotBuilt",
    "page",
    "ignoreSpaces",
    "willUseSecretKey",
    "secretKey",
    "contentBrief",
    "runStrategies",
    "tagTotalData",
    "wordCountData",
    "sectionCount",
    "gCompetitors",
    "relatedSearches",
    "relatedQuestions",
    "contentBriefFile",
    "isFromExtension",
    "showAsMobileView",
    "hideActionButtons",
    "excludedTermsByUser",
    "isAIMode",
    "isCalculating",
  ], // showAsMobileView : this will be true if this component will display inside extension modal or source editor modal
  mixins: [cb_mixin, ContentBriefMixin],
  components: {
    PageSettingModal,
    FulfillingSquareSpinner,
  },
  data() {
    return {
      expandedQuestion: "",
      sortMethod: "importance",
      showMorePageStructure: false,
      showMoreGCompetitors: false,
      showMoreRelatedSearches: false,
      showMoreRelatedQuestions: false,

      showSeatchEngineTitleExplainText: false,
      showPageTitleExplainText: false,
      showSubtitlesExplainText: false,
      showMainContentExplainText: false,

      termDispType: "table", // table/list
      isCollapseSummaryArea: false,
      isCollapseSearchEngineTitleArea: false,
      isCollapsePageTitleArea: false,
      isCollapseSubHeadingsArea: false,
      isCollapseMainContentArea: false,

      showNoteModal: false,
      selectedSection: "title", // title/subHeadings/p
      noteText: "",
      notes: [],
      currentNote: null, // in create mode this will be null, in edit mode this will be note

      TARGET_MIN_PERCENTAGE: TARGET_MIN_PERCENTAGE,

      showRemoveRecoverTermConfirmModal: false,
      donotShowAgainWarningForRemoveRecoverCBTerm: false,
      selectedCBData: null,
      selectedCBItems: [],
    };
  },
  computed: {
    isDemo () {
      return this.$store.getters.isDemoUser
    },
    user() {
      return this.$store.state.user;
    },

    nlpTerms() {
      let terms = this.lsaPhrases.reduce((rlt, item) => {
        if (item.isNLPTerm) {
          rlt.push(item.phrase);
        }
        return rlt;
      }, []);
      return terms;
    },
    googleNlpTerms() {
      let terms = this.lsaPhrases.reduce((rlt, item) => {
        if (item.isGoogleNLPTerm) {
          rlt.push(item.phrase);
        }
        return rlt;
      }, []);
      return terms;
    },
    contentBriefStatus() {
      let status = {};
      if (this.page && this.page.contentBriefStatus) {
        status = this.page.contentBriefStatus;
      }
      if (!status["title"]) status["title"] = "InCompleted";
      if (!status["subHeadings"]) status["subHeadings"] = "InCompleted";
      if (!status["p"]) status["p"] = "InCompleted";
      return status;
    },
    currentContentBriefNotes() {
      return this.notes.filter(item => item.type == this.selectedSection);
    },
    cleanedContentBrief() {
      if (this.contentBrief) {
        return this.contentBrief;
      }
      let found = this.runStrategies.find(
        item => item.dashboardType == "contentBrief"
      );
      if (found) {
        return found;
      }
      return {};
    },
    hasContentBriefData() {
      return (
        !!this.cleanedContentBrief &&
        Object.keys(this.cleanedContentBrief).length > 0
      );
    },
    hasPageTitleData() {
      return !!this.cleanedContentBrief["pageTitle"];
    },

    titleData() {
      return this.sortContentBriefData("title", this.cleanedContentBrief["title"]);
    },
    pageTitleData() {
      return this.sortContentBriefData(
        "pageTitle",
        this.cleanedContentBrief["pageTitle"] || []
      );
    },
    subHeadingsData() {
      return this.sortContentBriefData("subHeadings", this.cleanedContentBrief["subHeadings"]);
    },
    /**
     * Function Name: bodyContentData
     * if user is in Pro tab, then limit main content size to 30
     * else, display all the elements.
     */
    bodyContentData() {
      if (
        this.cleanedContentBrief["p"].length > 30 &&
        this.selectedMainTab == "standard"
      ) {
        return this.sortContentBriefData(
          "p",
          this.cleanedContentBrief["p"].slice(0, 30)
        );
      } else {
        return this.sortContentBriefData("p", this.cleanedContentBrief["p"]);
      }
    },
    titleTotal() {
      return this.cleanedContentBrief["titleTotal"];
    },
    pageTitleTotal() {
      return (
        this.cleanedContentBrief["pageTitleTotal"] || {
          current: 0,
          min: 0,
          max: 0
        }
      );
    },
    subTitleTotal() {
      return this.cleanedContentBrief["subHeadingsTotal"];
    },
    contentTotal() {
      return this.cleanedContentBrief["pTotal"];
    },
    excludedTermsForTitle() {
      return this.excludedTermsByUser.title || [];
    },
    excludedTermsForPageTitle() {
      return this.excludedTermsByUser.pageTitle || [];
    },
    excludedTermsForSubHeadings() {
      return this.excludedTermsByUser.subHeadings || [];
    },
    excludedTermsForP() {
      return this.excludedTermsByUser.p || [];
    },
    score() {
      return this.cleanedContentBrief["pageScore"]["pageScore"];
    },
    subHeadingsTarget() {
      return this.sectionCount
    },

    uniqueUrlForEditor() {
      if (!this.secretKey) return "";
      let routeData = this.$router.resolve({
        name: "FullPageSourceEditor",
        query: { secretKey: this.secretKey }
      });
      return window.location.origin + "/" + routeData.href;
    },
    selectedSecitonDisplayName() {
      switch (this.selectedSection) {
        case "title":
          return "Search Engine Title";
        case "pageTitle":
          return "Page Title";
        case "subHeadings":
          return "SubHeadings";
        case "p":
          return "Main Content";
        default:
          return "";
      }
    },
    scoreStatusMsg () {
      return this.scoreToText(this.score)
    }
  },
  watch: {
    isAIMode: function (val) {
      console.log('watch function is called bb', val);
      if (val == true) {
        this.collapseAllContentBriefSection();
      }
    }
  },
  methods: {
    fakeWC () {
      if (!this.$store.getters.isDemoUser) return;

      const aiStep = this.$store.getters.aiWriterStep;
      const isMainDone = this.$store.state.isDemoMainContentDone;
      if (isMainDone && aiStep > 6) return parseInt(this.wordCountData[1])
      else if (aiStep > 6) return parseInt(this.wordCountData[1] * 0.7)
      else if (aiStep > 5) return parseInt(this.wordCountData[1] * 0.4)
      else if (aiStep > 4) return parseInt(this.wordCountData[1] * 0.3)
    },
    collapseAllContentBriefSection () {
      this.isCollapseSearchEngineTitleArea =  true;
      this.isCollapsePageTitleArea =  true;
      this.isCollapseSubHeadingsArea =  true;
      this.isCollapseMainContentArea =  true;
    },
    enterPageSetting() {
      // console.log(this.page)
      // console.log(this.$refs.pageSettingModal)
      this.$refs.pageSettingModal.show(this.page);
    },
    isSecondaryKeyword(term) {
      let secondaryKeywords = this.secondaryKeywords || [];
      return secondaryKeywords.includes(term);
    },
    isNLPTerm(term) {
      return (this.nlpTerms || []).includes(term);
    },
    isGoogleNLPTerm(term) {
      return (this.googleNlpTerms || []).includes(term);
    },
    performRemoveRecoverAction() {
      if (!this.selectedCBData) {
        console.log("no any item selected");
        return;
      }
      this.showRemoveRecoverTermConfirmModal = false;
      console.log("Selected data", this.selectedCBData);
      switch (this.selectedCBData.action) {
        case "remove":
          this.removeTerm();
          break;
        case "recover":
          this.recoverTerm();
          break;
        default:
          break;
      }
    },
    removeOrRecoverTerm(action, item, sectionName) {
      let doNotNeedDisplayConfirmModal;

      this.selectedCBData = {
        action,
        item,
        sectionName
      };
      switch (action) {
        case "remove":
          doNotNeedDisplayConfirmModal =
            this.user.settings.dontDisplayCBRemoveConfirmModal || false;
          if (doNotNeedDisplayConfirmModal) {
            setTimeout(() => {
              this.performRemoveRecoverAction();
            }, 100);
          } else {
            this.donotShowAgainWarningForRemoveRecoverCBTerm = false;
            this.showRemoveRecoverTermConfirmModal = true;
          }
          break;
        case "recover":
          doNotNeedDisplayConfirmModal =
            this.user.settings.dontDisplayCBRecoverConfirmModal || false;
          if (doNotNeedDisplayConfirmModal) {
            setTimeout(() => {
              this.performRemoveRecoverAction();
            }, 100);
          } else {
            this.donotShowAgainWarningForRemoveRecoverCBTerm = false;
            this.showRemoveRecoverTermConfirmModal = true;
          }
          break;
        default:
          break;
      }
    },
    removeTerm() {
      let { item, sectionName } = this.selectedCBData;
      let data = {
        siteId: this.page.siteId,
        pageId: this.page.id,
        pageRunId: this.pageRunId,
        sectionName,
        termData: item
      };
      if (this.donotShowAgainWarningForRemoveRecoverCBTerm) {
        data["doNotDisplayWarningAgain"] = true;
      }
      this.$store.commit("showLoading");
      this.$store
        .dispatch("removeTermFromCB", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.$notify({
              group: 'info', type: 'success',
              title: 'Alert', text: 'Success!'
            });
            if (response.data.pageRun) {
              this.$store.commit("updatePageRun", response.data.pageRun);
            }
            if (response.data.user) {
              this.$store.commit("setUser", response.data.user);
            }

            this.$emit('needChecklistUpdate')
          } else {
            this.$notify({
              group: 'info', type: 'error',
              title: 'Alert', text: 'Failed to remove term.'
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    recoverTerm() {
      let { item, sectionName } = this.selectedCBData;
      let data = {
        siteId: this.page.siteId,
        pageId: this.page.id,
        pageRunId: this.pageRunId,
        sectionName,
        termData: item
      };

      if (this.donotShowAgainWarningForRemoveRecoverCBTerm) {
        data["doNotDisplayWarningAgain"] = true;
      }

      this.$store.commit("showLoading");
      this.$store
        .dispatch("recoverTermForCB", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.$notify({
              group: 'info', type: 'success',
              title: 'Alert', text: 'Success!'
            });
            if (response.data.pageRun) {
              this.$store.commit("updatePageRun", response.data.pageRun);
            }
            if (response.data.user) {
              this.$store.commit("setUser", response.data.user);
            }
          } else {
            this.$notify({
              group: 'info', type: 'error',
              title: 'Alert', text: 'Failed to recover term.'
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    removeTerms() {
      if (this.selectedCBItems.length == 0) {
        this.$notify({
          group: 'info', type: 'warning',
          title: 'Alert', text: 'Please select terms you want to remove.'
        })
        return;
      }
      let data = {
        siteId: this.page.siteId,
        pageId: this.page.id,
        pageRunId: this.pageRunId,
        selectedTerms: this.selectedCBItems,
      };
      if (this.donotShowAgainWarningForRemoveRecoverCBTerm) {
        data["doNotDisplayWarningAgain"] = true;
      }
      this.$store.commit("showLoading");
      this.$store
        .dispatch("removeTermsFromCB", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.selectedCBItems = []
            this.$notify({
              group: 'info', type: 'success',
              title: 'Alert', text: 'Success!'
            });
            if (response.data.pageRun) {
              this.$store.commit("updatePageRun", response.data.pageRun);
            }
            if (response.data.user) {
              this.$store.commit("setUser", response.data.user);
            }
            this.$emit('needChecklistUpdate')
          } else {
            this.$notify({
              group: 'info', type: 'error',
              title: 'Alert', text: 'Failed to remove term.'
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    getDateAsLocalString(dateStr) {
      return new Date(dateStr.replace(" ", "T") + "Z").toLocaleString();
    },
    getSubFolderPartFromUrl(url) {
      if (this.pageNotBuilt) {
        return "N / A";
      }
      if (!url || url.length <= 8) {
        return "";
      }
      let idx = url.indexOf("/", 8);
      let rlt = url.substr(idx).trim();

      if (idx == -1 || rlt == "/" || rlt == "") {
        return "Homepage";
      }
      return rlt;
    },
    openUrlInNewTab(url) {
      window.open(url, "_blank");
    },
    updateContentBriefSectionStatus(sectionName, status) {
      // this.contentBriefStatus[sectionName])
      if (this.contentBriefStatus[sectionName] == status) {
        return;
      }
      let data = {
        willUseSecretKey: this.willUseSecretKey,
        secretKey: this.secretKey,
        pageId: this.page.id,
        sectionName,
        status
      };
      this.$store.commit("showLoading");
      this.$store
        .dispatch("updateContentBriefStatus", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            if (response.data.page) {
              if (this.isFromExtension) {
                this.$emit("updateCurrentPage", response.data.page);
              } else {
                this.$store.commit("setPage", response.data.page);
              }
            }
          }
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
    displayNoteModalFor(sectionName) {
      this.selectedSection = sectionName;
      this.showNoteModal = true;
    },
    hideNoteModalAction() {
      this.showNoteModal = false;
      this.currentNote = null;
    },
    cancelEditNoteMode() {
      this.currentNote = null;
    },
    addNote() {
      let data = {
        willUseSecretKey: this.willUseSecretKey,
        secretKey: this.secretKey,
        pageRunId: this.pageRunId,
        type: this.selectedSection,
        note: this.noteText
      };
      this.$store.commit("showLoading");
      this.$store
        .dispatch("createContentBriefNote", data)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            let note = response.data.note;
            this.notes = [note, ...this.notes];
            this.noteText = "";
          }
        })
        .finally(() => this.$store.commit("hideLoading"));
    },
    setEditNoteMode(note) {
      this.currentNote = note;
      this.noteText = note.note;
    },
    updateNote() {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("updateContentBriefNote", {
          willUseSecretKey: this.willUseSecretKey,
          secretKey: this.secretKey,
          noteId: this.currentNote.id,
          note: this.noteText
        })
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            let updatedNote = response.data.note;
            this.notes = this.notes.map(item => {
              if (item.id == updatedNote.id) {
                return updatedNote;
              }
              return item;
            });
            this.noteText = "";
            this.currentNote = null;
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    deleteNote(noteId) {
      if (!window.confirm("Do you want to delte this note ?")) {
        return;
      }
      this.$store.commit("showLoading");
      this.$store
        .dispatch("deleteContentBriefNote", noteId)
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            let updatedNote = response.data.note;
            this.notes = this.notes.filter(item => {
              if (item.id == noteId) {
                return false;
              }
              return true;
            });
            this.noteText = "";
            this.currentNote = null;
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },

    sortContentBriefData(contentType, contentBriefData) {
      let data = contentBriefData;
      const colors = Object.keys(StopLightBGColorOptions).map(key=>StopLightBGColorOptions[key]).sort((a,b)=>(a.order - b.order)).map(c=>c.color)

      data.sort((a, b) => {
        if (this.sortMethod == "status") {
          const acolorIndex = colors.indexOf(this.getStopLightColorForItem(contentType, a))
          const bcolorIndex = colors.indexOf(this.getStopLightColorForItem(contentType, b))
          return acolorIndex - bcolorIndex
        }
        if (this.sortMethod == "alphabetic") {
          if (a.term.phrase < b.term.phrase) return -1;
          else if (a.term.phrase > b.term.phrase) return 1;
          return 0;
        } else {
          if (a.term.type == b.term.type) {
            if (this.sortMethod == "importance") {
              if (a.term.weight < b.term.weight) return 1;
              else if (a.term.weight > b.term.weight) return -1;
              return 0;
            } else if (this.sortMethod == "deficit") {
              if (a.term.weight < b.term.weight) return -1;
              else if (a.term.weight > b.term.weight) return 1;
              return 0;
            }
          } else {
            if (this.sortMethod == "importance") {
              if (TYPE_ORDER[`${a.term.type}`] < TYPE_ORDER[`${b.term.type}`])
                return -1;
              if (TYPE_ORDER[`${a.term.type}`] > TYPE_ORDER[`${b.term.type}`])
                return 1;
              return 0;
            } else if (this.sortMethod == "deficit") {
              if (TYPE_ORDER[`${a.term.type}`] < TYPE_ORDER[`${b.term.type}`])
                return 1;
              if (TYPE_ORDER[`${a.term.type}`] > TYPE_ORDER[`${b.term.type}`])
                return -1;
              return 0;
            }
          }
        }
      });
      return data;
    },
    downloadAsTxt(contentBriefType) {
      let fileName = "";
      let data = "";
      switch (contentBriefType) {
        case "title":
          this.titleData.map(item => {
            data += `${item.term.phrase} ( ${item.contentBrief.current} / ${
              item.term.type == "keyword"
                ? item.contentBrief.target
                : item.contentBrief.targetMin +
                  " - " +
                  item.contentBrief.targetMax
            } )\r\n`;
          });
          fileName = `ContentBrief_${contentBriefType}.txt`;
          break;
        case "pageTitle":
          this.pageTitleData.map(item => {
            data += `${item.term.phrase} ( ${item.contentBrief.current} / ${
              item.term.type == "keyword"
                ? item.contentBrief.target
                : item.contentBrief.targetMin +
                  " - " +
                  item.contentBrief.targetMax
            } )\r\n`;
          });
          fileName = `ContentBrief_${contentBriefType}.txt`;
          break;
        case "subHeadings":
          fileName = `ContentBrief_${contentBriefType}.txt`;
          this.subHeadingsData.map(item => {
            data += `${item.term.phrase} ( ${item.contentBrief.current} / ${
              item.term.type == "keyword"
                ? item.contentBrief.target
                : item.contentBrief.targetMin +
                  " - " +
                  item.contentBrief.targetMax
            } )\r\n`;
          });
          break;
        case "p":
          fileName = `ContentBrief_BodyContent.txt`;
          this.bodyContentData.map(item => {
            data += `${item.term.phrase} ( ${item.contentBrief.current} / ${
              item.term.type == "keyword"
                ? item.contentBrief.target
                : item.contentBrief.targetMin +
                  " - " +
                  item.contentBrief.targetMax
            } )\r\n`;
          });
          break;
      }
      if (data) {
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(data)
        );
        element.setAttribute("download", fileName);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }
    },

    pageScoreTintColor(score) {
      let value = score ? score : 0;
      if (value >= 0 && value <= 69) {
        return "#FF1F2D";
      } else if (value > 69 && value <= 74) {
        return "#FF9B2D";
      } else if (value > 74 && value <= 80) {
        return "#FF9B2D";
      } else if (value > 80 && value <= 83) {
        return "#00CD49";
      } else if (value > 83 && value <= 85) {
        return "#00CD49";
      } else if (value > 85 && value <= 88) {
        return "#00CD49";
      } else if (value > 88 && value <= 90) {
        return "#00CD49";
      } else if (value > 90 && value <= 93) {
        return "#00CD49";
      } else if (value > 93 && value <= 95) {
        return "#00CD49";
      } else if (value > 95) {
        return "#00CD49";
      }
      return "#000000";
    },
    getBlockBGAndBorder(type, item) {
      return ["transparent", borderColors[8]];

      let { current, target, targetMin, targetMax } = item.contentBrief;

      let targetValue = 0;
      if (type == "keyword") {
        targetValue = target;
      } else {
        if (targetMin > 0) targetValue = targetMin;
        else targetValue = targetMax / 2;
      }

      if (targetValue == 0) return stateColors[4];
      let value = (current * 100.0) / targetValue;

      if (value >= 0 && value <= 20) {
        return [stateColors[0], borderColors[0]];
      } else if (value > 20 && value <= 40) {
        return [stateColors[1], borderColors[1]];
      } else if (value > 40 && value <= 60) {
        return [stateColors[2], borderColors[2]];
      } else if (value > 60 && value <= 80) {
        return [stateColors[3], borderColors[3]];
      } else if (value > 80 && value <= 100) {
        return [stateColors[4], borderColors[4]];
      } else if (value > 100 && value <= 120) {
        return [stateColors[5], borderColors[5]];
      } else if (value > 120 && value <= 140) {
        return [stateColors[6], borderColors[6]];
      } else if (value > 140 && value <= 160) {
        return [stateColors[7], borderColors[7]];
      } else if (value > 160) {
        return [stateColors[8], borderColors[8]];
      }
    },
    occurrencesInString(str, findingWord) {
      if (this.ignoreSpaces) {
        return (str.match(new RegExp(findingWord, "gi")) || []).length;
      } else {
        return (str.match(new RegExp(`\\b${findingWord}\\b`, "gi")) || [])
          .length;
      }
    },
    needsToDisplayUseAlone(sectionName, item) {
      let data = [];
      switch (sectionName) {
        case "title":
          data = this.pageTitleData;
          break;
        case "subHeadings":
          data = this.subHeadingsData;
          break;
        case "p":
          data = this.bodyContentData;
          break;
        default:
          break;
      }
      let rlt = false;
      data.map(obj => {
        if (
          obj.term.phrase != item.term.phrase &&
          this.occurrencesInString(obj.term.phrase, item.term.phrase)
        ) {
          rlt = true;
        }
      });
      return rlt;
    },
    getTooltipForColor(color) {
      switch (color) {
        case StopLightBGColorOptions.red.color:
          return 'You are below the target range for this term.  We recommend increasing the use of this term to get into the target range. '
          break;
        case StopLightBGColorOptions.yellow.color:
          return 'You are below the target range for this term.  We recommend increasing the use of this term to get into the target range. '
          break;
        case StopLightBGColorOptions.green.color:
          return 'You are in the target range for this term.  We recommend reaching at least the mid-point of all target ranges for best results.'
          break;
        case StopLightBGColorOptions.purple.color:
          return 'While you are over the target range for this term, we do not recommend reducing this term until you have increased the use of other terms first'
          break;
      
        default:
          return ''
          break;
      }
    },
    getStopLightStateTextForSection(contentBriefType) {
      const isMainDone = this.$store.state.isDemoMainContentDone;
      const isHeadingsDone = this.$store.state.isDemoHeadingsDone;
      if (this.isDemo) {
        if ((contentBriefType === 'subHeadings' && !isHeadingsDone) || (contentBriefType === 'p' && !isMainDone)) {
          return ['underoptimized', { 'underoptimize-1': true }, 'warning', '#FF9B2D']
        }
        return ['optimized', { 'optimized': true }, 'check_circle', '#00CD49']
      }
      let current = 0,
        targetMin = 0,
        targetMax = 0;
      switch (contentBriefType) {
        case "title":
          current = this.titleTotal["current"];
          targetMin = this.titleTotal["min"];
          targetMax = this.titleTotal["max"];
          break;
        case "pageTitle":
          current = this.pageTitleTotal["current"];
          targetMin = this.pageTitleTotal["min"];
          targetMax = this.pageTitleTotal["max"];
          break;
        case "subHeadings":
          current = this.subTitleTotal["current"];
          targetMin = this.subTitleTotal["min"];
          targetMax = this.subTitleTotal["max"];
          break;
        case "p":
          current = this.contentTotal["current"];
          targetMin = this.contentTotal["min"];
          targetMax = this.contentTotal["max"];
          break;
        default:
          break;
      }
      let state = this.getStopLightStateText(
        contentBriefType,
        current,
        targetMin,
        targetMax,
        true
      );
      switch (state) {
        case 'underoptimized2':
          return ['underoptimized', { 'underoptimize-2' : true }, 'warning', '#FF1F2D']
        case 'underoptimized1':
          return ['underoptimized', { 'underoptimize-1': true }, 'warning', '#FF9B2D']
        case 'optimized':
          return ['optimized', { 'optimized': true }, 'check_circle', '#00CD49']
        default:
          return ['', '', '', '']
      }
    },
    showNoSecondaryKeywordsInSection (contentBriefType) {
      if (this.secondaryKeywords && this.secondaryKeywords.length == 0) {
        return false;
      }

      let data = [];
      switch (contentBriefType) {
        case "title":
          data = this.titleData;
          break;
        case "pageTitle":
          data = this.pageTitleData;
          break;
        case "subHeadings":
          data = this.subHeadingsData;
          break;
        case "p":
          data = this.bodyContentData;
          break;
        default:
          break;
      }
      let terms = data.map(item => item.term.phrase);
      for (const keyword of this.secondaryKeywords) {
        if (terms.includes(keyword)) {
          return false;
        }
      }
      return true;
    },
    loadNotes() {
      this.$store
        .dispatch("getContentBriefNotes", {
          pageRunId: this.pageRunId,
          willUseSecretKey: this.willUseSecretKey,
          secretKey: this.secretKey
        })
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.notes = response.data.notes;
          }
        });
    },
    downloadContentBrief(downloadType) {
      switch (downloadType) {
        case "excel":
        case "pdf":
          this.$store
            .dispatch("downloadContentBrief", { downloadType, pageRunId: this.pageRunId })
            .then(response => {
              if (response.data && response.data.status == "SUCCESS") {
                let contentBriefDataFile = response.data.contentBriefDataFile;
                let downloadUrl = "";
                let fileName = "";
                switch (downloadType) {
                  case "excel":
                    fileName = contentBriefDataFile["excel"]
                    break;
                  case "pdf":
                    fileName = contentBriefDataFile["pdf"]
                    break;
                }
                downloadUrl = `${API_URL}/download/?file=${
                  fileName
                }`;
                this.downloadFile(downloadUrl);
              } else {
                console.log("failed to make content brief");
              }
            })
            .catch(error => {
              console.log("error check server side");
            });
          break;
        default:
          break;
      }
    },
    downloadFile(downloadUrl) {
      if (!downloadUrl) return;
      let fileName = downloadUrl.split("/").pop();
      let downloadLink;
      downloadLink = document.createElement("a");
      downloadLink.download = fileName;
      downloadLink.href = downloadUrl;
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setTimeout(() => {
        document.body.removeChild(downloadLink);
      }, 100);
    }
  },
  mounted() {
    if (!this.isFromExtension || this.willUseSecretKey) {
      this.loadNotes();
    }
    if (this.isAIMode) {
      this.collapseAllContentBriefSection();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./content-brief.scss";

.copy-to-clipboard-button {
  width: 12px;
  margin-right: 0.3rem;
  cursor: pointer;
}
.font-large {
    font-size: 20px;
    font-weight: bold;
}

.font-bold {
    font-weight: bold;
}
.report-card-dot {
  float: right;
}
.my-notes-btn {
  display: inline-flex;
  align-items: center;
  i {
    font-size: 12px;
    margin-right: 5px;
  }
 color: var(--v-profileAnchorColor-base);
}
.show-hide-link {
  font-size: 0.9rem;
  line-height: 24px;
  text-decoration: none;
  color: var(--v-gray2Color-base);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  i {
    margin-left: -8px;
  }
  span {
    text-decoration: underline;
  }
}
.stoplights-box {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 0 0 0 5px;
  background: lightgray;
}
.section-count-disp {
  display: flex;
  align-items: center;
  gap: 16px;
  * {
    color: var(--v-darkGrayColor-base);
  }
  .section-count-number {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--v-mainColor3-base);
    margin-bottom: 4px;
  }
  .section-count-label {
    font-size: 0.9rem;
  }
  & > div:first-child {
    border-right: 0.59px solid #BCBCBC;
    padding-right: 16px;
  }
}

</style>

<style lang="scss">
.optimization-progress {

  .v-progress-linear__background {
    background-color: #E6E9F0 !important;
  }

  .underoptimize-2 {
    .v-progress-linear__determinate {
      background-color: #FF1F2D !important;
    }
  }
  .underoptimize-1 {
    .v-progress-linear__determinate {
      background-color: #FF9B2D !important;
    }
  }
  .optimized {
    .v-progress-linear__determinate {
      background-color: #00CD49 !important;
    }
  }
}
</style>