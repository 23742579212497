<template>
  <div class="modal" :class="{'is-active': true}">
    <div class="modal-background" @click.stop="() => !submitting ? $emit('close') : undefined"></div>
    <div class="modal-content">
      <div class="modal-container">
        
        <div class="mb-6 mainColor--text font-weight-bold" style="font-size: 1.3rem;">
          Get GSC data
        </div>

        <div class="card-small-title mb-2">
          Connect Google account
        </div>

        <google-login></google-login>
        
        <div class="mt-2" style="color: #595959;"><i class="material-icons-outlined" style="font-size: 0.9rem;">lock</i> We don’t access or store any information from your Google account except for GSC data.</div>

        <div class="mt-10 location-lang-domain-area">
          <div class="mb-5">
            <div class="card-small-title mb-1">
              Select location
            </div>
            <v-autocomplete outlined dense hide-details="auto"
              :items="availableLocations"
              item-text="location_name"
              item-value="location_code"
              :value="config.wizardLocation"
              @input="value => setConfig('wizardLocation', value)"
              color="grayColor">
            </v-autocomplete>
            <div class="mt-2 d-flex align-center gap5">
              <vue-toggles
                :value="config.wizardIsCountryEnabled"
                @click="() => setConfig('wizardIsCountryEnabled', !config.wizardIsCountryEnabled)"
                width="40" height="20"
                :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
              />
              <div class="mainColor--text font-weight-bold">Enable country</div>
            </div>
          </div>
          <div class="mb-5">
            <div class="card-small-title mb-1">
              Select language
            </div>
            <v-autocomplete outlined dense hide-details
              :items="targetLanguages"
              :value="config.wizardLanguage"
              @input="value => setConfig('wizardLanguage', value)"
              item-text="language_name"
              item-value="language_code"
              color="grayColor">
            </v-autocomplete>
          </div>
          <div class="mb-5">
            <div class="card-small-title mb-1">
              Add Domain name
            </div>
            <domain-selector
              :baseDomain="campaign?.targetUrl || ''"
              :initialDomain="config.wizardDomain || campaign?.targetUrl || config.wizardPODomain"
              @onChangeDomain="(d) => {
                googleSiteUrl = d;
                setConfig('wizardDomain', d);
              }"
            />
          </div>
          <div class="mb-8">
            <div class="card-small-title mb-1">
              Select period
            </div>
            <v-autocomplete outlined dense hide-details
              :items="availablePeriods"
              :value="config.wizardPeriod"
              @change="(value) => setConfig('wizardPeriod', availablePeriods.find(period => period.value === value))"
              item-text="label"
              color="grayColor">
            </v-autocomplete>
          </div>
        </div>

        <div class="d-flex justify-end">
          <v-btn rounded
            color="profileAnchorColor"
            class="text-none whiteColor--text"
            :disabled="!canProceed"
            :loading="submitting"
            @click="onStart"
            >
            <span>Get GSC data</span>
          </v-btn>
        </div>



      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        :disabled="submitting"
        @click.stop="$emit('close')">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
</template>

<script>
import GoogleLogin from '@/components/Campaigns/GoogleLogin.vue';
import DomainSelector from '@/components/Campaigns/DomainSelector.vue';

import languages from '@/consts/dataforseo_languages.json';

import dataForSeoLocations from '@/consts/dataforseo_locations.json';

import { gapi } from 'gapi-script';

import { getCountryCode_ISO3 } from '@/utils/search-console-helper.js';
import { DATE_RANGE_OPTIONS, getOptionDate } from '@/components/Campaigns/keyword-wizard/DateRangeSelect.vue';
import { groupBy, forEach, sumBy, maxBy, meanBy } from "lodash"

let google_console_data_aggregated = []

export default {
  props: ['site', 'activeAudit'],
  components: {
    GoogleLogin,
    DomainSelector
  },
  data () {
    return {
      googleSiteUrl: '',
      submitting: false,
    }
  },
  computed: {
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return null
    },
    config() {
      return this.$store.getters.insightKeywordsConfig;
    },
    availableLocations () {
      return dataForSeoLocations.locations;
    },
    targetLanguages () {
      return languages;
    },
    availablePeriods () {
      return DATE_RANGE_OPTIONS
    },
    googleUser () {
      return this.$store.state.googleUser
    },
    campaign () {
      return this.$store.state.currentCampaign
    },
    targetUrl () {
      if (this.campaign) {
        return this.campaign.targetUrl
      }
      return ''
    },
    canProceed () {
      return this.googleUser && this.googleSiteUrl
    },
    wizardLanguage () {
      return this.config.wizardLanguage
    },
    wizardLocation () {
      return this.config.wizardLocation
    },
  },
  methods: {
    setConfig(key, value) {
      this.$store.commit("setInsightKeywordsConfig", { key, value });
    },
    getConsoleDataAggregated (startRow = 0) {
      
      const rowLimit = 25000
      const range = getOptionDate(this.config.wizardPeriod)
      const endDate = range.endDate
      const startDate = range.startDate

      let filterList = []
      if (this.config.wizardIsCountryEnabled) {
          filterList.push({
              "dimension": "COUNTRY",
              "expression": getCountryCode_ISO3(this.config.wizardLocation)
          })
      }
      if (this.config.wizardIsBrandedEnabled) {
          this.brandedTerms.slice(0, 10).map((keyword) => {
              filterList.push({
                  "dimension": "QUERY",
                  "operator": "excludingRegex",
                  "expression": `(?i)${keyword}`
              })
          })
      }

      const queryParams = {
        "siteUrl": this.googleSiteUrl,
        "resource": {
            "startDate": startDate,
            "endDate": endDate,
            "dimensionFilterGroups": [
                {
                    "filters": filterList
                }
            ],
            "dimensions": [
                "QUERY",
                "PAGE"
            ],
            "rowLimit": rowLimit,
            "startRow": startRow,
        }
      }

      gapi.client.webmasters.searchanalytics.query(queryParams)
        .then((response) => {
          if (response.status == 200) {
            if (response.result?.rows) {
              google_console_data_aggregated = [...google_console_data_aggregated, ...response.result.rows]
              if (response.result?.rows.length == rowLimit) {
                  this.getConsoleDataAggregated(startRow + rowLimit + 1)
              } else {
                // calculate cannibalization metrics to results
                let results = this.calcForKeywordCannibalization()
                // calculate total impressions for every page (needed for default sort when showing data to user)
                results = this.calcPageMetrics(results)

                let POresults = this.formatPOResults()

                // save data in backend, then show it to user
                return this.$store.dispatch('saveKeywordWizardResult', {
                  campaignId: this.campaign.id,
                  locationCode: this.config.wizardLocation,
                  languageCode: this.config.wizardLanguage,
                  tabType: 'SITE_ONLY_EXIST',
                  result: results,
                })
                  .then((response) => {
                    if (response.data && response.data.status == 'SUCCESS') {
                      return Promise.resolve()
                    } else {
                      this.$notify({
                        group: 'info', type: 'error',
                        text: 'Could not save results.'
                      })
                      return Promise.reject()
                    }
                  })
                  .then(() => {
                    return this.$store.dispatch('saveKeywordWizardResult', {
                      campaignId: this.campaign.id,
                      locationCode: this.config.wizardLocation,
                      languageCode: this.config.wizardLanguage,
                      tabType: 'PAGE_OPPORTUNITY',
                      result: POresults,
                      shouldReturnResult: true,
                    })
                      .then((response) => {
                        if (response.data && response.data.status == 'SUCCESS') {
                          return Promise.resolve()
                        } else {
                          this.$notify({
                            group: 'info', type: 'error',
                            text: 'Could not save results.'
                          })
                          return Promise.reject()
                        }
                      })
                  })
                  .then(() => {
                    return this.$store.dispatch('updateSiteContentAudit', {
                      auditId: this.activeAudit.id,
                      body: {
                        hasRetrievedFreshGSCData: 1,
                      }
                    }).then(() => {
                      this.$notify({
                        group: 'info', type: 'success',
                        text: 'Data successfully retrieved.'
                      })
                      this.$emit('success');
                      this.$emit('close');
                    })
                  })
                  .finally(() => {
                    this.submitting = false
                  })
              }
            }
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: 'no history found'
            });

            this.submitting = false
          }
        },
        (err) => {
          this.$notify({
            group: 'info', type: 'error',
            text: 'Failed to get analystic data'
          });

          this.submitting = false
        });
    },
    calcForKeywordCannibalization () {
      let data = google_console_data_aggregated.map((d, index) => {
        return {
          ...d,
          keyword: d.keys[0],
          clicks: d.clicks,
          page: d.keys[1],
          ctr: Number(Number(d.ctr * 100).toFixed(2)),
          position: Number(Number(d.position).toFixed(2)),
          index: index,
        }
      })

      data.sort((a, b) => {
        if (a.impressions == b.impressions) {
          if (a.keyword < b.keyword) return -1;
          if (a.keyword > b.keyword) return 1;
        } else {
          return Number(b.impressions) - Number(a.impressions);
        }
      });
      const dataByKeywords = groupBy(data, "keyword");

      forEach(dataByKeywords, (records) => {
        const count = records.length;
        if (count == 1) return;

        const avg = sumBy(records, "impressions") / count;

        forEach(records.slice(1), (el) => {
          const { impressions, index } = el;
          const diff = (avg - impressions) / avg;
          try {
            data[index]['cannibalizedKeywords'] = records.map(r => {
              return {
                page: r.page,
                keyword: r.keyword,
                clicks: r.clicks,
                impressions: r.impressions,
                ctr: r.ctr,
                position: r.position,
              }
            })
          } catch (eee) {
            console.log('Error on cannibalizedKeywords calculation', eee);
          }

          /**highlight with red above avg */
          if (impressions > avg) {
            return data[index]['keywordCannibalizationText'] = "high";
          }
          /**highlight with orange diff <= 25% */
          /**highlight with green diff > 25% */
          data[index]['keywordCannibalizationText'] = diff <= 0.25 ? "medium" : "low";
        })
      })
      return data;
    },
    /**
     * Adds additional per page metrics/aggregations to every row in the data
     */
    calcPageMetrics (data) {
      const dataByPage = groupBy(data, "page");

      const metricsPerPage = Object.fromEntries(
        Object.entries(dataByPage).map(([key, records]) => [key, {
          totalPageImpressions: sumBy(records, "impressions"),
          maxPagePosition: maxBy(records, "position").position,
        }])
      )

      return data.map(row => (
        {...row, ...metricsPerPage[row['page']]}
      ))
    },
    formatPOResults () {
      const google_data = google_console_data_aggregated.map((d) => {
        return {
          ...d,
          keyword: d.keys[0],
          page: d.keys[1],
          clicks: d.clicks,
          ctr: Number(Number(d.ctr * 100).toFixed(2)),
          position: Number(Number(d.position).toFixed(2))
        }
      })

      const google_data_by_page = groupBy(google_data, "page");

      let results = Object.entries(google_data_by_page).map(([key, records]) => ({
        page: key,
        impressions: sumBy(records, "impressions"),
        clicks: sumBy(records, "clicks"),
        avgCTR: Number(Number(
          meanBy(records, "ctr")
        ).toFixed(2)),
        avgPosition: Number(Number(
          meanBy(records, "position")
        ).toFixed(2)),
        keywordsCount: records.length,
      }))

      return results;
    },
    onStart() {
      this.submitting = true

      google_console_data_aggregated = []

      this.getConsoleDataAggregated(0)
    },
    loadDomainLevelCampaign () {
      this.$store.commit('showLoading')
      return this.$store.dispatch('getCampaignById', this.activeAudit.associatedDomainLevelCampaign)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            let campaign = response.data.campaign
            this.$store.commit('setCurrentCampaign', campaign)
            this.setConfig('wizardLanguage', this.campaign.languageCode)
            this.setConfig('wizardLocation', parseInt(this.campaign.locationCode))
            if (!this.googleSiteUrl && this.config.wizardDomain) {
              this.googleSiteUrl = this.config.wizardDomain
            }
            if (!this.config.wizardPeriod) this.setConfig('wizardPeriod', this.availablePeriods[1])  // to give default value from the computed property
            return Promise.resolve()
          }
          return Promise.reject('Invalid response')
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
  },
  mounted () {
    this.loadDomainLevelCampaign()
  },
}
</script>

<style lang="scss" scoped>
.google-login-root {
  .main-area {
    max-width: initial !important;
  }
}
</style>
