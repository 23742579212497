<template>
    <div class="start-page">
        <div class="start-page-container">
          <div style="flex: 1; display: flex; flex-direction: column; justify-content: center;">
              <div class="welcome-title">Start writing with POP AI Writer</div>
              <div class="mt-4 base-text-style">
                Choose your mode - instant and easy or fully customized.
              </div>
              <div class="mt-8 mb-8 writing-mode-options">
                  <div>
                      <div class="icon-badge">
                        <img src="/static/bolt_outlined_icon.svg" width="17" />
                      </div>
                      <div class="base-text-style" style="font-weight: 700; font-size: 1.2rem;">Auto Writing</div>
                      <div class="base-text-style mt-3 mb-12" style="color: var(--v-gray50Color-base);">Configure your settings, click, and let AI handle the rest. Your content, ready instantly.</div>
                      <v-btn large block outlined color="mainColor3" @click.stop="$emit('update:mode', 'auto'); goNext(STEPS.START)">
                        Start writing <i class="material-icons-outlined" style="font-size: 1rem;">arrow_forward</i>
                      </v-btn>
                  </div>
                  <div>
                      <div class="icon-badge">
                        <i class="material-icons-outlined">edit</i>
                      </div>  
                      <div class="base-text-style" style="font-weight: 700; font-size: 1.2rem;">Guided Writing</div>
                      <div class="base-text-style mt-3 mb-12" style="color: var(--v-gray50Color-base);">Create content step-by-step: start with a title, add subheadings, then build the full content.
                      </div>
                      <v-btn large block outlined color="mainColor3" @click.stop="$emit('update:mode', 'guided'); goNext(STEPS.START)">
                        Start writing <i class="material-icons-outlined" style="font-size: 1rem;">arrow_forward</i>
                      </v-btn>
                  </div>
              </div>

              <div class="tip-before-use">
                <div class="tip">
                  <div class="tip-title" style="color: var(--v-mainColor3-base); font-weight: 700;">{{ tips[currentTip].title }}</div>
                  <div class="tip-text mt-2" style="color: var(--v-gray50Color-base);">{{ tips[currentTip].text }}</div>
                </div>  
                <v-btn
                  v-if="tips[currentTip].btn_txt"
                  outlined color="mainColor3"
                  class="action-btn mainColor3--text text-none"
                  @click="tips[currentTip].btn_action">
                  <i class="material-icons-outlined mr-1" style="font-size: 1.2rem; margin-left: -3px;">play_circle</i>
                  {{ tips[currentTip].btn_txt }}
                </v-btn>
                <v-btn 
                  outlined
                  small
                  color="mainColor3"
                  class="mainColor3--text text-none next-btn"
                  @click="currentTip = currentTip === tips.length - 1 ? 0 : currentTip + 1">
                  <i class="material-icons-outlined" style="font-size: 0.9rem;">arrow_forward_ios</i>
                </v-btn>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'Step0Start',
    props: [
        'goNext',
        'mode',
    ],
    data() {
      return {
        currentTip: 0
      }
    },
    computed: {
        STEPS() {
            return STEPS
        },
        tips() {
          return [
          {
            title: 'Tip: Before you use POP AI Writer',
            text: 'You may want to clean up some important terms from the Content Brief. This improves AI readability and quality.',
            btn_txt: 'How to clean up terms',
            btn_action: () => {
                this.$store.commit('setCurrentVideoTutorialToDisplay', {
                    gifLink: '/static/ai_writer_clean_tutorial.gif',
                    gifWidth: 1100,
                    noWidthHeightConstraints: true,
                });
            },
          },
          {
            title: 'Tip: After you use POP AI Writer',
            text: 'It is best to think of AI content, from any tool, as a good first draft. Keep in mind that you may need to manually tweak the content a bit for tone, voice, and optimization.',
          },
        ]
      }
    },
}
</script>

<style lang="scss" scoped>
.start-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--v-mainBgColor-base);
  z-index: 10;
  overflow: auto;

  .start-page-container {
    display: flex;
    flex-direction: column;
    padding: 50px;
    max-width: 800px;
    margin: 0 auto;
  }

  .welcome-title {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 800;
    color: var(--v-mainColor3-base);
  }
  .base-text-style {
    font-size: 1rem;
    color: var(--v-mainColor3-base);
  }

  .writing-mode-options {
    display: flex;
    flex-direction: row;
    gap: 12px;
    & > div {
      flex: 1;
      border: 0.5px solid var(--v-normalLightGray-base);
      border-radius: 10px;
      padding: 18px;
      background: var(--v-whiteColor-base);
    }

    .icon-badge {
      background: #F2F7FD;
      padding: 8px;
      border-radius: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      width: 38px;
      height: 38px;

      i {
        font-size: 1.6rem;
        color: #3E65B7;
      }
    }
    
    .base-text-style {
      font-size: 0.9rem;
    }

    .v-btn {
      text-transform: none;
      border-color: #CCC;
    }

  }

  .tip-before-use {
    background: #E9F3FF;
    border: 1px solid #316BFF;
    border-radius: 8px;
    padding: 24px;

    display: flex;
    flex-direction: row;
    gap: 28px;
    align-items: center;

    .tip-text {
      font-size: 0.85rem;
      line-height: 1.5;
    }

    .action-btn {
      border: 0.5px solid var(--v-grayColor-base);
      background: var(--v-whiteColor-base);
      letter-spacing: initial;
      border-radius: 8px;
      margin-top: 0 !important;

      padding-right: 12px !important;
      padding-left: 12px !important;

      .v-btn__content {
        padding-top: 8px;
        padding-bottom: 8px;
        span {
          font-weight: normal;
          font-size: 0.85rem;
        }
      }

      i {
        color: var(--v-grayColor-base);
      }
    }

    .next-btn {
      border: none;
      background: var(--v-whiteColor-base);
      letter-spacing: initial;
      border-radius: 5px;
      margin-top: 0 !important;
      min-width: 28px;
      padding-right: 0 !important;
      padding-left: 0 !important;

      .v-btn__content {
        span {
          font-weight: normal;
          font-size: 0.85rem;
        }
      }

    }
  }
}

.theme--dark {
  .tip-before-use {
    border: 0.5px solid #52555C;
    background: #1B252F;
    color: var(--v-mainColor3-base);
  }
}
@media screen and (max-width: 800px) {
  .start-page {
    display: block;
  }
}
</style>

<style lang="scss">
.writing-mode-options {
  .v-btn {    
    .v-btn__content {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 0.9rem;
    }
  }
}
</style>