<template>
    <v-card>
        <div class="pa-4">
            <div class="font-large mb-4">Breakdown:</div>
            <div class="notify-label" v-if="aiRun?.useMyOwnApiKey">
                This article uses your own api key.
            </div>
            <div class="mt-4">
                <ul class="credits-breakdown-ul">
                    <li>
                        <span class="font-weight-bold">{{ articleCredits }} credits:</span> generate content ({{ numberOfWords }} words)
                    </li>
                    <li>
                        <span class="font-weight-bold">{{ richContentCreditsForArticle }} credits:</span> add rich content
                    </li>
                    <li v-if="titleRegenerateCount > 0">
                        <span class="font-weight-bold">{{ titleRegenerateCount }} credits:</span> search engine title regenerate
                    </li>
                    <li v-if="pageTitleRegenerateCount > 0">
                        <span class="font-weight-bold">{{ pageTitleRegenerateCount }} credits:</span> page title regenerate
                    </li>
                    <li v-if="subHeadingsRegenerateCount > 0">
                        <span class="font-weight-bold">{{ subHeadingsRegenerateCount }} credits:</span>  subheadings title regenerate
                    </li>
                    <li v-if="paragraphRegenerateCount > 0">
                        <span class="font-weight-bold">{{ paragraphRegenerateCount }} credits:</span>  main content regenerate
                    </li>
                    <li v-if="contentPromptSignalRegenerateCount > 0">
                        <span class="font-weight-bold">{{ contentPromptSignalRegenerateCount }} credits:</span> rich content regenerate
                    </li>
                </ul>
            </div>

            <v-btn
                outlined
                color="mainColor3"
                class="button-with-icon text-none mt-8"
                style="border-radius: 6px; border-color: var(--v-grayColor-base); width: 100%; letter-spacing: initial;"
                target="_blank"
                href="https://help.pageoptimizer.pro/article/how-pop-credits-work-with-the-pop-ai-writer"
            >
            How do credits work?
            </v-btn>

        </div>
    </v-card>
</template>

<script>

export default {
    props: [
        'articleCredits',
        'richContentCreditsForArticle',
        'titleRegenerateCount',
        'pageTitleRegenerateCount',
        'subHeadingsRegenerateCount',
        'paragraphRegenerateCount',
        'contentPromptSignalRegenerateCount',
        'totalCreditsSpent',
    ],
    data() {
        return {
            slider_options: {
                dotSize: 16,
                width: 'auto',
                height: 4,
                min: 0,
                max: 100,
                interval: 1,
                dotStyle: { border: '1px solid #D2D3D9' },
                processStyle: { background: '#FF5700' },
            },
        };
    },
    computed: {
        aiRun () {
            return this.$store.state.currentAIRun
        },
        numberOfWords () {
            if (!this.aiRun) return 0;
            return this.aiRun.wordCount;
        },
    },
};
</script>
<style lang="scss" scoped>
.font-large {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
}

.credits-breakdown-ul {
    list-style: initial;

    li:not(:last-child) {
        margin-bottom: 8px;
    }
}

.notify-label {
    color: var(--v-mainColor3-base);
}

</style>