<template>
    <div v-if="isLoading" class="progress-bar d-flex align-center gap10 mt-5 mb-5" style="flex-direction: column;">
        <v-progress-linear
            rounded
            height="8"
            class="progress-bar"
            background-color="gray7Color"
            color="#00CD49"
            :value="progressValue"
            style="max-width: 300px;"
        />
        <div v-if="loadingMessage" style="font-size: 0.9rem;">
            {{ loadingMessage }}...
        </div>
    </div>
  </template>
  
  <script>
    
  
  export default {
    name: 'ProgressBar',
    props: [
      'isDemo',  
      'isLoading',  
      'runningSection',
      'progress',
      'titleProgress',
      'subHeadProgress',
      'mainContentProgress',
    ],
    data () {
      return {
        
      }
    },
    computed: {
      loadingMessage() {
        if (this.runningSection === 'auto') {
            return 'Generating your article'
        }
        if (this.runningSection === 'title') {
            return 'Preparing your title'
        }
        if (this.runningSection === 'pageTitle' || (this.titleProgress > 0 && this.titleProgress < 100)) {
            return 'Preparing your title'
        }
        if (this.runningSection === 'subHeadings' || (this.subHeadProgress > 0 && this.subHeadProgress < 100)) {
            return 'Generating your sub-headings'
        }
        if (this.runningSection === 'p' || (this.mainContentProgress > 0 && this.mainContentProgress < 100)) {
            return 'Generating your main content'
        }
        return null
      },
      progressValue() {
        if (this.isDemo) {
            if (this.titleProgress > 0 && this.titleProgress < 100) {
                return this.titleProgress
            }
            if (this.subHeadProgress > 0 && this.subHeadProgress < 100) {
                return this.subHeadProgress
            }
            if (this.mainContentProgress > 0 && this.mainContentProgress < 100) {
                return this.mainContentProgress
            }
        }
        return this.progress
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>

  </style>