<template>
    <div>

      <div class="d-flex gap20 pb-4" style="border-bottom: 1px solid var(--v-gray13Color-base); margin-bottom: 20px;">
        <div style="flex:2;">
          <div class="font-weight-bold">Tone</div>
          <div class="mt-2">Examples you could use: formal or informal, positive or negative, funny, encouraging, neutral and more.</div>
        </div>
  
        <div style="flex:5;">
          <div :class="{ 'demo-highlight-f': isDemo }">
            <v-textarea
              outlined
              dense
              :value="toneOfContent"
              @input="$emit('update:toneOfContent', $event)"
              :disabled="isDemo"
              color="greenColor"
              counter="80"
              placeholder="Set your tone of voice.."
              :counter-value="countWords"
              :rules="[(v) => countWords(v) <= 80 || `Must be less than 80 words`]"
              rows="2"
            >
            </v-textarea>
          </div>
        </div>
      </div>

      <div class="d-flex gap20 pb-4" style="border-bottom: 1px solid var(--v-gray13Color-base); margin-bottom: 20px;">
        <div style="flex:2;">
          <div class="font-weight-bold">Author</div>
          <div class="mt-2">Experiment with different personas, such as lawyer, SEO, scientist, accountant and more.</div>
        </div>
  
        <div style="flex:5;">
          <div :class="{ 'demo-highlight-f': isDemo }">
            <v-textarea
              outlined
              dense
              :value="author"
              @input="$emit('update:author', $event)"
              :disabled="isDemo"
              color="greenColor"
              counter="80"
              placeholder="Describe the author.."
              :counter-value="countWords"
              :rules="[(v) => countWords(v) <= 80 || `Must be less than 80 words`]"
              rows="2"
            >
            </v-textarea>
          </div>
        </div>
      </div>

      <div class="d-flex gap20 pb-4">
        <div style="flex:2;">
          <div class="font-weight-bold">Brand</div>
          <div class="mt-2">Unique name given to a product, service, or business that distinguishes it from others in the market, such as "PageOptimizer PRO".</div>
        </div>
  
        <div style="flex:5;">
          <div :class="{ 'demo-highlight-f': isDemo }">
            <v-textarea
              outlined
              dense
              :value="brand"
              @input="$emit('update:brand', $event)"
              :disabled="isDemo"
              color="greenColor"
              counter="80"
              placeholder="Your brand name is.."
              :counter-value="countWords"
              :rules="[(v) => countWords(v) <= 80 || `Must be less than 80 words`]"
              rows="2"
            >
            </v-textarea>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigTone',
    props: [
        'isDemo',

        'countWords',
        'toneOfContent',
        'author',
        'brand',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>