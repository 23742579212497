<template>
    <div>
      {{ webPageType ? webPageTypeOptions.filter(option => option.value === webPageType)?.[0].text : 'Not selected' }}
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigPreviewWebPageType',
    props: [
        'webPageTypeOptions',
        'webPageType',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>