<template>
  <div class="step-scroll-target">
    <div class="mt-5 mb-1 mainColor3--text">
      Review and edit the outline before generating your article.
    </div>
    <v-card class="white-common-box pa-5">
      <template>
        <div class="result-one-section">
          <div v-if="calculated_titleAIResult" class="title-row">
            <span class="tag-symbol-span h1-tag">{{
              calculated_titleAIResult.shortenTagName
            }}</span>
            <span class="text-part h1-size">
              <div
                v-if="isTitleOrPageTitleInEditingMode('title')"
                class="d-flex align-center flex-gap-5"
              >
                <input
                  type="text"
                  class="flex-grow-1 edit-ai-result-input"
                  :disabled="isDemo"
                  :value="calculated_titleAIResult.text"
                  @input="$emit('update:calculated_titleAIResult', {
                    ...calculated_titleAIResult,
                    text: $event
                  })"
                />
                <div class="d-flex flex-gap-5">
                  <button
                    class="action-btn"
                    v-clipboard:copy="calculated_titleAIResult.text"
                    v-clipboard:success="
                      () =>
                        $notify({
                          group: 'info',
                          type: 'success',
                          text: 'Copied to clipboard',
                        })
                    "
                    v-clipboard:error="
                      () =>
                        $notify({
                          group: 'info',
                          type: 'error',
                          text: 'Cannot copy to clipboard',
                        })
                    "
                  >
                    <span class="material-icons-outlined"
                      >file_copy</span
                    >
                  </button>
                  <button
                    class="action-btn"
                    @click="() => cancelEditItemInOutlineFor('title')"
                  >
                    <span class="material-icons-outlined">done</span>
                  </button>
                </div>
              </div>
              <div
                v-else
                class="clickable d-flex align-center"
                @click="
                  (e) => {
                    $emit('update:editInOutlineInfo', {
                      section: 'title',
                    })
                  }
                "
              >
                {{ calculated_titleAIResult.text }}
                <span class="search-engines-only ml-3">
                  <!-- <i class="material-icons">error</i> -->
                  <span>For Search Engines Only</span>
                </span>
              </div>
            </span>

            <div class="actions"></div>
          </div>
        </div>
      </template>
      <template>
        <div class="result-one-section">
          <div v-if="calculated_pageTitleAIResult" class="title-row">
            <span class="tag-symbol-span h1-tag">{{
              calculated_pageTitleAIResult.tagName
            }}</span>
            <span class="text-part">
              <div
                v-if="isTitleOrPageTitleInEditingMode('pageTitle')"
                class="d-flex align-center flex-gap-5"
              >
                <input
                  type="text"
                  class="flex-grow-1 edit-ai-result-input"
                  :disabled="isDemo"
                  :value="calculated_pageTitleAIResult.text"
                  @input="$emit('update:calculated_pageTitleAIResult', {
                    ...calculated_pageTitleAIResult,
                    text: $event
                  })"
                />
                <div class="d-flex flex-gap-5">
                  <button
                    class="action-btn"
                    v-clipboard:copy="
                      calculated_pageTitleAIResult.text
                    "
                    v-clipboard:success="
                      () =>
                        $notify({
                          group: 'info',
                          type: 'success',
                          text: 'Copied to clipboard',
                        })
                    "
                    v-clipboard:error="
                      () =>
                        $notify({
                          group: 'info',
                          type: 'error',
                          text: 'Cannot copy to clipboard',
                        })
                    "
                  >
                    <span class="material-icons-outlined"
                      >file_copy</span
                    >
                  </button>
                  <button
                    class="action-btn"
                    @click="
                      () => cancelEditItemInOutlineFor('pageTitle')
                    "
                  >
                    <span class="material-icons-outlined">done</span>
                  </button>
                </div>
              </div>
              <div
                class="clickable"
                v-else
                @click="
                  (e) => {
                    if (currentStep < STEPS.SUBHEADINGS) {
                      // disable edit in subheadings page
                      $emit('update:editInOutlineInfo', {
                        section: 'pageTitle',
                      })
                    }
                  }
                "
              >
                {{ calculated_pageTitleAIResult.text }}
              </div>
            </span>
            <div class="actions"></div>
          </div>
        </div>
      </template>
    </v-card>
  </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'Result6SubheadingsDemo',
    props: [
        'isDemo',
        'currentStep',

        'calculated_titleAIResult',
        'calculated_pageTitleAIResult',
        'isTitleOrPageTitleInEditingMode',
        'cancelEditItemInOutlineFor',
        'editInOutlineInfo',
    ],
    computed: {
        STEPS() {
            return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
