<template>
  <div>
    <div class="d-flex gap20" style="border-bottom: 1px solid var(--v-gray13Color-base); margin-bottom: 20px;">

      <div style="flex:2;">
        <div class="font-weight-bold">Describe your product/service</div>
        <div class="mt-2">POP AI will use this information to write more informed content about your product/service.</div>
      </div>

      <div style="flex:5;">
        <div :class="{ 'demo-highlight-f': isDemo }">
          <v-textarea
            outlined
            dense
            :value="productDescription"
            @input="$emit('update:productDescription', $event)"
            :disabled="isDemo"
            color="greenColor"
            counter="250"
            placeholder="Add description.."
            :counter-value="countWords"
            :rules="[(v) => countWords(v) <= 250 || `Must be less than 250 words`]"
          >
          </v-textarea>
        </div>
      </div>

    </div>

    <div class="d-flex gap20">

      <div style="flex:2;">
        <div class="font-weight-bold">Provide sample content</div>
        <div class="mt-2">Provide a piece of content for POP AI to draw inspiration from.</div>
      </div>

      <div style="flex:5;">
        <div :class="{ 'demo-highlight-f': isDemo }">
          <v-textarea
            outlined
            dense
            :value="sampleContent"
            @input="$emit('update:sampleContent', $event)"
            :disabled="isDemo"
            color="greenColor"
            counter="500"
            placeholder="Add sample content.."
            :counter-value="countWords"
            :rules="[(v) => countWords(v) <= 500 || `Must be less than 500 words`]"
          >
          </v-textarea>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigProductDescription',
    props: [
        'isDemo',

        'countWords',
        'productDescription',
        'sampleContent',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>