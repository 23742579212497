<template>
    <div>
      {{ selectedContentPromptsSignals?.length ? `${selectedContentPromptsSignals.length} elements selected` : 'Not set' }}
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigPreviewRichContent',
    props: [
      'selectedContentPromptsSignals',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>