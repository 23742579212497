<template>
    <div class="d-flex gap20 align-center">

      <div style="flex:1;">
        <div class="font-weight-bold">Generate search engine title and page title separately (not recommended)</div>
        <div class="mt-2">For best SEO results, leave the toggle off to use the same page title and H1.</div>
      </div>

      <div style="flex:2;">
        <div class="d-flex align-center gap10">
          <vue-toggles
            :value="separateSearchEngineTitleAndTitle"
            width="40"
            height="20"
            :disabled="useMyCurrentTitle || isDemo"
            :checkedBg="$vuetify.theme.currentTheme['green7Color']"
            :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
            @click="$emit('update:separateSearchEngineTitleAndTitle', !separateSearchEngineTitleAndTitle)"
          ></vue-toggles>
          <div class="mainColor3--text">
            Generate search engine title and page title separately
          </div>
          <div class="not-recommended-label">
            NOT RECOMMENDED
          </div>
        </div>
      </div>

    </div>
</template>

<script>
export default {
    name: 'ConfigSearchPageTitle',
    props: [
        'isDemo',

        'separateSearchEngineTitleAndTitle',
        'useMyCurrentTitle',
    ],
}
</script>

<style lang="scss" scoped>
.not-recommended-label {
  background-color: #FFECEC;
  color: #D62323;
  text-transform: uppercase;
  border-radius: 3px;
  font-size: 0.7rem;
  font-weight: 700;
  padding: 2px 4px;
}
</style>