<template>
    <div>
      {{ targetAudience ? 'Set' : 'Not set' }}
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigPreviewTargetAudience',
    props: [
        'targetAudience',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>