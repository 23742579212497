<template>
    <div>
      <div>
        <div class="font-weight-bold">Add rich content elements</div>
        <div class="mt-2">Set the amount for each component you'd like POP AI Writer to generate. Rich content recommendations for your page are based on POP Content prompts report.</div>
      </div>

      <table class="select-content-prompt-table mt-5">
        <thead>
          <tr>
            <th>SELECT</th>
            <th>COMPONENT</th>
            <th>COST</th>
            <th>RECOMMENDED RANGE</th>
            <th>QUANTITY</th>
            <th>TOTAL COST</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(row, idx) in richContentItemsForAIWriter"
            
          >
            <tr
              :key="idx"
              :class="
                row.signalName === 'Internal links' && selectedContentPromptsSignals.includes('Internal links')
                ? 'remove-bottom-border'
                : ''
              "
            >
              <td>
                <vue-toggles
                  :value="
                    selectedContentPromptsSignals.includes(row.signalName)
                  "
                  width="40"
                  height="20"
                  :disabled="isDemo"
                  :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                  :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                  @click="
                    () => {
                      if (
                        selectedContentPromptsSignals.includes(
                          row.signalName
                        )
                      ) {
                        $emit('update:selectedContentPromptsSignals', selectedContentPromptsSignals.filter(
                          (item) => item != row.signalName
                        ))
                      } else {
                        $emit('update:selectedContentPromptsSignals', [
                          ...selectedContentPromptsSignals,
                          row.signalName
                        ])
                        if (
                          row.isYesNoSignal ||
                          !selectedCountForPromptSignals[row.signalName]
                        ) {
                          $emit('update:selectedCountForPromptSignals', {
                            ...selectedCountForPromptSignals,
                            [row.signalName]: 1
                          })
                        }
                        $nextTick(() => {
                          $refs?.contentPromptCountItems?.[idx]?.focus?.();
                        });
                      }
                    }
                  "
                ></vue-toggles>
              </td>
              <td class="font-weight-bold">
                <div class="d-flex align-center">
                  {{ row.dispName ? row.dispName : row.label }}
                  <!-- {{ row.signalName }} -->
                  <v-tooltip
                    right
                    color="transparent"
                    v-if="CONTENT_PROMPT_SIGNALS_TOOLTIPS[row.signalName]"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 mainColor3--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip small-tooltip" style="white-space: pre-wrap;">{{ CONTENT_PROMPT_SIGNALS_TOOLTIPS[row.signalName] }}</div>
                  </v-tooltip>
                </div>
              </td>
              <td>
                <span
                  v-if="
                    costedContentPromptSignals.includes(row.signalName) && !isUsingOwnApiKey
                  "
                  class="credit-1"
                >
                  <img src="/static/credit-1.png" />
                  1 POP credit
                </span>
                <span v-else class="credit-0">
                  <img src="/static/credit-0.png" />
                  0 POP credits
                </span>
              </td>
              <td>
                {{
                  row.isYesNoSignal && row.signalName !== 'Internal links'
                    ? "Use 1"
                    : `Use between ${row.targetMin} to ${row.targetMax}`
                }}
              </td>
              <td>
                <v-text-field
                  v-if="row.signalName !== 'Internal links'"
                  outlined
                  dense
                  hide-details
                  single-line
                  color="greenColor"
                  style="width: 70px"
                  :rules="[(v) => v > 0, (v) => v <= row.targetMax]"
                  ref="contentPromptCountItems"
                  :disabled="
                    !selectedContentPromptsSignals.includes(
                      row.signalName
                    ) ||
                    row.isYesNoSignal ||
                    isDemo
                  "
                  :value="selectedCountForPromptSignals[row.signalName]"
                  @input="
                    (newVal) => {
                      $emit('update:selectedCountForPromptSignals', {
                        ...selectedCountForPromptSignals,
                        [row.signalName]: parseInt(newVal)
                      })
                    }
                  "
                  type="number"
                ></v-text-field>
              </td>
              <td
                :class="{
                  'pr-5':
                    getCostForContentPromptSignal(row.signalName) < 10,
                }"
              >
                {{ getCostForContentPromptSignal(row.signalName) }} POP
                credit{{
                  getCostForContentPromptSignal(row.signalName) != 1
                    ? "s"
                    : ""
                }}
              </td>
            </tr>
            <tr
              v-if="row.signalName === 'Internal links' && selectedContentPromptsSignals.includes('Internal links')"
              :key="idx + 'internal-links-data'"
            >
              <td></td>
              <td colspan="4">
                <div class="mainColor3--text">Add the main page you want to optimize. This is usually a key sales, product, or service page on your website that converts visitors into leads or customers.</div>
                <div class="mainColor3--text mt-3 font-weight-bold">Top-level page link (main target page)</div>
                <v-text-field
                  outlined
                  dense
                  :value="topLevelInternalLink"
                  @input="$emit('update:topLevelInternalLink', $event)"
                  :disabled="isDemo"
                  color="greenColor"
                  :rules="[(v) => isValidInternalLink(v) || `Must be an internal link that starts with ${page?.siteBaseURL || 'https://'}`]"
                  placeholder="https://www.example.com/product"
                >
                </v-text-field>
                <div class="mainColor3--text mt-3">Add two pages that are closely related to the main page. These should be pages that help explain, support, or provide additional value to the main page topic.</div>
                <div class="mainColor3--text mt-3 font-weight-bold">Supporting-level page link 1</div>
                <v-text-field
                  outlined
                  dense
                  :value="supportingLevelInternalLink1"
                  @input="$emit('update:supportingLevelInternalLink1', $event)"
                  :disabled="isDemo"
                  color="greenColor"
                  :rules="[(v) => isValidInternalLink(v) || `Must be an internal link that starts with ${page?.siteBaseURL || 'https://'}`]"
                  hint=""
                  placeholder="https://www.example.com/product-details"
                >
                </v-text-field>
                <div class="mainColor3--text mt-3 font-weight-bold">Supporting-level page link 2</div>
                <v-text-field
                  outlined
                  dense
                  :value="supportingLevelInternalLink2"
                  @input="$emit('update:supportingLevelInternalLink2', $event)"
                  :disabled="isDemo"
                  color="greenColor"
                  :rules="[(v) => isValidInternalLink(v) || `Must be an internal link that starts with ${page?.siteBaseURL || 'https://'}`]"
                  placeholder="https://www.example.com/product-reviews"
                >
                </v-text-field>
              </td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigRichContent',
    props: [
        'isDemo',
        'page',
        'isUsingOwnApiKey',

        'richContentItemsForAIWriter',
        'CONTENT_PROMPT_SIGNALS_TOOLTIPS',
        'costedContentPromptSignals',
        'getCostForContentPromptSignal',
        'isValidInternalLink',

        'selectedContentPromptsSignals',
        'selectedCountForPromptSignals',
        'topLevelInternalLink',
        'supportingLevelInternalLink1',
        'supportingLevelInternalLink2',
        
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>
.select-content-prompt-table {
  width: 100%;
  th {
    background: var(--v-gray7Color-base);
    border-bottom: 1px solid var(--v-grayColor-base);
    color: var(--v-darkGrayColor-base);
    padding: 12px 10px;
    font-size: 0.8rem;
    font-weight: 500;
  }
  td {
    padding: 12px 10px;
    border-bottom: 1px solid var(--v-grayColor-base);
    vertical-align: middle;
    color: var(--v-mainColor3-base);
  }

  .credit-1,
  .credit-0 {
    display: inline-flex;
    gap: 5px;
    align-items: center;
    font-size: 0.67rem;
    padding: 7px 13px;
    border-radius: 10px;
    font-weight: 600;
    white-space: nowrap;
  }
  .credit-1 {
    background: var(--v-yellow7Color-base);
    color: var(--v-yellow8Color-base);
  }
  .credit-0 {
    background: var(--v-link9Color-base);
    color: var(--v-blue5Color-base);
  }

  tr.remove-bottom-border {
    td {
      border-bottom: 0 !important;
    }
  }
}
</style>