export const DEFAULT_PREFIX = 'P3__'

//// up to date packages are like below
// SERVICE_PKG_B2, SERVICE_PKG_C, SERVICE_PKG_D, SERVICE_PKG_E
// SERVICE_PKG_AA, SERVICE_PKG_BB, SERVICE_PKG_CC
// EXTRA_SEAT_AA, EXTRA_SEAT_BB, EXTRA_SEAT_CC
/////////////////

export const freePlans = [
  'gold_free',
  'platinum_free',
  'BASIC_FREE',
  'PREMIUM_FREE',
  'UNLIMITED_AGENCY5_FREE',
  'UNLIMITED_AGENCY20_FREE',
  'UNLIMITED_AGENCY_FREE_EDUCATION',
  'WHITE_GLOVE_FREE',
]

export const agencyMonthlyPlans = [
  'SERVICE_PKG_F',
  'SERVICE_PKG_G',
  'SERVICE_PKG_H',
  'SERVICE_PKG_L', // special plan for agency 100 sub accounts

  // 'P1__SERVICE_PKG_F',
  // 'P1__SERVICE_PKG_G',
  // 'P1__SERVICE_PKG_H',
  
  `SERVICE_PKG_AA`,
  `SERVICE_PKG_CC`,
]

export const agencyYearlyPlans = [
  'SERVICE_PKG_I',
  'SERVICE_PKG_J',
  'SERVICE_PKG_K',
  'SERVICE_PKG_M', // special plan for agency 100 sub accounts
  
  // 'P1__SERVICE_PKG_I',
  // 'P1__SERVICE_PKG_J',
  // 'P1__SERVICE_PKG_K',
  `SERVICE_PKG_BB`,
];

export const agencyPlans = [
  ...agencyMonthlyPlans,
  ...agencyYearlyPlans,

  'UNLIMITED_AGENCY5_FREE',
  'UNLIMITED_AGENCY20_FREE',
  'UNLIMITED_AGENCY_FREE_EDUCATION',
  'WHITE_GLOVE_FREE',
];

export const agencyPlansWithAdditionalSeatsAvailable = [
  `SERVICE_PKG_AA`,
  `SERVICE_PKG_BB`,
  `SERVICE_PKG_CC`,
]

export const additionalSeatPlans = [
  `EXTRA_SEAT_AA`,
  `EXTRA_SEAT_BB`,
  `EXTRA_SEAT_CC`,
]

export const PURE_TRIAL_PLANS = [
  'SERVICE_PKG_A',
  'SERVICE_PKG_B2',  
]

export const whiteGlovePlans = [
  'SERVICE_PKG_CC',
  'WHITE_GLOVE_FREE',
];


// this must be full package name mapping not pure
export const correspondingSeatPlans = {
  [`P2__SERVICE_PKG_AA`]: `P2__EXTRA_SEAT_AA`,
  [`P2__SERVICE_PKG_BB`]: `P2__EXTRA_SEAT_BB`,
  [`P2__SERVICE_PKG_CC`]: `P2__EXTRA_SEAT_CC`,
  [`${DEFAULT_PREFIX}SERVICE_PKG_AA`]: `${DEFAULT_PREFIX}EXTRA_SEAT_AA`,
  [`${DEFAULT_PREFIX}SERVICE_PKG_BB`]: `${DEFAULT_PREFIX}EXTRA_SEAT_BB`,
  [`${DEFAULT_PREFIX}SERVICE_PKG_CC`]: `${DEFAULT_PREFIX}EXTRA_SEAT_CC`,
}

export const unlimitedServices = [
  'platinum', 'UnlimitedYearly', 'platinum_free', 'gold_free',
  'SERVICE_PKG_D', 'SERVICE_PKG_E',
  ...agencyPlans,
];

export const UNLIMITED_SUBSCRIPTION_SERVICES = [
  ...unlimitedServices,
  'P1__SERVICE_PKG_D',
  'P1__SERVICE_PKG_E',
  'P1__SERVICE_PKG_F',
  'P1__SERVICE_PKG_G',
  'P1__SERVICE_PKG_H',
  'P1__SERVICE_PKG_I',
  'P1__SERVICE_PKG_J',
  'P1__SERVICE_PKG_K',
  'P2__SERVICE_PKG_D',
  'P2__SERVICE_PKG_E',
  'P2__SERVICE_PKG_AA',
  'P2__SERVICE_PKG_BB',
  'P2__SERVICE_PKG_CC',
  'P3__SERVICE_PKG_D',
  'P3__SERVICE_PKG_E',
  'P3__SERVICE_PKG_AA',
  'P3__SERVICE_PKG_BB',
  'P3__SERVICE_PKG_CC',
  'WHITE_GLOVE_FREE'
]

export const SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS = UNLIMITED_SUBSCRIPTION_SERVICES

export const SUBSCRIPTIONS_WITH_UNLIMITED_ACCESS_TO_KEYWORD_RESEARCH_TOOLS = whiteGlovePlans

export const watchdogOnlyServices = [
  'P1__SERVICE_PKG_Z',
  'P2__SERVICE_PKG_Z',
  'P3__SERVICE_PKG_Z',
]

// this must be full service package name not pure
export const oldPlans = [
  'SERVICE_PKG_A', 'SERVICE_PKG_B',
  'SERVICE_PKG_B2', 'SERVICE_PKG_C', 'SERVICE_PKG_D', 'SERVICE_PKG_E',
  'SERVICE_PKG_F', 'SERVICE_PKG_G', 'SERVICE_PKG_H', 'SERVICE_PKG_I', 'SERVICE_PKG_J', 'SERVICE_PKG_K',
  'P1__SERVICE_PKG_B2', 'P1__SERVICE_PKG_C', 'P1__SERVICE_PKG_D', 'P1__SERVICE_PKG_E',
  'P1__SERVICE_PKG_F', 'P1__SERVICE_PKG_G', 'P1__SERVICE_PKG_H', 'P1__SERVICE_PKG_I', 'P1__SERVICE_PKG_J', 'P1__SERVICE_PKG_K',
  
  'P2__SERVICE_PKG_B2', 'P2__SERVICE_PKG_C', 'P2__SERVICE_PKG_D', 'P2__SERVICE_PKG_E',
  'P2__SERVICE_PKG_F', 'P2__SERVICE_PKG_G', 'P2__SERVICE_PKG_H', 'P2__SERVICE_PKG_I', 'P2__SERVICE_PKG_J', 'P2__SERVICE_PKG_K',
  // 'SERVICE_PKG_L', 'SERVICE_PKG_M',
]

export const CURRENT_YEARLY_PLANS = [
  `${DEFAULT_PREFIX}SERVICE_PKG_E`,
  `${DEFAULT_PREFIX}SERVICE_PKG_BB`,
  `${DEFAULT_PREFIX}EXTRA_SEAT_BB`,
]



export const PRICE_OF_EACH_VERSIONING = {
  'SERVICE_PKG_A': 10,
  'SERVICE_PKG_B': 20,
  'SERVICE_PKG_B2': 20,
  'SERVICE_PKG_C': 30,
  'SERVICE_PKG_D': 300,


  'P1__SERVICE_PKG_B2': 22,
  'P1__SERVICE_PKG_C': 33,
  'P1__SERVICE_PKG_D': 44,
  'P1__SERVICE_PKG_E': 440,

  'P2__SERVICE_PKG_B2': 27,
  'P2__SERVICE_PKG_C': 38,
  'P2__SERVICE_PKG_D': 49,
  'P2__SERVICE_PKG_E': 490,

  'P3__SERVICE_PKG_B2': 37,
  'P3__SERVICE_PKG_C': 47.5,
  'P3__SERVICE_PKG_D': 67,
  'P3__SERVICE_PKG_E': 670,
  'P3__SERVICE_PKG_AA': 130,
  'P3__SERVICE_PKG_BB': 1300,
  'P3__SERVICE_PKG_CC': 130,
  'P3__EXTRA_SEAT_AA': 12,
  'P3__EXTRA_SEAT_BB': 120,
  'P3__EXTRA_SEAT_CC': 12,

  // One-time purchases
  'WHITE_GLOVE_ADDON_CONTENT_AUDIT': 225,
  'WHITE_GLOVE_ADDON_CONTENT_AUDIT_SPEEDY_DELIVERY': 297,
}
export const PURE_PAKCAGE_TO_DISP_NAME_MAP = {
  'platinum_free': 'Unlimited',
  'SERVICE_PKG_B': 'Basic',
  'SERVICE_PKG_B2': 'Basic',
  'SERVICE_PKG_C': 'Premium',
  'SERVICE_PKG_D': 'Unlimited',
  'SERVICE_PKG_E': 'Unlimited',
}

export const ALLOWED_SILO_ORDER_DELIVERY_PERIODS = [3, 7, 14, 28]
export const SILO_ORDER_SPEEDY_DELIVERY_PERIOD = 3
export const SILO_ORDER_SPEEDY_DELIVERY_MULTIPLIER = 0.25
