<template>
    <div>
      {{ isUsingOwnApiKey ? 'Enabled' : 'Not set' }}
    </div>
</template>

<script>
export default {
    name: 'ConfigPreviewApiKey',
    props: [
      'isUsingOwnApiKey'
    ],
}
</script>

<style lang="scss" scoped>

</style>