<template>
<div
  class="table-of-content-side-bar"
>
  <div>
    <span
      class="material-icons-outlined hide-icon"
      @click="
        () => {
          $emit('close')
        }
      "
      >arrow_back</span
    >
    <div class="sidebar-label">Table of Contents</div>
    <div class="article-title">
      {{ calculated_pageTitleAIResult?.text }}
    </div>
    <div
      v-for="(item, idx) in calculated_subHeadingsAIResultArr"
      :key="`ai_result_${item.text}_${idx}`"
      @click="
        () => {
          $emit('itemClick', item.text)
        }
      "
      :class="{
        'h2-link': item.tagName == 'H2',
        'h3-link': item.tagName == 'H3',
      }"
    >
      {{ item.text }}
      <div></div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'TableOfContents',
  props: [
    'calculated_pageTitleAIResult',
    'calculated_subHeadingsAIResultArr'
  ],
}
</script>

<style lang="scss" scoped>
.table-of-content-side-bar {
  background: var(--v-whiteColor-base);
  flex-basis: 300px;
  flex-shrink: 0;
  border-right: 1px solid var(--v-grayColor-base);
  padding: 20px 10px 20px 15px;
  overflow-y: auto;
  .hide-icon {
    font-size: 1.5rem;
    color: #5f6368;
    cursor: pointer;
  }
  .sidebar-label {
    margin: 30px 0;
    text-transform: uppercase;
  }
  .article-title {
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 110%;
  }
  .h2-link,
  .h3-link {
    margin-top: 15px;
    cursor: pointer;
    line-height: 110%;
  }
  .h2-link {
    font-size: 0.85rem;
    margin-left: 10px;
  }
  .h3-link {
    font-size: 0.8rem;
    margin-left: 30px;
  }
  .h2-link:hover,
  .h3-link:hover {
    color: var(--v-profileAnchorColor-base);
    text-decoration: underline;
    font-weight: 700;
  }
}
</style>