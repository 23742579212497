<template>
    <div class="d-flex gap20">

      <div style="flex:2;">
        <div class="font-weight-bold">What is your target audience?</div>
        <div class="mt-2">POP AI will use this information to match the content intent.</div>
      </div>

      <div style="flex:5;">
        <div :class="{ 'demo-highlight-f': isDemo }">
          <v-textarea
            outlined
            dense
            :value="targetAudience"
            @input="$emit('update:targetAudience', $event)"
            :disabled="isDemo"
            color="greenColor"
            counter="250"
            placeholder="Add description.."
            :counter-value="countWords"
            :rules="[(v) => countWords(v) <= 250 || `Must be less than 250 words`]"
          >
          </v-textarea>
        </div>
      </div>

    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigTargetAudience',
    props: [
        'isDemo',

        'countWords',
        'targetAudience',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>