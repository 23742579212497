<template>
    <div>
      <v-card
        class="settings-area white-common-box mt-10"
        :class="{
          'step-scroll-target': calculated_titleAIResult || calculated_pageTitleAIResult
        }"
      >

        <div
          v-if="!calculated_titleAIResult && !calculated_pageTitleAIResult"
          class="d-flex flex-column align-center pt-4 pb-4"
        >
          <img src="/static/empty_outline_builder.svg" alt="empty outline builder icon" />
          <div class="font-weight-bold mt-4" style="font-size: 1.1rem;">Outline builder</div>
          <div class=" mt-4">Your outline builder will show here after you set up your titles</div>

          <progress-bar
            v-if="isLoading"
            class="mt-10"
            :isDemo="isDemo"  
            :isLoading="isLoading"  
            :runningSection="runningSection"
            :progress="progress"
            :titleProgress="titleProgress"
            :subHeadProgress="subHeadProgress"
            :mainContentProgress="mainContentProgress"
          />
        </div>
      
        <template v-else>
        
          <div class="section-label mb-6" style="text-transform: uppercase;">Outline Builder</div>
            
          <div
            v-if="calculated_titleAIResult"
          >
            <div
              class="ai-result-outline result-one-section"
              @mouseover="mouseenter('title')"
              @mouseleave="mouseleave()"
              id="ai-title"
            >
              <div
                class="title-row ai-result-card ai-outline-item"
              >
                <span class="tag-symbol-span h1-tag">{{
                  calculated_titleAIResult.shortenTagName
                }}</span>
                <span class="text-part h1-size">
                  <div class="d-flex align-center flex-gap-5 justify-space-between">
                    <input
                      v-if="isTitleOrPageTitleInEditingMode('title')"
                      id="input"
                      type="text"
                      class="flex-grow-1 edit-ai-result-input"
                      :disabled="isDemo"
                      :value="calculated_titleAIResult.text"
                      @input="$emit('update:calculated_titleAIResult', {
                        ...calculated_titleAIResult,
                        text: $event.target.value
                      })"
                    />
                    <div
                      v-else
                      class="clickable d-flex align-center action"
                      @click="
                        $emit('update:editInOutlineInfo', {
                          section: 'title',
                        })
                        focusInput();
                      "
                    >
                      <b-tooltip
                        class="mr-2"
                        label="Title for search engines only"
                        type="is-dark"
                      >
                        <img src="/static/search_engine_icon.png" alt="For Search Engines Only" style="width: 1rem; height: 1rem;" />
                      </b-tooltip>
                      <span class="editable-ai-result-text">{{ calculated_titleAIResult.text }}</span>
                    </div>
                    <div
                      class="d-flex flex-gap-5"
                      v-if="
                        isTitleOrPageTitleInEditingMode('title') ||
                        hoveredItem === 'title'
                      "
                    >
                      <button
                        class="action-btn action"
                        v-clipboard:copy="calculated_titleAIResult.text"
                        v-clipboard:success="
                          () =>
                            $notify({
                              group: 'info',
                              type: 'success',
                              text: 'Copied to clipboard',
                            })
                        "
                        v-clipboard:error="
                          () =>
                            $notify({
                              group: 'info',
                              type: 'error',
                              text: 'Cannot copy to clipboard',
                            })
                        "
                      >
                        <span class="material-icons-outlined action"
                          >file_copy</span
                        >
                      </button>
                    </div>
                  </div>
                </span>

              </div>
            </div>

            <div class="d-flex align-center gap10" v-if="aiRun?.isSeparateTitleAndPagetitle">
              <b-tooltip
                label="Regenerate"
                type="is-dark"
              >
                <button
                  class="action-btn action-btn-lg"
                  :disabled="
                    isLoading ||
                    (!useMyCurrentTitle &&
                      !optimizeCurrentTitle &&
                      !optimizeCompetitorTitle &&
                      !useTitleInspirationText)
                  "
                  @click="
                    () => {
                      showWarningModalForGenerate('title', () =>
                        generateResultFor('title')
                      );
                    }
                  "
                >
                  <span class="material-icons-outlined action"
                    >replay</span
                  >
                </button>
              </b-tooltip>
    
              <b-tooltip
                v-if="titleAIResultHistory.length > 0"
                label="Show history"
                type="is-dark"
              >
                <button
                  class="action-btn action-btn-lg"
                  @click="$emit('update:showTitleHistoryModal', true)"
                >
                  <span class="material-icons-outlined action"
                    >schedule</span
                  >
                </button>
              </b-tooltip>
    
              <b-tooltip
                label="Download outline"
                type="is-dark"
              >
                <button
                  class="action-btn action-btn-lg"
                  @click="() => downloadAIResult('title')"
                >
                  <span class="material-icons-outlined action"
                    >file_download</span
                  >
                </button>
              </b-tooltip>
            </div>
          </div>

          <div
            v-if="
              currentStep >= STEPS.PAGE_TITLE &&
              currentStep < STEPS.SUBHEADINGS &&
              Boolean(calculated_pageTitleAIResult)
            "
            :class="{
              'mt-10': Boolean(calculated_titleAIResult) && aiRun?.isSeparateTitleAndPagetitle
            }"
          >
            <div
              class="ai-result-outline result-one-section"
              @mouseover="mouseenter('pageTitle')"
              @mouseleave="mouseleave()"
              id="ai-page-title"
            >
              <div
                v-if="calculated_pageTitleAIResult"
                class="title-row ai-result-card ai-outline-item"
              >
                <span class="tag-symbol-span h1-tag">{{
                  calculated_pageTitleAIResult.tagName
                }}</span>
                <span class="text-part">
                  <div class="d-flex align-center flex-gap-5 justify-space-between">
                    <input
                      v-if="isTitleOrPageTitleInEditingMode('pageTitle')"
                      id="input"
                      type="text"
                      class="flex-grow-1 edit-ai-result-input"
                      :disabled="isDemo"
                      :value="calculated_pageTitleAIResult.text"
                      @input="$emit('update:calculated_pageTitleAIResult', {
                        ...calculated_pageTitleAIResult,
                        text: $event.target.value
                      })"
                    />
                    <div
                      class="clickable action"
                      v-else
                      @click="
                        if (currentStep < STEPS.SUBHEADINGS) {
                          // disable edit in subheadings page
                          $emit('update:editInOutlineInfo', {
                            section: 'pageTitle',
                          })
                          focusInput();
                        }
                      "
                    >
                      <span class="editable-ai-result-text">{{ calculated_pageTitleAIResult.text }}</span>
                    </div>
                    <div
                      class="d-flex flex-gap-5"
                      v-if="
                        isTitleOrPageTitleInEditingMode('pageTitle') ||
                        hoveredItem === 'pageTitle'
                      "
                    >
                      <button
                        class="action-btn"
                        v-clipboard:copy="
                          calculated_pageTitleAIResult.text
                        "
                        v-clipboard:success="
                          () =>
                            $notify({
                              group: 'info',
                              type: 'success',
                              text: 'Copied to clipboard',
                            })
                        "
                        v-clipboard:error="
                          () =>
                            $notify({
                              group: 'info',
                              type: 'error',
                              text: 'Cannot copy to clipboard',
                            })
                        "
                      >
                        <span class="material-icons-outlined action"
                          >file_copy</span
                        >
                      </button>
                    </div>
                  </div>
                </span>

              </div>
            </div>

            <div class="d-flex align-center gap10">
              <b-tooltip
                label="Regenerate"
                type="is-dark"
              >
                <button
                  class="action-btn action-btn-lg"
                  :disabled="
                    isDemo ||
                    isLoading ||
                    (!useMyCurrentPageTitle &&
                      !optimizeCurrentPageTitle &&
                      !optimizeCompetitorPageTitle &&
                      !usePageTitleInspirationText)
                  "
                  @click="
                    () => {
                      showWarningModalForGenerate('pageTitle', () =>
                        generateResultFor('pageTitle')
                      );
                    }
                  "
                >
                  <span class="material-icons-outlined action"
                    >replay</span
                  >
                </button>
              </b-tooltip>
    
              <b-tooltip
                v-if="pageTitleAIResultHistory.length > 0"
                label="Show history"
                type="is-dark"
              >
                <button
                  class="action-btn action-btn-lg"
                  :disabled="isDemo"
                  @click="$emit('update:showPageTitleHistoryModal', true)"
                >
                  <span class="material-icons-outlined action"
                    >schedule</span
                  >
                </button>
              </b-tooltip>
    
              <b-tooltip
                label="Download outline"
                type="is-dark"
              >
                <button
                  :disabled="isDemo"
                  class="action-btn action-btn-lg"
                  @click="() => downloadAIResult('pageTitle')"
                >
                  <span class="material-icons-outlined action"
                    >file_download</span
                  >
                </button>
              </b-tooltip>
            </div>
          </div>

          <progress-bar
            v-if="isLoading"
            class="mt-10"
            :isDemo="isDemo"  
            :isLoading="isLoading"  
            :runningSection="runningSection"
            :progress="progress"
            :titleProgress="titleProgress"
            :subHeadProgress="subHeadProgress"
            :mainContentProgress="mainContentProgress"
          />

        </template>
      </v-card>
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";
import ProgressBar from '@/components/FullPageSourceEditorV2/components/AiWriter/components/ProgressBar.vue';

export default {
    name: 'Result4And5PageTitle',
    components: {
      ProgressBar,
    },
    props: [
        'isDemo',
        'currentStep',

        'aiRun',
        'pageTitleAIResult',
        'mouseenter',
        'mouseleave',
        'calculated_titleAIResult',
        'isTitleOrPageTitleInEditingMode',
        'editInOutlineInfo',
        'focusInput',
        'hoveredItem',
        'calculated_pageTitleAIResult',

        'showWarningModalForGenerate',
        'generateResultFor',

        'isLoading',
        'optimizeCurrentTitle',
        'optimizeCompetitorTitle',
        'useMyCurrentTitle',
        'useTitleInspirationText',
        'titleAIResultHistory',
        'showTitleHistoryModal',
        'downloadAIResult',

        'optimizeCurrentPageTitle',
        'optimizeCompetitorPageTitle',
        'usePageTitleInspirationText',
        'useMyCurrentPageTitle',
        'pageTitleAIResultHistory',
        'showPageTitleHistoryModal',

        'runningSection',
        'progress',
        'titleProgress',
        'subHeadProgress',
        'mainContentProgress',
    ],
    computed: {
        STEPS() {
            return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>