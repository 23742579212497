<template>
    <div>
      {{ WORDCOUNT_OPTIONS?.filter?.(option => option.value === selectedWordCountOption)?.[0]?.label }} ({{ numberOfWords }} words)
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigPreviewWordCount',
    props: [
        'goNext',

        'isDemo',
        'isFailedPageRun',

        'WORDCOUNT_OPTIONS',
        'currentWordCount',
        'targetWordCount',

        'selectedWordCountOption',
        'numberOfWords',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>