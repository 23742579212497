<template>
    <v-card class="white-common-box mb-5 d-flex api-key-on-card">
        <div class="icon-part">
            <i class="material-icons-outlined" style="color: #25A97A; font-size: 2.5rem;">power</i>
        </div>
        <div class="" style="flex: 1; padding: 20px 30px;">
            <div class="mb-3" style="font-size: 1.4rem; font-weight: 600;">GPT API key is on</div>
            <div>This article uses your own API key.</div>
        </div>
    </v-card>
</template>

<script>

export default {
    components: {
    },
};
</script>
<style lang="scss" scoped>
.api-key-on-card {
    background-color: #EFFFFF !important;
    overflow-x: initial;
    border-color: #5EE29C !important;

    .icon-part {
        background-color: #D5FFF0;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
    }
}

.theme--dark {
    .api-key-on-card {
        background-color: #171818 !important;
        border-color: #5EE29C !important;

        .icon-part {
            background-color: #282e2b;
        }
    }
}
</style>