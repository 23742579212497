<template>
  <div class="text-center mr-2">
    <v-menu offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          small
          color="mainColor"
          class="button-with-icon text-none"
          v-bind="attrs"
          v-on="on"
          @click="loadSiloData"
        >
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item 
          v-for="(item, index) in getMenuItems()" 
          :key="index" 
          v-if="item.isVisible && (item.isAvailableToBoth || item.isTopLevelKeyword == isTopLevelKeyword)"
          :class="{ 'basic-divider': item.divider }"
          :disabled="item.isDisabled">
          <v-menu v-if="item.children" offset-x>
            <template v-slot:activator="{ on: x, attrs: y }">
              <span class="flex align-center">
                <span v-if="item.icon" class="box-wh35 mr-2 cursor-pointer">
                  <span v-html="item.icon" />
                </span>
                <span v-else-if="item.vIcon">
                  <v-icon class="mr-2">{{ item.vIcon }}</v-icon>
                </span>
                <v-list-item-title
                  v-bind="y"
                  v-on="x"
                  class="cursor-pointer"
                  :class="item.class || ''"
                >
                  {{ item.title }}
                </v-list-item-title>
                <v-icon v-bind="y" v-on="x">mdi-play</v-icon>
              </span>
            </template>
            <v-list>
              <v-list-item
                v-for="(subItem, index) in item.children"
                :key="index"
                :class="{ 'cursor-pointer': !!subItem.onClick }"
                @click="getMethod(subItem.onClick, subItem.params)"
              >
                <v-list-item-title>
                  {{ subItem.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span 
            v-else class="flex"
            :class="{ 'cursor-pointer': !!item.onClick }"
            @click="getMethod(item.onClick, item.params)"
          >
            <span v-if="item.icon" class="mr-2 cursor-pointer">
              <span v-html="item.icon" />
            </span>
            <span v-else-if="item.vIcon">
              <v-icon color="#000000" class="mr-2">{{ item.vIcon }}</v-icon>
            </span>
            <v-list-item-title :class="item.class || ''">
              {{ item.title }}
            </v-list-item-title>
          </span>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";
import { isEmpty } from "lodash";
import { 
  downloadFile, 
  downloadWordFile, 
  downloadEditorReport,
  generateSchemasJSON,
} from "@/utils/download-reports.js"
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  AlignmentType,
  ImageRun,
  ExternalHyperlink,
  WidthType,
  BorderStyle,
  HeadingLevel,
  convertInchesToTwip,
  PageBreak,
} from "docx";
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import eeatPdfHeader from '../../../public/static/eeat-pdf-header.png';
import bulbIcon from '../../../public/static/bulb-icon.png';
import eeatWorldIcon from '../../../public/static/eeat-world-icon.png';
import eeatKeywordWarningIcon from '../../../public/static/eeat-keyword-warning-icon.png';
import eeatKeywordPresentIcon from '../../../public/static/eeat-keyword-present-icon.png';
import wordDocHeadImg from '../../../public/static/word-doc-head-image.png';
import explainData from '@/components/PageRun/eat-data/EatSchemaExplanation';
import pdfHeaderLogo from '../../../public/static/schema-page-run-pdf-logo.png';
import { ReportTypes } from "../PageRun/constants";


function parseHtmlData(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const paragraphs = [];

    doc.body.childNodes.forEach(node => {
      let paragraph = []
      paragraph = new Paragraph({
          children: parseNodeToTextRuns(node),
      })
      paragraphs.push(paragraph);
    });

    return paragraphs;
}

function parseNodeToTextRuns(node) {
  const textRuns = [];

  node.childNodes.forEach(childNode => {
    if (childNode.nodeType === Node.TEXT_NODE) {
      textRuns.push(new TextRun({ text: childNode.textContent }));
    } else if (childNode.nodeType === Node.ELEMENT_NODE) {
      switch (childNode.nodeName.toLowerCase()) {
        case 'b':
        case 'strong':
          textRuns.push(new TextRun({ text: childNode.textContent, bold: true }));
          break;
        case 'i':
          textRuns.push(new TextRun({ text: childNode.textContent, italics: true }));
          break;
        case 'img':
          textRuns.push(new TextRun({ text: '🖼️ <image: your image file link here>' }));
          break;
        case 'form':
          textRuns.push(new TextRun({ text: '<form><!-- your form here --></form>' }));
          break;
        case 'u':
          textRuns.push(new TextRun({ text: childNode.textContent, underline: {} }));
          break;
        case 'p':
          textRuns.push(...parseNodeToTextRuns(childNode)); // Recursively handle nested nodes
          break;
        // Handle other tags as needed
        default:
          textRuns.push(new TextRun({ text: childNode.textContent }));
      }
    }
  });

  return textRuns;
}

function getBase64FromUrl(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

function makeSchemaData(schemaType) {
  for (const generated of this.genPageSchemaTypes) {
    if (generated.type === schemaType) {
      return JSON.parse(JSON.stringify(generated.schema, null, 4));
    }
  }
  return '';
}

let linksCountSignal = [
  'Total Internal Links',
  'Internal links without 404',
  'Internal Broken links',
]

export default {
  props: {
    siloKeyword: {}
  },
  data() {
    return {
      wordDocHeadImg: null,
      bulbIcon: null,
      eeatWorldIcon: null,
      eeatKeywordWarningIcon: null,
      eeatKeywordPresentIcon: null,
      eeatReportLogo: null,
      schemasReportLogo: null,
      descriptionObj: {},
    }
  },
  computed: {
    siloReportProgress() {
      return this.$store.getters.siloReportProgress;
    },
    isSelectionDisabled() {
      return !!this.siloReportProgress || isEmpty(this.siloKeyword.guardDiffData);
    },
    isExistingReportVisible() {
      return !isEmpty(this.siloKeyword.guardDiffData)
    },
    isAIDraftReportVisible() {
      return this.siloKeyword.aiRunResult.aiGenDraft || this.siloKeyword.editedReports.replaceAiDraftReport || this.siloKeyword.hasEditedSource.hasAiDraft
    },
    isHumanDraftVisible() {
      return this.siloKeyword.aiRunResult.aiGenDraft || this.siloKeyword.editedReports.replaceHumanDraftReport || this.siloKeyword.hasEditedSource.hasHumanDraft
    },
    isAIOutlineVisible() {
      return this.siloKeyword.aiRunResult.aiOutline || this.siloKeyword.editedReports.replaceOutlineReport || this.siloKeyword.hasEditedSource.hasOutline
    },
    isTopLevelKeyword() {
      return this.siloKeyword.keywordData.isTopLevelKeyword
    },
    customLogo () {
      return this.$store.state.user.customLogo
    },
    pageType() {
      return this.siloKeyword.keywordData.isTopLevelKeyword ? "top_level_page" : "supporting_page"
    },
    isPageRun() {
      return this.siloKeyword.pageRunId
    },
    isEATTAvailable() {
      return this.siloKeyword.pageRunResult.isEEATAvailable
    },
    areSchemasAvailable() {
      return this.siloKeyword.pageRunResult.isSchemaAvailable
    },
    selectedSubAccountRelation() {
      return this.$store.state.selectedSubAccountRelation
    },
    aiWriterLink() {
      let runId = this.siloKeyword.pageRunId;
      if (!runId) return;
      let url = `/#/content-editor?pageRunId=${runId}&editorMode=AI_MODE&selectTargetWC=true`
      if (this.selectedSubAccountRelation) {
        url += `&relId=${ this.selectedSubAccountRelation.id }`
      }
      return url
    },
    contentEditorLink() {
      let secretKey = this.siloKeyword.pageRunResult.secretKey;
      if (!secretKey) return;
      let url = `/#/content-editor?secretKey=${secretKey}&siloKeywordId=${this.siloKeyword.id}`
      if (this.selectedSubAccountRelation) {
        url += `&relId=${ this.selectedSubAccountRelation.id }`
      }
      return url
    }
  },
  methods: {
    mainPageSchemaTypes(data) {
      return data.eatSchemaTypes || []
    },
    getHostName(url) {
      if (url.indexOf("//") > -1) {
        return url.split("/")[2];
      } else {
        return url.split("/")[0];
      }
    },
    extractHostnameWithProtocol(url) {
      let hostname = this.getHostName(url);
      if (!url.includes(hostname)) hostname = this.getHostName(url); // this validated domain is different to user inputed, then we will use user inputted
      if (url.toLowerCase().startsWith("https")) {
        return "https://" + hostname;
      } else {
        return "http://" + hostname;
      }
    },
    reportConditions(reportType = "top_level") {
      const { siloKeyword, siloReportProgress, isTopLevelKeyword } = this;
      if (!siloKeyword) {
        return true;
      }
      const isTopLevel = this.isTopLevelKeyword;
      if (isTopLevelKeyword) {
        if (reportType === "top_level") {
          return (siloReportProgress || isEmpty(siloKeyword.guardDiffData)) && isTopLevel;
        } else {
          return !siloKeyword.aiRunResult.aiRunData;
        }
      } else {
        return !siloKeyword.aiRunResult.aiRunData;
      }
    },
    gotoAiRun() {
      let url = this.aiWriterLink
      if (!url) return

      window
        .open(
          this.aiWriterLink,
          '_blank',
        )
        .focus();
    },
    gotoContentEditor() {
      let url = this.contentEditorLink;
      if (!url) return;

      window
        .open(
          url,
          '_blank',
        )
        .focus();
    },
    getMenuItems() {
      return [
        {
          title: `Create AI Writer Outline`,
          class: "mainColor--text font-weight-bold",
          icon: `<i class="material-icons-outlined">list</i>`,
          onClick: "gotoAiRun",
          isDisabled: false,
          isTopLevelKeyword: null,
          isAvailableToBoth: true,
          isVisible: this.isPageRun,
        },
        {
          title: `Create AI Writer article`,
          class: "mainColor--text font-weight-bold",
          onClick: "gotoAiRun",
          vIcon: "mdi-creation",
          isDisabled: false,
          isTopLevelKeyword: null,
          isAvailableToBoth: true,
          isVisible: this.isPageRun,
        },
        {
          title: `Open Content Editor`,
          class: "mainColor--text font-weight-bold",
          vIcon: "mdi-open-in-new",
          onClick: "gotoContentEditor",
          isDisabled: false,
          isTopLevelKeyword: null,
          isAvailableToBoth: true,
          isVisible: this.isPageRun,
        },
        {
          title: `Download EEAT report`,
          class: "mainColor--text font-weight-bold",
          icon: `<i class="material-icons-outlined">fact_check</i>`,
          children: [
            { title: 'PDF', onClick: 'generateEEATPDF' },
            { title: 'Word', onClick: 'createEEATDocx' },
            { title: 'PDF - Without instructions', onClick: 'generateEEATPDF', params: [true] },
            { title: 'Word - Without instructions', onClick: 'createEEATDocx', params: [true] }
          ],
          divider: true,
          isDisabled: false,
          isTopLevelKeyword: null,
          isAvailableToBoth: true,
          isVisible: this.isEATTAvailable,
        },
        {
          title: `Download Schema report`,
          class: "mainColor--text font-weight-bold",
          icon: `<i class="material-icons-outlined">code</i>`,
          children: [
            { title: 'PDF', onClick: 'generateSchemasPDF' },
            { title: 'JSON', onClick: 'generateSchemasJSONReport' },
            { title: 'PDF - Without instructions', onClick: 'generateSchemasPDF', params: [true] },
            { title: 'JSON - Without instructions', onClick: 'generateSchemasJSONReport', params: [true] }
          ],
          divider: !this.isEATTAvailable,
          isDisabled: false,
          isTopLevelKeyword: null,
          isAvailableToBoth: true,
          isVisible: this.areSchemasAvailable,
        },
        {
          title: `Download ${ this.isTopLevelKeyword ? 'top-level' : 'supporting'} page reports`,
          class: "mainColor--text font-weight-bold",
          icon: '<i class="material-icons-outlined">file_download</i>',
          divider: true,
          isDisabled: false,
          isTopLevelKeyword: this.isTopLevelKeyword,
          isAvailableToBoth: false,
          isVisible: this.isAIDraftReportVisible || this.isHumanDraftVisible || this.isExistingReportVisible || this.isAIOutlineVisible,
        },
        {
          title: `New ${ this.isTopLevelKeyword ? 'top-level' : 'supporting'} page draft (AI generated)`,
          link: '#',
          children: [
            { title: 'PDF', onClick: "generateAIDraftPDF" },
            { title: 'Word', onClick: "generateAIPageDraftWord" },
            { title: 'PDF - Without instructions', onClick: "generateAIDraftPDF", params: [true] },
            { title: 'Word - Without instructions', onClick: "generateAIPageDraftWord", params: [true] }
          ],
          isVisible: this.isAIDraftReportVisible,
          isTopLevelKeyword: this.isTopLevelKeyword,
          isAvailableToBoth: false,
          isDisabled: false,
        },
        {
          title: `New ${ this.isTopLevelKeyword ? 'top-level' : 'supporting'} page draft (human edited)`,
          link: '#',
          children: [
            { title: 'PDF', onClick: "generateHumanEditedPDF" },
            { title: 'Word', onClick: "generateEditedPageDraftWord" },
            { title: 'PDF - Without instructions', onClick: "generateHumanEditedPDF", params: [true] },
            { title: 'Word - Without instructions', onClick: "generateEditedPageDraftWord", params: [true] }
          ],
          isVisible: this.isHumanDraftVisible,
          isTopLevelKeyword: this.isTopLevelKeyword,
          isAvailableToBoth: false,
          isDisabled: false
        },
        {
          title: "Existing top-level page update",
          link: "#",
          children: [
            { title: "PDF", onClick: "generateTopLevelPDFBriefReport" },
            { title: "Word", onClick: "generateTopLevelWordBriefReport" },
            { title: "PDF - Without instructions", onClick: "generateTopLevelPDFBriefReport", params: [true] },
            { title: "Word - Without instructions", onClick: "generateTopLevelWordBriefReport", params: [true] },
          ],
          isDisabled: this.isSelectionDisabled,
          isTopLevelKeyword: true,
          isAvailableToBoth: false,
          isVisible: this.isExistingReportVisible,
        },
        {
          title: `New ${ this.isTopLevelKeyword ? 'top-level' : 'supporting'} page outline`,
          link: '#',
          children: [
            { title: 'PDF', onClick: "generateOutlinePDF" },
            { title: 'Word', onClick: "generateOutlineWord" },
            { title: 'PDF - Without instructions', onClick: "generateOutlinePDF", params: [true] },
            { title: 'Word - Without instructions', onClick: "generateOutlineWord", params: [true] }
          ],
          isVisible: this.isAIOutlineVisible,
          isTopLevelKeyword: this.isTopLevelKeyword,
          isAvailableToBoth: false,
          isDisabled: false
        },
      ]
    },
    getReportData(siloId, reportType) {
      if (!siloId) return Promise.resolve(null);;
      this.$store.commit('showLoading');
      return this.$store
        .dispatch('getSiloWordReport', { siloKeywordId: siloId, reportType: reportType })
        .then((response) => {
          return response.data
        }).finally(() => {
          this.$store.commit('hideLoading');
        })
    },
    generateTopLevelWordBriefReport(ommitInstructions = false) {
      if (this.reportConditions("top_level")) {
        return;
      }
      this.getReportData(this.siloKeyword.id, "top_level").then((responseData) => {
        if (responseData) {
          if (!responseData) return

          // Create a new document
          const doc = new Document({
            styles: { default: { document: { run: { font: "Arial" } } } },
            sections: [
              {
                children: [
                  // First Table: One row, two columns
                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: responseData.headerImg,
                        transformation: {
                          width: 600,
                          height: 230,
                        },
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                    spacing: {
                      line: 240,
                      after: 480,
                    },
                  }),
                  ...!ommitInstructions
                    ?[
                      new Table({
                        rows: [
                          new TableRow({
                            children: [
                              new TableCell({
                                children: [
                                  new Paragraph({
                                    children: [
                                      new TextRun({
                                        text: "💡 Instructions",
                                        color: "#FFFFFF",
                                        bold: true,
                                        size: 38,
                                      }),
                                    ],
                                    heading: HeadingLevel.HEADING_1,
                                    alignment: AlignmentType.CENTER,
                                    spacing: {
                                      before: 120,
                                      after: 120,
                                      afterAutoSpacing: true,
                                    },
                                  }),
                                ],
                                width: {
                                  size: 100,
                                  type: WidthType.PERCENTAGE,
                                },
                                verticalAlign: "center",
                                shading: {
                                  fill: "#FF5700",
                                },
                                margins: {
                                  top: convertInchesToTwip(0.1),
                                  bottom: convertInchesToTwip(0.1),
                                  left: convertInchesToTwip(0.2),
                                  right: convertInchesToTwip(0.2),
                                },
                                borders: {
                                  top: {
                                    color: "#FF5700",
                                    size: 5,
                                  },
                                  bottom: {
                                    style: BorderStyle.SINGLE,
                                    color: "#FF5700",
                                    size: 5,
                                  },
                                  left: {
                                    style: BorderStyle.SINGLE,
                                    color: "#FF5700",
                                    size: 5,
                                  },
                                  right: {
                                    style: BorderStyle.SINGLE,
                                    color: "#FF5700",
                                    size: 5,
                                  },
                                },
                              }),
                            ],
                          }),
                          new TableRow({
                            children: [
                              new TableCell({
                                children: [
                                  new Paragraph({
                                    children: [
                                      new TextRun({
                                        text: "Here are the recommended updates for this existing page. You have a couple of different",
                                      }),
                                      new TextRun({
                                        text: " options ",
                                        bold: true,
                                      }),
                                      new TextRun({
                                        text: "for implementing these updates:",
                                      }),
                                    ],
                                    bullet: {
                                      level: 0,
                                    },
                                    spacing: {
                                      before: 240,
                                    },
                                  }),
                                  new Paragraph({
                                    children: [
                                      new TextRun({
                                        text: "Make edits directly from your ",
                                      }),
                                      new TextRun({
                                        text: "website editor ",
                                        bold: true,
                                      }),
                                      new TextRun({
                                        text: "(Wordpress / Squarespace etc).",
                                      }),
                                    ],
                                    bullet: {
                                      level: 1,
                                    },
                                  }),
                                  new Paragraph({
                                    children: [
                                      new TextRun({
                                        text: "Copy the content of the existing page (including page markup) into a",
                                      }),
                                      new TextRun({
                                        text: " Google Doc ",
                                        bold: true,
                                      }),
                                      new TextRun({
                                        text: "and edit there.",
                                      }),
                                    ],
                                    bullet: {
                                      level: 1,
                                    },
                                  }),
                                  new Paragraph({
                                    children: [
                                      new TextRun({
                                        text: "Whichever option you choose, when satisfied with the content, you may want to do an SEO check of your final draft using the",
                                      }),
                                      new TextRun({
                                        text: " POP Chrome Extension ",
                                        bold: true,
                                      }),
                                      new TextRun({
                                        text: "ensure the content scores",
                                      }),
                                      new TextRun({
                                        text: " above 80.",
                                        bold: true,
                                      }),
                                    ],
                                    bullet: {
                                      level: 0,
                                    },
                                    spacing: {
                                      before: 120,
                                      after: 120,
                                    },
                                  }),
                                  new Paragraph({
                                    children: [
                                      new ExternalHyperlink({
                                        children: [
                                          new TextRun({
                                            text: "Here is a tutorial explaining how to do that.",
                                            style: "Hyperlink",
                                            bold: true,
                                            color: "#FF5700",
                                          }),
                                        ],
                                        link: "https://help.pageoptimizer.pro/article/pageoptimizer-pro-chrome-extension-guide",
                                      }),
                                    ],
                                    bullet: {
                                      level: 1,
                                    },
                                    spacing: {
                                      before: 120,
                                      after: 120,
                                    },
                                  }),
                                  new Paragraph({
                                    children: [
                                      new ExternalHyperlink({
                                        children: [
                                          new TextRun({
                                            text: "Here is a list of POP Chrome Extension compatible editors.",
                                            style: "Hyperlink",
                                            bold: true,
                                            color: "#FF5700",
                                          }),
                                        ],
                                        link: "https://help.pageoptimizer.pro/article/compatible-editors",
                                      }),
                                    ],
                                    bullet: {
                                      level: 1,
                                    },
                                    spacing: {
                                      before: 120,
                                      after: 120,
                                    },
                                  }),
                                  new Paragraph({
                                    children: [
                                      new TextRun({
                                        text: "As you are updating an existing page you may need to add new subsections of content to meet word count targets. ",
                                      }),
                                      ...(!responseData.data.utilize
                                        ? []
                                        : [
                                          new TextRun({ text: "Utilize section" }),
                                          new TextRun({ text: responseData.data.utilize, bold: true }),
                                          new TextRun({
                                            text: " to assist you in adding new sections to the page.",
                                          }),
                                        ]),
                                    ],
                                    bullet: {
                                      level: 0,
                                    },
                                    spacing: {
                                      before: 240,
                                      after: 120,
                                    },
                                  }),
                                  new Paragraph({
                                    children: [
                                      new TextRun({
                                        text: "When satisfied with your updates, it’s time to",
                                      }),
                                      new TextRun({
                                        text: " publish them to the existing page.",
                                        bold: true,
                                      }),
                                    ],
                                    bullet: {
                                      level: 0,
                                    },
                                    spacing: {
                                      before: 240,
                                    },
                                  }),
                                  new Paragraph({
                                    children: [
                                      new TextRun({
                                        text: "Don't forget to",
                                      }),
                                      new TextRun({
                                        text: " interlink",
                                        bold: true,
                                      }),
                                      new TextRun({
                                        text: " the page according to the",
                                      }),
                                      new TextRun({
                                        text: " Silo map",
                                        bold: true,
                                      }),
                                      new TextRun({
                                        text: " included in the same folder as this document.",
                                      }),
                                    ],
                                    bullet: {
                                      level: 0,
                                    },
                                    spacing: {
                                      before: 240,
                                    },
                                  }),
                                  new Paragraph({
                                    children: [
                                      new TextRun({
                                        text: "For a detailed",
                                      }),
                                      new TextRun({
                                        text: " tutorial",
                                        bold: true,
                                      }),
                                      new TextRun({
                                        text: " on how the POP on-page process works ",
                                      }),
                                      new ExternalHyperlink({
                                        children: [
                                          new TextRun({
                                            text: "start here",
                                            style: "Hyperlink",
                                            bold: true,
                                            color: "#FF5700",
                                          }),
                                        ],
                                        link: "https://academy.pageoptimizer.pro/lessons/introduction/",
                                      }),
                                      new TextRun({
                                        text: ".",
                                      }),
                                    ],
                                    bullet: {
                                      level: 0,
                                    },
                                    spacing: {
                                      before: 240,
                                    },
                                  }),
                                ],
                                width: {
                                  size: 100,
                                  type: WidthType.PERCENTAGE,
                                },
                                verticalAlign: "",
                                shading: {
                                  fill: "#FEF7EF",
                                },
                                margins: {
                                  top: convertInchesToTwip(0.15),
                                  left: convertInchesToTwip(0.15),
                                  bottom: convertInchesToTwip(0.15),
                                  right: convertInchesToTwip(0.15),
                                },
                                borders: {
                                  top: {
                                    color: "#FF5700",
                                    size: 5,
                                  },
                                  bottom: {
                                    style: BorderStyle.SINGLE,
                                    color: "#FF5700",
                                    size: 5,
                                  },
                                  left: {
                                    style: BorderStyle.SINGLE,
                                    color: "#FF5700",
                                    size: 5,
                                  },
                                  right: {
                                    style: BorderStyle.SINGLE,
                                    color: "#FF5700",
                                    size: 5,
                                  },
                                },
                              }),
                            ],
                          }),
                        ],
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        columnWidths: [10000, 3300],
                      }),
                    ]
                    :[],
                  new Paragraph({ children: [new TextRun({ text: "  " })] }),
                  new Table({
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Type of the Page",
                                    bold: true,
                                    size: 24,
                                    color: "#FFFFFF",
                                  }),
                                ],
                                spacing: {
                                  before: 60,
                                  after: 60,
                                  afterAutoSpacing: true,
                                },
                              }),
                            ],
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FF5700",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: responseData.data.pageType,
                                    size: 22,
                                  }),
                                ],
                              }),
                            ],
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FEF7EF",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                        ],
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Main Keyword",
                                    bold: true,
                                    size: 24,
                                    color: "#FFFFFF",
                                  }),
                                ],
                                spacing: {
                                  before: 60,
                                  after: 60,
                                  afterAutoSpacing: true,
                                },
                              }),
                            ],
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FF5700",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: responseData.data.mainKeyword,
                                    size: 22,
                                  }),
                                ],
                              }),
                            ],
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FEF7EF",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                        ],
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Page URL",
                                    bold: true,
                                    size: 24,
                                    color: "#FFFFFF",
                                  }),
                                ],
                                spacing: {
                                  before: 60,
                                  after: 60,
                                  afterAutoSpacing: true,
                                },
                              }),
                            ],
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FF5700",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: responseData.data.pageURL,
                                    size: 22,
                                  }),
                                ],
                              }),
                            ],
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FEF7EF",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                        ],
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Current word count",
                                    bold: true,
                                    size: 24,
                                    color: "#FFFFFF",
                                  }),
                                ],
                                spacing: {
                                  before: 60,
                                  after: 60,
                                  afterAutoSpacing: true,
                                },
                              }),
                            ],
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FF5700",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: responseData.data.currentWC.toLocaleString(),
                                    size: 22,
                                  }),
                                ],
                              }),
                            ],
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FEF7EF",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                        ],
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Recommended word count",
                                    bold: true,
                                    size: 24,
                                    color: "#FFFFFF",
                                  }),
                                ],
                                spacing: {
                                  before: 60,
                                  after: 60,
                                  afterAutoSpacing: true,
                                },
                              }),
                            ],
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FF5700",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text:
                                      "Around " + responseData.data.recommendedWC.toLocaleString() +
                                      " words (give or take 10%)",
                                    size: 22,
                                  }),
                                ],
                              }),
                            ],
                            width: {
                              size: 50,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FEF7EF",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                        ],
                      }),
                    ],
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    columnWidths: [7700, 3300],
                  }),

                  new Paragraph({ children: [new TextRun({ text: "  " })] }),
                  new Table({
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Main Keyword Updates",
                                    color: "#FFFFFF",
                                    bold: true,
                                    size: 38,
                                  }),
                                ],
                                heading: HeadingLevel.HEADING_1,
                                alignment: AlignmentType.CENTER,
                                spacing: {
                                  before: 240,
                                  after: 240,
                                  afterAutoSpacing: true,
                                },
                              }),
                            ],
                            width: {
                              size: 100,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FF5700",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 5,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FF5700",
                                size: 5,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FF5700",
                                size: 5,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FF5700",
                                size: 5,
                              },
                            },
                          }),
                        ],
                      }),
                    ],
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    columnWidths: [7700, 3300],
                  }),
                  new Paragraph({ children: [new TextRun({ text: "  " })] }),
                  responseData.data.keywordUpdates &&
                  new Table({
                    rows: responseData.data.keywordUpdates.map((row, idx) => {
                      return row.data && (new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: `${idx + 1
                                      }. ${row.section_label}`,
                                    bold: true,
                                    size: 24,
                                    color: "#FFFFFF",
                                  }),
                                ],
                              }),
                            ],
                            width: {
                              size: 35,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FF5700",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                          new TableCell({
                            children: [
                              row.data.map((line) => {
                              return new Paragraph({
                                children: line.map((textRun) => {
                                  return new TextRun({
                                    text: textRun.text,
                                    size: 22,
                                    bold: textRun.bold
                                  });
                                }),
                                bullet: {
                                  level: 0,
                                },
                                spacing: {
                                  before: 240,
                                },
                              });
                            }),
                            row.should_disp_related_questions ?
                            row.faqData.map((faq) => {
                              return new Paragraph({
                                children: [
                                  new TextRun({
                                    text: faq.question,
                                    size: 22,
                                  }),
                                ],
                                bullet: {
                                  level: 1,
                                },
                                spacing: {
                                  before: 240,
                                },
                              });
                            }).flat() : {}

                          ].flat(),
                            width: {
                              size: 65,
                              type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: "center",
                            shading: {
                              fill: "#FEF7EF",
                            },
                            margins: {
                              top: convertInchesToTwip(0.1),
                              bottom: convertInchesToTwip(0.1),
                              left: convertInchesToTwip(0.1),
                              right: convertInchesToTwip(0.1),
                            },
                            borders: {
                              top: {
                                color: "#FFFFFF",
                                size: 10,
                              },
                              bottom: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              left: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                              right: {
                                style: BorderStyle.SINGLE,
                                color: "#FFFFFF",
                                size: 10,
                              },
                            },
                          }),
                        ],
                      }));
                    }),

                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    columnWidths: [7700, 3300],
                  }),
                ],
              },
            ],
          });

          const timestamp = moment().format("YYYY_M_D_h_mm_ss");
          const fileName = `existing_page_update_brief_keyword_${responseData.data.mainKeyword.replace(" ", "_")}_${timestamp}.docx`;
          downloadWordFile(doc, fileName)
        }
      });
    },
    // Top Level Outline
    generateOutlineWord(ommitInstructions = false) {
      if (this.siloKeyword.editedReports.replaceOutlineReport) {
        downloadEditorReport(this.$store, ReportTypes.OUTLINE, "word", this.siloKeyword.id, this.siloKeyword.pageRunResult.secretKey, ommitInstructions)
        return
      }
      if (this.reportConditions("outline")) {
        return;
      }
      this.getReportData(this.siloKeyword.id, "outline").then((responseData) => {

        // Create a new document
        const doc = new Document({
          styles: { default: { document: { run: { font: "Arial" } } } },
          sections: [
            {
              children: [
                // First Table: One row, two columns
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: responseData.headerImg,
                      transformation: {
                        width: 600,
                        height: 230,
                      },
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                  spacing: {
                    line: 240,
                    after: 480,
                  },
                }),
                ...!ommitInstructions
                  ?[
                    new Table({
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "💡 Instructions",
                                      color: "#FFFFFF",
                                      bold: true,
                                      size: 38,
                                    }),
                                  ],
                                  heading: HeadingLevel.HEADING_1,
                                  alignment: AlignmentType.CENTER,
                                  spacing: {
                                    before: 120,
                                    after: 120,
                                    afterAutoSpacing: true,
                                  },
                                }),
                              ],
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              verticalAlign: "center",
                              shading: {
                                fill: "#FF5700",
                              },
                              margins: {
                                top: convertInchesToTwip(0.1),
                                bottom: convertInchesToTwip(0.1),
                                left: convertInchesToTwip(0.2),
                                right: convertInchesToTwip(0.2),
                              },
                              borders: {
                                top: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                bottom: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                left: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                right: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                              },
                            }),
                          ],
                        }),
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "Use your favorite AI or human writing tool to draft the content for this page based on the",
                                    }),
                                    new TextRun({
                                      text: " instructions ",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: "below.",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                                new Paragraph({
                                  children:
                                    this.siloKeyword.keywordData.isTopLevelKeyword ?
                                      [new TextRun({
                                        text: "When satisfied with the outcome, you may want to do a final SEO check of your final draft using the",
                                      }),
                                      new TextRun({
                                        text: " POP Chrome Extension ",
                                        bold: true,
                                      }),
                                      new TextRun({
                                        text: "to ensure the content scores above 80.",
                                        bold: true,
                                      }),]
                                      :
                                      [new TextRun({
                                        text: "When satisfied with the outcome, just do a final check",
                                      }),
                                      new TextRun({
                                        text: " final check ",
                                        bold: true,
                                      }),
                                      new TextRun({
                                        text: "to ensure the main keyword is included at least once in these places:",
                                        bold: true,
                                      }),]
                                  ,
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 120,
                                    after: 120,
                                  },
                                }),
                                ...this.siloKeyword.keywordData.isTopLevelKeyword ?
                                  [
                                    new Paragraph({
                                      children: [
                                        new ExternalHyperlink({
                                          children: [
                                            new TextRun({
                                              text: "Here is a tutorial explaining how to do that.",
                                              style: "Hyperlink",
                                              bold: true,
                                              color: "#FF5700",
                                            }),
                                          ],
                                          link: "https://help.pageoptimizer.pro/article/pageoptimizer-pro-chrome-extension-guide",
                                        }),
                                      ],
                                      bullet: {
                                        level: 1,
                                      },
                                      spacing: {
                                        before: 120,
                                        after: 120,
                                      },
                                    }),
                                    new Paragraph({
                                      children: [
                                        new ExternalHyperlink({
                                          children: [
                                            new TextRun({
                                              text: "Here is a list of POP Chrome Extension compatible editors.",
                                              style: "Hyperlink",
                                              bold: true,
                                              color: "#FF5700",
                                            }),
                                          ],
                                          link: "https://help.pageoptimizer.pro/article/compatible-editors",
                                        }),
                                      ],
                                      bullet: {
                                        level: 1,
                                      },
                                      spacing: {
                                        before: 120,
                                        after: 120,
                                      },
                                    }),
                                  ].flat()
                                  :
                                  [
                                    new Paragraph({
                                      children: [
                                        new TextRun({
                                          text: "The title tag & H1 tag.",
                                        }),
                                      ],
                                      bullet: {
                                        level: 1,
                                      },
                                      spacing: {
                                        before: 120,
                                        after: 120,
                                      },
                                    }),
                                    new Paragraph({
                                      children: [
                                        new TextRun({
                                          text: "The main content.",
                                        }),
                                      ],
                                      bullet: {
                                        level: 1,
                                      },
                                      spacing: {
                                        before: 120,
                                        after: 120,
                                      },
                                    }),
                                    new Paragraph({
                                      children: [
                                        new TextRun({
                                          text: "If possible, the URL of the page (however do not change an existing URL).",
                                        }),
                                      ],
                                      bullet: {
                                        level: 1,
                                      },
                                      spacing: {
                                        before: 120,
                                        after: 120,
                                      },
                                    })
                                  ].flat()
                                ,

                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "Your content is now ready to",
                                    }),
                                    new TextRun({
                                      text: " publish ",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: "to your website."
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "Don't forget to",
                                    }),
                                    new TextRun({
                                      text: " interlink",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: " the page according to the",
                                    }),
                                    new TextRun({
                                      text: " Silo map",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: " included in the same folder as this document.",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "For a detailed",
                                    }),
                                    new TextRun({
                                      text: " tutorial",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: " on how the POP on-page process works ",
                                    }),
                                    new ExternalHyperlink({
                                      children: [
                                        new TextRun({
                                          text: "start here",
                                          style: "Hyperlink",
                                          bold: true,
                                          color: "#FF5700",
                                        }),
                                      ],
                                      link: "https://academy.pageoptimizer.pro/lessons/introduction/",
                                    }),
                                    new TextRun({
                                      text: ".",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                              ],
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              verticalAlign: "",
                              shading: {
                                fill: "#FEF7EF",
                              },
                              margins: {
                                top: convertInchesToTwip(0.15),
                                left: convertInchesToTwip(0.15),
                                bottom: convertInchesToTwip(0.15),
                                right: convertInchesToTwip(0.15),
                              },
                              borders: {
                                top: {
                                  color: "#FF5700",
                                  size: 5,
                                },
                                bottom: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                left: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                right: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                              },
                            }),
                          ],
                        }),
                      ],
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      columnWidths: [9000, 3300],
                    }),
                  ]
                  :[],
                new Paragraph({ children: [new TextRun({ text: "  " })] }),
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Type of the Page",
                                  bold: true,
                                  size: 24,
                                  color: "#FFFFFF",
                                }),
                              ],
                              spacing: {
                                before: 60,
                                after: 60,
                                afterAutoSpacing: true,
                              },
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FF5700",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: responseData.data.pageType,
                                  size: 22,
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FEF7EF",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Main Keyword",
                                  bold: true,
                                  size: 24,
                                  color: "#FFFFFF",
                                }),
                              ],
                              spacing: {
                                before: 60,
                                after: 60,
                                afterAutoSpacing: true,
                              },
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FF5700",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: responseData.data.mainKeyword,
                                  size: 22,
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FEF7EF",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Recommended word count",
                                  bold: true,
                                  size: 24,
                                  color: "#FFFFFF",
                                }),
                              ],
                              spacing: {
                                before: 60,
                                after: 60,
                                afterAutoSpacing: true,
                              },
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FF5700",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    `Around ${responseData.data.recommendedWC.toLocaleString()} words${this.siloKeyword.keywordData.isTopLevelKeyword ? " (give or take 10%)" : ""}`,
                                    size: 22,
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FEF7EF",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                      ],
                    }),
                  ],
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  columnWidths: [4500, 4500],
                }),

                new Paragraph({
                  children: [new PageBreak()],
                }),
              ],
            },
          ],
        });
        // Outline
        const outlineTable = new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: "📝 Page Outline",
                          color: "#FFFFFF",
                          bold: true,
                          size: 38,
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                      alignment: AlignmentType.CENTER,
                      spacing: {
                        before: 240,
                        after: 240,
                        afterAutoSpacing: true,
                      },
                    }),
                  ],
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  verticalAlign: "center",
                  shading: {
                    fill: "#FF5700",
                  },
                  margins: {
                    top: convertInchesToTwip(0.1),
                    bottom: convertInchesToTwip(0.1),
                    left: convertInchesToTwip(0.1),
                    right: convertInchesToTwip(0.1),
                  },
                  borders: {
                    top: {
                      color: "#FF5700",
                      size: 5,
                    },
                    bottom: {
                      style: BorderStyle.SINGLE,
                      color: "#FFFFFF",
                      size: 5,
                    },
                    left: {
                      style: BorderStyle.SINGLE,
                      color: "#FF5700",
                      size: 5,
                    },
                    right: {
                      style: BorderStyle.SINGLE,
                      color: "#FF5700",
                      size: 5,
                    },
                  },
                }),
              ],
            })
          ],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          columnWidths: [9000, 3300],
        })
        // Heading Section
        if (responseData.data.aiRunData.heading) {
          const heading = responseData.data.aiRunData.heading;
          const headingSection = new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `<${heading.tag.toUpperCase()}> `,
                        color: "#000000",
                        size: 28,
                      }),
                      new TextRun({
                        text: `${heading.text}`,
                        color: "#000000",
                        bold: true,
                        size: 28,
                      }),
                    ],
                    spacing: {
                      before: 60,
                      after: 60,
                      afterAutoSpacing: true,
                    },
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: 'Note: We recommend your page title tag be the same as your H1.',
                        size: 22,
                        italics: true
                      }),
                    ],
                    spacing: {
                      before: 60,
                      after: 60,
                      afterAutoSpacing: true,
                    },
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                verticalAlign: "center",
                shading: {
                  fill: "#FEF7EF",
                },
                margins: {
                  top: convertInchesToTwip(0.1),
                  bottom: convertInchesToTwip(0.1),
                  left: convertInchesToTwip(0.1),
                  right: convertInchesToTwip(0.1),
                },
                borders: {
                  top: {
                    style: BorderStyle.SINGLE,
                    color: "#FEF7EF",
                    size: 5,
                  },
                  bottom: {
                    style: BorderStyle.SINGLE,
                    color: "#FEF7EF",
                    size: 5,
                  },
                  left: {
                    style: BorderStyle.SINGLE,
                    color: "#FF5700",
                    size: 5,
                  },
                  right: {
                    style: BorderStyle.SINGLE,
                    color: "#FF5700",
                    size: 5,
                  },
                },
              }),
            ],
          })
          outlineTable.addChildElement(headingSection)
        }
        // Introduction Section
        if (responseData.data.aiRunData.introduction) {
          const introductionSection = new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Intro",
                        bold: true,
                        size: 28,
                      }),
                    ],
                    spacing: {
                      before: 60,
                      after: 60,
                      afterAutoSpacing: true,
                    },
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: responseData.data.aiRunData.introduction,
                        size: 24,
                      }),
                    ],
                    spacing: {
                      before: 60,
                      after: 60,
                      afterAutoSpacing: true,
                    },
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                verticalAlign: "center",
                shading: {
                  fill: "#FEF7EF",
                },
                margins: {
                  top: convertInchesToTwip(0.1),
                  bottom: convertInchesToTwip(0.1),
                  left: convertInchesToTwip(0.1),
                  right: convertInchesToTwip(0.1),
                },
                borders: {
                  top: {
                    style: BorderStyle.SINGLE,
                    color: "#FEF7EF",
                    size: 5,
                  },
                  bottom: {
                    style: BorderStyle.SINGLE,
                    color: "#FEF7EF",
                    size: 5,
                  },
                  left: {
                    style: BorderStyle.SINGLE,
                    color: "#FF5700",
                    size: 5,
                  },
                  right: {
                    style: BorderStyle.SINGLE,
                    color: "#FF5700",
                    size: 5,
                  },
                },
              }),
            ],
          })
          outlineTable.addChildElement(introductionSection)
        }
        // Sub heading section
        const subHeadingSection = new Table({
          rows: [
            ...responseData.data.aiRunData.subHeadingsTree.map(
              (heading) => {
                return [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: `<${heading.tag.toUpperCase()}> `,
                                color: "#000000",
                                size: 28,
                              }),
                              new TextRun({
                                text: `${heading.text}`,
                                color: "#000000",
                                bold: true,
                                size: 28,
                              }),
                            ],
                            spacing: {
                              before: 60,
                              after: 60,
                              afterAutoSpacing: true,
                            },
                          })
                        ],
                        width: {
                          size: 100,
                          type: WidthType.PERCENTAGE,
                        },
                        verticalAlign: "center",
                        shading: {
                          fill: "#FEF7EF",
                        },
                        margins: {
                          top: convertInchesToTwip(0.1),
                          bottom: convertInchesToTwip(0.1),
                          left: convertInchesToTwip(0.1),
                          right: convertInchesToTwip(0.1),
                        },
                        borders: {
                          top: {
                            style: BorderStyle.SINGLE,
                            color: "#FEF7EF",
                            size: 5,
                          },
                          bottom: {
                            style: BorderStyle.SINGLE,
                            color: "#FEF7EF",
                            size: 5,
                          },
                          left: {
                            style: BorderStyle.SINGLE,
                            color: "#FF5700",
                            size: 5,
                          },
                          right: {
                            style: BorderStyle.SINGLE,
                            color: "#FF5700",
                            size: 5,
                          },
                        },
                      }),
                    ],
                  }),
                  ...heading.children.map(
                    (subHeading) => {
                      return [
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: `        <${subHeading.tag.toUpperCase()}> `,
                                      size: 24,
                                    }),
                                    new TextRun({
                                      text: `${subHeading.text}`,
                                      size: 24,
                                    }),
                                  ],
                                  spacing: {
                                    before: 60,
                                    after: 60,
                                    afterAutoSpacing: true,
                                  },
                                }),
                              ],
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              verticalAlign: "center",
                              shading: {
                                fill: "#FEF7EF",
                              },
                              margins: {
                                top: convertInchesToTwip(0.1),
                                bottom: convertInchesToTwip(0.1),
                                left: convertInchesToTwip(0.1),
                                right: convertInchesToTwip(0.1),
                              },
                              borders: {
                                top: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FEF7EF",
                                  size: 5,
                                },
                                bottom: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FEF7EF",
                                  size: 5,
                                },
                                left: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                right: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                              },
                            }),
                          ],
                        }),
                      ]
                    }
                  ).flat(),
                ]
              }
            ).flat(),
          ],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          columnWidths: [7700, 3300],
        })
        // FAQs
        if (responseData.data.aiRunData.faqData.length > 0) {
          const faqSection = new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "FAQs",
                        bold: true,
                        size: 28,
                      }),
                    ],
                    spacing: {
                      before: 60,
                      after: 60,
                      afterAutoSpacing: true,
                    },
                  }),
                  ...responseData.data.aiRunData.faqData.map(
                    (faq) => {
                      return [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: faq.question,
                              size: 24,
                            }),
                          ],
                          spacing: {
                            before: 60,
                            after: 60,
                            afterAutoSpacing: true,
                          },
                          bullet: {
                            level: 0,
                          },
                        }),
                      ]
                    }
                  ).flat(),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                },
                verticalAlign: "center",
                shading: {
                  fill: "#FEF7EF",
                },
                margins: {
                  top: convertInchesToTwip(0.1),
                  bottom: convertInchesToTwip(0.1),
                  left: convertInchesToTwip(0.1),
                  right: convertInchesToTwip(0.1),
                },
                borders: {
                  top: {
                    style: BorderStyle.SINGLE,
                    color: "#FEF7EF",
                    size: 5,
                  },
                  bottom: {
                    style: BorderStyle.SINGLE,
                    color: "#FEF7EF",
                    size: 5,
                  },
                  left: {
                    style: BorderStyle.SINGLE,
                    color: "#FF5700",
                    size: 5,
                  },
                  right: {
                    style: BorderStyle.SINGLE,
                    color: "#FF5700",
                    size: 5,
                  },
                },
              }),
            ],
          })

          subHeadingSection.addChildElement(faqSection)
        }

        // Conclusion
        if (responseData.data.aiRunData.conclusion) {
          const conclusionSection =
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: "Conclusion",
                          bold: true,
                          size: 28,
                        }),
                      ],
                      spacing: {
                        before: 60,
                        after: 60,
                        afterAutoSpacing: true,
                      },
                    }),
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: responseData.data.aiRunData.conclusion,
                          size: 24,
                        }),
                      ],
                      spacing: {
                        before: 60,
                        after: 60,
                        afterAutoSpacing: true,
                      },
                    }),
                  ],
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  verticalAlign: "center",
                  shading: {
                    fill: "#FEF7EF",
                  },
                  margins: {
                    top: convertInchesToTwip(0.1),
                    bottom: convertInchesToTwip(0.1),
                    left: convertInchesToTwip(0.1),
                    right: convertInchesToTwip(0.1),
                  },
                  borders: {
                    top: {
                      style: BorderStyle.SINGLE,
                      color: "#FEF7EF",
                      size: 5,
                    },
                    bottom: {
                      style: BorderStyle.SINGLE,
                      color: "#FF5700",
                      size: 5,
                    },
                    left: {
                      style: BorderStyle.SINGLE,
                      color: "#FF5700",
                      size: 5,
                    },
                    right: {
                      style: BorderStyle.SINGLE,
                      color: "#FF5700",
                      size: 5,
                    },
                  },
                }),
              ],
            })

          subHeadingSection.addChildElement(conclusionSection)
        } else {
          // Add closing border to outlineTable
          subHeadingSection.addChildElement(new TableRow({
            children: [
              new TableCell({
                children: [
                ],
                shading: {
                  fill: "#FEF7EF",
                },
                borders: {
                  top: {
                    style: BorderStyle.SINGLE,
                    color: "#FEF7EF",
                    size: 5,
                  },
                  bottom: {
                    style: BorderStyle.SINGLE,
                    color: "#FF5700",
                    size: 5,
                  },
                  left: {
                    style: BorderStyle.SINGLE,
                    color: "#FF5700",
                    size: 5,
                  },
                  right: {
                    style: BorderStyle.SINGLE,
                    color: "#FF5700",
                    size: 5,
                  },
                }
              }),
            ],
          })
          )
        }

        // Add outline and subheading section to doc
        doc.addSection({ children: [outlineTable, subHeadingSection] })



        const timestamp = moment().format("YYYY_M_D_h_mm_ss");
        const fileName = `${this.pageType}_outline_keyword_${responseData.data.mainKeyword.replace(" ", "_")}_${timestamp}.docx`;
        downloadWordFile(doc, fileName)
      })
    },
    generateSchemas() {
      this.getReportData(this.siloKeyword.id, "outline").then((responseData) => {
        
      })
    },
    getSiloPDFReport(siloId, reportType, ommitInstructions = false) {
      if (!siloId) return;
      if (reportType != "schema" && reportType != "eeat" && this.reportConditions(reportType)) {
        return;
      }
      const timestamp = moment().format("YYYY_M_D_h_mm_ss");
      this.$store.commit('showLoading');
      this.$store
        .dispatch('getSiloPDFReport', { siloKeywordId: siloId, reportType: reportType, ommitInstructions })
        .then((response) => {
          const fileName = `${this.pageType}_${reportType}_keyword_${this.siloKeyword.keyword.replace(" ", "_")}_${timestamp}`
          downloadFile(response.data, fileName)
        })
        .finally(() => {
          this.$store.commit('hideLoading');
        });
    },
    // AI and Human Draft Word
    // Word
    generatePageDraftWord(siloId, edited, reportType, ommitInstructions = false) {
      if (this.reportConditions(reportType)) {
        return;
      }
      this.getReportData(siloId, reportType).then((responseData) => {
        // Create a new document
        const doc = new Document({
          styles: { default: { document: { run: { font: "Arial" } } } },
          sections: [
            {
              children: [
                // First Table: One row, two columns
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: responseData.headerImg,
                      transformation: {
                        width: 600,
                        height: 230,
                      },
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                  spacing: {
                    line: 240,
                    after: 480,
                  },
                }),
                ...!ommitInstructions
                  ?[
                    new Table({
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "💡 Instructions",
                                      color: "#FFFFFF",
                                      bold: true,
                                      size: 38,
                                    }),
                                  ],
                                  heading: HeadingLevel.HEADING_1,
                                  alignment: AlignmentType.CENTER,
                                  spacing: {
                                    before: 120,
                                    after: 120,
                                    afterAutoSpacing: true,
                                  },
                                }),
                              ],
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              verticalAlign: "center",
                              shading: {
                                fill: "#FF5700",
                              },
                              margins: {
                                top: convertInchesToTwip(0.1),
                                bottom: convertInchesToTwip(0.1),
                                left: convertInchesToTwip(0.2),
                                right: convertInchesToTwip(0.2),
                              },
                              borders: {
                                top: {
                                  color: "#FF5700",
                                  size: 5,
                                },
                                bottom: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                left: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                right: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                              },
                            }),
                          ],
                        }),
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "From an SEO perspective this is a",
                                    }),
                                    new TextRun({
                                      text: " fully written page draft. ",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: "The page sections, word count and placement of words have all been",
                                    }),
                                    new TextRun({
                                      text: " optimized for search engines. ",
                                      bold: true,
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "Whether this is an",
                                    }),
                                    new TextRun({
                                      text: " AI generated draft ",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: "or",
                                    }),
                                    new TextRun({
                                      text: " Human edited draft",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: ", (you can confirm by checking the table below in ‘Level of content’) you still need to review and potentially edit the content to ensure it is factually correct, on brand and meets your own content standards.",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 120,
                                    after: 120,
                                  },
                                }),
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "Do keep in mind ",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: "that if this is Supporting Page content, it is not intended to be super high value, it's intended to meet the needs of search engines first and foremost. If a Supporting Page starts to perform very well it can then be upgraded to a Top Level page and benefit from getting its own Supporting Pages, (no need to worry about this now)",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "Have the below content draft",
                                    }),
                                    new TextRun({
                                      text: " reviewed ",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: "and then publish it to the website.",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "Don't forget to",
                                    }),
                                    new TextRun({
                                      text: " interlink the page according to the Silo map ",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: "included in the same folder as this document.",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "For a detailed",
                                    }),
                                    new TextRun({
                                      text: " tutorial",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: " on how the POP on-page process works ",
                                    }),
                                    new ExternalHyperlink({
                                      children: [
                                        new TextRun({
                                          text: "start here",
                                          style: "Hyperlink",
                                          bold: true,
                                          color: "#FF5700",
                                        }),
                                      ],
                                      link: "https://academy.pageoptimizer.pro/lessons/introduction/",
                                    }),
                                    new TextRun({
                                      text: ".",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                              ],
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              verticalAlign: "",
                              shading: {
                                fill: "#FEF7EF",
                              },
                              margins: {
                                top: convertInchesToTwip(0.15),
                                left: convertInchesToTwip(0.15),
                                bottom: convertInchesToTwip(0.15),
                                right: convertInchesToTwip(0.15),
                              },
                              borders: {
                                top: {
                                  color: "#FF5700",
                                  size: 5,
                                },
                                bottom: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                left: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                right: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                              },
                            }),
                          ],
                        }),
                      ],
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      cellWidths: [10000, 3300],
                    }),
                  ]
                  :[],
                new Paragraph({ children: [new TextRun({ text: "  " })] }),
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Type of the Page",
                                  bold: true,
                                  size: 24,
                                  color: "#FFFFFF",
                                }),
                              ],
                              spacing: {
                                before: 60,
                                after: 60,
                                afterAutoSpacing: true,
                              },
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FF5700",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: responseData.data.pageType,
                                  size: 22,
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FEF7EF",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Level of content",
                                  bold: true,
                                  size: 24,
                                  color: "#FFFFFF",
                                }),
                              ],
                              spacing: {
                                before: 60,
                                after: 60,
                                afterAutoSpacing: true,
                              },
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FF5700",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: edited ? "Human edited draft" : "AI generated draft",
                                  size: 22,
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FEF7EF",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Main Keyword",
                                  bold: true,
                                  size: 24,
                                  color: "#FFFFFF",
                                }),
                              ],
                              spacing: {
                                before: 60,
                                after: 60,
                                afterAutoSpacing: true,
                              },
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FF5700",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: responseData.data.mainKeyword,
                                  size: 22,
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                          },
                          verticalAlign: "center",
                          shading: {
                            fill: "#FEF7EF",
                          },
                          margins: {
                            top: convertInchesToTwip(0.1),
                            bottom: convertInchesToTwip(0.1),
                            left: convertInchesToTwip(0.1),
                            right: convertInchesToTwip(0.1),
                          },
                          borders: {
                            top: {
                              color: "#FFFFFF",
                              size: 10,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                          },
                        }),
                      ],
                    }),
                  ],
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  cellWidths: [7700, 3300],
                }),
              ],
            },
          ],
        });
        // Page Draft
        const draftTable = new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: "📝 Page Draft",
                          color: "#FFFFFF",
                          bold: true,
                          size: 38,
                        }),
                      ],
                      heading: HeadingLevel.HEADING_1,
                      alignment: AlignmentType.CENTER,
                      spacing: {
                        before: 240,
                        after: 240,
                        afterAutoSpacing: true,
                      },
                    }),
                  ],
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  verticalAlign: "center",
                  shading: {
                    fill: "#FF5700",
                  },
                  margins: {
                    top: convertInchesToTwip(0.1),
                    bottom: convertInchesToTwip(0.1),
                    left: convertInchesToTwip(0.1),
                    right: convertInchesToTwip(0.1),
                  },
                  borders: {
                    top: {
                      color: "#FFFFFF",
                      size: 5,
                    },
                    bottom: {
                      style: BorderStyle.SINGLE,
                      color: "#FF5700",
                      size: 5,
                    },
                    left: {
                      style: BorderStyle.SINGLE,
                      color: "#FF5700",
                      size: 5,
                    },
                    right: {
                      style: BorderStyle.SINGLE,
                      color: "#FF5700",
                      size: 5,
                    },
                  },
                }),
              ],
            }),

          ],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          cellWidths: [7700, 3300],
        })

        const htmlContentRow = new TableRow({
          children: [],
        })

        const htmlContentCell = new TableCell({
          children: [],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          shading: {
            fill: "#FEF7EF"
          },
          verticalAlign: "center",
          margins: {
            top: convertInchesToTwip(0.2),
            bottom: convertInchesToTwip(0.2),
            left: convertInchesToTwip(0.2),
            right: convertInchesToTwip(0.2),
          },
          borders: {
            top: {
              style: BorderStyle.SINGLE,
              color: "#FF5700",
              size: 5,
            },
            bottom: {
              style: BorderStyle.SINGLE,
              color: "#FF5700",
              size: 5,
            },
            left: {
              style: BorderStyle.SINGLE,
              color: "#FF5700",
              size: 5,
            },
            right: {
              style: BorderStyle.SINGLE,
              color: "#FF5700",
              size: 5,
            }
          }
        })
        let page_heading;
        if (responseData.data.aiRunData.heading) {
          const page_title = new Paragraph(
            {
              children: [
                new TextRun({
                  text: "PAGE TITLE",
                  bold: true,
                  size: 18,
                  color: "#FF5700"
                })
              ]
            }
          )
          const page_title_content = new Paragraph(
            {
              children: [
                new TextRun({
                  text: `${responseData.data.aiRunData.heading.text}`,
                  bold: true,
                  size: 32,
                })
              ]
            }
          )
          page_heading = new Paragraph(
            {
              children: [
                new TextRun({
                  text: `<${responseData.data.aiRunData.heading.tag.toUpperCase()}>`,
                  bold: true,
                  size: 32,
                  color: "#FF5700"
                }),
                new TextRun({
                  text: ` ${responseData.data.aiRunData.heading.text}`,
                  bold: true,
                  size: 32
                }),
              ]
            }
          )
          htmlContentCell.addChildElement(page_title)
          htmlContentCell.addChildElement(page_title_content)
          htmlContentCell.addChildElement(new Paragraph({ text: " " }))
        }

        if (responseData.data.aiRunData.metaDescription) {
          const meta_description = new Paragraph(
            {
              children: [
                new TextRun({
                  text: "META DESCRIPTION",
                  bold: true,
                  size: 18,
                  color: "#FF5700"
                })
              ]
            }
          )
          const meta_description_content = new Paragraph(
            {
              children: [
                new TextRun({
                  text: `${responseData.data.aiRunData.metaDescription}`,
                  size: 20,
                })
              ]
            }
          )
          htmlContentCell.addChildElement(meta_description)
          htmlContentCell.addChildElement(meta_description_content)
          htmlContentCell.addChildElement(new Paragraph({ text: " " }))
        }
        htmlContentCell.addChildElement(page_heading)
        htmlContentCell.addChildElement(new Paragraph({ text: " " }))


        const mainSections = responseData.data.aiRunData.sections.map(
          (section) => {
            return section.tag !== "list" ? [
              new Paragraph({
                children: [
                  section.tag === "img" ?
                    new TextRun({
                      text: `🖼️ <image: your image file link here>`,
                      size: 20,
                    }) :
                    section.tag === "form" ?
                      new TextRun({
                        text: `<form: your form file link here>`,
                        size: 20,
                      }) :
                      new TextRun({
                        children: [
                          new TextRun({
                            text: `<${section.tag.toUpperCase()}>`,
                            bold: true,
                            size: 26,
                            color: "#FF5700"
                          }),
                          new TextRun({
                            text: ` ${section.tagText}`,
                            bold: true,
                            size: 26,
                          })
                        ]
                      })
                ],
                spacing: {
                  before: 60,
                  after: 60,
                  afterAutoSpacing: true,
                },
              }),
              ...parseHtmlData(section.result, 'docx').flat(),
            ] : [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${section.tagText}`,
                    bold: true,
                    size: 26,
                  }),
                ]
              }),
              ...section.result.map((item) => {
                return new Paragraph({
                  children: [
                    new TextRun({
                      text: `${item}`,
                      size: 20,
                    })
                  ],
                  bullet: {
                    level: 0,
                  }
                })
              }),
            ]

          }
        ).flat()
        mainSections.forEach(
          (section) => {
            htmlContentCell.addChildElement(section)
          }
        )
        htmlContentRow.addChildElement(htmlContentCell)

        draftTable.addChildElement(htmlContentRow)
        // Add outline and subheading section to doc
        doc.addSection({ children: [draftTable] })



        const timestamp = moment().format("YYYY_M_D_h_mm_ss");
        const fileName = `${this.pageType}_${reportType}_keyword_${responseData.data.mainKeyword.replace(" ", "_")}_${timestamp}.docx`;
        downloadWordFile(doc, fileName)
      })
    },
    generateAIPageDraftWord(ommitInstructions = false) {
      if (this.siloKeyword.editedReports.replaceAiDraftReport) {
        downloadEditorReport(this.$store, ReportTypes.AI_DRAFT, "word", this.siloKeyword.id, this.siloKeyword.pageRunResult.secretKey, ommitInstructions)
      } else {
        this.generatePageDraftWord(this.siloKeyword.id, false, "ai_draft", ommitInstructions)
      }
    },
    generateEditedPageDraftWord(ommitInstructions = false) {
      if (this.siloKeyword.editedReports.replaceHumanDraftReport) {
        downloadEditorReport(this.$store, ReportTypes.HUMAN_DRAFT, "word", this.siloKeyword.id, this.siloKeyword.pageRunResult.secretKey, ommitInstructions)
      } else {
        this.generatePageDraftWord(this.siloKeyword.id, true, "human_draft", ommitInstructions)
      }
    },
    // PDF
    getSiloTopLevelReport(ommitInstructions = false) {
      this.getSiloPDFReport(this.siloKeyword.id, 'top_level', ommitInstructions);
    },
    getSiloTopLevelOutlineReport(ommitInstructions = false) {
      if (this.siloKeyword.aiRunResult.aiOutline){
        if (this.siloKeyword.editedReports.replaceOutlineReport){
          downloadEditorReport(this.$store, ReportTypes.OUTLINE, "pdf", this.siloKeyword.id, this.siloKeyword.pageRunResult.secretKey, ommitInstructions)
        } else {
          this.getSiloPDFReport(this.siloKeyword.id, 'outline', ommitInstructions);
        }
      } else if (this.siloKeyword.hasEditedSource.hasOutline) {
        downloadEditorReport(this.$store, ReportTypes.OUTLINE, "pdf", this.siloKeyword.id, this.siloKeyword.pageRunResult.secretKey, ommitInstructions)
      }
    },
    generateAIDraftPDF(ommitInstructions = false) {
      if (this.siloKeyword.aiRunResult.aiGenDraft){
        if (this.siloKeyword.editedReports.replaceAiDraftReport){
          downloadEditorReport(this.$store, ReportTypes.AI_DRAFT, "pdf", this.siloKeyword.id, this.siloKeyword.pageRunResult.secretKey, ommitInstructions)
        } else {
          this.getSiloPDFReport(this.siloKeyword.id, 'ai_draft', ommitInstructions);
        }
      } else if (this.siloKeyword.hasEditedSource.hasAiDraft) {
        downloadEditorReport(this.$store, ReportTypes.AI_DRAFT, "pdf", this.siloKeyword.id, this.siloKeyword.pageRunResult.secretKey, ommitInstructions)
      }
    },
    generateHumanEditedPDF(ommitInstructions = false) {
      if (this.siloKeyword.aiRunResult.aiGenDraft){
        if (this.siloKeyword.editedReports.replaceHumanDraftReport){
          downloadEditorReport(this.$store, ReportTypes.HUMAN_DRAFT, "pdf", this.siloKeyword.id, this.siloKeyword.pageRunResult.secretKey, ommitInstructions)
        } else {
          this.getSiloPDFReport(this.siloKeyword.id, 'human_draft', ommitInstructions);
        }
      } else if (this.siloKeyword.hasEditedSource.hasHumanDraft) {
        downloadEditorReport(this.$store, ReportTypes.HUMAN_DRAFT, "pdf", this.siloKeyword.id, this.siloKeyword.pageRunResult.secretKey, ommitInstructions)
      }
    },
    isNumber(n) {
      return !isNaN(parseFloat(n)) && !isNaN(n - 0)
    },
    getValidSchemas(schemas) {
      if (!Array.isArray(schemas)) return []
      return schemas.filter(item => {
        if (item.result === true) return true
        if (this.isNumber(item.result) && item.result > 0) return true
        return false
      })
    },
    commonEatSchemaTypes(data) {
      return data.pageRunData.commonEatSchemaTypes || []
    },
    missingSignals(data) {
      const signalsCurrentPageHas = this.getValidSchemas(this.mainPageSchemaTypes(data)).map(item => item.signal)
      return this.commonEatSchemaTypes(data).filter(item => {
        if (linksCountSignal.includes(item.signal)) {
          return false;
        }
        if (signalsCurrentPageHas.includes(item.signal)) {
          return false;
        }
        if (item.count <= 1) {
          return false;
        }
        return true;
      })
    },
    generateEEATPDF(ommitInstructions = false) {
      this.getReportData(this.siloKeyword.id, 'eeat').then((responseData) => {
        const doc = new jsPDF();
        doc.setDrawColor(0);
        doc.setFillColor(1, 35, 35);
        const startX = 14;
        let currentY = 10;
        doc.addImage(responseData.headerImg, 'png', startX, currentY, 182, 71);
        currentY += 70;
  
        if (!ommitInstructions) {
          const instructionsTableBulletPoints = [
            {
              text: [
                { text: 'While ticking off Google\'s EEAT items (as addressed in ', style: {} },
                { text: 'Google\'s Quality Rater ', style: { fontStyle: 'bold', textColor: '#FF5700', isLink: true, link: 'https://static.googleusercontent.com/media/guidelines.raterhub.com/en//searchqualityevaluatorguidelines.pdf' } },
                { text: 'Guidelines', style: { fontStyle: 'bold', textColor: '#FF5700', isLink: true, link: 'https://static.googleusercontent.com/media/guidelines.raterhub.com/en//searchqualityevaluatorguidelines.pdf' } },
                { text: ') on your website may not directly benefit rankings, by missing too many of', style: {} },
                { text: 'these signals you', style: {} },
                { text: 'risk being ignored by Google', style: { fontStyle: 'bold' } },
                { text: ', which will obviously affect your ability to', style: {} },
                { text: 'generate SEO traffic.', style: {} },
              ]
            },
            {
              text: [
                { text: 'In essence, Google is trying to decipher whether you are a', style: {} },
                { text: ' real business', style: { fontStyle: 'bold' } },
                { text: 'whether you are', style: {} },
                { text: 'who you say you are and that you can be trusted to deliver what you say you are going to', style: {} },
                { text: 'deliver.', style: {} },
              ]
            },
            {
              text: [
                { text: 'We have found the EEAT signals below on ', style: {} },
                { text: 'your top competitor\'s', style: { fontStyle: 'bold' } },
                { text: 'websites and have', style: {} },
                { text: 'tagged them as being either present or missing from your own website.', style: {} },
              ]
            },
            {
              text: [
                { text: 'We have given a description of each signal and your job is to try and ', style: {} },
                { text: 'implement', style: { fontStyle: 'bold' } },
                { text: 'as many', style: {} },
                { text: 'of the missing signals as possible. Most of them are very easy and can be', style: {} },
                { text: 'knocked off in a matter of minutes.', style: {} },
              ]
            }
          ];
    
          let instructionsTableRows = [
            [
              {
                content: '',
                styles: {
                  minCellWidth: 30,
                  minCellHeight: 20,
                  cellPadding: 1,
                  halign: 'right',
                  valign: 'middle',
                  fillColor: '#FF5700',
                  textColor: '#FFFFFF',
                },
              },
              {
                content: 'Instructions',
                styles: {
                  minCellWidth: 5,
                  minCellHeight: 20,
                  halign: 'left',
                  cellPadding: 1,
                  fillColor: '#FF5700',
                  fontSize: 22.65,
                  fontStyle: 'bold',
                  textColor: '#FFFFFF',
                },
              },
            ],
            [
              {
                colSpan: 2,
                content: '',
                styles: {
                  fillColor: '#FFEDDD',
                  minCellHeight: 80,
                  fontSize: 12,
                  lineHeight: 20,
                  fontStyle: 'normal',
                  textColor: '#000000',
                },
              },
            ],
          ];
    
          // Define the styles for the table
          let instructionsTableOptions = {
            startY: currentY + 8, // You can adjust this to place the table where you want in the PDF
            tableLineColor: '#FF5700',
            tableLineWidth: 0.5,
            styles: {
              cellPadding: 5,
              valign: 'middle',
              fontSize: 12,
              textColor: [0, 0, 0], // Black text color
            },
            body: instructionsTableRows,
            didDrawCell: (imageSrc => function (data) {
              const doc = data.doc;
    
              if (data.row.index === 0 && data.column.index === 0) {
                const { x, y, width, height } = data.cell;
                const imgWidth = height - 4;
                const imgX = x + width - imgWidth - 2;
                doc.addImage(imageSrc, 'png', imgX + 8, y + 4, 10, 10);
              }
              if (data.row.index === 1) {
                let startY = data.cell.y + 10
                let startX = data.cell.x + 6
                instructionsTableBulletPoints.forEach((point) => {
                  // Draw the bullet
                  doc.circle(startX, startY - 1.5, 1, 'F');
    
                  let currentX = startX + 3; // Start position after bullet
    
                  point.text.forEach((part, idx) => {
                    if (part.style.fontStyle === 'bold') {
                      doc.setFont('helvetica', 'bold');
                    } else {
                      doc.setFont('helvetica', 'normal');
                    }
    
                    if (part.style.textColor) {
                      doc.setTextColor(part.style.textColor);
                    } else {
                      doc.setTextColor('#000000');
                    }
                    if (idx) currentX++
    
                    doc.text(part.text, currentX, startY);
    
                    if (part.style.isLink) {
                      const textWidth = doc.getTextWidth(part.text);
                      doc.setTextColor(part.style.textColor || '#0000FF');
                      doc.text(part.text, currentX, startY);
    
                      doc.link(currentX, startY - 4, textWidth, 8, { url: part.style.link });
                    }
    
                    // Update X position for the next part
                    currentX += doc.getTextWidth(part.text);
                    if (idx !== point.text.length - 1 && currentX > 170) {
                      currentX = startX + 3
                      startY += 5
                    }
                  });
    
                  // Move Y position for the next bullet point
                  startY += 8; // Adjust spacing between bullet points
                });
              }
            })(this.bulbIcon),
          };
    
          doc.autoTable(instructionsTableOptions);
        }
        
        let rows = [
          [
            {
              colSpan: 2,
              content: "Your site's EEAT audit",
              styles: {
                halign: 'center',
                fillColor: '#FFEDDD',
                fontSize: 22.65,
                fontStyle: 'bold',
                textColor: '#000000',
              },
            },
          ],
          [
            {
              content: '',
              styles: {
                halign: 'right',
                cellPadding: 1,
                valign: 'middle',
                fillColor: '#FFEDDD',
                fontSize: 12,
                fontStyle: 'bold',
                minCellWidth: responseData.data.pageRunData.url?.length > 50 ? 15 : 35,
                textColor: '#FF5700',
              },
            },
            {
              content: this.extractHostnameWithProtocol(responseData.data.pageRunData.url),
              styles: {
                halign: 'left',
                valign: 'middle',
                fillColor: '#FFEDDD',
                cellPadding: 1,
                fontSize: 12,
                fontStyle: 'bold',
                minCellWidth: responseData.data.pageRunData.url?.length > 50 ? 80 : 35,
                textColor: '#FF5700',
              },
            },
          ],
          [
            {
              colSpan: 2,
              content:
                "We have found these EEAT signals on your top competitors' sites. The ones marked 'Add' have not been found on your site and as many as possible should be added before you begin publishing new content.",
              styles: {
                halign: 'center',
                fillColor: '#FFEDDD',
                fontSize: 12,
                textColor: '#000000',
              },
            },
          ],
          [
            {
              colSpan: 2,
              content:
                "For instructions on what each of the EEAT signals looks like on the page,",
              styles: {
                cellPadding: { top: 3, right: 3, bottom: 3, left: 17 },
                fillColor: '#FFEDDD',
                fontSize: 12,
                textColor: '#000000',
              },
            },
          ],
        ];
  
        let tableOptions = {
          startY: ommitInstructions ? currentY + 8 : undefined,
          tableLineColor: '#FF5700',
          tableLineWidth: 0.5,
          styles: {
            cellPadding: 5,
            valign: 'middle',
            fontSize: 12,
            textColor: [0, 0, 0], // Black text color
          },
          body: rows,
          didDrawCell: (imageSrc => function (data) {
            const doc = data.doc;
            
            if (data.row.index === 1 && data.column.index === 0) {
              const { x, y, width, height } = data.cell;
              const imgWidth = height - 4;
              const imgX = x + width - imgWidth - 2;
              doc.addImage(imageSrc, 'png', imgX, y + 1, 4, 4);
            } else if (data.row.index === 3 && data.column.index === 0) {
              const { x, y } = data.cell;
  
              doc.setTextColor(5, 99, 193);
              doc.textWithLink('click here', x + 155, y + 7, { url: 'https://help.pageoptimizer.pro/article/what-eeat-signals-look-like' });
            }
          })(this.eeatWorldIcon),
        };
  
        doc.autoTable(tableOptions);
  
        rows = this.getValidSchemas(this.mainPageSchemaTypes(responseData.data)).concat(this.missingSignals(responseData.data))
        .map((row) => ({
          name: {
            content: row.signal,
            styles: {
              halign: 'left',
              valign: 'middle',
              fillColor: '#FF5700',
              fontSize: 12,
              fontStyle: 'bold',
              textColor: '#FFFFFF',
            },
          },
          description: {
            content: this.descriptionObj?.[row.signal] || '',
            styles: {
              fillColor: '#FFEDDD',
              fontSize: 12,
              fontStyle: 'normal',
              valign: 'middle',
              halign: 'left',
              cellPadding: { top: 5, right: 5, bottom: 5, left: 5 },
            },
          },
          status: {
            content: row.result,
            styles: {
              fillColor: row.result ? '#D7F4E8' : '#FFC4C4',
              fontSize: 8.7,
              fontStyle: 'bold',
              valign: 'middle',
              halign: 'center',
              textColor: '#FFFFFF',
            },
          },
        }));
  
        // Define the styles for the table
        tableOptions = {
          startY: doc.lastAutoTable.finalY,
          tableLineColor: '#FF5700',
          tableLineWidth: 0.5,
          head: [
            [
              {
                content: 'Name',
                styles: {
                  fillColor: '#FF5700',
                  textColor: '#FFFFFF',
                  fontSize: 12,
                  fontStyle: 'bold',
                  halign: 'left',
                },
              },
              {
                content: 'Description',
                styles: {
                  fillColor: '#FF5700',
                  textColor: '#FFFFFF',
                  fontSize: 12,
                  fontStyle: 'bold',
                  halign: 'left',
                },
              },
              {
                content: 'Status',
                styles: {
                  fillColor: '#FF5700',
                  textColor: '#FFFFFF',
                  fontSize: 12,
                  fontStyle: 'bold',
                  halign: 'left',
                },
              },
            ],
          ],
          body: rows.map((row) => [
            row.name.content,
            row.description.content ? row.description.content + "*" : "",
            {
              content: row.status.content,
              styles: {
                ...row.status.styles,
                // minCellHeight: 18,
                // minCellWidth: 38,
                margin: 2,
                padding: 4,
                textAlign: 'center',
                borderRadius: 10,
                lineHeight: 1.2,
              },
            },
          ]),
          styles: {
            cellPadding: 3.5,
            fontSize: 12,
            textColor: [0, 0, 0],
            fontStyle: 'normal',
          },
          columnStyles: {
            0: {
              fillColor: '#FF5700',
              textColor: '#FFFFFF',
              fontSize: 12,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'middle',
            },
            1: {
              fillColor: '#FFEDDD',
              fontSize: 12,
              fontStyle: 'normal',
              valign: 'middle',
              halign: 'left',
            },
            2: {
              fontSize: 8.7,
              fontStyle: 'bold',
              valign: 'middle',
              halign: 'center',
              textColor: '#FFFFFF',
            },
          },
          didDrawCell: ((addImage, presentImage) => function (data) {
            const doc = data.doc;
            const cell = data.cell;
  
            if (
              data.column.dataKey === 2 &&
              data.row.section === 'body' &&
              data.row.index >= 0
            ) {
              // Draw the custom component (pill shape with check icon)
              const centerX = cell.x + cell.width / 2;
              const centerY = cell.y + cell.height / 2;
              const isCheck = data.row?.raw?.[2]?.content;
  
              // Draw the pill shape
              const pillWidth = isCheck ? 18 : 14;
              const pillHeight = 7;
              doc.setFillColor(isCheck ? '#25A97A' : '#FF3939');
              doc.roundedRect(
                centerX - pillWidth / 2,
                centerY - pillHeight / 2,
                pillWidth,
                pillHeight,
                pillHeight / 2,
                pillHeight / 2,
                'F',
              );
  
              if (isCheck) {
                doc.addImage(presentImage, 'png', (centerX - pillWidth / 2) + 1, (centerY - pillHeight / 2) + 2, 3, 3);
              } else {
                doc.addImage(addImage, 'png', (centerX - pillWidth / 2) + 2, (centerY - pillHeight / 2) + 2, 3, 3);
              }
  
  
              // Draw the check icon
              doc.setTextColor('#FFFFFF');
              doc.setFontSize(8.7);
              doc.text(isCheck ? 'Present' : 'Add', centerX + (isCheck ? 1.5 : 2), centerY + 1.2, {
                align: 'center',
              });
            }
  
            // Set custom border colors for each side
            let borderColorTop = '#FF5700';
            let borderColorBottom = '#FF5700';
            const borderColorRight = '#FF5700';
            const borderColorLeft = '#FF5700';
  
            if (!data.column.dataKey) {
              if (data.row.section !== 'head') borderColorTop = '#FFFFFF';
              borderColorBottom = '#FFFFFF';
            }
  
            doc.setLineWidth(0.3);
  
            // Draw top border
            doc.setDrawColor(borderColorTop);
            doc.line(cell.x, cell.y, cell.x + cell.width, cell.y);
  
            // Draw right border
            doc.setDrawColor(borderColorRight);
            doc.line(
              cell.x + cell.width,
              cell.y,
              cell.x + cell.width,
              cell.y + cell.height,
            );
  
            // Draw bottom border
            doc.setDrawColor(borderColorBottom);
            doc.line(
              cell.x,
              cell.y + cell.height,
              cell.x + cell.width,
              cell.y + cell.height,
            );
  
            // Draw left border
            doc.setDrawColor(borderColorLeft);
            doc.line(cell.x, cell.y, cell.x, cell.y + cell.height);
          })(this.eeatKeywordWarningIcon, this.eeatKeywordPresentIcon),
        };
  
        doc.autoTable(tableOptions);
  
        rows = [
          [
            {
              content: '*Usually, the signal is detected in these elements. We recommend incorporating them accordingly. However, our AI can identify these signals even if they are implemented unconventionally.',
              styles: { halign: 'center', valign: 'middle', fontStyle: 'italic', fillColor: null },
            },
          ],
        ];
  
        tableOptions = {
          startY: doc.lastAutoTable.finalY + 2,
          startX: 20,
          body: rows,
          columnStyles: { 0: { cellWidth: 170 } }
        };
        doc.autoTable(tableOptions);
  
        const timestamp = moment().format('YYYY_M_D_h_mm_ss');
        doc.save(`google_eeat_${timestamp}.pdf`);
      })
    },
    createEEATDocx(ommitInstructions = false) {
      this.getReportData(this.siloKeyword.id, 'eeat').then((responseData) => {
        const data = this.getValidSchemas(this.mainPageSchemaTypes(responseData.data)).concat(this.missingSignals(responseData.data))
        const doc = new Document({
          styles: { default: { document: { run: { font: "Arial" } } } },
          sections: [
            {
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: responseData.headerImg,
                      transformation: {
                        width: 600,
                        height: 230,
                      },
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                  spacing: {
                    line: 240,
                    after: 480,
                  },
                }),
  
                // Second Table: One row, one column, with bullet points
                ...!ommitInstructions
                  ?[
                    new Table({
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "💡 Instructions",
                                      color: "#FFFFFF",
                                      bold: true,
                                      size: 38,
                                    }),
                                  ],
                                  heading: HeadingLevel.HEADING_1,
                                  alignment: AlignmentType.CENTER,
                                  spacing: {
                                    before: 120,
                                    after: 120,
                                    afterAutoSpacing: true,
                                  },
                                }),
                              ],
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              verticalAlign: "center",
                              shading: {
                                fill: "#FF5700",
                              },
                              margins: {
                                top: convertInchesToTwip(0.1),
                                bottom: convertInchesToTwip(0.1),
                                left: convertInchesToTwip(0.2),
                                right: convertInchesToTwip(0.2),
                              },
                              borders: {
                                top: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                bottom: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                left: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                right: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                              },
                            }),
                          ],
                        }),
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "While ticking off Google’s EEAT items (as addressed in",
                                    }),
                                    new TextRun({
                                      text: " Google’s Quality Rater Guidelines",
                                      bold: true,
                                      color: "#FF5700"
                                    }),
                                    new TextRun({
                                      text: ") on your website may not directly benefit rankings, by missing too many of these signals you ",
                                    }),
                                    new TextRun({
                                      text: "risk being ignored by Google",
                                      bold: true
                                    }),
                                    new TextRun({
                                      text: ", which will obviously affect your ability to generate SEO traffic.",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "In essence, Google is trying to decipher whether you are a",
                                    }),
                                    new TextRun({
                                      text: " real business ",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: ", whether you are who you say you are and that you can be trusted to deliver what you say you are going to deliver.",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "We have found the EEAT signals below on",
                                    }),
                                    new TextRun({
                                      text: " your top competitor’s ",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: "websites and have tagged them as being either present or missing from your own website.",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: "We have given a description of each signal and your job is to try and",
                                    }),
                                    new TextRun({
                                      text: " implement ",
                                      bold: true,
                                    }),
                                    new TextRun({
                                      text: "as many of the missing signals as possible. Most of them are very easy and can be knocked off in a matter of minutes.",
                                    }),
                                    new TextRun({
                                      text: ".",
                                    }),
                                  ],
                                  bullet: {
                                    level: 0,
                                  },
                                  spacing: {
                                    before: 240,
                                  },
                                }),
                              ],
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              verticalAlign: "",
                              shading: {
                                fill: "#FEF7EF",
                              },
                              margins: {
                                top: convertInchesToTwip(0.15),
                                left: convertInchesToTwip(0.15),
                                bottom: convertInchesToTwip(0.15),
                                right: convertInchesToTwip(0.15),
                              },
                              borders: {
                                top: {
                                  color: "#FF5700",
                                  size: 5,
                                },
                                bottom: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                left: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                                right: {
                                  style: BorderStyle.SINGLE,
                                  color: "#FF5700",
                                  size: 5,
                                },
                              },
                            }),
                          ],
                        }),
                      ],
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      columnWidths: [9000, 3300],
                    }),
                  ]
                  :[],
                new Paragraph({ children: [new TextRun({ text: "  " })] }),
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  font: "Arial",
                                  text: "Your site's EEAT audit",
                                  size: "20pt",
                                  bold: true
                                })
                              ],
                              spacing: {
                                before: 60,
                                after: 120,
                              }
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: " "
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new ExternalHyperlink({
                                  children: [
                                    new ImageRun({
                                      data: responseData.data.siteIcon,
                                      transformation: {
                                        width: 10,
                                        height: 10,
                                      },
                                    }),
                                    new TextRun({
                                      text: " "
                                    }),
                                    new TextRun({
                                      font: "Arial",
                                      text: this.extractHostnameWithProtocol(responseData.data.pageRunData.url),
                                      style: "Hyperlink",
                                      bold: true,
                                      color: "#FF5700"
                                    })
                                  ],
                                  link: this.extractHostnameWithProtocol(responseData.data.pageRunData.url)
                                })
                              ],
                              spacing: {
                                before: 60,
                                after: 120,
                              }
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: " "
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  font: "Arial",
                                  text: "We have found these EEAT signals on your top competitors' sites. The ones marked ‘Add’ have not been found on your site and as many as possible should be added before you begin publishing new content.",
                                }),
                              ],
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: " "
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  font: 'Arial',
                                  text: 'For instructions on what each of the EEAT signals looks like on the page, ',
                                }),
                                new ExternalHyperlink({
                                  children: [
                                    new TextRun({
                                      font: 'Arial',
                                      text: 'click here',
                                      style: 'Hyperlink',
                                      color: "#FF5700"
                                    }),
                                  ],
                                  link: 'https://help.pageoptimizer.pro/article/what-eeat-signals-look-like',
                                }),
                              ],
                              spacing: {
                                before: 120,
                                after: 120,
                              }
                            })
                          ],
                          verticalAlign: 'center',
                          width: {
                            size: 100,
                            type: WidthType.PERCENTAGE,
                          },
                          shading: {
                            fill: "#FEF7EF",
                          },
                          margins: {
                            top: convertInchesToTwip(0.20),
                            left: convertInchesToTwip(0.20),
                            bottom: convertInchesToTwip(0.20),
                            right: convertInchesToTwip(0.20),
                          },
                          borders: {
                            top: {
                              color: "#FF5700",
                              style: BorderStyle.SINGLE,
                              size: 5,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                          }
                        }),
                        
                      ],
                    }),
                  ],
                  columnWidths: [11000],
                }),
  
                new Paragraph({
                  children: [
                    new PageBreak()
                  ]
                }),
  
                // Third Table: 3 columns, 3 rows
                new Table({
                  rows: [
                    // Header row
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({ font: "Arial", text: "Name", bold: true, color: "#FFFFFF" }),
                              ],
                            }),
                          ],
                          width: { size: 15, type: "pct" },
                          shading: {
                            fill: "#FF5700"
                          },
                          borders: {
                            top: {
                              color: "#FF5700",
                              style: BorderStyle.SINGLE,
                              size: 5,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 5,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                          },
                          margins: {
                            top: convertInchesToTwip(0.10),
                            left: convertInchesToTwip(0.10),
                            bottom: convertInchesToTwip(0.10),
                            right: convertInchesToTwip(0.10),
                          },
                          verticalAlign: "center",
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({ font: "Arial", text: "Description", bold: true, color: "#FFFFFF" }),
                              ],
                            }),
                          ],
                          width: { size: 70, type: "pct" },
                          shading: {
                            fill: "#FF5700"
                          },
                          borders: {
                            top: {
                              color: "#FF5700",
                              style: BorderStyle.SINGLE,
                              size: 5,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 5,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                          },
                          margins: {
                            top: convertInchesToTwip(0.10),
                            left: convertInchesToTwip(0.10),
                            bottom: convertInchesToTwip(0.10),
                            right: convertInchesToTwip(0.10),
                          },
                          verticalAlign: "center",
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({ font: "Arial", text: "Status", bold: true, color: "#FFFFFF" }),
                              ],
                            }),
                          ],
                          width: { size: 15, type: "pct" },
                          shading: {
                            fill: "#FF5700"
                          },
                          borders: {
                            top: {
                              color: "#FF5700",
                              style: BorderStyle.SINGLE,
                              size: 5,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 5,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                          },
                          margins: {
                            top: convertInchesToTwip(0.10),
                            left: convertInchesToTwip(0.10),
                            bottom: convertInchesToTwip(0.10),
                            right: convertInchesToTwip(0.10),
                          },
                          verticalAlign: "center",
                        }),
                      ],
                    }),
  
                    ...data.map((row) => {
                      return row.signal && (new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    font: "Arial",
                                    text: row.signal,
                                    bold: true,
                                    color: "#FFFFFF"
                                  }),
                                ],
                              }),
                            ],
                            shading: {
                              fill: "#FF5700"
                            },
                            verticalAlign: 'center',
                            margins: {
                              top: convertInchesToTwip(0.10),
                              left: convertInchesToTwip(0.10),
                              bottom: convertInchesToTwip(0.10),
                              right: convertInchesToTwip(0.10),
                            },
                            borders: {
                              top: {
                              color: "#FFFFFF",
                              style: BorderStyle.SINGLE,
                              size: 5,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FFFFFF",
                              size: 10,
                            },
                            }
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                font: "Arial",
                                text: this.descriptionObj?.[row.signal] ? this.descriptionObj?.[row.signal] + "*" : '',
                              }),
                            ],
                            shading: {
                              fill: "#FFEDDD"
                            },
                            margins: {
                              top: convertInchesToTwip(0.10),
                              left: convertInchesToTwip(0.10),
                              bottom: convertInchesToTwip(0.10),
                              right: convertInchesToTwip(0.10),
                            },
                            borders: {
                              top: {
                              color: "#FF5700",
                              style: BorderStyle.SINGLE,
                              size: 5,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            }
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                font: "Arial",
                                children: [
                                  new ImageRun({
                                    data: row.result ? responseData.data.presentIcon : responseData.data.addIcon,
                                    transformation: {
                                      width: row.result ? 80 : 60,
                                      height: 25,
                                    },
                                  }),
                                ],
                              }),
                            ],
                            verticalAlign: 'center',
                            shading: {
                              fill: row.result ? "#D7F4E8" : "#FFC4C4"
                            },
                            margins: {
                              top: convertInchesToTwip(0.10),
                              left: convertInchesToTwip(0.10),
                              bottom: convertInchesToTwip(0.10),
                              right: convertInchesToTwip(0.10),
                            },
                            borders: {
                              top: {
                              color: "#FF5700",
                              style: BorderStyle.SINGLE,
                              size: 5,
                            },
                            bottom: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            left: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            right: {
                              style: BorderStyle.SINGLE,
                              color: "#FF5700",
                              size: 5,
                            },
                            }
                          }),
                        ],
                      }))
                    }),
                  ],
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  columnWidths: [2200, 6600, 2200],
                }),
  
                new Paragraph({
                  text: "",
                  heading: HeadingLevel.HEADING_2,
                }),
  
                new Paragraph({
                  children: [
                    new TextRun({
                      size: 18,
                      font: "Arial",
                      text: "*Usually, the signal is detected in these elements. We recommend incorporating them accordingly. However, our AI can identify these signals even if they are implemented unconventionally.",
                      italics: true,
                    }),
                  ],
                }),
              ],
            },
          ],
        });
  
        const mimeType =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        const timestamp = moment().format('YYYY_M_D_h_mm_ss');
        const fileName = `google_eeat_${timestamp}.docx`;
        Packer.toBlob(doc).then((blob) => {
          const docblob = blob.slice(0, blob.size, mimeType);
          saveAs(docblob, fileName);
        });
      })
    },
    generateSchemasPDF(ommitInstructions = false) {
      this.getReportData(this.siloKeyword.id, 'schema').then((responseData) => {
        const schemas = responseData.data.schemas;
        const doc = new jsPDF();
        doc.setDrawColor(0);
        doc.setFillColor(1, 35, 35);
        const startX = 14;
        let currentY = 10;
        doc.addImage(responseData.headerImg, 'png', startX, currentY, 182, 71);
        currentY += 70;

        if (!ommitInstructions) {
          const instructionsBullets = [
            'POP analyzes the schema used on top competing pages.',
            'We then filter and build the optimal custom schema for you to add to the page.',
            ''
          ];
  
          let rows = [
            [
              {
                content: '',
                styles: {
                  minCellWidth: 40,
                  minCellHeight: 20,
                  cellPadding: 1,
                  halign: 'right',
                  valign: 'middle',
                  fillColor: '#FF5700',
                  textColor: '#FFFFFF',
                },
              },
              {
                content: 'Add Schema to this page!',
                styles: {
                  minCellWidth: 40,
                  minCellHeight: 20,
                  cellPadding: 1,
                  halign: 'left',
                  valign: 'middle',
                  fillColor: '#FF5700',
                  fontSize: 22.65,
                  fontStyle: 'bold',
                  textColor: '#FFFFFF',
                },
              },
            ],
            [
              {
                colSpan: 2,
                content: instructionsBullets.map((bullet) => `${bullet}`).join('\n'),
                styles: {
                  halign: 'center',
                  fillColor: '#FFEDDD',
                  fontSize: 12,
                  lineHeight: 20,
                  fontStyle: 'normal',
                  textColor: '#000000',
                },
              },
            ],
          ];
          let instructionsTableOptions = {
            startY: currentY + 8,
            tableLineColor: '#FF5700',
            tableLineWidth: 0.5,
            styles: {
              cellPadding: 5,
              valign: 'middle',
              fontSize: 12,
              textColor: [0, 0, 0],
            },
            body: rows,
            didDrawCell: (imageSrc => function (data) {
              const doc = data.doc;
              
              if (data.row.index === 0 && data.column.index === 0) {
                const { x, y, width, height } = data.cell;
                const imgWidth = height - 4;
                const imgX = x + width - imgWidth - 2;
                doc.addImage(imageSrc, 'png', imgX + 8, y + 4, 10, 10);
              }
              if (data.row.index === 1 && data.column.index === 0) {
                // First part of the text
                const textBeforeLink = 'For instructions on how to publish schema to your page, ';
                const linkText = 'click here.';
                const linkY = data.cell.y + data.cell.height - 6;
                const cellWidth = data.cell.width;
                const totalTextWidth = doc.getTextWidth(textBeforeLink) + doc.getTextWidth(linkText);
                const centeredX = data.cell.x + (cellWidth - totalTextWidth) / 2; // Center the total text width
  
                // Add the first part of the text
                doc.text(textBeforeLink, centeredX, linkY); 
  
                // Calculate the width of the text before the link
                const linkStartX = centeredX + doc.getTextWidth(textBeforeLink); 
                
                // Add the link text
                doc.setTextColor(255, 87, 0);
                doc.text(linkText, linkStartX, linkY); 
  
                const underlineY = linkY + 1;
                const linkWidth = doc.getTextWidth(linkText);
                const lineHeight = 0.5;
                doc.setDrawColor(255, 87, 0);
                doc.setLineWidth(lineHeight);
                doc.line(linkStartX, underlineY, linkStartX + linkWidth, underlineY);
  
                // Create the clickable link
                doc.link(linkStartX, linkY - 10, doc.getTextWidth(linkText), 10, { url: 'https://help.pageoptimizer.pro/article/how-to-add-pageoptimizer-pros-schema-to-your-pages' }); // Replace with your actual URL
              }
            })(this.bulbIcon),
          };
  
          doc.autoTable(instructionsTableOptions);
        }
        

        let rows = schemas
          .filter((schema)=>schema.schema)
          .map((schema) => {
          return [
            [
              {
                content: schema.type,
                styles: {
                  fillColor: '#FFEDDD',
                  fontSize: 22.65,
                  fontStyle: 'bold',
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: schema.schema,
                styles: {
                  fillColor: '#FFEDDD',
                  fontSize: 12,
                  textColor: '#000000',
                },
              },
            ],
          ]
        }).flat()


        let tableOptions = {
          startY: ommitInstructions ? currentY + 8 : undefined,
          tableLineColor: '#FF5700',
          tableLineWidth: 0.5,
          styles: {
            cellPadding: 5,
            valign: 'middle',
            fontSize: 12,
            textColor: [0, 0, 0], // Black text color
          },
          body: rows,
        };

        doc.autoTable(tableOptions);

        const timestamp = moment().format('YYYY_M_D_h_mm_ss');
        doc.save(`combined_schema_report_${timestamp}.pdf`);
        })
    },
    generateSchemasJSONReport() {
      this.getReportData(this.siloKeyword.id, 'schema').then((responseData) => {
        generateSchemasJSON(responseData)
      })
    },
    getMethod(name, params) {
      const func = {
        generateTopLevelPDFBriefReport: this.getSiloTopLevelReport,
        generateTopLevelWordBriefReport: this.generateTopLevelWordBriefReport,
        generateOutlinePDF: this.getSiloTopLevelOutlineReport,
        generateOutlineWord: this.generateOutlineWord,
        generateAIDraftPDF: this.generateAIDraftPDF,
        generateAIPageDraftWord: this.generateAIPageDraftWord,
        generateHumanEditedPDF: this.generateHumanEditedPDF,
        generateEditedPageDraftWord: this.generateEditedPageDraftWord,
        gotoAiRun: this.gotoAiRun,
        generateEEATPDF: this.generateEEATPDF,
        createEEATDocx: this.createEEATDocx,
        generateSchemasPDF: this.generateSchemasPDF,
        generateSchemasJSONReport: this.generateSchemasJSONReport,
        gotoContentEditor: this.gotoContentEditor,
      }[name];
      func?.apply(this, params || []);
    },
    loadSiloData() {
      this.$emit('refreshData');
    }
  },
  beforeMount() {
    this.descriptionObj = explainData
  },
  mounted() {
    getBase64FromUrl(eeatPdfHeader, (base64) => {
      this.eeatReportLogo = base64;
    });
    getBase64FromUrl(eeatWorldIcon, (base64) => {
      this.eeatWorldIcon = base64;
    });
    getBase64FromUrl(eeatKeywordWarningIcon, (base64) => {
      this.eeatKeywordWarningIcon = base64;
    });
    getBase64FromUrl(eeatKeywordPresentIcon, (base64) => {
      this.eeatKeywordPresentIcon = base64;
    });
    getBase64FromUrl(bulbIcon, (base64) => {
      this.bulbIcon = base64;
    });
    getBase64FromUrl(wordDocHeadImg, (base64) => {
      this.wordDocHeadImg = base64;
    });
    getBase64FromUrl(pdfHeaderLogo, (base64) => {
      this.schemasReportLogo = base64;
    });
  },
};
</script>

<style scoped>
.basic-divider {
  border-top: 1px solid var(--v-grayColor-base);
  break-after: always;
}

.box-wh35:has(.menu-img) {
  width: 30px;
  height: 30px;
  background-color: var(--v-lightYellowColor-base);
  padding: 6px;
}

.box-wh35:has(.menu-icon) {
  background-color: var(--v-lightYellowColor-base);
  border-radius: 2px;
  color: var(--v-profileAnchorColor-base);
  padding: 3px;
}

.url-link {
  margin-left: 5px;
  font-weight: 500;
  color: #0a6cdb;
  text-decoration: underline;
}
</style>
