<template>
    <div>
      {{ toneOfContent || author || brand ? 'Set' : 'Not set' }}
    </div>
</template>

<script>
import {
  STEPS,
} from "@/components/FullPageSourceEditorV2/components/AiWriter/constants.js";

export default {
    name: 'ConfigPreviewTone',
    props: [
        'toneOfContent',
        'author',
        'brand',
    ],
    computed: {
        STEPS() {
          return STEPS
        },
    }
}
</script>

<style lang="scss" scoped>

</style>